<template>
  <div>
    <ys-page-list
        ref="table"
        action="/projects/List"
        :IDs="IDs"
        :multiColumn="false"
        :isEmptySelect="isEmptySelect"
        :params="params"
        :tableHeight="height-260"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="所在地区" prop="region">
          <ysRegion v-model="params.region" @on-change="getRegion"></ysRegion>
        </FormItem>
        <FormItem label="形象进度" prop="progress">
          <Select v-model="params.progress" transfer clearable placeholder="形象进度" style="width: 100px;">
            <Option v-for="(item,index) in baseData['施工阶段']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="关键词" clearable style="width: 150px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
    </ys-page-list>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok">确定</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList';

export default {
  name: 'pro-component',
  mixins: [pageList],
  props: {
    hasAzx: {type: Number, default: null},
    limit: {type: Number, default: 0},
    IDs: {type: Array, default: () => []},
  },
  computed: {
    isEmptySelect() {
      return this.limit === 1;
    },
  },
  data() {
    return {
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {minWidth: 230, title: '项目名称', key: 'iname', align: 'left'},
        {width: 120, title: '工程类型', key: 'category', align: 'left', renderConfig: {type: 'baseData', parentName: '项目类型'}},
        {width: 220, title: '施工单位', key: 'contractName', align: 'left'},
        {minWidth: 220, title: '形象进度', key: 'progress', align: 'left', renderConfig: {type: 'baseData', parentName: '施工阶段'}},
        {width: 200, title: '项目所在地', key: 'address', align: 'left'},
        {minWidth: 220, title: '承保机构', key: 'policyOrgName', align: 'left'},
      ],
      modal: '',
      params: {
        hasAzx: '',
        countyCode: '',
        cityCode: '',
        provinceCode: '',
        progress: '',
        keyword: '',
      },
    }
  },
  mounted() {
    this.modal = this.$parent.$parent;
    this.params.hasAzx = this.hasAzx;
    this.getList();
  },
  methods: {
    getRegion(data) {
      this.params.countyCode = data[2] ? data[2].code : '';
      this.params.cityCode = data[1] ? data[1].code : '';
      this.params.provinceCode = data[0] ? data[0].code : '';
    },
    close() {
      this.modal.modShow = false;
    },

    ok() {
      if (this.tableSelectItem.length === 0) {
        this.$Notice.warning({
          title: "操作提示！",
          desc: "请选择至少一条数据！"
        });
        return false;
      }
      if (this.limit !== 0) {
        if (this.tableSelectItem.length > this.limit) {
          this.$Notice.warning({
            title: "操作提示！",
            desc: "最多可选择 " + this.limit + ' 条数据！',
          });
          return false;
        }
      }
      this.$emit('on-ok', this.tableSelectItem);
      this.close();
    },
  }
}
</script>
