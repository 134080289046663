<template>
  <ys-detail :icon="detailIcon" :loading="loading" :name="detail.iname">
    <Tag size="medium" color="blue" slot="status">{{ detail.status|getBaseDataName('整改单状态') }}</Tag>
    <template slot="brief">
      <ys-cell title="整改单编号" :label="detail.code"/>
      <ys-cell title="整改单标题" :label="detail.iname"/>
      <ys-cell title="项目名称" :label="detail.projectName"/>
      <ys-cell title="服务单位" :label="detail.serverOrgName"/>
    </template>
    <template slot="main-left">
      <Tabs v-model="tab" name="detail" type="card">
        <Tab-pane label="风险列表" name="1" icon="md-list-box">
          <problems type="amend" :tableHeight="pageListHeight" :mid="detail.checkId" v-if="tab==='1'&&detail.checkId"></problems>
        </Tab-pane>
        <Tab-pane label="处理专家" name="3" icon="ios-contacts">
          <experts :tableHeight="pageListHeight" :midData="[detail]" :mid="detail.checkId" v-if="tab==='3'&&detail.checkId"></experts>
        </Tab-pane>
        <Tab-pane label="相关附件" name="6" icon="md-document">
          <ys-resource :resourceType="1" :tableHeight="pageListHeight" :mid="detailID" v-if="tab==='6'"></ys-resource>
        </Tab-pane>
      </Tabs>
    </template>
    <template slot="main-right">
      <h1 class="h1Title">其他信息</h1>
      <div style="text-align: center">暂未开放！</div>
    </template>
  </ys-detail>
</template>

<script>
import detailMixins from '@/mixins/detail'
import {getAmendInfo} from "@/api/task";
import {getProblemsList} from "@/api/problem";
import experts from "@/views/task/list/detail/experts";
import problems from "@/views/task/problems";

export default {
  name: "detail",
  components: { experts, problems},
  mixins: [detailMixins],
  data() {
    return {
      commentList: [],
    }
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.loading = true;
      getProblemsList({amendId: this.detailID}).then((res) => {
        this.commentList = res.data.list || []
      })
      getAmendInfo({id: this.detailID}).then(res => {
        this.loading = false;
        if (res.code === 200) {
          this.detail = res.data;
        }
        this.setTagName(this.detail.number)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.detailMain {
  padding-top: 10px;
}
</style>