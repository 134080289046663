<template>
  <div>
    <Form ref="form" class="editForm" :model="form" :rules="ruleValidate" :label-width="80">
      <FormItem label="等级名称" prop="name" style="width: 100%;">
        <Input v-model="form.name" placeholder="等级名称"> </Input>
      </FormItem>
      <FormItem label="默认配置" prop="default" >
        <i-switch v-model="form.default" :true-value="1" :false-value="0" />
      </FormItem>
      <FormItem label="规则配置" prop="rule" style="width: 100%;">
        <Input v-model="form.rule" type='textarea' placeholder="规则配置"></Input>
        <ul class="level">
          <li v-for="(item,index) in levelParams" :class="{['uuid'+index]:true}" :data-clipboard-text="item" class="uuid" :key="index" @click="copy(index)">
            {{ item }}
          </li>
        </ul>
      </FormItem>

    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="save">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>
<script>
import edit from '@/mixins/edit.js'
import Clipboard from 'clipboard';
import {uuid} from 'ys-admin'

export default {
  name: '',
  mixins: [edit],
  data() {
    return {
      levelParams: [],
      ruleValidate: {},
      form: {
        name: '',
        default: 0,
        index: '',
        rule: '',
      }
    }
  },
  mounted() {
    this.baseData['风险等级'].forEach(item => {
      this.levelParams.push(item.name + '.数量')
      this.levelParams.push(item.name + '.占比')
    });
    this.levelParams.push('隐患总数');
    this.levelParams.push('总分');
    if (this.midData.length > 0) {
      this.form.name = this.midData[0].name;
      this.form.default = this.midData[0].default;
      this.form.rule = this.midData[0].rule;
      this.form.index = this.midData[0].index;
    } else {
      this.form.index = uuid()
    }
  },
  methods: {
    copy(index) {
      let clipboard = new Clipboard('.uuid' + index)
      clipboard.on('success', () => {
        this.$Message.success('复制成功！');
        // 释放内存
        clipboard.destroy();
      })
      clipboard.on('error', () => {
        // 不支持复制
        this.$Message.error('该浏览器不支持自动复制')
        // 释放内存
        clipboard.destroy()
      })
      this.form.rule = this.levelParams[index]
    },
    save() {
      this.$emit('on-ok', this.form);
      this.close();
    },
  }
}
</script>
<style lang="less" scoped>
.level {
  overflow: hidden;

  li {
    font-size: 12px;
    margin: 3px;
    padding: 3px 5px;
    height: 26px;
    line-height: 16px;
    border: 1px solid #ececec;
    background: #f4f4f4;
    float: left;
    cursor: pointer;
    list-style: none;
  }
}

</style>
