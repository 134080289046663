<template>
  <div>
    <Split v-model="split" :style="{height:'calc(100vh - 200px)'}" style="overflow-y: auto;">
      <div slot="left" class="splitPaneLeft">
        <Form ref="form" class="editForm" :model="form" :rules="ruleValidate" :label-width="80">
          <FormItem label="角色名称" prop="iname">
            <Input v-model="form.iname" placeholder="角色名称"/>
          </FormItem>
          <FormItem label="角色类型" prop="type">
            <Select v-model="form.type" @on-change="roleTypeChange" transfer placeholder="角色类型">
              <Option :value="0">系统默认角色</Option>
              <Option :value="1">平台角色</Option>
            </Select>
          </FormItem>
          <FormItem label="平台类型" prop="category">
            <Select v-model="form.category" :disabled="!!form.id" @on-change="getBaseMenuList" transfer placeholder="平台类型">
              <Option v-for="item in baseData['平台类型']" :value="item.value" :key="item.value">{{ item.name }}</Option>
            </Select>
          </FormItem>
          <FormItem label="所属机构" prop="orgName" v-if="form.type===1">
            <Input v-model="form.orgName" disabled placeholder="不选择则为系统级角色">
              <Button slot="append" @click="orgFlag=true">选择机构</Button>
            </Input>
          </FormItem>
        </Form>
      </div>
      <div slot="right" class="splitPaneRight">
        <Split v-model="menuSplit">
          <div slot="left" class="menuSplitLeft">
            <h1 class="h1Title">菜单权限</h1>
            <div class="operationBtn">
              <ButtonGroup>
                <Button @click="check('all')">全选</Button>
                <Button @click="check('cancel')">取消全选</Button>
              </ButtonGroup>
              <ButtonGroup>
                <Button @click="check('reverse')">反选</Button>
              </ButtonGroup>
            </div>
            <Tree ref="menuTree"
                  empty-text="暂无数据，请在左侧切换平台类型！"
                  :data="menuList"
                  show-checkbox multiple check-strictly
                  :style="{height:'calc(100vh - 250px)'}"
                  style="padding-top:30px;overflow-y: auto;"></Tree>
          </div>
          <div slot="right" class="menuSplitRight">
            <h1 class="h1Title">操作权限</h1>
            <table cellpadding="0" cellspacing="1" border="0" width="100%" class="baseTable">
              <tr>
                <th width="25%">菜单名称</th>
                <th width="75%">操作权限</th>
              </tr>
              <tr v-for="(item,index) in ruleList" :key="index">
                <td align="center">
                  <Checkbox v-model="item.checked" :indeterminate="item.indeterminate" @on-change="groupChange($event,index)">{{ item.menuName }}</Checkbox>
                </td>
                <td>
                  <CheckboxGroup v-model="form.ruleIds" @on-change="ruleChange(index)">
                    <Checkbox :label="subItem.id" v-for="subItem in item.ruleList" :key="subItem.id">{{ subItem.iname }}</Checkbox>
                  </CheckboxGroup>
                </td>
              </tr>
            </table>
          </div>
        </Split>
      </div>
    </Split>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="save">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
    <ys-modal v-model="orgFlag" :width="1200" title="选择机构">
      <org-component :oType="oTypeFn" :limit="1" @on-ok="getData"></org-component>
    </ys-modal>
  </div>
</template>
<script>
import edit from '@/mixins/edit'
import {getRoleInfo} from "@/api/base";
import OrgComponent from "_c/org";

export default {
  name: 'projectEdit',
  components: {OrgComponent},
  mixins: [edit],
  props: {oType: {type: Number, default: 1}},
  data() {
    return {
      ruleValidate: {
        iname: [
          {required: true, message: '请填写角色名称', trigger: 'blur'}
        ],
      },
      menuList: [],
      ruleList: [],
      menuAllList: [],
      split: 0.3,
      menuSplit: 0.30,
      orgFlag: false,
      childrenFlag: false,
      childrenData: [],
      form: {
        id: '',
        orgName: '',
        type: 1,
        orgId: 1,
        menuIds: [],
        ruleIds: [],
        iname: '',
        category: 1,
      },
    }
  },
  computed: {
    oTypeFn() {
      let category = ''
      switch(this.form.category) {
        case 1:
          category = 2
          break;
        case 2:
          category = 1
          break;
        case 3:
          category = 4
          break;
        case 4:
          category = 3
          break;
        case 6:
          category = 99
          break;
      }
      return category
    }
  },
  mounted() {
    if (this.mid) {
      this.modal.spinShow = true;
      getRoleInfo({id: this.mid}).then(res => {
        if (res.code === 200) {
          Object.keys(this.form).forEach((item) => {
            this.form[item] = res.data[item]
          })
          this.getBaseMenuList();
          this.form.type = this.form.orgId === 0 ? 0 : 1
          this.modal.spinShow = false;
        }
      })
    } else {
      this.getBaseMenuList();
    }
  },
  methods: {
    ruleChange(index) {
      let i = 0;
      let len = this.ruleList[index].ruleList.length;
      this.ruleList[index].ruleList.forEach(item => {
        if (this.form.ruleIds.indexOf(item.id) >= 0) i++;
      });
      this.ruleList[index].indeterminate = i > 0 && i < len;
      this.ruleList[index].checked = i === len;
    },
    groupChange($event, index) {
      this.ruleList[index].indeterminate = false;
      this.ruleList[index].ruleList.forEach(item => {
        let len = this.form.ruleIds.indexOf(item.id);
        if (!$event && len >= 0) {
          this.form.ruleIds.splice(len, 1);
        }
        if ($event && len < 0) {
          this.form.ruleIds.push(item.id)
        }
      })
    },
    deepDoTree(ary, type) {
      return ary.map(({...item}) => {
        switch (type) {
          case 'all':
            item.checked = true;
            break;
          case 'reverse':
            item.checked = !item.checked;
            break;
          case 'cancel':
            item.checked = false;
            break;
        }
        if (Array.isArray(item.children) && item.children.length > 0) {
          item.children = this.deepDoTree(item.children, type);
        }
        return item;
      })
    },
    roleTypeChange() {
      this.form.orgId = this.form.type === 0 ? 0 : 1;
    },
    check(type) {
      this.menuList = this.deepDoTree((this.menuList || []), type);
    },
    handleContextMenu(data) {
      if (data.children && data.children.length > 0) {
        this.childrenFlag = true;
        this.childrenData = data.children
      } else {
        this.childrenFlag = false;
        this.childrenData = []
      }
    },
    getBaseMenuList() {
      this.$get("/menu/RoleMenuListByAdm", {oType: this.form.category, roleId: this.mid}, {orgId: null}).then(res => {
        if (res.code === 200) {
          this.ruleList = [];
          this.form.ruleIds = [];
          this.menuAllList = res.data || [];
          this.menuAllList.forEach(item => {
            if (item.ruleList) {
              let currentRules = item.ruleList.filter(item => item.checked).map(item => item.id);
              this.form.ruleIds.push.apply(this.form.ruleIds, currentRules);
              this.ruleList.push({menuName: item.title, indeterminate: false, checked: currentRules.length === item.ruleList.length && item.ruleList.length > 0, ruleList: item.ruleList});
            }
          })
          this.menuList = this.$listToTree(this.menuAllList.filter(item => !item.hideInMenu), {parentKey: 'parentId', sonKey: 'id', opLevelVal: 0, addProperties: {expand: true, contextmenu: true}});
        }
      })
    },
    save() {
      const menuIds = this.$refs.menuTree.getCheckedNodes().filter(item => !item.hideInMenu).map(item => item.id);
      this.form.menuIds = [];
      menuIds.forEach(item => {
        this.form.menuIds.push.apply(this.form.menuIds, this.menuAllList.filter(sunItem => sunItem.relationId === item).map(sunItem => sunItem.id));
      })
      this.form.menuIds = this.form.menuIds.concat(menuIds);
      this.ok('/permission/role/AddOrEdit');
    },
    getData(data) {
      this.form.orgName = data.map((item) => {
        return item.iname
      }).join(',')
      this.form.orgId = data[0].id
    }
  }
}
</script>

<style lang="less" scoped>

</style>


