export const categoryList = [
  {
    label: '房屋建筑工程', value: '01', children: [
      {label: '综合类', value: '0101'},
      {label: '基坑内', value: '0102'},
      {label: '起重设备类', value: '0103'},
    ]
  },
  {
    label: '市政工程', value: '02', children: [
      {label: '建筑材料类', value: '0201'},
      {label: '起重设备类', value: '0202'},
      {label: '爬架类', value: '0203'},
    ]
  },
  {
    label: '其他', value: '99', children: [
      {label: '综合类', value: '9901'},
      {label: '基坑内', value: '9902'},
    ]
  },
  {label: '园林工程', value: '30',},
  {label: '装修工程', value: '31',},
]