<template>
  <div>
    <ys-page-list
        ref="table"
        :action="'/user/List'"
        :params="params"
        :showContextMenu="false"
        :multiColumn="false"
        :isEmptySelect="isEmptySelect"
        :tableHeight="height-260"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns">
      <template slot="search">
        <!-- <FormItem label="所在地区" prop="region"> -->
          <!-- <ys-region changeOnSelect v-model="params.region" @on-change="getRegion"></ys-region> -->
        <!-- </FormItem> -->
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="关键词" clearable style="width: 150px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
    </ys-page-list>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok">确定</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList';

export default {
  name: 'users-component',
  mixins: [pageList],
  props: {
    limit: {type: Number, default: 0},
    IDs: {type: Array, default: () => []},
    companyId: {
      type: [Number, String]
    }
  },
  computed: {
    isEmptySelect() {
      return this.limit === 1;
    },
  },
  data() {
    return {
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 80, align: 'center'},
        {width: 130, title: '姓名', key: 'iname', align: 'left'},
        {minWidth: 150, title: '所在机构', key: 'orgName', align: 'left'},
        {width: 130, title: '联系电话', key: 'phone', align: 'left'},
        {width: 180, title: '身份证号码', key: 'identityCode', align: 'left'},
        {width: 200, title: '邮箱', key: 'mail', align: 'left'},
        {width: 180, title: '登录账号', key: 'username', align: 'left'},
        {width: 180, title: '创建时间', key: 'createdAt', align: 'left'},
        {width: 180, title: '最近修改时间', key: 'updatedAt', align: 'left'},
      ],
      modal: '',
      params: {
        countyCode: '',
        isAdmin: 0,
        cityCode: '',
        provinceCode: '',
        orgId: '',
        keyword: '',
      },
    }
  },
  mounted() {
    this.params.hasAzx = this.hasAzx;
    this.params.type = this.type;
    this.params.types = this.types;
    this.params.oType = this.oType;
    this.params.orgId = this.companyId;
    this.getList();
    this.modal = this.$parent.$parent;
  },
  methods: {
    getRegion(data) {
      this.params.countyCode = data[2] ? data[2].code : '';
      this.params.cityCode = data[1] ? data[1].code : '';
      this.params.provinceCode = data[0] ? data[0].code : '';
    },
    close() {
      this.modal.modShow = false;
    },

    ok() {
      if (this.tableSelectItem.length === 0) {
        this.$Notice.warning({
          title: "操作提示！",
          desc: "请选择至少一条数据！"
        });
        return false;
      }
      if (this.limit !== 0) {
        if (this.tableSelectItem.length > this.limit) {
          this.$Notice.warning({
            title: "操作提示！",
            desc: "最多可选择 " + this.limit + ' 条数据！',
          });
          return false;
        }
      }
      this.$emit('on-ok', this.tableSelectItem);
      this.close();
    },
  }
}
</script>
