<template>
  <div>
    <ys-page-list
        ref="table"
        action="/standard/dictDetail/List"
        keyID="46676E5F596B11EC9C5C00163E15C775"
        :params="params"
        :tableHeight="height-250"
        :multiBtn="multiBtn"
        noDataText="请先选择左侧项"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="关键词" clearable style="width: 150px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template slot="btn">
        <ButtonGroup>
          <Button type="primary" @click="add" :disabled="!params.dictItemId">新增风险项</Button>
        </ButtonGroup>
      </template>
      <template slot="detail">
      </template>
    </ys-page-list>
    <ys-modal v-model="modShow" :width="600" title="新增/修改风险项">
      <editDetail :standardInfo="standardInfo" :midData="midData" @on-ok="getList" :mid="detailID"></editDetail>
    </ys-modal>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'

export default {
  name: 'detail-list',
  mixins: [pageList],
  props: {
    standardInfo: {type: Array, default: () => []},
  },
  components: {
    'editDetail': () => import('@/views/base/standard/editDetail.vue'),
  },
  data() {
    return {
      params: {
        dictItemId: '',
        keyword: '',
      },
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {width: 80, title: '序号', key: 'index', align: 'left'},
        {minWidth: 230, title: '风险项内容', key: 'desc', align: 'left'},
        {width: 150, title: '可能事故类型', key: 'accidentType', align: 'left'},
        {width: 120, title: '风险大项名称', key: 'dictItemName', align: 'left'},
        {width: 150, title: '风险表名称', key: 'dictName', align: 'left'},
        {width: 150, title: '风险等级', key: 'riskGrade', align: 'left', renderConfig: {type: 'baseData', parentName: '风险等级'}},
        {width: 150, title: '总项名称', key: 'categoryName', align: 'left'},
        {width: 110, title: '最大扣分值', key: 'maxScore', align: 'center'},
        {width: 110, title: '最小扣分值', key: 'minScore', align: 'center'},
      ]
    }
  },
  computed: {
    multiBtn() {
      return [
        {
          children: [
            {
              click: () => this.modShow = true,
              title: '编辑',
              icon: 'md-create',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.delItem('/standard/dictDetail/Remove', {ids: this.tableSelectItem.map(item => item.id)}),
              iconColor: '#ed4014',
              title: '删除',
              icon: 'md-trash',
              disabled: this.tableSelectItem.length === 0
            },
          ]
        }
      ]
    },
  },
  watch: {
    midData: function (data) {
      if (data.length === 1) {
        this.params.categoryId = '';
        this.params.dictId = '';
        this.params.dictItemId = '';
        if (data[0].categoryId) {
          this.params.categoryId = data[0].categoryId;
          if (data[0].dictId) {
            this.params.dictId = data[0].dictId;
            this.params.dictItemId = data[0].id;
          } else {
            this.params.dictId = data[0].id;
          }
        } else {
          this.params.categoryId = data[0].id;
        }
        this.params.page = 1;
        this.getList();
      }
    }
  },
  methods: {}
}
</script>
<style lang="less" scoped>

</style>
