<template>
  <div class="home">
    <ys-page-list
        ref="table"
        :action="projectID?'amend/ListByProject':'amend/List'"
        :params="params"
        :multiBtn="multiBtn"
        keyID="46676E44596B11EC9C5C00163E15C775"
        :tableHeight="tableHeight"
        showContextMenu
        :multiColumn="true"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="整改单类型" prop="type">
          <Select clearable v-model="params.type" style="width: 140px;">
            <Option v-for="(item,index) in baseData['整改单类型']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="状态" prop="status">
          <Select clearable v-model="params.status" style="width: 100px;">
            <Option v-for="(item,index) in baseData['整改单状态']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="提交时间" prop="daterange">
          <ys-date-picker type="daterange" clearable v-model="params.daterange" style="width: 220px" placeholder="时间周期"></ys-date-picker>
        </FormItem>
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="关键词" clearable style="width: 150px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template #detail>
        <Detail :mid="detailID"></Detail>
      </template>
    </ys-page-list>
    <ys-modal v-model="doReport" title="下载整改单">
      <doReport :type="2" :mid="tableSelectItem.length===1?tableSelectItem[0].checkId:''" @on-ok="getList"></doReport>
    </ys-modal>
    <ys-modal v-model="modShow" :width="900" :title="(tableSelectItem.length===0?'新增':'修改')+'整改单'">
      <Edit @on-ok="getList" :parentID="tableSelectItem.length===1?tableSelectItem[0].checkId:''" :mid="detailID"></Edit>
    </ys-modal>
    <ys-drawer v-model="detailFlag">
      <check-detail :mid="tableSelectItem.length===1?tableSelectItem[0].checkId:''"></check-detail>
    </ys-drawer>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'
import Detail from "@/views/task/amend/detail";
import checkDetail from "@/views/task/list/detail";
import doReport from "@/views/task/reports/doReport";
import Edit from "@/views/task/amend/Edit";
import {getProblemsList,amendCommit} from '@/api/problem'

export default {
  name: 'projects',
  mixins: [pageList],
  components: {checkDetail,Edit,doReport,Detail},
  props: {
    projectID: {
      type: [String,Number],
      default: ''
    }
  },
  data() {
    return {
      params: {
        projectId: '',
        type: '',
        status: '',
        keyword: '',
        daterange: [],
        startAt: '',
        endAt: '',
      },
      detailFlag: false,
      doReport: false,
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 50, align: 'center'},
        {width: 200, title: '整改建议书编号', key: 'code', align: 'left'},
        {minWidth: 150, title: '整改标题', key: 'iname', align: 'left', renderConfig: {type: 'gotoDetail'}},
        {width: 150, title: '类型', key: 'type', align: 'center', renderConfig: {type: 'baseData', parentName: '整改单类型'}},
        {minWidth: 200, title: '项目名称', tooltip: true, key: 'projectName', align: 'left'},
        {width: 200, title: '服务单位', key: 'serverOrgName', align: 'left'},
        {width: 160, title: '打卡日期', key: 'serveTime', align: 'left', renderConfig: {type: 'formatDate', fmt: 'yyyy-MM-dd hh:mm:ss'}},
        {width: 160, title: '提交时间', key: 'publishedAt', align: 'left', renderConfig: {type: 'formatDate', fmt: 'yyyy-MM-dd hh:mm:ss'}},
        {width: 80, title: '风险数量', key: 'commentCount', align: 'center'},
        {width: 100, title: '状态', key: 'status', align: 'center', renderConfig: {type: 'baseData', parentName: '整改单状态'}},
        {width: 170, title: '创建时间', key: 'createdAt', align: 'center'},
      ]
    }
  },
  watch: {
    'params.daterange'() {
      this.params.startAt = this.params.daterange[0] ? this.params.daterange[0] + ' ' + '00:00:00' : ''
      this.params.endAt = this.params.daterange[1] ? this.params.daterange[1] + ' ' + '23:59:59' : ''
    },
  },
  computed: {
    multiBtn() {
      return [
        {
          children: [
            {
              type: 'detail',
              title: '查看详情',
              targetUrl: '/main/task/list/detail/' + (this.tableSelectItem.length === 1 ? this.tableSelectItem[0].id : null),
              icon: 'md-eye',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.modShow = true,
              title: '修改',
              icon: 'md-create',
              disabled: this.tableSelectItem.length !== 1 || this.tableSelectItem[0].status !== 1
            },
          ]
        },

        {
          children: [
            {
              click: () => this.doReport = true,
              title: '下载整改单',
              icon: 'md-trash',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.commit(),
              title: '提交',
              icon: 'md-cloud-upload',
              disabled: this.tableSelectItem.length !== 1 || this.tableSelectItem[0].status !== 1 || [10, 30, 50].indexOf(this.tableSelectItem[0].checkStatus) < 0,
            },
          ]
        }
      ]
    },
  },
  mounted() {
    this.params.projectId = this.projectID;
    this.getList();
  },
  methods: {
    commit() {
      this.$Modal.confirm({
        title: '提示',
        content: '请确认项目阶段、五牌一图单体建筑物数据是否更新，提交后将无法修改报告信息！',
        okText: '确定',
        loading: true,
        cancelText: '取消',
        onOk: () => {
          this.checkProblems()
        }
      });
    },
    checkProblems() {
      getProblemsList({amendId: this.tableSelectItem[0].id, limit: -1}).then(res => {
        let errorList = [], content = '';
        (res.data.list || []).forEach(item => {
          item.fileList = item.fileList || [];
          const errorItem = [];
          if (item.fileList.length === 0) errorItem.push("附件")
          if (!item.riskGrade) errorItem.push("风险等级")
          if (!item.accidentType) errorItem.push("可能导致的事故类型")
          if (!item.remarksText) errorItem.push("风险描述")
          if (!item.remarksRisk) errorItem.push("风险依据")
          if (!item.remarksSuggest) errorItem.push("整改建议")
          if (errorItem.length > 0) errorList.push({itemName: item.itemName, errorItem})
        })
        if (errorList.length > 0) {
          errorList.forEach((item, index) => {
            content = content + (index + 1) + '、【' + item.itemName + '】的 <span style="color:#ff0900">' + item.errorItem.join('、') + '</span> 等未填写完整；<br/>'
          })
          this.$Modal.remove();
          window.setTimeout(() => {
            this.$Modal.confirm({
              okText: '前往修改',
              width:500,
              cancelText: '强制提交',
              title: '风险必填项检查失败！',
              content: content + '请前往任务详情查看风险信息，再仔细检查并完善信息！',
              onOk: () => {
                this.detailFlag = true;
              },
              onCancel: () => {
                window.setTimeout(()=>{
                  this.forceSubmit();
                },500)
              }
            });
          }, 300);
        } else {
          this.amendCommit();
        }
      })
    },
    forceSubmit() {
      this.$Modal.confirm({
        okText: '强制提交',
        title: '强制提交整改单！',
        content: '确定要强制提交整改单？',
        onOk: () => {
          window.setTimeout(()=>{
            this.amendCommit();
          },500)
        },
      });
    },
    amendCommit() {
      amendCommit({id: this.tableSelectItem[0].id}).then((res) => {
        if (res.code === 200) {
          this.$Notice.success({
            title: '操作成功！'
          });
          this.$Modal.remove();
          this.getList();
        }
      });
    },
  }
}
</script>
