<template>
  <div>
    <ys-page-list
        ref="table"
        :params="params"
        action="/sms/orgConfig/List"
        :tableHeight="tableHeight+60"
        @on-selection-change="checkItem"
        :multiBtn="multiBtn"
        :header="false"
        :headerColumns="headerColumns">
    </ys-page-list>
    <ys-modal v-model="modShow" :width="800" title="新增/修改配置">
      <Edit @on-ok="getList" :parentID="mid" :midData="tableSelectItem"></Edit>
    </ys-modal>
  </div>
</template>

<script>
import pageListMixins from '@/mixins/pageList';

export default {
  name: 'corp',
  mixins: [pageListMixins],
  components: {
    'Edit': () => import('@/views/org/list/detail/smsConfig/edit.vue'),
  },
  computed: {
    multiBtn() {
      return [
        {
          children: [
            {
              click: () => this.modShow = true,
              title: '编辑',
              icon: 'md-create',
              disabled: this.tableSelectItem.length !== 1
            },
          ]
        }
      ]
    },
  },
  data() {
    return {
      params: {
        orgId: '',
      },
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 50, align: 'center'},
        {width: 200, title: '通知名称', tooltip: true, key: 'title', align: 'left'},
        {width: 200, title: '通知类型', key: 'smsType', align: 'center', renderConfig: {type: 'baseData', parentName: '通知类型'}},
        {minWidth: 100, title: '通知内容', key: 'templateContent', align: 'left',},
        {width: 120, title: '允许设置接收人', key: 'setAcceptor', align: 'center', renderConfig: {type: 'baseData', parentName: '是否'}},
        {width: 90, title: '允许启停', key: 'setStatus', align: 'center', renderConfig: {type: 'baseData', parentName: '是否'}},
        {width: 90, title: '启用状态', key: 'status', align: 'center', renderConfig: {type: 'baseData', parentName: '启停状态'}},
        {minWidth: 300, title: '备注', key: 'desc', align: 'left'},
      ]
    }
  },
  mounted() {
    this.params.orgId = this.mid;
    this.getList()
  },
  methods: {}
}
</script>
