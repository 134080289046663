import {get, post} from 'ys-admin'


/**
 * 参建单位关联表详情
 * @param {Object} params
 * @returns {Object}
 */
export const projectsOrgInfo = function (params) {
  return get('/projects/org/GetById', params)
};
/**
 * 机构详情
 * @param {Object} params
 * @returns {Object}
 */
export const orgInfo = function (params) {
  return get('/org/GetInfo', params)
};
/**
 * 用户成员详情
 * @param {Object} params
 * @returns {Object}
 */
export const userInfo = function (params) {
  return get('/user/GetInfo', params)
};
/**
 * 机构用户成员详情
 * @param {Object} params
 * @returns {Object}
 */
export const userInfoByOrg = function (params) {
  return get('/org/user/GetInfo', params)
};
/**
 *通过社会信用代码精确查询机构列表
 * @param {Object} params
 * @returns {Object}
 */
export const getOrgListByCode = function (params) {
  return get('/organization/CodeGetList', params)
};
/**
 * 共保体配置详情
 * @param {Object} params
 * @returns {Object}
 */
export const coInsurerInfo = function (params) {
  return get('/coInsurer/GetInfo', params)
};/**
 * 共保体配置详情
 * @param {Object} params
 * @returns {Object}
 */
export const getCoInsurer = function (params) {
  return get('/coInsurer/List', params)
};
/**
 * 菜单详情
 * @param {Object} params
 * @returns {Object}
 */
export const menuIfo = function (params) {
  return get('/menu/Info', params)
};

/**
 * 获取组织机构列表
 * @param {Object} params
 * @returns {Object}
 */
export const orgList = function (params) {
  return get('/org/List', params)
};
/**
 * 获取组织机构列表
 * @param {Object} params
 * @returns {Object}
 */
export const orgListByUser = function (params) {
  return get('/org/ListByUser', params)
};
/**
 * 用户解绑机构
 * @param {Object} params
 * @returns {Object}
 */
export const orgUnLinkUser = function (params) {
  return post('/org/UnLinkUser', params)
};
/**
 * 用户绑定机构
 * @param {Object} params
 * @returns {Object}
 */
export const orgLinkUser = function (params) {
  return post('/org/LinkUser', params)
};

/**
 * 机构解绑项目
 * @param {Object} params
 * @returns {Object}
 */
export const orgUnLinkProject = function (params) {
  return post('/org/UnLinkProject', params)
};
/**
 * 一键绑定项目
 * @param {Object} params
 * @returns {Object}
 */
export const initGovProject = function (params) {
  return post('/organization/InitGovProject', params)
};
/**
 * 启用机构
 * @param {Object} params
 * @returns {Object}
 */
export const orgEnable = function (params) {
  return post('/organization/Enable', params)
};
/**
 * 停用机构
 * @param {Object} params
 * @returns {Object}
 */
export const orgDisable = function (params) {
  return post('/organization/Disable', params)
};

/**
 * 机构绑定项目
 * @param {Object} params
 * @returns {Object}
 */
export const orgLinkProject = function (params) {
  return post('/org/LinkProject', params)
};

/**
 * 获取平台角色列表
 * @param {Object} params
 * @returns {Object}
 */
 export const roleList = function (params) {
  return get('/permission/role/List', params)
};

/**
 * 批量生成无账户机构下的初始账户
 * @param {Object} params
 * @returns {Object}
 */
export const initOrgUser = function (params) {
  return post('/organization/InitOrgUser',params)
};
/**
 * 获取机构同步信息
 * @param {Object} params
 * @returns {Object}
 */
export const syncInfo = function (params) {
  return get('/sync/GetInfo',params)
};
/**
 * 获取机构同步信息
 * @param {Object} params
 * @returns {Object}
 */
export const enableDownload = function (params) {
  return post('/sync/EnableDownload',params)
};
/**
 * 获取机构同步信息
 * @param {Object} params
 * @returns {Object}
 */
export const disableDownload = function (params) {
  return post('/sync/DisableDownload',params)
};
/**
 * 获取机构同步信息
 * @param {Object} params
 * @returns {Object}
 */
export const disableUpload = function (params) {
  return post('/sync/DisableUpload',params)
};
/**
 * 获取机构同步信息
 * @param {Object} params
 * @returns {Object}
 */
export const enableUpload = function (params) {
  return post('/sync/EnableUpload',params)
};