<template>
  <div>
    <Form ref="form" class="editForm" :model="form" :rules="ruleValidate" :label-width="80" :style="{maxHeight:'calc(100vh - 200px)'}" style="overflow-y: auto">
      <FormItem label="迁移类型" prop="type">
        <Select v-model="form.type" @on-change="typeChange">
          <Option :value="1">变更承保机构</Option>
          <Option :value="3">变更服务机构</Option>
          <Option :value="4">变更投保机构</Option>
        </Select>
      </FormItem>
      <FormItem label="旧机构" prop="fromOrgName">
        <Input v-model="form.fromOrgName" disabled placeholder="旧机构名称"></Input>
      </FormItem>
      <FormItem label="新机构" prop="toOrgName">
        <Input v-model="form.toOrgName" disabled placeholder="新机构名称">
          <Button slot="append"  @click="choice('toOrg')">选择机构</Button>
        </Input>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok('/policy/Exchange')">确定迁移</Button>
      <Button @click="close">取消</Button>
    </div>
    <ys-modal v-model="orgFlag" :width="1200" title="选择机构">
      <org-component :limit="1" :oType="form.type" :types="form.type===1?types:[]" @on-ok="getData"></org-component>
    </ys-modal>
  </div>
</template>
<script>
import edit from '@/mixins/edit'
import OrgComponent from "_c/org";
import {policyInfo} from "@/api/policy";
import {orgInfo} from "@/api/org";

export default {
  name: 'orgEdit',
  components: {OrgComponent},
  mixins: [edit],
  props: {companyId: {type: Number, default: null}},
  data() {
    return {
      ruleValidate: {
        fromOrgName: [
          {required: true, message: '请填旧机构名称', trigger: 'change', type: 'string'}
        ],
        toOrgName: [
          {required: true, message: '请选择新机构名称', trigger: 'change', type: 'string'}
        ],
      },
      orgFlag: false,
      types: [],
      type: '',
      detail: {},
      form: {
        policyId: '',
        fromOrgId: '',
        type: 1,
        fromOrgName: '',
        toOrgName: '',
        toOrgId: '',
      },
    }
  },
  mounted() {
    this.getOrgInfo();
    this.getDetail();
  },
  methods: {
    getOrgInfo() {
      orgInfo({id: this.companyId}).then(res => {
        this.types = res.data.type===6?[6]:[1,2,3,4,5];
      })
    },
    getDetail() {
      this.modal.initLoading = true;
      policyInfo({id: this.mid}).then(res => {
        this.modal.initLoading = false;
        if (res.code === 200) {
          this.detail = res.data;
          this.form.fromOrgName = this.detail.companyName;
          this.form.fromOrgId = this.detail.companyId;
          this.form.policyId = this.detail.id;
        }
      })
    },
    choice(type) {
      this.type = type;
      this.orgFlag = true;
    },
    typeChange() {
      const {serviceList = []} = this.detail;
      switch (this.form.type) {
        case 1:
          this.form.fromOrgName = this.detail.companyName;
          this.form.fromOrgId = this.detail.companyId;
          break;
        case 3:
          this.form.fromOrgName = serviceList.length > 0 ? serviceList[0].iname : '';
          this.form.fromOrgId = serviceList.length > 0 ? serviceList[0].id : '';
          break;
        case 4:
          this.form.fromOrgName = this.detail.enterpriseName;
          this.form.fromOrgId = this.detail.enterpriseId;
          break;
      }
      this.form.toOrgName = '';
      this.form.toOrgId = '';
    },
    getData(data) {
      const {fromOrgId, toOrgId} = this.form;
      if (fromOrgId === toOrgId && fromOrgId && toOrgId) {
        this.$Notice.error({title: '提示', desc: '所选新旧机构相同，无法操作！'});
        return false;
      }
      this.form[this.type + 'Id'] = data[0].id;
      this.form[this.type + 'Name'] = data[0].iname;
    },
  },
}
</script>

<style lang="less" scoped>
</style>
