<template>
  <div>
    <ys-page-list
        ref="table"
        method="$get"
        action="/pageListKeys/List"
        :params="params"
        keyLabel="ID"
        :tableHeight="tableHeight"
        :btnArray="[]"
        :isPage="false"
        :multiBtn="multiBtn"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns">
      <template slot="search"></template>
      <template slot="btn">
        <ButtonGroup>
          <Button type="primary" @click="add">新增对照表</Button>
        </ButtonGroup>
      </template>
    </ys-page-list>
    <ys-modal v-model="modShow" title="新增/修改对照表">
      <Edit :mid="detailID" :midData="tableSelectItem" :parentID="keyId" @on-ok="getList"></Edit>
    </ys-modal>
  </div>

</template>

<script>
import pageList from '@/mixins/pageList.js'

export default {
  name: 'projects',
  mixins: [pageList],
  props: {keyId: {type: String, default: ''}},
  components: {
    Edit: () => import('./edit')
  },
  data() {
    return {
      params: {
        keyId: '',
        id: '',
        limit: -1,
        keyword: '',
      },
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 70, align: 'center'},
        {width: 70, title: '排序', key: 'order', align: 'center'},
        {width: 120, title: 'KEY', key: 'key', align: 'center'},
        {width: 150, title: 'KEY名称', key: 'keyName', align: 'center'},
        {width: 100, title: 'KEY类型', key: 'type', align: 'center', renderConfig: {type: 'baseData', parentName: '表头配置key类型'}},
        {minWidth: 80, title: '参数', key: 'typeParams', align: 'left'},
        {width: 160, title: '创建时间', key: 'createdAt', align: 'center'},
      ]
    }
  },
  computed: {
    multiBtn() {
      return [
        {
          children: [
            {
              click: () => this.modShow = true,
              title: '编辑',
              icon: 'md-create',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () =>  this.delItem('/pageListKeys/Remove', {ids: this.tableSelectItem.map(item => item.ID)}),
              iconColor: '#ed4014',
              title: '删除',
              icon: 'md-trash',
              disabled: this.tableSelectItem.length !== 1
            },
          ]
        }
      ]
    },
  },
  mounted() {
    this.params.keyId = this.keyId;
    this.getList();
  },
  methods: {

  }
}
</script>
