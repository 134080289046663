<template>
  <div>
    <Form ref="form" class="editForm twoPartsEditForm" :model="form" :rules="ruleValidate" inline :label-width="100" :style="{maxHeight:'calc(100vh - 200px)'}" style="overflow-y: auto">
      <FormItem label="任务名称" prop="name">
        <Input v-model="form.name" placeholder="任务名称"/>
      </FormItem>
      <FormItem label="任务类型" prop="taskType">
        <Select v-model="form.taskType" @on-select="taskTypeChange" placeholder="任务类型">
          <Option v-for="(item,index) in taskTypeList" :value="item.type" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="触发类型" prop="type">
        <Select v-model="form.type" transfer placeholder="触发类型">
          <Option :value="1">每天</Option>
          <Option :value="4">每周</Option>
          <Option :value="2">每月</Option>
          <Option :value="3">每年</Option>
        </Select>
      </FormItem>
      <FormItem label="触发月份" prop="month" v-if="form.type>2&&form.type!==4">
        <Select v-model="form.month" transfer placeholder="指定月份触发任务">
          <Option v-for="item in 12" :value="item" :key="item">{{ item }}月</Option>
        </Select>
      </FormItem>
      <FormItem label="触发日" prop="dayOfMonth" v-if="form.type>1&&form.type!==4">
        <Select v-model="form.dayOfMonth" transfer placeholder="指定几日触发任务">
          <Option v-for="item in 31" :value="item" :key="item">{{ item }}日</Option>
        </Select>
      </FormItem>
      <FormItem label="每周触发日" prop="dayOfWeek" v-if="form.type===4">
        <Select v-model="form.dayOfWeek" transfer placeholder="指定每周几触发任务">
          <Option v-for="item in [{value:'日',num:'0'},{value:'一',num:'1'},{value:'二',num:'2'},{value:'三',num:'3'},{value:'四',num:'4'},{value:'五',num:'5'},{value:'六',num:'6'},]" :value="item.num" :key="item.num">周{{ item.value }}</Option>
        </Select>
      </FormItem>
      <FormItem label="触发时间" prop="time">
        <TimePicker format="HH:mm" :clearable="false" transfer placeholder="触发时间" v-model="form.time" style="width:100%"></TimePicker>
      </FormItem>
      <FormItem label="args " prop="args" style="width: 100%">
        <Input v-model="form.args" type="textarea" maxlength="128" show-word-limit :autosize="{minRows: 3,maxRows: 5}" placeholder="备注"></Input>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="save">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>

  </div>
</template>
<script>
import edit from '@/mixins/edit'
import {getTaskTypeList} from "@/api/base";

export default {
  name: 'projectEdit',
  mixins: [edit],
  props: {oType: {type: Number, default: 1}},
  data() {
    return {
      ruleValidate: {
        name: [
          {required: true, message: '请填写任务名称', trigger: 'blur'}
        ],
        taskType: [
          {required: true, type: 'string', message: '请选择任务类型', trigger: 'change'}
        ],
        month: [
          {required: true, type: 'number', message: '请选择触发月份', trigger: 'change'}
        ],
        dayOfMonth: [
          {required: true, type: 'number', message: '请选择触发日', trigger: 'change'}
        ],
        dayOfWeek: [
          {required: true, type: 'string', message: '请选择每周触发日', trigger: 'change'}
        ],
        time: [
          {required: true, type: 'string', message: '请选择触发时间', trigger: 'change'}
        ],
      },
      taskTypeList: [],
      form: {
        id: '',
        type: 1,
        time: '00:00',
        taskType: '',
        name: '',
        min: '',
        hour: '',
        dayOfMonth: '*',
        dayOfWeek: '*',
        month: '*',
        args: '',
        orgId: '',
      },
    }
  },
  created() {

  },
  async mounted() {
    await this.getTaskTypeList();
    if (this.midData.length === 1) {
      Object.keys(this.form).forEach((item) => {
        this.form[item] = this.midData[0][item]
      })
      if (this.form.month === '*') {
        if (this.form.dayOfWeek) {
          this.form.type = 4;
        } else if (this.form.dayOfMonth === '*') {
          this.form.type = 1;
        } else {
          this.form.type = 2;
        }
      }
      this.form.time = this.form.hour + ':' + this.form.min;
    }
  },
  methods: {
    async getTaskTypeList() {
      await getTaskTypeList().then(res => {
        if (res.code === 200) {
          this.taskTypeList = res.data || [];
          this.form.taskType = this.taskTypeList.length > 0 ? this.taskTypeList[0].type : '';
        }
      })
    },
    taskTypeChange(data) {
      let result = this.taskTypeList.filter(item => item.type === data.value);
      this.form.args = result.length === 1 ? JSON.parse(result[0].payloadStruct || null) : null;
    },
    save() {
      switch (this.form.type) {
        case 1:
          this.form.month = '*';
          this.form.dayOfMonth = '*';
          this.form.dayOfWeek = '*'
          break;
        case 2:
          this.form.month = '*';
          this.form.dayOfWeek = '*'
          break;
        case 4:
          this.form.month = '*';
          this.form.dayOfMonth = '*';
          break;
      }
      this.form.hour = this.form.time.split(':')[0];
      this.form.min = this.form.time.split(':')[1];
      this.ok('/taskScheduler/Add');
    }
  }
}
</script>

<style lang="less" scoped>
</style>
