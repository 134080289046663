<template>
  <div>
    <Form ref="form" class="editForm" :model="form" :rules="ruleValidate" :label-width="100" :style="{maxHeight:'calc(100vh - 200px)'}" style="overflow-y: auto">
      <FormItem label="序号" prop="index">
        <InputNumber :min="0" style="width: 100%" v-model="form.index" placeholder="最大扣分值"></InputNumber>
      </FormItem>
      <FormItem label="风险项内容" prop="desc" style="width: 100%">
        <Input v-model="form.desc" placeholder="风险项内容"></Input>
      </FormItem>
      <FormItem label="风险等级" prop="riskGrade">
        <Select v-model="form.riskGrade" transfer placeholder="风险等级">
          <Option v-for="(item,index) in riskGradeList" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="可能事故类型" prop="accidentType" style="width: 100%">
        <Input v-model="form.accidentType"  placeholder="英文逗号分割"></Input>
      </FormItem>
      <FormItem label="最大扣分值" prop="maxScore">
        <InputNumber :min="-100" :max="0" style="width: 100%" v-model="form.maxScore" placeholder="最大扣分值"></InputNumber>
      </FormItem>
      <FormItem label="最小扣分值" prop="minScore">
        <InputNumber :min="-100" :max="0" style="width: 100%" v-model="form.minScore" placeholder="最小扣分值"></InputNumber>
      </FormItem>
      <FormItem label="风险依据" prop="remarks" style="width: 100%">
        <Input v-model="form.remarks" :autosize="{minRows: 2,maxRows: 5}" type="textarea" placeholder="风险依据"></Input>
      </FormItem>
      <FormItem label="整改建议" prop="remarksSuggest" style="width: 100%">
        <Input v-model="form.remarksSuggest" :autosize="{minRows: 2,maxRows: 5}" type="textarea" placeholder="整改建议"></Input>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok(form.id?'/standard/dictDetail/Edit':'/standard/dictDetail/Add')">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>
<script>
import edit from '@/mixins/edit'
import {getDictDetailInfo} from "@/api/base";

export default {
  name: 'projectEdit',
  mixins: [edit],
  props: {
    standardInfo: {type: Array, default: () => []},
  },
  data() {
    return {
      ruleValidate: {
        desc: [
          {required: true, message: '请填写风险项内容', trigger: 'blur'}
        ],
        maxScore: [
          {required: true, type: 'number', message: '请设定最大扣分值', trigger: 'change'}
        ],
        minScore: [
          {required: true, type: 'number', message: '请设定最小扣分值', trigger: 'change'}
        ],
      },
      form: {
        id: '',
        index: 0,
        categoryId: '',
        remarks: '',
        dictId: '',
        dictItemId: '',
        maxScore: -10,
        minScore: -10,
        desc: '',
        riskGrade: null,
        remarksSuggest: '',
        accidentType: ''
      },
    }
  },
  computed: {
    riskGradeList() {
      return this.baseData['风险等级'].filter(item => {
        return this.standardInfo[0]?.riskGrades.split(',').some(strItem => Number(strItem) === item.value)
      })
    }
  },
  mounted() {
    if (this.mid) {
      getDictDetailInfo({id: this.mid}).then((res) => {
        Object.keys(this.form).forEach((item) => {
          this.form[item] = res.data[item]
        })
      })
    }else{
      this.form.riskGrade = this.riskGradeList[0]?.value
      this.form.categoryId = this.midData[0].categoryId;
      this.form.dictId = this.midData[0].dictId;
      this.form.dictItemId = this.midData[0].id;
    }
  },
  methods: {}
}
</script>

