<template>
  <div>
    <Form ref="form" class="editForm " :model="form" :rules="ruleValidate" :label-width="70">
      <FormItem label="名称" prop="name">
        <Input v-model="form.name" placeholder="角色名称"/>
      </FormItem>
      <FormItem label="审核人" prop="userName">
        <Input v-model="form.userName" disabled placeholder="审核人">
          <Button slot="append" @click="userFlag=true">选择</Button>
        </Input>
      </FormItem>
      <FormItem label="备注" prop="desc" style="width: 100%">
        <Input v-model="form.desc" type="textarea" maxlength="128" show-word-limit :autosize="{minRows: 3,maxRows: 10}" placeholder="备注"></Input>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="save">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
    <ys-modal v-model="userFlag" :width="1200" title="选择审批人">
      <org-user :companyId="orgId" :limit="0" @on-ok="getUserData"></org-user>
    </ys-modal>
  </div>
</template>
<script>
import edit from '@/mixins/edit'
import orgUser from '@/components/user'

export default {
  name: 'projectEdit',
  mixins: [edit],
  components: {orgUser},
  props: {orgId: {type: [Number, String], default: null}},
  data() {
    return {
      ruleValidate: {
        iname: [
          {required: true, message: '请填写角色名称', trigger: 'blur'}
        ],
        userName: [
          {required: true, message: '请选择审批人', trigger: 'change'}
        ],
      },
      userFlag: false,
      form: {
        id: '',
        children: [],
        userName: '',
        name: '',
        desc: '',
      },
    }
  },
  computed: {},
  mounted() {
    if (!this.orgId) {
      this.$Notice.error({
        title: "操作失败！",
        desc: '请选择所属机构！'
      });
      this.close();
    }
  },
  methods: {
    save() {
      this.modal.spinShow = true;
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.$emit('on-ok', this.form);
          this.close();
          this.modal.spinShow = false;
        } else {
          this.$Notice.error({
            title: "操作失败！",
            desc: '请检查表单是否填项正确！'
          });
          this.modal.spinShow = false;
        }
      })
    },
    getUserData(data) {
      this.form.userName = data.map((item) => {
        return item.iname
      }).join(',')
      this.form.children = data.map((item) => {
        return {
          id: '',
          userId: item.id,
          username: item.iname,
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>

</style>



