<template>
  <div>
    <ys-page-list
        ref="table"
        :params="params"
        action="/coInsurer/List"
        :tableHeight="tableHeight"
        @on-selection-change="checkItem"
        :multiBtn="multiBtn"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="配置名称" prop="iname">
          <Input placeholder="配置名称" clearable style="width: 150px;" v-model="params.iname"></Input>
        </FormItem>
      </template>
      <template slot="btn">
        <ButtonGroup>
          <Button type="primary" @click="add">新增配置</Button>
        </ButtonGroup>
      </template>
      <template slot="detail">
        <Detail :mid="detailID"></Detail>
      </template>
    </ys-page-list>
    <ys-modal v-model="modShow" :width="800" title="新增/修改配置">
      <Edit @on-ok="getList" :mid="detailID" :parentID="mid"></Edit>
    </ys-modal>
  </div>
</template>

<script>
import pageListMixins from '@/mixins/pageList';

export default {
  name: 'corp',
  mixins: [pageListMixins],
  components: {
    'Edit': () => import('@/views/org/list/detail/rate/edit.vue'),
    'Detail': () => import('@/views/projects/list/detail.vue')
  },
  computed: {
    multiBtn() {
      return [
        {
          children: [
            {
              click: () => this.modShow = true,
              title: '编辑',
              icon: 'md-create',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.delItem('/coInsurer/Remove', {ids: this.tableSelectItem.map(item => item.id)}),
              iconColor: '#ed4014',
              title: '删除',
              icon: 'md-trash',
              disabled: this.tableSelectItem.length === 0
            },
          ]
        }
      ]
    },
  },
  data() {
    return {
      params: {
        iname: '',
        orgId: '',
      },
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 50, align: 'center'},
        {minWidth: 230, title: '配置名称', tooltip: true, key: 'iname', align: 'left'},
        {width: 100, title: '配置类型', key: 'type', align: 'left', renderConfig: {type: 'baseData', parentName: '共保体配置类型'}},
        {width: 120, title: '保单类型', key: 'policyType', align: 'left', renderConfig: {type: 'baseData', parentName: '保单类型'}},
        {width: 90, title: '默认配置', key: 'isDefault', align: 'left', renderConfig: {type: 'baseData', parentName: '是否'}},
        {width: 220, title: '创建日期', key: 'createdAt', align: 'left'},
        {width: 220, title: '更新日期', key: 'updatedAt', align: 'left'},
      ]
    }
  },
  mounted() {
    this.params.orgId = this.mid;
    this.getList()
  },
  methods: {

  }
}
</script>
