<template>
  <div>
    <Form ref="form" class="editForm" :model="form" :rules="ruleValidate" :label-width="80" :style="{maxHeight:'calc(100vh - 200px)'}" style="overflow-y: auto">
      <FormItem label="保单编号" prop="number">
        <Input v-model="form.number" disabled> </Input>
      </FormItem>
      <FormItem label="投保机构" prop="enterpriseName">
        <Input v-model="form.enterpriseName" disabled> </Input>
      </FormItem>
      <FormItem label="项目名称" prop="projectId">
        <Input v-model="form.projectName" placeholder="项目">
          <Button slot="append" @click="orgFlag=true">选择项目</Button>
        </Input>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="save">确定绑定</Button>
      <Button @click="close">取消</Button>
    </div>
    <ys-modal v-model="orgFlag" :width="1200" title="选择项目">
      <OrgProject :limit="1" @on-ok="getData"></OrgProject>
    </ys-modal>
  </div>
</template>
<script>
import edit from '@/mixins/edit'
import OrgProject from "_c/projects";
import {getProjectOrgList} from "@/api/projects";

export default {
  name: 'orgEdit',
  components: {OrgProject},
  mixins: [edit],
  data() {
    return {
      ruleValidate: {
        projectId: [
          {required: true, message: '请选择项目', trigger: 'change', type: 'number'}
        ]
      },
      orgFlag: false,
      enterprise: {},
      form: {
        id: '',
        number: '',
        enterpriseName: '',
        enterpriseId: '',
        projectId: '',
        force: 0,
      },
    }
  },
  mounted() {
    if (this.midData.length === 1) {
      this.form.id = this.midData[0].id;
      this.form.number = this.midData[0].number;
      this.form.enterpriseName = this.midData[0].enterpriseName;
      this.form.enterpriseId = this.midData[0].enterpriseId;
    }
  },
  methods: {
    async getProjectOrgList() {
      if (!this.form.projectId) return;
      getProjectOrgList({projectId: this.form.projectId}).then(res => {
        this.enterprise = res.data.list.find(item => item.type === 31 && this.form.enterpriseId === item.id);
      })
    },
    save() {
      if (!this.enterprise) {
        this.$Modal.confirm({
          title: '提示',
          content: '该项目的施工单位和投保单位不一致，是否继续绑定该项目？',
          onOk: () => {
            this.form.force = 1;
            this.ok('/policy/BindProject')
          },
        });
      }else{
        this.ok('/policy/BindProject')
      }
    },
    getData(data) {
      this.form.projectId = data[0].id;
      this.form.projectName = data[0].iname;
      this.getProjectOrgList();
    },
  },
}
</script>

<style lang="less" scoped>
</style>
