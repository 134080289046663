<template>
  <div>
    <ys-page-list
        ref="table"
        :params="params"
        action="/org/ListByUser"
        :tableHeight="tableHeight"
        @on-selection-change="checkItem"
        :multiBtn="multiBtn"
        showContextMenu
        :multiColumn="true"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="机构类型" prop="oType">
          <Select v-model="params.oType" transfer clearable placeholder="机构类型" style="width: 100px;">
            <Option v-for="(item,index) in baseData['组织类型']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="关键词" clearable style="width: 150px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template slot="btn">
        <ButtonGroup>
          <Button type="primary" @click="add">绑定机构</Button>
        </ButtonGroup>
      </template>
    </ys-page-list>
    <ys-modal v-model="modShow" :width="1000" title="绑定机构">
      <org-component :limit="1" @on-ok="getData"></org-component>
    </ys-modal>
  </div>
</template>

<script>
import pageListMixins from '@/mixins/pageList';
import OrgComponent from "_c/org";
import {orgLinkUser, orgUnLinkUser} from "@/api/org";

export default {
  name: 'corp',
  mixins: [pageListMixins],
  components: {
    OrgComponent
  },
  computed: {
    multiBtn() {
      return [
        {
          children: [
            {
              click: () => this.unlink(),
              title: '解绑',
              icon: 'md-create',
              disabled: this.tableSelectItem.length !== 1
            },
          ]
        }
      ]
    },
  },
  data() {
    return {
      params: {
        oType: '',
        type: '',
        userId: ''
      },
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {width: 250, title: '单位名称', key: 'iname', align: 'left'},
        {width: 100, title: '机构类型', key: 'oType', align: 'center', renderConfig: {type: 'baseData',parentName: '组织类型'}},
        {width: 130, title: '单位类型', key: 'type', align: 'left', renderConfig: {type: 'baseData', parentName: '参建单位类型'}},
        {width: 200, title: '统一社会信用代码', key: 'societyCode', align: 'left'},
        {width: 150, title: '联系人', key: 'contacts', align: 'left'},
        {minWidth: 150, title: '联系人电话', key: 'phone', align: 'left'},
      ]
    }
  },
  mounted() {
    this.params.userId = this.mid;
    this.getList()
  },
  methods: {
    unlink() {
      this.$Modal.confirm({
        title: '提示',
        content: '确认要解绑该机构么？',
        okText: '确认',
        cancelText: '取消',
        onOk: () => {
          orgUnLinkUser({id: this.tableSelectItem[0].id, userId: this.mid}).then(res => {
            if (res.code === 200) {
              this.$Notice.success({
                title: '提示',
                desc: '操作成功！'
              });
              this.getList();
            }
          })
        }
      })
    },
    getData(data) {
      orgLinkUser({userId: this.mid, id: data[0].id}).then(res => {
        if (res.code === 200) {
          this.$Notice.success({title: '提示', desc: '绑定成功!'});
          this.getList();
        }
      })
    }
  }
}
</script>
