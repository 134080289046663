<template>
  <div class="home">
    <ys-page-list
        ref="table"
        :action="'/org/user/ListByOrg'"
        :params="params"
        :tableHeight="tableHeight"
        :multiBtn="multiBtn"
        showContextMenu
        @on-selection-change="checkItem"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="关键词" clearable style="width: 150px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template slot="btn">
        <ButtonGroup>
          <Button type="primary" @click="bindFlag=true">绑定成员</Button>
          <Button type="primary" @click="add">新增成员</Button>
        </ButtonGroup>
      </template>
      <template slot="detail">
        <Detail :tabName="tabName" :mid="detailID"></Detail>
      </template>
    </ys-page-list>
    <ys-modal v-model="modShow" :width="800" title="新增/修改成员">
      <Edit :midData="midData" :mid="detailID" :orgId="mid" @on-ok="getList"></Edit>
    </ys-modal>
    <ys-modal v-model="modFlag" title="修改密码">
      <modPassword :mid="detailID"></modPassword>
    </ys-modal>
    <ys-modal v-model="enterFlag" title="选择平台">
      <enter :mid-data="tableSelectItem"></enter>
    </ys-modal>
    <ys-modal v-model="bindFlag" :width="1000" title="绑定成员">
      <users @on-ok="getUser"></users>
    </ys-modal>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'
import Edit from "@/views/org/member/edit";
import Detail from "@/views/org/member/detail.vue";
import modPassword from '_c/header/ModPwd.vue'
import users from '_c/users'
import {orgLinkUser, orgUnLinkUser} from "@/api/org";
import enter from "@/views/org/member/enter";

export default {
  name: 'projects',
  mixins: [pageList],
  components: {enter,users, Detail, modPassword, Edit},
  data() {
    return {
      params: {
        countyCode: '',
        isAdmin: 0,
        cityCode: '',
        provinceCode: '',
        orgId: '',
        keyword: '',
      },
      enterFlag: false,
      bindFlag: false,
      modFlag: false,
      IDs: [],
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 80, align: 'center'},
        {width: 130, title: '姓名', key: 'iname', align: 'left', renderConfig: {type: 'gotoDetail'}},
        {width: 130, title: '联系电话', key: 'phone', align: 'left'},
        {width: 180, title: '身份证号码', key: 'identityCode', align: 'left'},
        {width: 200, title: '邮箱', key: 'mail', align: 'left'},
        {width: 180, title: '登录账号', key: 'username', align: 'left'},
        {width: 180, title: '创建时间', key: 'createdAt', align: 'left'},
        {width: 180, title: '最近修改时间', key: 'updatedAt', align: 'left'},
      ]
    }
  },
  computed: {
    multiBtn() {
      return [
        {
          children: [
            {
              type: 'detail',
              title: '查看详情',
              targetUrl: '/main/org/member/detail/' + (this.tableSelectItem.length === 1 ? this.tableSelectItem[0].id : null),
              icon: 'md-eye',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.modShow = true,
              title: '编辑',
              icon: 'md-create',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.unlink(),
              iconColor: '#ed4014',
              title: '解绑',
              icon: 'md-trash',
              disabled: this.tableSelectItem.length !== 1
            },
          ]
        },
        {
          children: [
            {
              click: () => this.modFlag = true,
              title: '修改密码',
              icon: 'ios-lock',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.enterFlag = true,
              title: '进入账户',
              icon: 'ios-contact',
              disabled: this.tableSelectItem.length !== 1
            },
          ]
        }
      ]
    },
  },
  mounted() {
    this.params.orgId = this.mid;
    this.getList();
  },
  methods: {
    unlink() {
      this.$Modal.confirm({
        title: '提示',
        content: '确认要解绑该用户么？',
        okText: '确认',
        cancelText: '取消',
        onOk: () => {
          orgUnLinkUser({id: this.mid, userId: this.tableSelectItem[0].id}).then(res => {
            if (res.code === 200) {
              this.$Notice.success({
                title: '提示',
                desc: '操作成功！'
              });
              this.getList();
            }
          })
        }
      })
    },
    getUser(data) {
      orgLinkUser({
        id: this.mid,
        userId: data[0].id,
      }).then(res => {
        if (res.code === 200) {
          this.$Notice.success({
            title: "提示！",
            desc: '绑定成功！'
          });
          this.getList();
        }
      })
    }
  }
}
</script>
