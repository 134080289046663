<template>
  <div class="home">
    <ys-page-list
        ref="table"
        action="/permission/rule/List"
        :params="params"
        :tableHeight="tableHeight"
        :multiBtn="multiBtn"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="关键词" clearable style="width: 150px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template slot="btn">
        <ButtonGroup>
          <Button type="primary" @click="add">新增权限</Button>
        </ButtonGroup>
      </template>
    </ys-page-list>
    <ys-modal v-model="modShow" :width="500" title="新增/修改菜单权限">
      <Edit :mid="detailID" :parentID="mid" @on-ok="getList"></Edit>
    </ys-modal>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'
import Edit from "@/views/base/menu/detail/power/edit.vue";

export default {
  name: 'power',
  mixins: [pageList],
  components: {
    Edit,
  },
  props: {
    oType: {type: Number, default: 2},
  },
  data() {
    return {
      params: {
        platform: '',
        menuId: '',
        keyword: '',
      },
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 50, align: 'center'},
        {minWidth: 180, title: '权限名称', key: 'iname', align: 'left'},
      ]
    }
  },
  computed: {
    multiBtn() {
      return [
        {
          children: [

            {
              click: () => this.modShow = true,
              title: '编辑',
              icon: 'md-create',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.delItem('/permission/rule/Remove', {id: this.tableSelectItem[0].id}),
              iconColor: '#ed4014',
              title: '删除',
              icon: 'md-trash',
              disabled: this.tableSelectItem.length !== 1
            },
          ]
        }
      ]
    },
  },
  mounted() {
    this.params.menuId = this.mid
    this.params.platform = this.oType
    this.getList();
  },
  methods: {}
}
</script>
