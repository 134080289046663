<template>
  <!-- 查看详情弹出框 -->
  <ys-detail :icon="detailIcon" :loading="loading" :name="detail.url">
    <Tag size="medium" color="blue" slot="status">{{ detail.errCode }}</Tag>
    <template slot="brief">
      <ys-cell title="来源地址" :label="detail.hostname"/>
      <ys-cell title="返回结果" :label="detail.errMsg"/>
      <ys-cell title="来源IP" :label="detail.addr"/>
      <ys-cell title="操作人" :label="detail.uname"/>
    </template>
    <template slot="main-left">
      <Tabs v-model="tab" name="detail" type="card">
        <Tab-pane label="基本信息" name="1" icon="ios-paper">
          <base-info :detail="detail" v-if="tab==='1'"/>
        </Tab-pane>
      </Tabs>
    </template>
    <template slot="main-right">
      <h1 class="h1Title">其他信息</h1>
      <div style="text-align: center">暂未开放！</div>
    </template>
  </ys-detail>
</template>

<script>
import detailMixins from '@/mixins/detail'
import {apiLogsInfo} from "@/api/base";
import BaseInfo from "@/views/base/logs/detail/baseInfo.vue";

export default {
  name: "detail",
  mixins: [detailMixins],
  components: {
    BaseInfo,
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.loading = true;
      apiLogsInfo({id: this.detailID}).then(res => {
        this.loading = false;
        if (res.code === 200) {
          this.detail = res.data;
        }
        this.setTagName(this.detail.iname)
      }).catch(() => {
        this.loading = false;
      })
    }
  }
}
</script>

<style scoped>

</style>