<template>
  <div>
    <Form ref="form" class="editForm" :model="form" :rules="ruleValidate" :label-width="80" :style="{maxHeight:'calc(100vh - 200px)'}" style="overflow-y: auto">
      <FormItem label="权限名称" prop="iname">
        <Input v-model="form.iname" placeholder="权限名称"/>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok('/permission/rule/AddOrEdit')">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>
<script>
import edit from '@/mixins/edit'
import {menuIfo} from "@/api/org";

export default {
  name: 'projectEdit',
  mixins: [edit],
  props: {
    oType: {type: Number, default: 2},
  },
  data() {
    return {
      ruleValidate: {
        iname: [
          {required: true, message: '请填写权限名称', trigger: 'blur'}
        ],
      },
      form: {
        id: '',
        platform: '',
        iname: '',
      },
    }
  },
  mounted() {
    if (this.mid) {
      menuIfo({id: this.mid, time: new Date().getTime()}).then((res) => {
        if (res.code === 200) {
          Object.keys(this.form).forEach((item) => {
            this.form[item] = res.data[item]
          })
        }
      })
    } else {
      this.form.platform = this.oType;
      this.form.menuId = this.parentID;
    }
  },
  methods: {}
}
</script>

<style lang="less" scoped>
</style>