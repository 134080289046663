<template>
  <div>
    <Form ref="form" class="editForm twoPartsEditForm" :model="form" :rules="ruleValidate" inline :label-width="100" :style="{maxHeight:'calc(100vh - 200px)'}">
      <Alert type="warning">无实体机构(如共保体)时，请点击此处<a href="javascript:" @click="virtual">排除同步</a>！</Alert>
      <FormItem label="关键词" prop="keyword" style="width: 100%;" class="ys-business">
        <ys-business :societyCode="societyCode" :source="2" @on-select="selectOrg" v-model="form.keyword"></ys-business>
      </FormItem>
      <FormItem label="公司名称" prop="iname" style="width: 100%;">
        <Input v-model="form.iname" disabled placeholder="公司名称"></Input>
      </FormItem>
      <FormItem label="信用代码" prop="societyCode">
        <Input v-model="form.societyCode" disabled placeholder="社会信用代码"></Input>
      </FormItem>
      <FormItem label="法人" prop="operName">
        <Input v-model="form.operName" disabled placeholder="法人"></Input>
      </FormItem>
      <FormItem label="成立日期" prop="startDate">
        <Input v-model="form.startDate" disabled placeholder="成立日期"></Input>
      </FormItem>
      <FormItem label="状态" prop="regStatus">
        <Input v-model="form.regStatus" disabled placeholder="状态"></Input>
      </FormItem>
      <FormItem label="注册地址" prop="address" style="width: 100%">
        <Input v-model="form.address" disabled placeholder="注册地址"></Input>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="save" :disabled="!form.uuid">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>
<script>
import edit from '@/mixins/edit'

export default {
  name: 'projectEdit',
  mixins: [edit],
  data() {
    return {
      societyCode:'',
      form: {
        id: '',
        iname: '',
        societyCode: '',
        oType: 1,
        type: 1,
        parentId: '',
        parentName: '',
        uuid: '',
        shortName: '',
        operName: '',
        startDate: '',
        regStatus: '',
        logo: '',
        logoTitle: '',
        contacts: '',
        phone: '',
        code: '',
        provinceCode: '',
        cityCode: '',
        countyCode: '',
        address: '',
        streetCode: '',
        keyword: '',
        area: '',
        virtual: 0,
      },
    }
  },
  mounted() {
    if (this.midData.length === 1) {
      const orgData = this.midData[0];
      Object.keys(this.form).forEach((item) => {
        this.form[item] = orgData[item]
      })
      this.form.keyword = orgData.iname;
      if (orgData.societyCode && this.$reg.unifiedSocialCreditReg.reg.test(orgData.societyCode)) {
        this.societyCode = orgData.societyCode
      }
    }
  },
  methods: {
    virtual() {
      this.$Modal.confirm({
        title: '提示',
        content: '确认该机构为虚拟机构，排除工商信息同步么？',
        okText: '确认',
        cancelText: '取消',
        onOk: () => {
          this.form.virtual = 1;
          this.ok('/organization/AddOrEdit')
        }
      })
    },
    save() {
      this.$Modal.confirm({
        title: '提示',
        content: '确认同步该工商信息么？',
        okText: '确认',
        cancelText: '取消',
        onOk: () => {
          this.ok('/organization/AddOrEdit')
        }
      })
    },
    selectOrg(data) {
      this.form.iname = data.iname;
      this.form.uuid = data.uuid;
      this.form.operName = data.operName;
      this.form.startDate = data.startDate;
      this.form.regStatus = data.regStatus;
      this.form.address = data.address;
      this.form.societyCode = data.societyCode;
    },
  }
}
</script>

<style lang="less" scoped>

</style>
