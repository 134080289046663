<template>
  <div class="organization">
    <ys-page-list
        keyID="CB586263FA72EA3B82147EB98EACBDE3"
        ref="table"
        :tableHeight="tableHeight"
        action="/manage/user/List"
        :params="params"
        @on-selection-change="checkItem"
        :multiBtn="multiBtn"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="姓名、电话或账号" style="width: 150px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template slot="btn">
        <ButtonGroup>
          <Button type="primary" @click="add">新增</Button>
        </ButtonGroup>
      </template>
    </ys-page-list>
    <ys-modal v-model="modShow" :width="650" title="新增/修改成员">
      <edit :modData="tableSelectItem" :orgId="orgId" :deptID="params.depId" @on-ok="getList"></edit>
    </ys-modal>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js' //引入混入组件tableList

export default {
  name: 'member',
  props: {
    orgId: {type: [Number, String], default: ''},
    depId: {type: [Number, String], default: ''},
  },
  mixins: [pageList],
  components: {
    'Edit': () => import('./edit.vue'),
  },
  data() {
    return {
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {minWidth: 120, title: '账号', key: 'username', align: 'center'},
        {minWidth: 100, title: '姓名', key: 'iname', align: 'center'},
        {minWidth: 180, title: '所在部门', key: 'depName', align: 'center'},
        {width: 210, title: 'email', key: 'mail', align: 'center'},
        {width: 140, title: '电话', key: 'phone', align: 'center'},
        {width: 130, title: '角色', key: 'roleName', align: 'center'},
        {width: 130, title: '职位', key: 'position', align: 'center'},
        {width: 100, title: '是否在职', key: 'jobStatus', align: 'center', renderConfig: {type: 'baseData', parentName: '是否'}}
      ],
      params: {
        depId: '',
        orgId: ''
      }
    }
  },
  watch: {
    depId: function () {
      this.params.depId = this.depId;
      this.getList()
    }
  },
  computed: {
    multiBtn() {
      return [
        {
          children: [
            {
              click: () => this.modShow = true,
              title: '编辑',
              icon: 'md-create',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.delItem('/manage/user/Remove', {orgId: this.params.orgId, userId: this.tableSelectItem[0].userId}),
              iconColor: '#ed4014',
              title: '删除',
              icon: 'md-trash',
              disabled: this.tableSelectItem.length !== 1
            },
          ]
        },
      ]
    }
  },

  mounted() {
    this.params.orgId = this.orgId || this.userInfo.org_id;
    this.params.depId = this.depId;
    this.getList();
  },
}
</script>
