<template>
  <div>
    <Form ref="form" class="editForm" :model="form" :label-width="140" :style="{height:'calc(100vh - 205px)'}" style="overflow-y: auto">
      <FormItem label="专家准入审核" prop="checkExpert">
        <i-switch v-model="form.checkExpert" :true-value="1" :false-value="0">
          <span slot="open">是</span>
          <span slot="close">否</span>
        </i-switch>
      </FormItem>
      <FormItem label="保险机构准入审核" prop="checkInsOrg">
        <i-switch v-model="form.checkInsOrg" :true-value="1" :false-value="0">
          <span slot="open">是</span>
          <span slot="close">否</span>
        </i-switch>
      </FormItem>
      <FormItem label="服务机构准入审核" prop="checkSrvOrg">
        <i-switch v-model="form.checkSrvOrg" :true-value="1" :false-value="0">
          <span slot="open">是</span>
          <span slot="close">否</span>
        </i-switch>
      </FormItem>
      <FormItem label="报告需保险公司审核" prop="checkReport">
        <i-switch v-model="form.checkReport" :true-value="1" :false-value="0">
          <span slot="open">是</span>
          <span slot="close">否</span>
        </i-switch>
      </FormItem>
      <FormItem label="允许创建保单" prop="insCanCreatePolicy">
        <i-switch v-model="form.insCanCreatePolicy" :true-value="1" :false-value="0">
          <span slot="open">是</span>
          <span slot="close">否</span>
        </i-switch>
      </FormItem>
      <FormItem label="允许创建项目" prop="insCanCreateProject">
        <i-switch v-model="form.insCanCreateProject" :true-value="1" :false-value="0">
          <span slot="open">是</span>
          <span slot="close">否</span>
        </i-switch>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok('/systemConfig/SetCheckConfig')">确定并保存</Button>
    </div>
  </div>
</template>

<script>

import {getSystemConfig} from "@/api/base";
import edit from "@/mixins/edit";

export default {
  name: 'set',
  mixins:[edit],
  data() {
    return {
      form: {
        checkAmend: 0,//整改单是否需要审核
        checkExpert: 1,//专家准入是否需要审核
        checkInsOrg: 1,//保险公司准入是否需要审核
        checkReport: 1,//报告是否需要保险公司审核
        checkSrvOrg: 1,//服务机构准入是否需要审核
        insCanCreatePolicy: 0,//是否允许创建保单
        insCanCreateProject: 0,//是否允许创建项目
        insCanUpdatePolicy: 0,
        insCanUpdateProject: 0,
      },
    }
  },
  mounted() {
    this.getSystemConfig()
  },
  methods: {
    getSystemConfig() {
      getSystemConfig().then(res => {
        if (res.code === 200) {
          Object.keys(this.form).forEach(item => {
            this.form[item] = res.data[item];
          })
        }
      })
    },
  }
}
</script>
<style lang="less" scoped>

</style>