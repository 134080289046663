<template>
  <div>
    <Form ref="form" class="editForm twoPartsEditForm" :model="form" :rules="ruleValidate" inline :label-width="80" :style="{maxHeight:'calc(100vh - 200px)'}" style="overflow-y: auto">
      <FormItem label="账号" prop="username">
        <Input v-model="form.username" :disabled="!!form.userId" placeholder="账号"></Input>
      </FormItem>
      <FormItem label="姓名" prop="iname">
        <Input type="text" v-model="form.iname" autocomplete="off" placeholder="姓名"></Input>
      </FormItem>
      <FormItem label="密码" prop="password" v-if="!(modData.length > 0)">
        <Input type="password" password v-model="form.password" placeholder="密码"></Input>
      </FormItem>
      <FormItem label="确认密码" prop="re_password" v-if="!(modData.length > 0)">
        <Input type="password" password v-model="form.re_password" placeholder="确认密码"></Input>
      </FormItem>
      <FormItem label="手机号" prop="phone">
        <Tooltip transfer content="该号码较为重要，会用于接收短信通知，请正确填写！" max-width="225">
          <Input v-model="form.phone" autocomplete="off" placeholder="请输入手机号" style="width: 225px;"></Input>
        </Tooltip>
      </FormItem>
      <FormItem label="邮箱" prop="mail">
        <Input v-model="form.mail" placeholder="请输入邮箱"></Input>
      </FormItem>
      <FormItem label="所属部门" prop="depId">
        <Cascader :data="departData" v-model="departArray" change-on-select filterable @on-change="deptChange"></Cascader>
      </FormItem>
      <FormItem label="用户角色" prop="roleId">
        <Select v-model="form.roleId" placeholder="请选择角色">
          <Option v-for="(item,index) in roleList" :value="item.id" :key="index">{{ item.iname }}</Option>
        </Select>
      </FormItem>
      <FormItem label="职位" prop="position">
        <Input v-model="form.position" placeholder="职位"></Input>
      </FormItem>
      <FormItem label="在职状态" prop="jobStatus">
        <Select v-model="form.jobStatus" placeholder="请选择在职状态">
          <Option v-for="(item,index) in baseData['是否']" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok('/manage/user/AddOrEdit')">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>

<script>
import edit from '@/mixins/edit'
import {getUserInfo, getRoleList, getDepartmentList} from "@/api/department";
import {getRecorderAryByDeep} from "ys-admin";

export default {
  name: '',
  mixins: [edit],
  props: {
    orgId: {type: [Number, String], default: ''},
    deptID: {
      type: String,
      default: null
    },
  },
  data() {
    return {
      departData: [],
      departArray: [],
      roleList: [],
      ruleValidate: {
        phone: [
          {required: true, pattern: this.$reg.phoneOrTelReg.reg, message: this.$reg.phoneOrTelReg.tips, trigger: 'blur'},
        ],
        mail: [
          {pattern: this.$reg.emailReg.reg, message: this.$reg.emailReg.tips, trigger: 'blur'},
        ],
        username: [
          {required: true, message: '账户不能为空！', trigger: 'blur'}
        ],
        iname: [
          {required: true, message: '姓名不能为空！', trigger: 'blur'}
        ],
        deptId: [
          {required: true, type: 'number', message: '请选择部门！', trigger: 'change'}
        ],
        rePassword: [
          {
            required: true, trigger: 'blur', validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('请再次输入密码!'));
              } else if (value !== this.form.password) {
                callback(new Error('两次密码输入不一致，请确认!'));
              } else {
                callback();
              }
            }
          },
        ],
        password: [
          {required: true, message: '密码不能为空！', trigger: 'blur'}
        ],
      },
      form: {
        depId: '',
        userId: '',
        orgId: '',
        position: '',
        jobStatus: 1,
        iname: '',
        areas: [],
        roleId: '',
        username: '',
        phone: '',
        mail: '',
        password: '',
        rePassword: '',
      }
    }
  },
  async mounted() {
    this.form.depId = this.deptID;
    this.form.orgId = this.orgId || this.userInfo.org_id;
    await this.getDeptRole()
    if (this.modData.length > 0) {
      await getUserInfo({orgId: this.form.orgId, userId: this.modData[0].userId}).then((res) => {
        Object.keys(this.form).forEach((item) => {
          if (item === 'areas') {
            this.form.areas = (res.data.areas || []).map((subItem) => {
              subItem.area = subItem.countyCode || subItem.cityCode || subItem.provinceCode;
              return subItem;
            });
          } else {
            this.form[item] = res.data[item]
          }
        });

      })
    }
    this.departArray = getRecorderAryByDeep({targetData: this.departData, targetValue: this.form.depId});
  },

  methods: {
    addArea() {
      this.form.areas.push({
        cityCode: "",
        countyCode: "",
        provinceCode: "",
        area: "",
      })
    },

    delArea(index) {
      this.form.areas.splice(index, 1);
    },

    getArea(data, index) {
      if (index >= 0) {
        this.form.areas[index].provinceCode = data[0] ? data[0].code : '';
        this.form.areas[index].cityCode = data[1] ? data[1].code : '';
        this.form.areas[index].countyCode = data[2] ? data[2].code : '';
      }
    },

    async getDeptRole() {
      await this.$all([
        getDepartmentList({orgId: this.form.orgId, limit: -1}),
        getRoleList({category:6,orgId: null, limit: -1})
      ]).then((res) => {
        this.departData = res[0].data || [];
        this.roleList = res[1].data.list || []
      });
    },

    deptChange(value) {
      this.form.depId = value[value.length - 1]
    }
  }


}
</script>
