<template>
  <div>
    <ys-page-list
        ref="table"
        :params="params"
        :multiBtn="multiBtn"
        action="/taskScheduler/exec/List"
        :tableHeight="tableHeight"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="关键词" clearable style="width: 150px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template slot="btn">

      </template>
      <template slot="detail">
        <Detail :mid="detailID"></Detail>
      </template>
    </ys-page-list>

  </div>
</template>

<script>
import pageListMixins from '@/mixins/pageList';
import {downByFileStream} from 'ys-admin'

export default {
  name: 'corp',
  mixins: [pageListMixins],
  components: {
    'Detail': () => import('@/views/projects/list/detail.vue')
  },
  data() {
    return {
      params: {
        orgId: '',
      },
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 50, align: 'center'},
        {minWidth: 250, title: '编号', tooltip: true, key: 'code', align: 'left'},
        {minWidth: 230, title: '任务类型', tooltip: true, key: 'name', align: 'left'},
        {minWidth: 180, title: '类型', key: 'taskType', align: 'left',},
        {width: 100, title: '状态', tooltip: true, key: 'status'},
        {width: 150, title: '创建时间', tooltip: true, key: 'createdAt'},
        {width: 150, title: '更新时间', tooltip: true, key: 'updatedAt'},
      ]
    }
  },
  computed: {
    multiBtn() {
      return [
        {
          children: [
            {
              click: () => this.downFile(),
              title: '下载',
              icon: 'md-arrow-round-down',
              disabled: this.tableSelectItem.length !== 1
            },
          ]
        }
      ]
    },
  },
  mounted() {
    this.params.schedulerId = this.mid;
    this.getList()
  },
  methods: {
    downFile() {
      downByFileStream({
        url: '/taskScheduler/exec/GetLog?id='+this.tableSelectItem[0].id,
        method:'get',
        fileName:this.tableSelectItem[0].code+'.txt',
        params: {},
      })
    },
  }
}
</script>
