import { render, staticRenderFns } from "./editCorp.vue?vue&type=template&id=c665aa26&scoped=true&"
import script from "./editCorp.vue?vue&type=script&lang=js&"
export * from "./editCorp.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c665aa26",
  null
  
)

export default component.exports