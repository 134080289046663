<template>
  <div>
    <ys-page-list
        ref="table"
        action="/standard/dictReason/List"
        keyID="C0D908D826910EA97C87C9BF3CA6D26B"
        :params="params"
        :tableHeight="height-250"
        :multiBtn="multiBtn"
        noDataText="请先选择左侧项"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="关键词" clearable style="width: 150px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template slot="btn">
        <ButtonGroup>
          <Button type="primary" @click="add" :disabled="!params.categoryId">新增风险依据</Button>
        </ButtonGroup>
      </template>
      <template slot="detail">
      </template>
    </ys-page-list>
    <ys-modal v-model="modShow" :width="600" title="新增/修改评依据">
      <editReason :midData="midData" @on-ok="getList" :mid="detailID"></editReason>
    </ys-modal>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'

export default {
  name: 'detail-list',
  mixins: [pageList],
  components: {
    'editReason': () => import('@/views/base/standard/editReason.vue'),
  },
  data() {
    return {
      params: {
        categoryId: '',
        dictId: '',
        dictItemId: '',
        keyword: '',
      },
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {width: 60, title: '序号', key: 'index', align: 'center'},
        {minWidth: 230, title: '风险依据', key: 'desc', align: 'left'},
        {width: 150, title: '款项', key: 'remarks', align: 'left'},
        {width: 200, title: '风险大项名称', key: 'dictItemName', align: 'left'},
        {width: 200, title: '风险表名称', key: 'dictName', align: 'left'},
        {width: 200, title: '总项名称', key: 'categoryName', align: 'left'},
      ]
    }
  },
  computed: {
    multiBtn() {
      return [
        {
          children: [
            {
              click: () => this.modShow = true,
              title: '编辑',
              icon: 'md-create',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.delItem('/standard/dictReason/Remove', {ids: this.tableSelectItem.map(item => item.id)}),
              iconColor: '#ed4014',
              title: '删除',
              icon: 'md-trash',
              disabled: this.tableSelectItem.length === 0
            },
          ]
        }
      ]
    },
  },
  watch: {
    midData: function (data) {
      if (data.length === 1) {
        this.params.categoryId = '';
        this.params.dictId = '';
        this.params.dictItemId = '';
        if (data[0].categoryId) {
          this.params.categoryId = data[0].categoryId;
          if (data[0].dictId) {
            this.params.dictId = data[0].dictId;
            this.params.dictItemId = data[0].id;
          } else {
            this.params.dictId = data[0].id;
          }
        } else {
          this.params.categoryId = data[0].id;
        }
        this.params.page = 1;
        this.getList();
      }
    }
  },
  methods: {}
}
</script>
<style lang="less" scoped>

</style>
