<template>
  <!-- 查看详情弹出框 -->
  <ys-detail :icon="detailIcon" :image="'/web/resource/GetFile/'+detail.pictureId+'?size=100'" :loading="loading" :name="detail.iname">
    <Tag size="medium" color="blue" slot="status">{{ detail.status|getBaseDataName('专家状态') }}</Tag>
    <template slot="brief">
      <ys-cell title="姓名" :label="detail.iname"/>
      <ys-cell title="联系电话" :label="detail.phone"/>
      <ys-cell title="身份证" :label="detail.identityCode"/>
      <ys-cell title="资质" :label="detail.position"/>
    </template>
    <template slot="main-left" v-if="!loading">
      <Tabs v-model="tab" name="detail" type="card" :key="detail.id">
        <Tab-pane label="基本信息" name="1" icon="ios-paper">
          <base-info :detail="detail" v-if="tab==='1'"/>
        </Tab-pane>
        <Tab-pane label="服务范围" name="3" icon="md-time" v-if="detail.type === 0 || detail.type === 20">
          <experts-service v-if="tab==='3'" :mid="detailID" :tableHeight="pageListHeight"/>
        </Tab-pane>
        <Tab-pane label="打卡记录" name="2" icon="ios-navigate">
          <sign :tableHeight="pageListHeight" :mid="detail.userId" v-if="tab==='2'"></sign>
        </Tab-pane>
        <Tab-pane label="任务列表" name="4" icon="md-list-box">
          <task :tableHeight="pageListHeight" :userId="detail.userId" v-if="tab==='4'&&detail.userId"></task>
        </Tab-pane>
        <Tab-pane label="相关附件" name="6" icon="md-document">
          <ys-resource :resourceType="3" :tableHeight="pageListHeight" :mid="detailID" v-if="tab==='6'"></ys-resource>
        </Tab-pane>
      </Tabs>
    </template>
    <template slot="main-right">
      <h1 class="h1Title">其他信息</h1>
      <div style="text-align: center">暂未开放！</div>
    </template>
  </ys-detail>
</template>

<script>
import detailMixins from '@/mixins/detail'
import {expertInfo} from "@/api/expert";
import BaseInfo from "@/views/experts/detail/baseInfo.vue";
import ExpertsService from "@/views/experts/detail/service/index.vue";
import task from "_c/task";
import sign from "@/views/sign/index.vue";

export default {
  name: "detail",
  mixins: [detailMixins],
  components: {
    ExpertsService,
    sign,
    task,
    BaseInfo,
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.loading = true;
      expertInfo({id: this.detailID}).then(res => {
        this.loading = false;
        if (res.code === 200) {
          this.detail = res.data;
        }
        this.setTagName(this.detail.iname)
      }).catch(() => {
        this.loading = false;
      })
    }
  }
}
</script>

<style scoped>

</style>