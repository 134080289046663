<template>
  <div>
    <Split v-model="split" :style="{height:'calc(100vh - 200px)'}" style="overflow-y: auto;">
      <div slot="left" class="nodeLeft">
        <Form ref="form" class="editForm twoPartsEditForm" :model="form" :rules="ruleValidate" :label-width="80" inline>
          <FormItem label="名称" prop="name">
            <Input v-model="form.name" placeholder="名称"/>
          </FormItem>
          <FormItem label="类型" prop="type">
            <Select v-model="form.type" transfer placeholder="类型">
              <Option v-for="item in baseData['审核流类型']" :value="item.value" :key="item.value">{{ item.name }}</Option>
            </Select>
          </FormItem>
          <FormItem label="所属机构" prop="orgId" style="width: 100%">
            <Input v-model="form.orgName" disabled placeholder="不选择则为系统级角色">
              <Button slot="append" @click="orgFlag=true">选择机构</Button>
            </Input>
          </FormItem>
          <FormItem label="备注" prop="desc" style="width: 100%">
            <Input v-model="form.desc" type="textarea" maxlength="128" show-word-limit :autosize="{minRows: 3,maxRows: 10}" placeholder="备注"></Input>
          </FormItem>
        </Form>
      </div>
      <div slot="right" class="nodeRight">
        <Button type="primary" @click="nodeShow=true" v-if="form.children.length===0">新增审批节点</Button>
        <ul v-else class="node">
          <li v-for="(item,index) in form.children" :key="index">
            <div class="itemBox">
              <h1 class="title">
                <span class="nodeName">{{ item.name }}</span>
                <Icon type="md-close" class="closeNode" size="14" @click="delNode(index)"/>
              </h1>
              <div class="userList">
                <p>审批人:
                  <Tag v-for="(subItem,subIndex) in item.children" closable @on-close="delUser(index,subIndex)" :key="subIndex">{{ subItem.username }}</Tag>
                  <Button size="small" @click="addUser(index)">
                    <Icon type="md-add-circle"/>
                  </Button>
                </p>
                <p v-if="item.desc">备注：{{ item.desc }}</p>
              </div>
            </div>
            <div class="addNode">
              <Button type="primary" icon="md-add" class="btn" shape="circle" @click="addNode(index+1)"></Button>
            </div>
          </li>
        </ul>
      </div>
    </Split>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok('/orgApprovalConfig/AddOrEdit')">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
    <ys-modal v-model="orgFlag" :width="1200" title="选择机构">
      <org-component :limit="1" @on-ok="getData"></org-component>
    </ys-modal>
    <ys-modal v-model="nodeShow" :width="500" title="新增/修改审批节点">
      <NodeEdit :orgId="form.orgId" :mid="detailID" @on-ok="getNodeData"></NodeEdit>
    </ys-modal>
  </div>
</template>
<script>
import edit from '@/mixins/edit'
import {approvalInfo} from "@/api/approval";
import OrgComponent from "_c/org";
import NodeEdit from '@/views/base/approval/editNode'
// import orgUser from '@/components/users'

export default {
  name: 'projectEdit',
  components: {NodeEdit, OrgComponent},
  mixins: [edit],
  data() {
    return {
      ruleValidate: {
        name: [
          {required: true, message: '请填写审批流名称', trigger: 'blur'}
        ],
        orgId: [
          {required: true, type: 'number', message: '请选择所属机构', trigger: 'change'}
        ],
      },
      split: 0.6,
      nodeShow: false,
      orgFlag: false,
      detailID: '',
      index: 0,
      form: {
        id: '',
        orgName: '',
        type: 901,
        orgId: '',
        children: [],
        name: '',
        desc: '',
      },
    }
  },
  computed: {},
  mounted() {
    if (this.mid) {
      this.modal.spinShow = true;
      approvalInfo({id: this.mid}).then(res => {
        if (res.code === 200) {
          Object.keys(this.form).forEach((item) => {
            this.form[item] = res.data[item]
          })
          this.modal.spinShow = false;
        }
      })
    }
  },
  methods: {
    addUser(index) {
      this.index = index || 0;
      this.userFlag = true;
    },
    delUser(index, subIndex) {
      this.form.children[index].children.splice(subIndex, 1);
    },
    delNode(index) {
      this.form.children.splice(index, 1);
    },
    addNode(index) {
      this.index = index || 0;
      this.nodeShow = true;
    },
    getNodeData(data) {
      this.form.children.splice(this.index, 0, data);
    },
    getUserData(data) {
      this.form.children[this.index].children.push({
        userId: data[0].id,
        username: data[0].iname,
      })
    },
    getData(data) {
      this.form.orgName = data.map((item) => {
        return item.iname
      }).join(',')
      this.form.orgId = data[0].id
    }
  }
}
</script>

<style lang="less" scoped>
.nodeRight {
  text-align: center;
  padding: 0 30px;
}

.node {
  li {
    list-style: none;
    text-align: center;

    .itemBox {
      border: 1px solid #ececec;

      .title {
        padding: 5px 10px;
        font-size: 12px;
        font-weight: normal;
        text-align: left;
        border-bottom: 1px solid #ececec;
        background-color: #f4f4f4;
        display: flex;
        align-items: center;

        .nodeName {
          flex: 1;
        }
      }

      .userList {
        padding: 5px 10px;
        text-align: left;
        font-size: 12px;
      }
    }

    .addNode {
      width: 1px;
      background-color: #ececec;
      height: 50px;
      margin: 0 auto;
      position: relative;

      .btn {
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -16px;
        margin-top: -16px;
      }
    }
  }
}
</style>


