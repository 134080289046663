<template>
  <div class="home">
    <ys-page-list
        ref="table"
        action="organization/List"
        :params="params"
        keyID="46676DE8596B11EC9C5C00163E15C775"
        :multiBtn="multiBtn"
        showContextMenu
        @on-selection-change="checkItem"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="组织类型" prop="oType">
          <Select v-model="params.oType" clearable placeholder="组织类型" style="width: 120px">
            <Option v-for="(item,index) in baseData['组织类型']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="机构类型" prop="type">
          <Select v-model="params.type" clearable placeholder="机构类型" style="width: 120px">
            <Option v-for="(item,index) in baseData['组织子类型']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="办公区域" prop="region">
          <ysRegion changeOnSelect placeholder="办公区域" v-model="params.region" @on-change="getRegion"></ysRegion>
        </FormItem>
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="关键词" clearable style="width: 150px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template #btn>
        <ButtonGroup>
          <Button type="primary" @click="add">新增机构</Button>
          <Dropdown placement="bottom-end">
            <Button type="primary">
              更多
              <Icon type="ios-arrow-down"></Icon>
            </Button>
            <DropdownMenu slot="list">
              <DropdownItem @click.native="exchangeFlag=true">数据迁移</DropdownItem>
              <DropdownItem @click.native="batchAccount">批量生成账号</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </ButtonGroup>

      </template>
      <template slot="detail">
        <detail :mid="detailID"></detail>
      </template>
    </ys-page-list>
    <ys-modal v-model="modShow" :width="900" title="新增/修改机构">
      <Edit :mid="detailID" @on-ok="getList"></Edit>
    </ys-modal>
    <ys-modal v-model="exchangeFlag" :width="500" title="数据迁移">
      <exchange @on-ok="getList"></exchange>
    </ys-modal>
    <ys-modal v-model="rateFlag" :width="800" title="新增/修改配置">
      <Rate :parentID="detailID" @on-ok="getList"></Rate>
    </ys-modal>
    <ys-modal v-model="syncFlag" :width="800" title="同步全国工商库">
      <sync :mid-data="tableSelectItem" @on-ok="getList"></sync>
    </ys-modal>
    <ys-modal v-model="statusFlag" :width="800" title="审批">
      <doApproval @on-ok="getList" :resourceId="detailID" :resourceType="19"></doApproval>
    </ys-modal>
    <ys-modal v-model="addApprovalFlag" :width="400" title="提交审批">
      <addApproval @on-ok="getList" :resourceId="detailID" :resourceType="19" :approvalConfig="approvalConfig"></addApproval>
    </ys-modal>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'
import Detail from "@/views/org/list/detail.vue";
import exchange from "@/views/org/list/exchange.vue";
import Edit from "@/views/org/list/edit.vue";
import sync from "@/views/org/list/sync.vue";
import Rate from "@/views/org/list/detail/rate/edit.vue";
import doApproval from "@/views/base/approval/doApproval.vue";
import addApproval from "@/views/base/approval/addApproval.vue";
import {initOrgUser, orgEnable, orgDisable} from "@/api/org";

export default {
  name: 'projects',
  mixins: [pageList],
  components: {addApproval, doApproval, exchange, Detail, Edit, sync, Rate},
  data() {
    return {
      approvalConfig: {
        args: {}
      },
      params: {
        countyCode: '',
        cityCode: '',
        onlyTopOrg: false,
        provinceCode: '',
        oType: '',
        keyword: '',
        type: '',
        region: '',
      },
      addApprovalFlag: false,
      statusFlag: false,
      syncFlag: false,
      exchangeFlag: false,
      rateFlag: false,
      headerColumns: [
        {type: 'selection', title: '选择框', width: 50, align: 'center'},
        {type: 'index', title: '序号', width: 50, align: 'center'},
        {width: 75, title: 'logo', key: 'logo', align: 'center', renderConfig: {type: 'image'}},
        {minWidth: 230, title: '机构名称', key: 'iname', align: 'left', renderConfig: {type: 'gotoDetail'}},
        {minWidth: 230, title: '上级机构名称', key: 'parentName', align: 'left',},
        {width: 100, title: '组织类型', key: 'oType', align: 'center', renderConfig: {type: 'baseData', parentName: '组织类型'}},
        {width: 100, title: '机构类型', key: 'type', align: 'center', renderConfig: {type: 'baseData', parentName: '组织子类型'}},
        {width: 130, title: '机构简称', key: 'shortName', align: 'left'},
        {width: 200, title: '社会信用代码', key: 'societyCode', align: 'left'},
        {width: 100, title: '联系人', key: 'contacts', align: 'left'},
        {width: 150, title: '联系电话', key: 'phone', align: 'left'},
        {width: 230, title: '办公地所在区域', key: 'countyCode', align: 'left', renderConfig: {type: 'area', key: ['countyCode', 'cityCode', 'provinceCode']}},
        {width: 180, title: '创建时间', key: 'createdAt', align: 'left'},
        {width: 180, title: '最近修改时间', key: 'updatedAt', align: 'left'},
        {width: 180, title: 'uuid', key: 'uuid', align: 'left'},
        {width: 180, title: '上次同步时间', key: 'syncAt', align: 'left'},
        {width: 100, title: '虚拟机构', key: 'virtual', align: 'center', renderConfig: {type: 'baseData', parentName: '是否'}},
        {width: 100, title: '机构状态', key: 'status', align: 'center', renderConfig: {type: 'baseData', parentName: '机构状态'}},
      ]
    }
  },
  computed: {
    multiBtn() {
      return [
        {
          children: [
            {
              type: 'detail',
              click: () => this.modShow = true,
              title: '查看详情',
              targetUrl: '/main/org/list/detail/' + (this.tableSelectItem.length === 1 ? this.tableSelectItem[0].id : null),
              icon: 'md-eye',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.modShow = true,
              title: '编辑',
              icon: 'md-create',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.delItem('/org/Remove'),
              iconColor: '#ed4014',
              title: '删除',
              icon: 'md-trash',
              disabled: this.tableSelectItem.length !== 1
            },
          ]
        },
        {
          children: [
            {
              click: () => this.rateFlag = true,
              title: '份额配置',
              icon: 'md-eye',
              disabled: this.tableSelectItem.length !== 1 || this.tableSelectItem[0].type !== 6,
              show: this.tableSelectItem.length === 1 && this.tableSelectItem[0]?.oType === 1,
            },
            {
              click: () => this.syncFlag = true,
              title: '同步全国工商库',
              icon: 'ios-refresh-circle-outline',
              disabled: this.tableSelectItem.length !== 1 || this.tableSelectItem[0].virtual === 1,
            },
          ]
        },
        {
          hiddenBtnIcon: true,
          children: [
            {
              click: () => this.enable(),
              title: '启用',
              icon: 'md-checkmark-circle',
              disabled: this.tableSelectItem.length === 0
            },
            {
              click: () => this.disable(),
              title: '停用',
              icon: 'md-close-circle',
              disabled: this.tableSelectItem.length === 0
            },
          ]
        },
        // {
        //   hiddenBtnIcon: true,
        //   children: [
        //     {
        //       click: () => this.subMitApproval(),
        //       title: '提交审批',
        //       icon: 'md-checkmark-circle',
        //       disabled: this.tableSelectItem.length === 0
        //     },
        //     {
        //       click: () => this.statusFlag = true,
        //       title: '审批',
        //       icon: 'md-close-circle',
        //       disabled: this.tableSelectItem.length === 0
        //     },
        //   ]
        // }
      ]
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    subMitApproval() {
      this.addApprovalFlag = true;
      this.approvalConfig.name = (this.tableSelectItem[0].status === 1 ? '停用 ' : '启用 ') + this.tableSelectItem[0].iname + ' 机构';
      this.approvalConfig.args.status = this.tableSelectItem[0].status === 1 ? 2 : 1
    },
    approval() {

    },
    batchAccount() {
      this.$Modal.confirm({
        title: '提示',
        content: '确认批量生成机构账户么？仅限无账户的机构，有账户的机构不会生成。',
        okText: '确认',
        cancelText: '取消',
        onOk: () => {
          initOrgUser({code: 1}).then(res => {
            if (res.code === 200) {
              this.$Notice.success({
                title: "提示！",
                desc: '操作成功！'
              });
              this.getList();
            }
          })
        }
      })
    },
    enable() {
      this.$Modal.confirm({
        title: '提示',
        content: '确定启用该机构么？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          orgEnable({Ids: this.tableSelectItem.map((item) => item.id)}).then((res) => {
            if (res.code === 200) {
              this.$Notice.success({
                title: "提示！",
                desc: '操作成功！'
              });
              this.getList();
            }
          })
        }
      })
    },
    disable() {
      this.$Modal.confirm({
        title: '提示',
        content: '确定停用该机构么？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          orgDisable({Ids: this.tableSelectItem.map((item) => item.id)}).then((res) => {
            if (res.code === 200) {
              this.$Notice.success({
                title: "提示！",
                desc: '操作成功！'
              });
              this.getList();
            }
          })
        }
      })
    },
    getRegion(data) {
      this.params.countyCode = data[2] ? data[2].code : '';
      this.params.cityCode = data[1] ? data[1].code : '';
      this.params.provinceCode = data[0] ? data[0].code : '';
    }
  }
}
</script>
