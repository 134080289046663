<template>
  <div>
    <Form ref="form" class="editForm twoPartsEditForm" :model="form" :rules="ruleValidate" inline :label-width="80" :style="{maxHeight:'calc(100vh - 200px)'}" style="overflow-y: auto">
<!--      <FormItem label="所属名称" prop="orgName">-->
<!--        <Input v-model="form.orgName" disabled placeholder="机构名称">-->
<!--          <Button slot="append" @click="bindOrg=true">选择机构</Button>-->
<!--        </Input>-->
<!--      </FormItem>-->
      <FormItem label="姓名" prop="iname">
        <Input v-model="form.iname" placeholder="姓名"></Input>
      </FormItem>
      <FormItem label="账户" prop="userName">
        <Input v-model="form.userName" :disabled="!!form.id" placeholder="账户"></Input>
      </FormItem>
      <FormItem label="密码" prop="password" v-if="!mid">
        <Input type="password" autocomplete="new-password" v-model="form.password" placeholder="密码"></Input>
      </FormItem>
      <FormItem label="电话" prop="phone">
        <Tooltip transfer content="该号码较为重要，会用于接收短信通知，请正确填写！" max-width="300">
          <Input v-model="form.phone" autocomplete="off" placeholder="电话" style="width: 300px;"></Input>
        </Tooltip>
      </FormItem>
      <FormItem label="身份号码" prop="identityCode">
        <Input v-model="form.identityCode" placeholder="身份号码"></Input>
      </FormItem>
      <FormItem label="邮箱" prop="mail">
        <Input v-model="form.mail" placeholder="邮箱"></Input>
      </FormItem>
      <FormItem label="用户角色" prop="roleId" v-if="!!orgId">
        <Select v-model="form.roleId" placeholder="请选择角色">
          <Option v-for="(item,index) in roleList" :value="item.id" :key="index">{{ item.iname }}</Option>
        </Select>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok(orgId?'/org/user/AddOrEdit':'/user/AddOrEdit')">保存</Button>
      <Button @click="close">取消</Button>
    </div>

    <ys-modal v-model="bindOrg" :width="1000" title="选择机构">
      <org-component @on-ok="getData" :limit="1"></org-component>
    </ys-modal>
  </div>
</template>
<script>
import edit from '@/mixins/edit'
import {userInfo, roleList} from "@/api/org";
import {all} from "ys-admin"
import OrgComponent from "_c/org";

export default {
  name: 'projectEdit',
  mixins: [edit],
  components: {OrgComponent},
  props: {orgId: {type: Number, default: null}},
  data() {
    return {
      ruleValidate: {
        iname: [
          {required: true, message: '请填写姓名', trigger: 'blur'}
        ],
        password: [
          {required: true, type: 'string', message: '请输入密码', trigger: 'blur'}
        ],
        userName: [
          {required: true, type: 'string', message: '请输入账户', trigger: 'blur'}
        ],

        societyCode: [
          {required: true, pattern: this.$reg.unifiedSocialCreditReg.reg, message: this.$reg.unifiedSocialCreditReg.tips, trigger: 'blur'}
        ],
        type: [
          {required: true, type: 'number', message: '请选择类型', trigger: 'change'}
        ],
        phone: [
          {required: true, pattern: this.$reg.phoneOrTelReg.reg, message: this.$reg.phoneOrTelReg.tips, trigger: 'blur'}
        ],
      },
      bindOrg: false,
      form: {
        id: '',
        photoId: '',
        iname: '',
        userName: '',
        isAdmin: 0,
        orgId: '',
        password: '',
        identityCode: '',
        mail: '',
        phone: '',
        roleId: '',
      },
      roleList: [],
    }
  },
  async mounted() {
    let category = 1
    switch(this.midData[0].oType) {
      case 1:
        category = 2
        break;
      case 2:
        category = 1
        break;
      case 3:
        category = 4
        break;
      case 4:
        category = 3
        break;
      case 99:
        category = 6
        break;
    }
    await all([roleList({category: category, orgId: this.midData[0].id}), roleList({category: category, orgId: 0})]).then(res => {
      this.roleList = (res[0].data.list || []).concat((res[1].data.list || []))
    })
    if (this.mid) {
      await userInfo({id: this.mid}).then((res) => {
        Object.keys(this.form).forEach((item) => {
          this.form[item] = res.data[item]
        })
        this.form.iname = res.data.name;
        this.form.orgId = this.orgId;
        this.form.roleId = res.data.orgs[0].roleId
      })
    } else {
      this.form.orgId = this.orgId;
    }
  },
  methods: {
    getData(data) {
      this.form.orgName = data[0].iname;
      this.form.orgId = data[0].id;
    },
  }
}
</script>

<style lang="less" scoped>
</style>
