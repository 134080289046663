<template>
  <div class="home">
    <ys-page-list
        ref="table"
        :action="'/page-list/GetPageList'"
        :params="params"
        keyID="C14AC39B41CD1DEEDFEA39A46CB0AE14"
        :multiBtn="multiBtn"
        :tableHeight="tableHeight"
        showContextMenu
        :multiColumn="true"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="普通员工配置" prop="isSelf">
          <Select v-model="params.isSelf" clearable="" style="width: 120px;">
            <Option v-for="(item,index) in baseData['是否']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="平台类型" prop="platFormType">
          <Select v-model="params.platFormType" clearable="" style="width: 120px;">
            <Option v-for="(item,index) in baseData['平台类型']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="项目名称" clearable style="width: 150px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template slot="btn">

      </template>
      <template slot="detail">
        <detail :mid="detailID" :keyId="tableSelectItem.length===1?tableSelectItem[0]['keyId']:''"></detail>
      </template>
    </ys-page-list>
    <ys-drawer v-model="modShow" :width="100">
      <ys-page-list-column manager @on-close="modShow=false" @on-ok="getList" :pageListId="detailID" :keyID="tableSelectItem.length===1?tableSelectItem[0]['keyId']:''"></ys-page-list-column>
    </ys-drawer>
    <ys-modal v-model="setGlobalFlag" :width="400" title="分发配置">
      <global :mid="detailID" :orgId="tableSelectItem.length===1?tableSelectItem[0].orgId:''" @on-ok="getList"></global>
    </ys-modal>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'
import Detail from "@/views/base/pageListSet/detail";
import global from "@/views/base/pageListSet/global";

export default {
  name: 'projects',
  mixins: [pageList],
  components: {Detail,global},
  data() {
    return {
      params: {
        platFormType: '',
        isSelf: '',
        keyword: '',
      },
      setGlobalFlag: false,
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 50, align: 'center'},
        {width: 150, title: '名称', key: 'name', align: 'left'},
        {width: 120, title: '平台类型', key: 'platFormType', align: 'center', renderConfig: {type: 'baseData', parentName: '平台类型'}},
        {width: 120, title: '所属机构', key: 'OrgName', align: 'left',},
        {minWidth: 200, title: 'keyID', key: 'keyId', align: 'left', renderConfig: {type: 'gotoDetail'}},
        {width: 180, title: '接口地址', key: 'action', align: 'left'},
        {width: 120, title: '创建人', key: 'userName', align: 'center',},
        {width: 120, title: '普通员工配置', key: 'isSelf', align: 'center', renderConfig: {type: 'baseData', parentName: '是否'}},
        {width: 80, title: '版本号', key: 'versions', align: 'center',},
        {width: 80, title: '表头数', key: 'columnsCounts', align: 'center'},
        {width: 170, title: '创建时间', key: 'createdAt', align: 'center',},
      ]
    }
  },
  computed: {
    multiBtn() {
      return [
        {
          children: [
            {
              type: 'detail',
              title: '查看详情',
              icon: 'md-eye',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.modShow = true,
              title: '修改',
              icon: 'md-create',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.delItem('/page-list/Remove'),
              iconColor: '#ed4014',
              title: '删除',
              icon: 'md-trash',
              disabled: this.tableSelectItem.length !== 1
            },
          ]
        },
        {
          children: [
            {
              click: () => this.setGlobalFlag=true,
              title: '分发配置',
              icon: 'md-eye',
              disabled: this.tableSelectItem.length !== 1
            },
          ]
        }
      ]
    },
  },
  mounted() {
    this.getList();
  },
  methods: {

  }
}
</script>
