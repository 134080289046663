<template>
  <div class="planHome">
    <ys-page-list
        keyID="5FEDBDEF2D47E3F88B681866BD0B55EC"
        ref="table"
        action="/servePlan/List"
        :multiBtn="multiBtn"
        :tableHeight="resultHeight"
        :params="params"
        showContextMenu
        :multiColumn="true"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="请输入项目名称" style="width: 120px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template slot="btn">
        <ButtonGroup>
          <Button type="primary" @click="add()">新增服务计划</Button>
        </ButtonGroup>
      </template>
      <template slot="detail">
        <Detail :mid="detailID"></Detail>
      </template>
    </ys-page-list>
    <ys-modal v-model="modShow" :width="1000" :title="'新增/修改服务计划'">
      <Edit @on-ok="getList" :midData="midData" :mid="detailID"></Edit>
    </ys-modal>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'
import Edit from "@/views/task/plan/edit.vue";
import Detail from "@/views/task/plan/detail.vue";

export default {
  name: 'plan',
  mixins: [pageList],
  components: {
    Detail,
    Edit
  },
  data() {
    return {
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 70, align: 'center'},
        {width: 200, title: '保单号', key: 'policyNumber', align: 'left'},
        {width: 300, title: '计划名称', key: 'title', align: 'left', renderConfig: {type: 'gotoDetail'}},
        {width: 100, title: '组长', key: 'leaderName', align: 'left'},
        {minWidth: 200, title: '项目名称', key: 'projectName', align: 'left'},
        {width: 100, title: '开始日期', key: 'startAt', align: 'left', renderConfig: {type: 'formatDate'}},
        {width: 100, title: '结束日期', key: 'endAt', align: 'left', renderConfig: {type: 'formatDate'}},
        {
          width: 100, title: '总服务次数', key: 'serveCount', align: 'center', render: (h, params) => {
            return h('Tooltip', [
              h('div', {
                slot: 'content'
              }, [
                h('p', '安责险检查：' + params.row.serveCount),
                h('p', '专项检查：' + params.row.specialCount),
                h('p', '应急救援演练：' + params.row.rescueCount),
                h('p', '安全教育培训：' + params.row.safetyCount),
                h('p', '验收任务：' + params.row.inspectsCount),
              ]),
              h('Tag', {attrs: {color:"primary"}}, params.row.specialCount + params.row.serveCount + params.row.rescueCount + params.row.safetyCount + params.row.inspectsCount)
            ]);
          }
        },
        {minWidth: 200, title: '服务说明', key: 'desc', align: 'left'},
      ],
      params: {
        "orgId": "",
        "policyId": "",
        "keyword": "",
        "startAt": "",
        "endAt": "",
      }
    }
  },

  computed: {
    multiBtn() {
      return [
        {
          hiddenBtnIcon: true,
          children: [
            {
              type: 'detail',
              title: '查看详情',
              targetUrl: '/main/plan/detail/' + this.detailID,
              icon: 'md-eye',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.modShow = true,
              title: '修改',
              icon: 'md-create',
              disabled: this.tableSelectItem.length !== 1 || this.tableSelectItem[0].status > 1
            },
            {
              click: () => this.delItem('/servePlan/Remove', {ids: [this.tableSelectItem[0].id]}),
              iconColor: '#ed4014',
              title: '删除',
              icon: 'md-trash',
              disabled: this.tableSelectItem.length !== 1
            },
          ]
        },
      ]
    },
  },

  mounted() {
    this.params.policyId = this.mid;
    this.getList();
  }
}
</script>
