<template>
  <div>
    <div style="display: flex">
    <div slot="left" class="nodeLeft" v-if="approvalList.result===20">
      <Form ref="form" class="editForm" :model="form" :rules="ruleValidate" :label-width="80">
        <FormItem label="审核名称" prop="name" style="width: 100%">
          <Input v-model="approvalList.name" disabled ></Input>
        </FormItem>
        <FormItem label="审核结果" prop="status">
          <RadioGroup v-model="form.status">
            <Radio :label="item.value" v-for="item in baseData['审核结果'].filter(item=>item.value===30||item.value===40)" border :key="item.value">{{ item.name }}</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem label="备注" prop="desc" style="width: 100%">
          <Input v-model="form.desc" type="textarea" maxlength="128" show-word-limit :autosize="{minRows: 3,maxRows: 10}" placeholder="备注"></Input>
        </FormItem>
        <FormItem label="附件" prop="contractFileList" style="width: 100%;text-align: center">
          <ys-upload
              @on-success="getFile"
              :headers="{token:token}"
              ref="upload"
              type="fullList"
              :limit="1"
              action="/web/resource/UploadFile">
          </ys-upload>
        </FormItem>
      </Form>
    </div>
    <div slot="right" class="nodeRight" :style="{maxHeight:'calc(100vh - 200px)'}" style="overflow-y: auto;">
      <ul class="approvalLine">
        <li class="node" v-for="(item,index) in approvalList.children" :key="item.id">
          <div class="info">
            <div class="icon" :style="{backgroundColor:item.result===10?'#ccc':(item.result===20?'#f68f5b':(item.result===30?'#00bd17':'#ff0900'))}">
              <Icon type="md-cloud-upload" size="23" v-if="item.result===10"/>
              <Icon type="md-code-working" size="25" v-if="item.result===20"/>
              <Icon type="md-checkmark-circle" size="20" v-if="item.result===30"/>
              <Icon type="md-close-circle" size="25" v-if="item.result===40"/>
            </div>
            <div class="content">
              <p class="title">{{ item.name || '未知节点' }}</p>
              <p class="time">{{ item.resultAt || '待处理' }}</p>
            </div>
            <div class="person">
              <div class="avatar" :class="{active:item.index===pIndex}" v-for="(p,pIndex) in item.children" :key="p.id"
                   @click="showDetail(index,pIndex)"
              >
                <Avatar
                    :style="{backgroundColor:p.result===10?'#ccc':(p.result===20?'#f68f5b':(p.result===30?'#00bd17':'#ff0900'))}"
                    size="small"
                    :title="p.username"
                >{{ p.username }}
                </Avatar>
              </div>
            </div>
          </div>
          <div class="line">
            <div class="result" v-if="item.index>=0">
              <p>审批：{{ item.children[item.index] ? item.children[item.index].username : '' }} {{ (item.children[item.index] ? item.children[item.index].result : 10)|getBaseDataName('审核结果') }}</p>
              <p>说明：{{ item.children[item.index] ? item.children[item.index].desc : '' }}</p>
              <p>时间：{{ item.children[item.index] ? item.children[item.index].resultAt : '' }}</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
    </div>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="save" v-if="approvalList.result===20">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>
<script>
import edit from '@/mixins/edit'
import {doApprovalList, doApprovalInfo} from "@/api/approval";

export default {
  name: 'projectEdit',
  mixins: [edit],
  props: {
    resourceId: {type: Number,},
    resourceType: {type: Number,}
  },
  data() {
    return {
      index: 0,
      approvalList: [],
      form: {
        desc: '',
        status: 30,
        id: '',
        fileId: '',
      },
    }
  },
  computed: {},
  async mounted() {
    this.modal.spinShow = true;
    let id;
    await doApprovalList({orgId: this.userInfo.org_id, resourceId: this.resourceId, resourceType: this.resourceType, type: 1901}).then(res => {
      if (res.code === 200) {
        id = res.data.total > 0 ? res.data.list[0].id : null;
      }
    })
    if (id) await doApprovalInfo({id}).then(res => {
      if (res.code === 200) {
        this.approvalList = res.data;
        let that = this;
        const loopChild = function (targetAry, deep = 1, data) {
          targetAry.children.forEach((item, index) => {
            if (item.children) {
              item.index = -1;
              loopChild(item, deep + 1, item);
            } else {
              if (deep === 2 && item.result !== 10) {
                data.result = item.result;
                data.resultAt = item.resultAt;
                data.index = index;
              }
              if (item.result === 20) {
                that.form.id = item.id;
              }
            }
          })
        }
        loopChild(this.approvalList);
      }
    })
    this.modal.spinShow = false;
  },
  methods: {
    showDetail(i, j) {
      if (this.approvalList.children[i].children[j].result === 10) {
        return
      }
      this.approvalList.children[i].index = j;
    },
    getFile(data) {
      this.form.fileId = data.map((item) => item.id).join()
    },
    save() {
      this.ok(this.form.status === 30 ? '/orgApprovalResult/Pass' : '/orgApprovalResult/Fail')
    },
  }
}
</script>

<style lang="less" scoped>
.nodeRight {
  text-align: left;
  flex: 1;
  padding: 0 10px ;
}

.approvalLine {
  .node {
    list-style: none;

    .info {
      display: flex;
      align-items: center;

      .icon {
        background-color: #0a69f4;
        width: 40px;
        height: 40px;
        line-height: 44px;
        color: #ffffff;
        border-radius: 3px;
        text-align: center;
      }

      .content {
        padding-left: 10px;
        text-align: left;

        p.time {
          font-size: 12px;
        }
      }

      .person {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        .avatar {
          cursor: pointer;
          border-width: 2px;
          border-style: solid;
          border-color: transparent;
          margin-left: 3px;
          border-radius: 100px;
          overflow: hidden;
          width: 29.8px;
          height: 29px;
          text-align: center;

          &.active {
            box-shadow: 0 0 5px #0a69f4;
          }
        }
      }
    }

    .line {
      border-left: 2px solid #0a69f4;
      padding-left: 30px;
      margin: 5px 0 5px 20px;
      min-height: 20px;
      padding-bottom: 5px;

      .result {
        border: 1px solid #ececec;
        padding: 5px 10px;
        font-size: 12px;
        background-color: #f4f4f4;
      }
    }
  }
}

</style>


