<template>
  <div>
    <Form ref="form" class="editForm twoPartsEditForm" :model="form" :rules="ruleValidate" inline :label-width="100" :style="{maxHeight:'calc(100vh - 200px)'}" style="overflow-y: auto">
      <FormItem label="标准名称" prop="iname">
        <Input v-model="form.iname" placeholder="标准名称"></Input>
      </FormItem>
      <FormItem label="简称" prop="shortName">
        <Input v-model="form.shortName" placeholder="简称"></Input>
      </FormItem>
      <FormItem label="标准等级" prop="level">
        <Select v-model="form.level" transfer placeholder="标准等级">
          <Option v-for="(item,index) in baseData['标准等级']" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="标准编码" prop="code">
        <Input v-model="form.code" placeholder="标准编码"></Input>
      </FormItem>
      <FormItem label="行业类型" prop="type">
        <Select v-model="form.type" transfer placeholder="行业类型">
          <Option v-for="(item,index) in baseData['行业类型']" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="发布时间" prop="publishedAt">
        <ys-date-picker v-model="form.publishedAt" style="width: 100%;" placeholder="发布时间"></ys-date-picker>
      </FormItem>
      <FormItem label="实施时间" prop="implementAt">
        <ys-date-picker v-model="form.implementAt" style="width: 100%;" placeholder="实施时间"></ys-date-picker>
      </FormItem>
      <FormItem label="风险等级类型" prop="selectRiskGrades">
        <Select v-model="form.selectRiskGrades" multiple transfer placeholder="风险等级类型">
          <Option v-for="(item,index) in baseData['风险等级']" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="启停" prop="status" style="width: 25%;">
        <i-switch :true-value="1" :false-value="2" v-model="form.status" size="large">
          <span slot="open">启用</span>
          <span slot="close">停用</span>
        </i-switch>
      </FormItem>
      <FormItem label="扣分体系" prop="hasScore" style="width: 25%;">
        <i-switch :true-value="1" :false-value="2" v-model="form.hasScore" size="large">
          <span slot="open">开启</span>
          <span slot="close">关闭</span>
        </i-switch>
      </FormItem>
      <FormItem label="多选风险辨别" prop="multiRisk" style="width: 50%;">
        <i-switch :true-value="1" :false-value="2" v-model="form.multiRisk" size="large">
          <span slot="open">允许</span>
          <span slot="close">禁止</span>
        </i-switch>
      </FormItem>
      <FormItem label="是否送审" prop="isGovCheck" style="width: 25%;">
        <i-switch :true-value="1" :false-value="2" v-model="form.isGovCheck" size="large">
          <span slot="open">是</span>
          <span slot="close">否</span>
        </i-switch>
      </FormItem>
      <FormItem label="送审风险等级" prop="selectGovCheckRiskGrades" style="width: 75%;">
        <Select v-model="form.selectGovCheckRiskGrades" multiple transfer :disabled="form.isGovCheck !== 1" placeholder="风险等级类型">
          <Option v-for="(item,index) in baseData['风险等级']" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="是否发送短信" prop="needSms" style="width: 25%;">
        <i-switch :true-value="1" :false-value="2" v-model="form.needSms" size="large">
          <span slot="open">是</span>
          <span slot="close">否</span>
        </i-switch>
      </FormItem>
      <FormItem label="发送风险等级" prop="selectSmsGrades" style="width: 75%;">
        <Select v-model="form.selectSmsGrades" multiple transfer :disabled="form.needSms !== 1" placeholder="风险等级类型">
          <Option v-for="(item,index) in baseData['风险等级']" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="描述" prop="desc" style="width: 100%">
        <Input v-model="form.desc" type="textarea" maxlength="128" show-word-limit style="width: 100%" :autosize="{minRows: 3,maxRows: 5}" placeholder="描述"></Input>
      </FormItem>
      <h1 class="h1Title">等级配置规则</h1>
      <level :midData="form.levelList" ref="level" v-if="!loading"></level>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="save">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>
<script>
import edit from '@/mixins/edit'
import {getStandardInfo} from "@/api/base";
import level from "@/views/base/standard/detail/level/index";

export default {
  name: 'projectEdit',
  mixins: [edit],
  components: {level},
  props: {type: {type: String, default: null}},
  data() {
    return {
      loading: false,
      ruleValidate: {
        shortName: [
          {required: true, message: '请填写简称', trigger: 'blur'}
        ],
        iname: [
          {required: true, message: '请填写总项名称', trigger: 'blur'}
        ],
        score: [
          {required: true, type: 'number', message: '请设定总分', trigger: 'change'}
        ],
        passScore: [
          {required: true, type: 'number', message: '请设定及格分', trigger: 'change'}
        ],
        publishedAt: [
          {required: true, type: 'string', message: '请选择发布时间', trigger: 'change'}
        ],
        implementAt: [
          {required: true, type: 'string', message: '请选择实施时间', trigger: 'change'}
        ],
      },
      form: {
        id: '',
        iname: '',
        code: '',
        orgId: '',
        orgName: '',
        level: 1,
        type: 1,
        subType: '',
        desc: '',
        publishedAt: new Date(),
        implementAt: new Date(),
        status: 1,
        shortName: '',
        govCheckRiskGrades: [],
        isGovCheck: 0, // 是否送审
        selectGovCheckRiskGrades: [], // 是否送审
        needSms: 0, // 是否发送短信
        selectSmsGrades: [], // 是否发送短信
        smsGrades: '', // 是否发送短信
        riskGrades: [],
        selectRiskGrades: [],
        levelList: [],
        hasScore: 1,
        multiRisk: 1,
      },
    }
  },
  mounted() {
    if (this.type !== 'add') {
      this.loading = true
      getStandardInfo({id: this.mid}).then((res) => {
        Object.keys(this.form).forEach((item) => {
          this.form[item] = res.data[item]
        })
        this.form.selectRiskGrades = this.form.riskGrades.split(',').map(Number)
        this.form.selectGovCheckRiskGrades = this.form.govCheckRiskGrades.split(',').map(Number)
        this.form.selectSmsGrades = this.form.smsGrades.split(',').map(Number)
        this.form.levelList = res.data.levelList || [];
        this.loading = false;
      })
    }
  },
  methods: {
    clearParent() {
      this.form.orgId = "";
      this.form.orgName = "";
    },
    getData(data) {
      this.form.orgName = data[0].iname;
      this.form.orgId = data[0].id;
    },
    save() {
      this.form.levelList = this.$refs.level.tableList || [];

      if(this.form.levelList.filter(item=>item.default===1).length>1){
        this.$Notice.error({
          title: "操作失败！",
          desc: '默认配置只能有一项！'
        });
        return false;
      }
      this.form.riskGrades = this.form.selectRiskGrades.join(',')
      this.form.govCheckRiskGrades = this.form.selectGovCheckRiskGrades.join(',');
      this.form.smsGrades = this.form.selectSmsGrades.join(',');
      this.ok('/standard/AddOrEdit')
    }
  }
}
</script>

