<template>
  <div>
    <ckeditor
      :config="editorConfig"
      v-model="editorData"
    ></ckeditor>
  </div>
</template>

<script>
// import Vue from 'vue';
// import CKEditor from 'ckeditor4-vue';
// Vue.use( CKEditor );

export default {
  name: '',
  props: {
    editorHtml: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      editorData: '', // 双向数据绑定
      editorConfig: { // 配置
        // editorplaceholder: "请编辑",
        // uiColor: '#9967FB', // 编辑器颜色
        // toolbar: [ // 工具栏
        //   [ 'Styles', 'Format', 'Font', 'FontSize' ],
        //   [ 'Bold', 'Italic' ],
        //   [ 'Undo', 'Redo' ],
        //   // [ 'About' ]
        // ]
        removeButtons: [
          
        ],
        
      }
    }
  },
  created() {
    this.editorData = this.editorHtml
  },
  methods: {
    expert() {
      console.log('富文本框内容', this.editorData);
    }
  }

}
</script>

<style>

</style>