<template>
  <div>
    <Form ref="form" class="editForm twoPartsEditForm" :model="form" :rules="ruleValidate" inline :label-width="80" :style="{maxHeight:'calc(100vh - 200px)'}" style="overflow-y: auto">
      <FormItem label="父级菜单" prop="parent_id">
        <Cascader :data="treeData" change-on-select @on-change="treeChange" v-model="form.parent_ary"></Cascader>
      </FormItem>
      <FormItem label="平台类型" prop="oType">
        <Select v-model="form.oType" placeholder="平台类型">
          <Option v-for="(item,index) in baseData['平台类型']" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="菜单名称" prop="title">
        <Input v-model="form.title" placeholder="菜单名称"/>
      </FormItem>
      <FormItem label="name值" prop="name">
        <Input v-model="form.name" placeholder="name值"/>
      </FormItem>
      <FormItem label="访问地址" prop="path">
        <Input v-model="form.path" placeholder="访问地址"/>
      </FormItem>
      <FormItem label="排序" prop="index" style="width: 25%">
        <Input v-model="form.index" placeholder="排序">
          <Tooltip content="数字越小越靠前" slot="suffix" placement="top">
            <Icon type="md-alert"/>
          </Tooltip>
        </Input>
      </FormItem>
      <FormItem label="icon" :label-width="50" prop="icon" style="width: 25%">
        <ys-icon-select v-model="form.icon" placeholder="icon"/>
      </FormItem>
      <FormItem label="颜色" prop="color" style="width: 20%">
        <ColorPicker alpha v-model="form.color" transfer/>
      </FormItem>
      <FormItem label="不显示在菜单" :label-width="100" prop="hideInMenu" style="width: 18%">
        <i-switch :true-value="1" :false-value="0" v-model="form.hideInMenu">
            <span slot="open">是</span>
            <span slot="close">否</span>
        </i-switch>
      </FormItem>
      <FormItem label="是否跳转" :label-width="120" prop="redirectFlag" style="width: 22%">
        <i-switch v-model="form.redirectFlag" @on-change="redirectChange">
            <span slot="open">是</span>
            <span slot="close">否</span>
        </i-switch>
      </FormItem>
      <FormItem label="固定页签" :label-width="120" prop="home" style="width: 22%">
        <i-switch :true-value="1" :false-value="0" v-model="form.home">
            <span slot="open">是</span>
            <span slot="close">否</span>
        </i-switch>
      </FormItem>
      <FormItem label="文件路径" prop="file" v-if="form.redirectFlag===false">
        <Input v-model="form.file" placeholder="文件路径"/>
      </FormItem>
      <FormItem label="跳转路径" prop="redirect" v-else>
        <Input v-model="form.redirect" placeholder="跳转路径"/>
      </FormItem>
      <FormItem label="绑定菜单" prop="relationId">
        <Input v-model="form.relationTitle" clearable @on-clear="clearRelation" readonly placeholder="绑定菜单">
          <Button slot="append" @click="bindFlag=true">选择菜单</Button>
        </Input>
      </FormItem>
      <FormItem label="备注" prop="desc" style="width: 100%">
        <Input v-model="form.desc" type="textarea" maxlength="128" show-word-limit :autosize="{minRows: 3,maxRows: 5}" placeholder="备注"/>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok('/menu/Add')">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
    <ys-modal v-model="bindFlag" :width="500" title="选择绑定的菜单">
      <menuTree :oType="form.oType" @on-ok="getTreeData"></menuTree>
    </ys-modal>
  </div>
</template>
<script>
import edit from '@/mixins/edit'
import {menuIfo} from "@/api/org";
import menuTree from "_c/menu";

export default {
  name: 'projectEdit',
  mixins: [edit],
  components: {menuTree},
  props: {
    oType: {type: Number, default: 2},
    treeData: {type: Array, default: () => []},
  },
  data() {
    return {
      ruleValidate: {
        title: [
          {required: true, message: '请填写菜单名称', trigger: 'blur'}
        ],
      },
      bindFlag: false,
      form: {
        id: '',
        parentId: '',
        hideInMenu: 0,
        parent_ary: [],
        oType: '',
        title: '',
        relationTitle: '',
        relationId: '',
        index: '',
        name: '',
        redirect: '',
        home: 0,
        redirectFlag: false,
        path: '',
        icon: '',
        file: '',
        color: '',
        desc: '',
      },
    }
  },
  mounted() {
    if (this.mid) {
      menuIfo({id: this.mid, time: new Date().getTime()}).then((res) => {
        if (res.code === 200) {
          Object.keys(this.form).forEach((item) => {
            this.form[item] = res.data[item]
          })
          this.form.redirectFlag = !!this.form.redirect || false;
          this.form.parent_ary = this.$getRecorderAryByDeep({
            targetData: Object.freeze(this.treeData),
            targetValue: this.form.parentId,
          })
        }
      })
    } else {
      this.form.oType = this.oType;
      if (this.midData.length === 1) {
        this.form.index = (this.midData[0].index || 0) + '-' + (this.midData[0].children.length + 1)
        this.form.parentId = this.midData[0].id;
        this.form.parent_ary = this.$getRecorderAryByDeep({
          targetData: Object.freeze(this.treeData),
          targetValue: this.midData[0].id,
          label: {valueKey: 'id', childKey: 'children'},
        })
      }
    }
  },
  methods: {
    clearRelation() {
      this.form.relationId = '';
      this.form.relationTitle = '';
    },
    getTreeData(data) {
      if (data.length === 1) {
        this.form.relationId = data[0].id;
        this.form.relationTitle = data[0].title;
      }
    },
    treeChange(value) {
      this.form.parentId = value[value.length - 1];
    },
    redirectChange() {
      if (this.form.redirectFlag) {
        this.form.file = '';
      } else {
        this.form.redirect = '';
      }
    },
  }
}
</script>

<style lang="less" scoped>
</style>