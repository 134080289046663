<template>
  <ys-cell-group>
    <ys-cell title="父级菜单" :label="detail.parentTitle"></ys-cell>
    <ys-cell title="平台类型" :label="detail.oType|getBaseDataName('平台类型')"></ys-cell>
    <ys-cell title="菜单名" :label="detail.title"></ys-cell>
    <ys-cell title="name值" :label="detail.name"></ys-cell>
    <ys-cell title="索引名称" :label="detail.name"></ys-cell>
    <ys-cell title="访问地址" :label="detail.path"></ys-cell>
    <ys-cell title="icon" :label="detail.icon"></ys-cell>
    <ys-cell title="文件路径" :label="detail.file" v-if="detail.file"></ys-cell>
    <ys-cell title="跳转路径" :label="detail.redirect" v-if="detail.redirect"></ys-cell>
    <ys-cell title="颜色" :label="detail.color"></ys-cell>
    <ys-cell title="不显示在菜单" :label="detail.hideInMenu?'是':'否'"></ys-cell>
    <ys-cell title="固定页签" :label="detail.home?'是':'否'"></ys-cell>
    <ys-cell title="备注" :label="detail.desc"></ys-cell>
  </ys-cell-group>
</template>

<script>
import {menuIfo} from "@/api/org";

export default {
  name: "baseInfo",
  props: {
    mid: {type: Number, default: 0},
    oType: {type: Number, default: 2},
  },
  data() {
    return {
      detail: {}
    }
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.loading = true;
      if (!this.mid) return;
      menuIfo({id: this.mid}).then(res => {
        if (res.code === 200) {
          this.detail = res.data;
        }
      })
    }
  }
}
</script>

<style scoped>

</style>