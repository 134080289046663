<template>
  <div class="menu">
    <Row>
      <Col span="6">
        <Button type="primary" long @click="addArea">新增省级行政区</Button>
        <!-- <Select v-model="params.oType" @on-change="getList" placeholder="平台类型">
          <Option v-for="(item,index) in []" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select> -->
        <div :style="{height:height-165+'px',overflowY:'auto'}" style="position: relative;margin: 10px 0 0 0;">
          <ys-tree
            :data="treeData"
            :render="renderContent"
            @on-select-change="onSelectChange($event, 'treeData')"
          ></ys-tree>
        </div>
      </Col>
      <Col span="6" v-if="childrenData.length > 0">
        <div :style="{height:height-165+'px',overflowY:'auto'}" style="position: relative;margin: 10px 0 0 0;">
          <ys-tree
            :data="childrenData"
            :render="renderContent"
            @on-select-change="onSelectChange($event, 'childrenData')"
          ></ys-tree>
        </div>
      </Col>
      <Col :span="childrenData.length > 0 ? 12 : 18" style="padding: 0 15px 15px 15px;">
        <detail :mid="mid" :gutter='childrenData.length > 0'></detail>
      </Col>
    </Row>
    <ys-modal v-model="modShow" :width="800" title="新增/修改菜单">
      <Edit :mid="mid" :treeData="treeData" :midData="midData" @on-ok="editOk"></Edit>
    </ys-modal>
  </div>
</template>

<script>
import Edit from "@/views/base/area/edit.vue";
import {areaDel} from '@/api/base'
import {mapGetters} from 'vuex'
import Detail from "@/views/base/area/detail";

export default {
  name: 'projects',
  components: {Detail, Edit},
  computed: {
    checkedFlag() {
      return this.checkedData.length !== 2
    },
    ...mapGetters(['height'])
  },
  data() {
    return {
      midData: [],
      treeData: [],
      allTertiaryData: [], // 所有的包含第三级以及往下的数据
      childrenData: [], // 当前被点击的二级的children数据
      modShow: false,
      mid: '',
      params: {
        keyword: '',
      },

    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.$get('/resource/Area').then(res => {
        if (res.code === 200) {
          this.treeData = []
          this.allTertiaryData = []
          this.childrenData = []
          // 全部加载的话会存在数据量太过庞大导致系统卡顿的情况
          this.rewriteData(res.data)
          this.treeData = res.data;
        }
      });
    },
    // 将树形结构数据中的第三级往下的数据保存后并剔除掉
    rewriteData(list) {
      list.forEach(item => {
        if(item.children.length > 0) {
          if(item.level === 2) {
            this.allTertiaryData.push(item.children)
            item.children = []
          } else {
            this.rewriteData(item.children)
          }
        }
      })
    },
    onSelectChange(item, model) {
      this.mid = item.id
      if(model === 'childrenData') return
      if(item.level === 2) {
        let parent = [{
          ...item,
          expand: true
        }]
        parent[0].children = this.allTertiaryData.filter(tertiaryItem => tertiaryItem[0].pid === item.id)[0] || []
        this.childrenData = parent
      } else {
        this.childrenData = []
      }
    },
    renderContent(h, {data}) {
      return h('div', {
        style: {height: '24px', lineHeight: '24px',},
      }, [
        h('span', [
          h('Icon', {
            props: {
              type: data.icon,
              color: data.color
            },
            style: {
              color: data.color,
              marginRight: '8px'
            }
          }),
          h('span', {
            style: {opacity: data.hideInMenu ? 0.5 : 1}
          }, data.name || data.iname + (data.index ? ' [' + data.index + ']' : ''))
        ]),
        h('span', {
          style: {display: 'inline-block', float: 'right', marginRight: '5px'}
        }, [
          // 省市才可以新增子行政区域
          data.level < 3 ? h('Button', {
            props: Object.assign({}, this.buttonProps, {
              icon: 'md-add',
              size: 'small'
            }),
            style: {display: !data.dictId ? 'inline-block' : 'none'},
            nativeOn: {
              click: (e) => {
                this.mid = '';
                this.midData = [data];
                this.modShow = true;
                e.stopPropagation();
              }
            }
          }) : '',
          h('Button', {
            props: Object.assign({}, this.buttonProps, {
              icon: 'md-create',
              size: 'small'
            }),
            style: {marginLeft: '5px', display: data.id === 0 ? 'none' : ''},
            nativeOn: {
              click: (e) => {
                this.mid = data.id;
                this.modShow = true;
                e.stopPropagation();
              }
            }
          }),
          h('Button', {
            props: Object.assign({}, this.buttonProps, {
              icon: 'md-close',
              size: 'small'
            }),
            style: {marginLeft: '5px', display: data.id === 0 ? 'none' : ''},
            nativeOn: {
              click: (e) => {
                this.remove(data)
                e.stopPropagation();
              }
            }
          })
        ])
      ]);
    },
    addArea() {
      this.modShow = true;
      this.midData = []
    },
    remove(data) {
      if (Array.isArray(data.children) && data.children.length > 0) {
        this.$Notice.error({
          title: '提示',
          desc: '该行政区下含有子级行政区，请先删除子级行政区！'
        })
        return false
      }
      this.$Modal.confirm({
        title: '提示',
        content: '确定要删除这一项么',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          areaDel({id: data.id}).then((res) => {
            if (res.code === 200) {
              this.$Notice.success({title: '提示', desc: '删除成功！'});
              // this.getList();
              switch(data.level) {
                case 1:
                  this.treeData.splice(this.treeData.findIndex(fItem => fItem.id === data.id), 1)
                  break;
                case 2:
                  this.treeData.forEach(item => {
                    if(item.id === data.pid) item.children.splice(item.children.findIndex(fItem => fItem.id === data.id), 1)
                  })
                  break;
                case 3:
                  this.allTertiaryData.forEach(item => {
                    if(item.id === data.pid) item.children.push(item.children.findIndex(fItem => fItem.id === data.id), 1)
                  })
                  this.childrenData[0].children.splice(this.childrenData[0].children.findIndex(fItem => fItem.id === data.id), 1)
                  break;
              }
            }
          })
        }
      })
    },
    editOk(data, type) {
      switch(type) {
        case 'add':
          switch(data.level) {
            case 1:
              this.treeData.push(data)
              break;
            case 2:
              this.treeData.forEach(item => {
                if(item.id === data.pid) item.children.push(data)
              })
              break;
            case 3:
              this.allTertiaryData.forEach(item => {
                if(item.id === data.pid) item.children.push(data)
              })
              this.childrenData[0].children.push(data)
              break;
          }
          break;
        case 'edit':
          this.getList();
          break;
      }
    }
  }
}
</script>
<style lang="less" scoped>
.demo-tree-render {
  overflow-x: hidden;

  .ivu-tree-title {
    width: 100%;
  }
}

.menu {
  padding: 10px;
}
</style>