<template>
  <div class="home">
    <ys-page-list
        ref="table"
        action="/org/area/List"
        :params="params"
        :tableHeight="tableHeight"
        :multiBtn="multiBtn"
        showContextMenu
        @on-selection-change="checkItem"
        :headerColumns="headerColumns">
      <template slot="btn">
        <ButtonGroup>
          <Button type="primary" @click="add">新增管辖区域</Button>
        </ButtonGroup>
      </template>
    </ys-page-list>
    <ys-modal v-model="modShow" :width="600" title="新增/修改管辖区域">
      <Edit :mid="detailID" :parentID="mid" @on-ok="getList"></Edit>
    </ys-modal>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'
import Edit from "@/views/org/list/detail/area/edit";

export default {
  name: 'projects',
  mixins: [pageList],
  components: {Edit},
  data() {
    return {
      params: {
        orgId: '',
      },
      modFlag: false,
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 80, align: 'center'},
        {minWidth: 130, title: '管辖区域', key: 'provinceCode', align: 'left', renderConfig: {type: 'area', key: ['countyCode', 'cityCode', 'provinceCode']}},
        {width: 180, title: '最近修改时间', key: 'updatedAt', align: 'left'},
      ]
    }
  },
  computed: {
    multiBtn() {
      return [
        {
          children: [
            {
              click: () => this.delItem('/org/area/Del', {ids: this.tableSelectItem.map(item => item.id)}),
              iconColor: '#ed4014',
              title: '删除',
              icon: 'md-trash',
              disabled: this.tableSelectItem.length !== 1
            },
          ]
        },
      ]
    },
  },
  mounted() {
    this.params.orgId = this.mid;
    this.getList();
  },
  methods: {}
}
</script>
