<template>
  <ys-detail :icon="detailIcon" :loading="loading" :name="detail.keyId+'配置'">
    <Tag size="medium" color="blue" slot="status">{{ detail.platFormType|getBaseDataName('平台类型') }}</Tag>
    <template slot="brief">
      <ys-cell title="keyID" :label="detail.keyId"/>
      <ys-cell title="请求接口" :label="detail.action"/>
      <ys-cell title="表头数" :label="detail.columns?detail.columns.length:0"/>
      <ys-cell title="描述" :label="detail.note"/>
    </template>
    <template slot="main-left">
      <Tabs v-model="tab" name="detail" type="card">
        <Tab-pane label="KEY对照表" name="1" icon="ios-key">
          <tableKeys :mid="mid" :keyId="keyId" :tableHeight="pageListHeight" v-if="tab==='1'&&detail.ID"></tableKeys>
        </Tab-pane>
        <Tab-pane label="筛选项配置" name="2" icon="ios-settings">
          <tableFilter :mid="mid" :keyId="keyId" :tableHeight="pageListHeight" v-if="tab==='2'&&detail.ID"></tableFilter>
        </Tab-pane>
      </Tabs>
    </template>
    <template slot="main-right">
      <h1 class="h1Title">其他信息</h1>
      <div style="text-align: center">暂未开放！</div>
    </template>
  </ys-detail>
</template>

<script>
import detailMixins from '@/mixins/detail'
import {genPageListInfoById} from "@/api/base";

export default {
  name: "detail",
  mixins: [detailMixins],
  props: {keyId: {type: String, default: ''}},
  components: {
    tableKeys: () => import('./detail/keys/index'),
    tableFilter: () => import('./detail/filter/index'),
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.loading = true;
      this.detail = {};
      genPageListInfoById({id: this.mid}).then(res => {
        this.loading = false;
        if (res.code === 200) {
          this.detail = res.data || {};
        }
        this.setTagName(this.detail.number)
      }).catch(() => {
        this.loading = false;
      })
    }
  }
}
</script>

<style scoped>

</style>