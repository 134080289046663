<template>
  <div>
    <ys-cell-group :gutter="3">
      <ys-cell title="接口地址" :label="detail.url" style="width: 50%;"/>
      <ys-cell title="方式" :label="detail.method" style="width: 50%;"/>
      <ys-cell title="参数" :label="detail.args" style="width: 100%;"/>
      <ys-cell title="浏览器类型" block :label="detail.agent"/>
      <ys-cell title="执行时间(ms)" :label="detail.duration"/>
      <ys-cell title="操作结果" :label="detail.errCode"/>
      <ys-cell title="返回内容" :label="detail.errMsg"/>
      <ys-cell title="平台类型" :label="detail.platform|getBaseDataName('平台类型')"/>
      <ys-cell title="操作时间" :label="detail.createdAt"/>
    </ys-cell-group>
  </div>
</template>

<script>

export default {
  name: "baseInfo",
  props: {detail: Object}
}
</script>

<style scoped>

</style>