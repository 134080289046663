import {get,post} from 'ys-admin'

/**
 * 获取简报详情
 * @param {Object} params
 * @returns {Object}
 */
export const approvalInfo = function (params) {
  return get('/orgApprovalConfig/Info', params)
};

/**
 * 提交审核
 * @param {Object} params
 * @returns {Object}
 */
export const subMitApprovalData = function (params) {
  return post('/orgApprovalResult/AddByConfig', params)
};
/**
 * 审核详情
 * @param {Object} params
 * @returns {Object}
 */
export const doApprovalInfo = function (params) {
  return get('/orgApprovalResult/Info', params)
};
/**
 * 审核详情
 * @param {Object} params
 * @returns {Object}
 */
export const doApprovalList = function (params) {
  return get('/orgApprovalResult/List', params)
};

/**
 * 审核取消
 * @param {Object} params
 * @returns {Object}
 */
export const approvalCancel = function (params) {
  return post('/orgApprovalResult/Cancel', params)
};

/**
 * 审核通过
 * @param {Object} params
 * @returns {Object}
 */
export const approvalPass = function (params) {
  return post('/orgApprovalResult/Pass', params)
};

/**
 * 审核驳回
 * @param {Object} params
 * @returns {Object}
 */
export const approvalFail = function (params) {
  return post('/orgApprovalResult/Fail', params)
};
