<template>
  <div class="home">
    <ys-page-list
        ref="table"
        action="/servePlan/subPlan/List"
        :multiBtn="multiBtn"
        :tableHeight="resultHeight"
        :params="params"
        showContextMenu
        :multiColumn="true"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="关联状态" prop="haveCheck">
          <Select v-model="params.haveCheck" transfer placeholder="关联状态" clearable style="width: 120px;">
            <Option v-for="(item,index) in baseData['子计划关联状态']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="类型" prop="type">
          <Select v-model="params.type" transfer placeholder="类型" clearable style="width: 120px;">
            <Option v-for="(item,index) in baseData['子计划检查类型']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="请输入" style="width: 120px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template slot="btn">
        <Button type="primary" @click="addPlan">
          {{ addTitle }}
        </Button>
      </template>
    </ys-page-list>
    <ys-modal v-model="modShow" :width="550" :title="'新增/修改计划明细'">
      <Edit @on-ok="getList" :midData="midData" :mid="detailID"></Edit>
    </ys-modal>
    <ys-modal v-model="checkFlag" :width="600" title="关联检查任务">
      <relationTask :midData="midData" :modData="tableSelectItem" @on-ok="getList"></relationTask>
    </ys-modal>
    <ys-modal v-model="planFlag" :width="1000" :title="'新增/修改服务计划'">
      <planEdit :midData="modData" @on-ok="setPlan"></planEdit>
    </ys-modal>
    <ys-modal v-model="azxFlag" :width="900" title="新增任务">
      <!-- 安责险/专项任务 -->
      <azxEdit @on-ok="taskEdit" :planData="tableSelectItem" :type="currentDataType" v-if="currentDataType < 3"></azxEdit>
      <!-- 安全演练任务未开放 -->
      <!-- 验收任务 -->
      <inspectsEdit @on-ok="taskEdit" :midData="tableSelectItem" :type="currentDataType" v-else-if="currentDataType===5"></inspectsEdit>
    </ys-modal>
  </div>
</template>
<script>
import pageList from '@/mixins/pageList.js';
import Edit from "@/views/task/plan/detail/subPlan/edit.vue";
import azxEdit from "@/views/task/list/Edit.vue";
import planEdit from '@/views/task/plan/edit';
import relationTask from '@/views/task/plan/detail/subPlan/relationTask.vue';
import inspectsEdit from "@/views/task/inspects/edit.vue";
import {publicTask} from '@/api/task';
import {subPLanUnSelectCheck, subPLanUnSelectEvent, subPLanUnSelectInspects} from "@/api/plan";

export default {
  name: '',
  mixins: [pageList],
  props: {
    policyId: {
      type: [Number, String],
      default: null
    },
    modData: {
      type: Array,
      default: () => []
    },
  },
  components: {
    Edit,
    planEdit,
    azxEdit,
    relationTask,
    inspectsEdit
  },
  data() {
    return {
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 70, align: 'center'},
        {width: 120, title: '类型', key: 'type', align: 'center', renderConfig: {type: 'baseData', parentName: '子计划检查类型'}},
        {minWidth: 200, title: '标题', key: 'title', align: 'left'},
        {width: 120, title: '开始日期', key: 'startAt', align: 'left', renderConfig: {type: 'formatDate'}},
        {width: 120, title: '结束日期', key: 'finishAt', align: 'left', renderConfig: {type: 'formatDate'}},
        {
          width: 100, title: '关联任务', key: 'checkId', align: 'center', render: (h, params) => {
            return h('span', params.row.checkId > 0 ? '已关联' : '未关联');
          }
        },
        {
          minWidth: 200, title: '专家', key: 'expertList', align: 'center',
          render: (h, params) => {
            return h('span', (params.row.expertList || []).map((item) => item.iname).join('，'));
          }
        }
      ],
      checkFlag: false,
      planModel: false,
      planFlag: false,
      azxFlag: false,
      params: {
        haveCheck: '',
        type: '',
        orgId: '',
        servePlanId: '',
        keyword: '',
        startAt: '',
        endAt: '',
      },
    }
  },
  computed: {
    currentDataType() {
      return this.tableSelectItem.length === 1 ? this.tableSelectItem[0].type : null
    },
    multiBtn() {
      return [
        {
          hiddenBtnIcon: true,
          children: [
            {
              click: () => this.modShow = true,
              title: '修改',
              icon: 'md-create',
              disabled: this.tableSelectItem.length !== 1,
            },
            {
              click: () => this.delItem('/servePlan/subPlan/Remove', {ids: this.tableSelectItem.map(item => item.id)}),
              iconColor: '#ed4014',
              title: '删除',
              icon: 'md-trash',
              disabled: this.tableSelectItem.length <= 0,
            },
          ]
        },
        {
          hiddenBtnIcon: true,
          children: [
            {
              click: () => this.checkFlag = true,
              title: '关联检查任务',
              icon: 'ios-lock',
              disabled: this.tableSelectItem.length !== 1,
            },
            {
              click: () => this.unboundTask(),
              title: '解绑检查任务',
              icon: 'md-key',
              disabled: this.tableSelectItem.length !== 1 || this.tableSelectItem[0].checkId <= 0,
            },
            {
              click: () => this.addTask(),
              title: '新建检查任务',
              icon: 'ios-add-circle',
              disabled: this.tableSelectItem.length !== 1 || this.tableSelectItem[0].checkId > 0,
            },
          ]
        },
      ]
    },
    addTitle() {
      return this.midData.length <= 0 ? '新建服务计划' : '新增计划明细'
    }
  },
  mounted() {
    this.params.servePlanId = this.mid;
    this.params.policyId = this.policyId;
    this.getList();
  },
  methods: {
    addTask() {
      if (this.tableSelectItem[0].type > 2 && this.tableSelectItem[0].type < 5) {
        this.$Notice.warning({
          title: '提示',
          desc: '当前选择的计划类型绑定任务功能暂未开放，请关注后续功能！'
        });
      } else {
        this.azxFlag = true;
      }
    },
    // 计划解绑任务
    unboundTask() {
      this.$Modal.confirm({
        title: '确定解绑当前计划',
        onOk: () => {
          let unBind
          switch(this.tableSelectItem[0].type) {
            case 1:
            case 2:
              unBind = () => subPLanUnSelectCheck({id: this.tableSelectItem[0].id})
              break;
            case 3:
            case 4:
              unBind = () => subPLanUnSelectEvent({id: this.tableSelectItem[0].id})
              break;
            case 5:
              unBind = () => subPLanUnSelectInspects({id: this.tableSelectItem[0].id})
              break;
          }
          unBind().then(res => {
            if (res.code === 200) {
              this.$Notice.success({
                title: '解绑成功！'
              });
              this.getList()
            }
          });
        },
      });
    },
    addPlan() {
      if (this.midData.length <= 0) {
        this.planFlag = true
        return
      }
      this.add()
    },
    // 新增完服务计划后,需要父组件重新请求一下最新的服务计划表
    setPlan() {
      this.$emit('setPlan')
      this.getList()
    },
    async taskEdit(data, nextType) {
      await this.getList()
      if (nextType === 'saveAndRelease') {
        await publicTask({id: data.id, noExpert: true, serveTime: data.serveTime}).then(res => {
          if (res.code === 200) {
            this.$Notice.success({
              title: '发布成功！'
            });
          } else {
            this.$Notice.error({
              title: '发布失败，请前往任务管理重新发布',
            });
          }
        })
      }
    }
  }
}
</script>
