<template>
  <div>
    <Form ref="form" class="editForm" :model="form" :rules="ruleValidate" :label-width="80" :style="{maxHeight:'calc(100vh - 200px)'}" style="overflow-y: auto">
      <Alert type="warning">注意：新旧机构必须是同一类型，且迁移后旧机构的保单、任务等等信息都会迁移到新机构。</Alert>
      <FormItem label="旧机构" prop="fromOrgId">
        <Input v-model="form.fromOrgName" clearable placeholder="机构名称">
          <Button slot="append" @click="choice('fromOrg')">选择机构</Button>
        </Input>
      </FormItem>
      <FormItem label="新机构" prop="toOrgId">
        <Input v-model="form.toOrgName" clearable placeholder="机构名称">
          <Button slot="append" @click="choice('toOrg')">选择机构</Button>
        </Input>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok('/org/Exchange')">确定迁移</Button>
      <Button @click="close">取消</Button>
    </div>
    <ys-modal v-model="orgFlag" :width="1200" title="选择机构">
      <org-component :limit="1" :oType="form.oType" @on-ok="getData"></org-component>
    </ys-modal>
  </div>
</template>
<script>
import edit from '@/mixins/edit'
import OrgComponent from "_c/org";

export default {
  name: 'orgEdit',
  components: {OrgComponent},
  mixins: [edit],
  data() {
    return {
      ruleValidate: {
        iname: [
          {required: true, message: '请填机构名称', trigger: 'blur'}
        ],
      },
      orgFlag: false,
      type: '',
      form: {
        fromOrgId: '',
        fromOrgName: '',
        toOrgName: '',
        toOrgId: '',
      },
    }
  },
  mounted() {

  },
  methods: {
    choice(type) {
      this.type = type;
      this.orgFlag = true;
    },
    getData(data) {
      const {fromOrgId, toOrgId} = this.form;
      if (fromOrgId === toOrgId && fromOrgId && toOrgId) {
        this.$Notice.error({title: '提示', desc: '所选新旧机构相同，无法操作！'});
        return false;
      }
      this.form[this.type + 'Id'] = data[0].id;
      this.form[this.type + 'Name'] = data[0].iname;
    },
  }
}
</script>

<style lang="less" scoped>
</style>
