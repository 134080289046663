<template>
  <Form ref="form" class="editForm twoPartsEditForm" :model="form" :rules="ruleValidate" inline :label-width="110">
    <div v-if="editFlag">
      <FormItem label="项目名称" prop="iname" style="width: 100%">
        <Input v-model="form.iname" placeholder="项目名称">
          <Button slot="append" @click="clearProject" v-if="!formData.id">重新检索</Button>
        </Input>
      </FormItem>
      <FormItem label="投保单位" prop="contractUnit" style="width: 65%" class="ys-business">
        <!-- 当有项目id且携带了投保单位时不显示 -->
        <ys-business :source="0" v-model="keyword" @on-clear="clearBusiness" :disabled="!!(form.id && form.contractUnit)" @on-select="selectOrg" v-show="!(form.id && form.contractUnit)"></ys-business>
        <!-- 当有项目id且有投保单位时显示 -->
        <Input v-model="form.contractUnit" :disabled="!!(form.id && form.contractUnit)" placeholder="投保单位" v-show="!!(form.id && form.contractUnit)"></Input>
      </FormItem>
      <FormItem label="社会信用代码" prop="contractCode" style="width: 35%">
        <Input v-model="form.contractCode" :disabled="!!form.id||form.contractUnitId>0" placeholder="社会信用代码"></Input>
      </FormItem>
      <FormItem label="施工许可证号" prop="licenseNum">
        <Input v-model="form.licenseNum" placeholder="施工许可证号" search enter-button="临时生成" @on-search="autoGenerate"></Input>
      </FormItem>
      <FormItem label="安监号" prop="code">
        <Input v-model="form.code" placeholder="安监号"></Input>
      </FormItem>
      <FormItem label="所在地区" prop="area" style="width: 100%">
        <ys-region changeOnSelect v-model="form.area" style="width: 30%;display: inline-block" @on-change="getArea" placeholder="所在地区"/>
        <Input v-model="form.address" placeholder="项目地址" style="width: 70%"></Input>
      </FormItem>
      <FormItem label="经纬度" prop="lngLat" style="width:100%">
        <Input v-model="form.lngLat" @on-change="lngLatChange" placeholder="经纬度" style="width: calc(100% - 130px)"></Input>
        <Button type="primary" target="_blank" to="https://lbs.qq.com/getPoint/">手动选择经纬度</Button>
      </FormItem>
      <FormItem label="项目简介" prop="description" style="width: 100%">
        <Input v-model="form.description" type="textarea" maxlength="128" show-word-limit style="width: 100%" :autosize="{minRows: 3,maxRows: 5}" placeholder="项目简介"></Input>
      </FormItem>
      <div v-show="moreFlag">
        <h1 class="h1Title">其他信息</h1>
        <FormItem label="工程类型" prop="category">
          <Select v-model="form.category" placeholder="工程类型">
            <Option v-for="(item,index) in baseData['项目类型']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="实际开工日期" prop="startReal">
          <ys-date-picker v-model="form.startReal" style="width: 100%;" placeholder="实际开工日期"></ys-date-picker>
        </FormItem>
        <FormItem label="实际完工日期" prop="endReal">
          <ys-date-picker v-model="form.endReal" style="width: 100%;" placeholder="实际完工日期"></ys-date-picker>
        </FormItem>
        <FormItem label="项目负责人" prop="principalName">
          <Input v-model="form.principalName" placeholder="项目负责人"></Input>
        </FormItem>
        <FormItem label="负责人电话" prop="principalPhone">
          <Input v-model="form.principalPhone" placeholder="负责人电话"></Input>
        </FormItem>
        <FormItem label="安责险联络员姓名" prop="safetyOfficerName">
          <Input v-model="form.safetyOfficerName" placeholder="安责险联络员姓名"></Input>
        </FormItem>
        <FormItem label="安责险联络员电话" prop="safetyOfficerPhone">
          <Input v-model="form.safetyOfficerPhone" placeholder="安责险联络员电话"></Input>
        </FormItem>
        <FormItem label="合同造价" prop="contractPrice">
          <Input type="number" v-model="form.contractPrice" placeholder="合同造价"><span slot="append">元</span></Input>
        </FormItem>
        <FormItem label="工程造价" prop="amount">
          <Input type="number" v-model="form.amount" placeholder="工程造价"><span slot="append">元</span></Input>
        </FormItem>
        <FormItem label="总面积" prop="buildArea">
          <Input type="number" v-model="form.buildArea" placeholder="总面积"><span slot="append">㎡</span></Input>
        </FormItem>
        <FormItem label="总长度" prop="buildLength">
          <Input type="number" v-model="form.buildLength" placeholder="总长度"><span slot="append">米</span></Input>
        </FormItem>
        <FormItem label="项目阶段" prop="status">
          <Select v-model="form.status" transfer clearable placeholder="项目阶段">
            <Option v-for="(item,index) in baseData['项目进度']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="形象进度" prop="progress">
          <Select v-model="form.progress" transfer clearable placeholder="形象进度">
            <Option v-for="(item,index) in baseData['施工阶段']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="高度" prop="buildHeight">
          <Input type="number" v-model="form.buildHeight" placeholder="高度"><span slot="append">米</span></Input>
        </FormItem>
        <FormItem label="最高楼层" prop="floorHeight">
          <Input type="number" v-model="form.floorHeight" placeholder="最高楼层"><span slot="append">层</span></Input>
        </FormItem>
        <FormItem label="施工许可证照片" prop="licenseFileList" style="width: 100%;text-align: center">
          <ysUpload
              @on-success="bLFile(arguments,'licenseFileList')"
              :headers="{token:token}"
              type="halfList"
              :defaultList.sync="licenseFileList"
              action="/web/resource/UploadFile"
          ></ysUpload>
        </FormItem>
        <FormItem label="平面图" prop="floorViewFileList" style="width: 100%;text-align: center">
          <ysUpload
              @on-success="bLFile(arguments,'floorViewFileList')"
              :headers="{token:token}"
              type="halfList"
              :defaultList.sync="floorViewFileList"
              action="/web/resource/UploadFile"
          ></ysUpload>
        </FormItem>
        <FormItem label="鸟瞰图" prop="aerialViewFileList" style="width: 100%;text-align: center">
          <ysUpload
              @on-success="bLFile(arguments,'aerialViewFileList')"
              :headers="{token:token}"
              type="halfList"
              :defaultList.sync="aerialViewFileList"
              action="/web/resource/UploadFile"
          ></ysUpload>
        </FormItem>
      </div>
      <FormItem style="width: 100%">
        <a href="javascript:" class="moreProjectBtn" @click="moreFlag=!moreFlag">{{ moreFlag ? '收起更多项目信息' : '编辑更多项目信息' }}>></a>
      </FormItem>
    </div>
    <div v-else>
      <FormItem label="投保项目" prop="projectName" style="width: 100%">
        <Select v-model="form.id"
                clearable
                filterable
                @on-change="change"
                @on-clear="clearProject"
                placeholder="请输入关键字检索项目"
                :remote-method="remote"
                :loading="loading">
          <Option v-for="item in projectList" :value="item.id" :key="item.id">{{ item.iname }}</Option>
        </Select>
      </FormItem>
      <div class="noData" v-if="noDataFlag">
        <Icon type="ios-albums-outline" size="50"/>
        <p>没有搜索到项目？可以<a href="javascript:" @click="editFlag=true">新增项目</a>！</p>
      </div>
    </div>
  </Form>
</template>

<script>
import {get,generateSocialCreditCode} from 'ys-admin'
import edit from "@/mixins/edit";

export default {
  name: "project",
  mixins: [edit],
  props: {
    formData: {
      type: Object, default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      moreFlag: false,
      keyword: '',
      projectList: [],
      licenseFileList: [],
      floorViewFileList: [],
      aerialViewFileList: [],
      ruleValidate: {
        iname: [
          {required: true, message: '请填项目名称', trigger: ''}
        ],
        area: [
          {required: true, message: '请选择所在区域', trigger: 'change'}
        ],
        contractUnit: [
          {required: true, message: '请输入投保单位名称', trigger: 'blur,change'}
        ],
        contractCode: [
          {required: true, message: '请输入投保单位社会信用代码', trigger: 'change'}
        ],
        code: [
          {required: true, message: '请填安监号', trigger: 'blur'}
        ],
      },
      editFlag: false,
      loading: false,
      form: {
        contractUnitId: '',
        iname: '',
        licenseNum: '',
        industryType: '1',
        tag: 1,
        principalName: '',
        principalPhone: '',
        category: '99',
        safetyOfficerName: '',
        safetyOfficerPhone: '',
        status: '',
        progress: '',
        startPlan: '',
        code: '',
        startReal: '',
        endPlan: '',
        endReal: '',
        id: '',
        lng: '',
        lat: '',
        contractUnit: '', // 投保单位名称，新增/编辑时合并到OrgList中
        contractCode: '', // 投保单位信用代码，新增/编辑时合并到OrgList中
        provinceCode: '',
        cityCode: '',
        countyCode: '',
        address: '',
        contractPrice: '',
        amount: '',
        buildLength: '',
        buildArea: '',
        buildHeight: '',
        floorHeight: '',
        lngLat: '',
        area: '',
        description: '',
        licenseFileList: [],
        floorViewFileList: [],
        aerialViewFileList: [],
        orgList: [], // 机构数组
      },
    }
  },
  computed: {
    noDataFlag() {
      return !this.editEnabled && !this.form.id && !this.editFlag;
    },
    editEnabled() {
      return !!this.formData.oldId || !!this.formData.id
    }
  },
  mounted() {
    this.form.id = this.formData.projectId;
    this.editFlag = !!this.formData.projectId;
    this.change();
  },
  methods: {
    clearBusiness() {
      this.form.contractUnit = '';
      this.form.contractCode = '';
      this.keyword = '';
      this.form.contractUnitId = '';
    },
    getNewOrg(data) {
      this.keyword = data.iname;
      this.form.contractUnitId = data.id;
      this.form.contractUnit = data.iname;
      this.form.contractCode = data.societyCode;
    },
    selectOrg(data) {
      this.form.contractUnit = data.iname;
      this.form.contractUnitId = data.id;
      this.form.contractCode = data.societyCode||generateSocialCreditCode();
      if(!data.societyCode){
        this.$Notice.warning({
          title: '提示',
          desc: '该单位没有社会统一代码，已自动创建临时社会统一代码！'
        });
      }
    },
    bLFile(data, key) {
      this.form[key] = data[0].map(item => item.id);
    },
    autoGenerate() {
      this.form.licenseNum = `XKZ${new Date().getTime()}`
    },
    change() {
      if (!this.form.id) return
      this.editFlag = true;
      get('/projects/GetInfo', {id: this.form.id}).then(res => {
        if (res.code === 200) {
          Object.keys(this.form).forEach((item) => {
            this.form[item] = res.data[item];
            this.licenseFileList = res.data.licenseFileList || [];
            this.floorViewFileList = res.data.floorViewFileList || [];
            this.aerialViewFileList = res.data.aerialViewFileList || [];
            this.form.licenseFileList = this.licenseFileList.map(item => item.id);
            this.form.floorViewFileList = this.floorViewFileList.map(item => item.id);
            this.form.aerialViewFileList = this.aerialViewFileList.map(item => item.id);
            this.form.area = res.data.countyCode || res.data.cityCode || res.data.provinceCode;
            this.form.lngLat = this.form.lat && this.form.lng ? (this.form.lat + ',' + this.form.lng) : '';
          });
        }
      }).catch(() => {

      })
    },
    clearProject() {
      this.form.projectName = ''
      this.form.iname = ''
      this.form.id = ''
      this.$refs.form.resetFields();
      this.editFlag = false;
      this.clearBusiness()
    },
    lngLatChange() {
      if (this.form.lngLat) {
        this.form.lng = this.form.lngLat.split(',')[1]
        this.form.lat = this.form.lngLat.split(',')[0]
      }
    },
    getArea(data) {
      this.form.provinceCode = data[0] ? data[0].code : '';
      this.form.cityCode = data[1] ? data[1].code : '';
      this.form.countyCode = data[2] ? data[2].code : '';
      if (data.length === 0) this.form.area = '';
    },
    remote(query) {
      if (query !== '') {
        this.loading = true;
        get('/projects/List', {limit: 30, keyword: query}).then(res => {
          this.loading = false;
          this.projectList = res.data.list || [];
          this.form.iname = this.projectList.length === 0 ? query : this.form.iname;
        }).catch(() => {
          this.loading = false;
        })
      } else {
        this.projectList = [];
      }
    },
  },
}
</script>

<style scoped lang="less">

.noData {
  text-align: center;
  padding: 10px 0;
  line-height: 40px;
  font-size: 14px;
}
</style>