<template>
  <div>
    <Form ref="form" class="editForm twoPartsEditForm" :model="form" :rules="ruleValidate" inline :label-width="80" :style="{maxHeight:'calc(100vh - 200px)'}" style="overflow-y: auto">

      <FormItem label="序号" prop="index">
        <InputNumber :min="0" style="width: 100%" v-model="form.index" placeholder="最大扣分值"></InputNumber>
      </FormItem>
      <FormItem label="总项名称" prop="iname">
        <Input v-model="form.iname" placeholder="总项名称"></Input>
      </FormItem>
      <FormItem label="总分" prop="score">
        <InputNumber :min="0" style="width: 100%" v-model="form.score" placeholder="总分"></InputNumber>
      </FormItem>
      <FormItem label="及格分" prop="passScore">
        <InputNumber :min="0" style="width: 100%" v-model="form.passScore" placeholder="及格分"></InputNumber>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok(form.id?'/standard/dictCategory/Edit':'/standard/dictCategory/Add')">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>
<script>
import edit from '@/mixins/edit'
import {getDictCategoryInfo} from "@/api/base";

export default {
  name: 'projectEdit',
  mixins: [edit],
  props: {standardId:{type: [Number,String], default: ''}},
  data() {
    return {
      ruleValidate: {
        iname: [
          {required: true, message: '请填写总项名称', trigger: 'blur'}
        ],
        score: [
          {required: true, type: 'number', message: '请设定总分', trigger: 'change'}
        ],
        passScore: [
          {required: true, type: 'number', message: '请设定及格分', trigger: 'change'}
        ],
      },
      form: {
        warehouseId: '',
        id: '',
        iname: '',
        index: 0,
        score: 10,
        passScore: 6,
      },
    }
  },
  mounted() {
    if (this.mid) {
      getDictCategoryInfo({id: this.mid}).then((res) => {
        Object.keys(this.form).forEach((item) => {
          this.form[item] = res.data[item]
        })
      })
    } else {
      this.form.warehouseId = this.standardId;
    }
  },
  methods: {}
}
</script>

