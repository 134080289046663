<template>
  <ys-cell-group :gutter="2">
    <ys-cell title="APPID" :label="detail.appId">
      <div slot="value">
        <Button type="primary" size="small" v-if="!detail.appId" shape="circle" @click="genSystemConfig" style="margin-right: 15px;">生成appid</Button>
        <Button type="primary" size="small" v-else shape="circle" @click="show" style="margin-right: 15px;">查看密钥</Button>
        <i-switch size="large" :loading="loading" :value="detail.appIdEnable" :true-value="1" :false-value="0" @click.native="detail.appIdEnable ? closeSystemConfig() : openSystemConfig()">
          <span slot="open">启用</span>
          <span slot="close">禁用</span>
        </i-switch>
      </div>
    </ys-cell>
  </ys-cell-group>
</template>

<script>

import {closeSystemConfig, genSystemConfig, getSystemConfig, openSystemConfig} from "@/api/base";

export default {
  name: 'set',
  data() {
    return {
      detail: {},
      loading: false,
    }
  },
  mounted() {
    this.getSystemConfig()
  },
  methods: {
    show() {
      this.$Modal.info({
        title: '您的密钥：',
        content: this.detail.appSecret
      })
    },
    genSystemConfig() {
      this.loading = true;
      genSystemConfig().then((res) => {
        this.loading = false;
        if (res.code === 200) {
          this.detail = res.data
        }
      })
    },
    closeSystemConfig() {
      this.loading = true;
      closeSystemConfig().then((res) => {
        this.loading = false;
        if (res.code === 200) {
          this.detail = res.data
        }
      })
    },
    openSystemConfig() {
      this.loading = true;
      openSystemConfig().then((res) => {
        this.loading = false;
        if (res.code === 200) {
          this.detail = res.data
          this.loading = false;
        }
      })
    },
    getSystemConfig() {
      this.loading = true;
      getSystemConfig().then(res => {
        this.loading = false;
        if (res.code === 200) {
          this.detail = res.data
        }
      })
    },
  }
}
</script>
<style lang="less" scoped>

</style>