<template>
  <div>
    <Button type="primary" size="small" @click="add" long>新增部门</Button>
    <Tree
        :data="treeData"
        :render="renderContent"
        @on-select-change="selectTree"
        class="demo-tree-render"
        :style="{ height: (tableHeight?tableHeight+60:height - 144) + 'px' }"
    ></Tree>
    <ys-modal v-model="modShow" title="新增/修改部门">
      <Edit @on-ok="getList" :midData="midData" :orgId="orgId" :modData="modData"></Edit>
    </ys-modal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {getDepartmentList} from "@/api/department";

export default {
  name: '',
  props: {
    orgId: {type: [Number, String], default: ''},
    tableHeight: {type: [Number, String], default: null},
  },
  components: {
    Edit: () => import("./edit.vue"),
  },
  data() {
    return {
      buttonProps: {
        type: "default",
        size: "small",
      },
      treeData: [],
      modData: [],
      midData: [],
      modShow: false
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'height'])
  },
  mounted() {
    this.getList()
  },
  methods: {
    add() {
      this.modShow = true
    },

    getTreeByList(tree) {
      let result = [];
      let map = {};
      tree.forEach((item) => {
        item.expand = true;
        map[item.id] = item;
      });

      tree.forEach((item) => {
        let parent = map[item.parent_id];
        if (parent) {
          (parent.children || (parent.children = [])).push(item);
        } else {
          result.push(item);
        }
      })
      return result
    },

    getList() {
      getDepartmentList({org_id: this.orgId || this.userInfo.org_id, limit: -1}).then((res) => {
        if (res.code === 200) {
          this.treeData = this.getTreeByList(res.data || []);
        }
      });
    },

    selectTree(data) {
      this.$emit("treeSelect", data);
    },

    renderContent(h, {/* root, node,*/ data}) {
      return h("span", {
            style: {
              display: "inline-block",
              lineHeight: "25px",
              width: "100%",
              paddingRight: "5px",
            },
          },
          [
            h("span", data.iname),
            h(
                "span",
                {
                  style: {
                    display: "inline-block",
                    float: "right",
                    marginRight: "8px",
                  },
                },
                [
                  h("Button", {
                    props: Object.assign({}, this.buttonProps, {
                      icon: "ios-add-circle",
                    }),
                    style: {
                      marginRight: "8px",
                      display: data.type === 6 ? "none" : "",
                    },
                    on: {
                      click: () => {
                        this.midData = [data];
                        this.modData = [];
                        this.modShow = true;
                      },
                    },
                  }),
                  h("Button", {
                    props: Object.assign({}, this.buttonProps, {
                      icon: "ios-create",
                    }),
                    style: {
                      marginRight: "8px",
                    },
                    on: {
                      click: () => {
                        this.modData = [data];
                        this.midData = [];
                        this.modShow = true;
                      },
                    },
                  }),
                  // h("Button", { // 删除
                  //   props: Object.assign({}, this.buttonProps, {
                  //     icon: "md-trash",
                  //   }),
                  //   on: {
                  //     click: () => {
                  //       this.remove(root, node, data);
                  //     },
                  //   },
                  // }),
                ]
            ),
          ]
      );
    },

    remove(/* root, node, data */) {
      this.$Modal.confirm({
        title: "提示",
        content: "确定要删除么？一旦删除将无法恢复！",
        onOk: () => {
          this.getList();
        },
      });
    },
  }

}
</script>
<style lang="less" scoped>
.demo-tree-render {
  margin-top: 10px;
  overflow-y: auto;
  overflow-x: hidden;

  .ivu-tree-children {
    li {
      margin: 0;
    }
  }

  .ivu-tree-arrow {
    line-height: 25px;
  }

  .ivu-tree-title {
    width: 100%;
  }
}
</style>
