<template>
  <div>
    <Form ref="form" class="editForm twoPartsEditForm" :model="form" :rules="ruleValidate" inline :label-width="80" :style="{maxHeight:'calc(100vh - 200px)'}" style="overflow-y: auto">
      <FormItem label="总项" prop="categoryId">
        <Select v-model="form.categoryId" transfer placeholder="总项">
          <Option v-for="(item,index) in categoryList" :value="item.id" :key="index">{{ item.iname }}</Option>
        </Select>
      </FormItem>
      <FormItem label="风险表项" prop="iname">
        <Input v-model="form.iname" placeholder="风险表项"></Input>
      </FormItem>
      <FormItem label="序号" prop="index">
        <InputNumber :min="0" style="width: 100%" v-model="form.index" placeholder="序号"></InputNumber>
      </FormItem>
      <FormItem label="总分" prop="score">
        <InputNumber :min="0" style="width: 100%" v-model="form.score" placeholder="总分"></InputNumber>
      </FormItem>
      <FormItem label="及格分" prop="passScore">
        <InputNumber :min="0" style="width: 100%" v-model="form.passScore" placeholder="及格分"></InputNumber>
      </FormItem>
      <!--      <FormItem label="类型" prop="type">-->
      <!--        <Select v-model="form.type" transfer placeholder="关注状态">-->
      <!--          <Option v-for="(item,index) in baseData['项目标注']" :value="item.value" :key="index">{{ item.name }}</Option>-->
      <!--        </Select>-->
      <!--      </FormItem>-->
      <FormItem label="启停状态" prop="status">
        <Select v-model="form.status" transfer placeholder="启停状态">
          <Option v-for="(item,index) in baseData['启停状态']" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok(form.id?'/standard/dict/Edit':'/standard/dict/Add')">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>
<script>
import edit from '@/mixins/edit'
import {getDictInfo,getCategory} from "@/api/base";

export default {
  name: 'projectEdit',
  mixins: [edit],
  data() {
    return {
      ruleValidate: {
        iname: [
          {required: true, message: '请填写风险表项', trigger: 'blur'}
        ],
        score: [
          {required: true, type: 'number', message: '请设定总分', trigger: 'change'}
        ],
        passScore: [
          {required: true, type: 'number', message: '请设定及格分', trigger: 'change'}
        ],
      },
      categoryList:[],
      form: {
        id: '',
        categoryId: '',
        iname: '',
        type: 1,
        status: 1,
        index: 0,
        score: 10,
        passScore: 6,
      },
    }
  },
  mounted() {
    this.getCategoryList();
    if (this.mid) {
      getDictInfo({id: this.mid}).then((res) => {
        Object.keys(this.form).forEach((item) => {
          this.form[item] = res.data[item]
        })
      })
    } else {
      this.form.categoryId = this.midData[0].id;
    }
  },
  methods: {
    getCategoryList(){
      getCategory({warehouseId: this.parentID,time:new Date().getTime()}).then((res) => {
        this.categoryList=res.data||[];
      })
    },
  }
}
</script>

