<template>
  <div class="home">
    <ys-page-list
        ref="table"
        action="/report/List"
        :params="params"
        :tableHeight="tableHeight"
        keyID="49D249C99094807697AFC96E84C6B204"
        :multiBtn="multiBtn"
        showContextMenu
        :multiColumn="true"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="报告状态" prop="status" show>
          <Select clearable v-model="params.status">
            <Option v-for="(item,index) in baseData['报告状态']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="报告类型" prop="type" show>
          <Select v-model="params.type" clearable placeholder="报告类型" style="width: 100px;">
            <Option v-for="(item,index) in baseData['检查单类型']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="报告提交时间" prop="publishedDateRange" show>
          <ys-date-picker type="daterange" clearable v-model="params.publishedDateRange" placeholder="时间周期"></ys-date-picker>
        </FormItem>
        <FormItem label="打卡日期" prop="signDateRange">
          <ys-date-picker type="daterange" v-model="params.signDateRange" clearable placeholder="时间周期"></ys-date-picker>
        </FormItem>
        <FormItem label="所在地区" prop="region">
          <ys-region placement="bottom-start" changeOnSelect v-model="params.region" @on-change="getRegion"></ys-region>
        </FormItem>
        <FormItem label="服务机构" prop="serOrgId">
          <Input placeholder="服务机构" @on-clear="params.serOrgId=''" clearable readonly style="width: 250px;" v-model="params.serOrgName" search enter-button @on-search="entOrgShow = true"></Input>
        </FormItem>
        <FormItem label="报告提交专家" prop="publishExpertId">
          <Input placeholder="报告提交专家" @on-clear="params.publishExpertId=''" clearable readonly style="width: 250px;" v-model="params.expertName" search enter-button @on-search="expertShow = true"></Input>
        </FormItem>
        <FormItem label="保单编号" prop="number" show>
          <Input placeholder="保单编号" clearable style="width: 150px;" v-model="params.number"></Input>
        </FormItem>
        <FormItem label="关键词" prop="keyword" show>
          <Input placeholder="项目名称" clearable style="width: 150px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template slot="detail">
        <Detail :mid="detailID"></Detail>
      </template>
    </ys-page-list>
    <ys-modal v-model="modShow" :width="800" title="新增/修改报告">
      <Edit
          :checkId="tableSelectItem.length===1?tableSelectItem[0].checkId:0"
          :mid="detailID"
          :serveOrgId="tableSelectItem.length===1?tableSelectItem[0].serveOrgId:0"
          :name="tableSelectItem.length===1?tableSelectItem[0].projectName:''"
          @on-ok="getList"></Edit>
    </ys-modal>
    <ys-modal v-model="doReport" title="下载报告">
      <doReport :mid="tableSelectItem.length===1?tableSelectItem[0].checkId:''" :name="tableSelectItem.length===1?tableSelectItem[0].projectName:''" @on-ok="getList"></doReport>
    </ys-modal>
    <ys-modal v-model="entOrgShow" :width="1200" title="选择服务机构">
      <OrgComponent :limit="1" :oType="[3]" @on-ok="getOrgData"></OrgComponent>
    </ys-modal>
    <ys-modal v-model="expertShow" :width="1200" title="选择专家">
      <expertsComponent :limit="1" @on-ok="getExpertData"></expertsComponent>
    </ys-modal>
    <ys-drawer v-model="detailFlag">
      <check-detail :mid="tableSelectItem.length===1?tableSelectItem[0].checkId:''"></check-detail>
    </ys-drawer>
    <ys-modal :z-index="500" v-model="submitFlag" title="提交报告">
      <submit @on-ok="getList" :mid="tableSelectItem.length===1?tableSelectItem[0].checkId:''"></submit>
    </ys-modal>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'
import Detail from "@/views/task/reports/detail.vue";
import Edit from "@/views/task/reports/edit.vue";
import doReport from "@/views/task/reports/doReport";
import OrgComponent from "@/components/org";
import expertsComponent from "@/components/experts";
import {getProblemsList} from '@/api/problem'
import {getReportInfo} from '@/api/report'
import checkDetail from "@/views/task/list/detail";
import submit from "@/views/task/reports/submit";

export default {
  name: 'reports',
  mixins: [pageList],
  components: {submit, checkDetail, expertsComponent, OrgComponent, doReport, Edit, Detail},
  props: {
    type: {type: String, default: ''},
    policyId: {type: Number, default: null},
  },
  data() {
    return {
      params: {
        type: '',
        keyword: '',
        publishedDateRange: [],
        signDateRange: [],
        publishedAtEnd: '',
        region: '',
        publishedAtStart: '',
        signStart: '',
        signEnd: '',
        publishExpertId: '',
        expertId: '',
        expertName: '',
        serOrgId: '',
        serOrgName: '',
        policyId: '',
      },
      submitFlag: false,
      detailFlag: false,
      doReport: false,
      expertShow: false,
      entOrgShow: false,
      putInFlag: false,
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 70, align: 'center'},
        {width: 220, title: '报告编号', key: 'number', align: 'left'},
        {width: 130, title: '报告类型', key: 'type', align: 'left', renderConfig: {type: 'baseData', parentName: '检查单类型'}},
        {minWidth: 180, title: '任务名称', key: 'checkName', align: 'left'},
        {minWidth: 130, title: '项目名称', key: 'projectName', align: 'left', renderConfig: {type: 'gotoDetail'}},
        {width: 180, title: '保单编号', key: 'policyNumber', align: 'left'},
        {width: 180, title: '所在区域', key: 'countyCode ', align: 'left', renderConfig: {type: 'area', key: ['countyCode', 'cityCode', 'provinceCode']}},
        {width: 180, title: '提交时间', key: 'publishedAt', align: 'center', renderConfig: {type: 'formatDate', fmt: 'yyyy-MM-dd hh:mm:ss'}},
        {width: 250, title: '施工单位', key: 'enterpriseName', align: 'left'},
        {width: 250, title: '服务单位', key: 'serveOrgName', align: 'left'},
        {width: 110, title: '服务阶段', key: 'phase', align: 'center', renderConfig: {type: 'baseData', parentName: '检查阶段'}},
        {width: 130, title: '专家', key: 'publishedUser', align: 'left'},
        {width: 180, title: '打卡时间', key: 'signTime', align: 'center', renderConfig: {type: 'formatDate', fmt: 'yyyy-MM-dd hh:mm:ss'}},
        {width: 130, title: '隐患个数', key: 'commentCount', align: 'left'},
        {width: 120, title: '状态', key: 'status', align: 'left', renderConfig: {type: 'baseData', parentName: '报告状态'}},
      ]
    }
  },
  computed: {
    multiBtn() {
      return [
        {
          children: [
            {
              type: 'detail',
              title: '查看详情',
              icon: 'md-eye',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.modShow = true,
              title: '编辑',
              icon: 'md-create',
              disabled: this.tableSelectItem.length !== 1 || this.tableSelectItem[0].status === 1
            },
          ]
        },
        {
          children: [
            {
              click: () => this.checkProblems(),
              title: '提交',
              icon: 'md-cloud-upload',
              disabled: this.tableSelectItem.length !== 1 || this.tableSelectItem[0].status === 1 || [10, 30, 50].indexOf(this.tableSelectItem[0].checkStatus) < 0,
            },
            {
              click: () => this.doReport = true,
              title: '下载报告',
              icon: 'md-trash',
              disabled: this.tableSelectItem.length !== 1
            },
          ]
        }
      ]
    },
  },
  watch: {
    'params.signDateRange'() {
      this.params.signStart = this.params.signDateRange[0] ? this.params.signDateRange[0] + ' ' + '00:00:00' : ''
      this.params.signEnd = this.params.signDateRange[1] ? this.params.signDateRange[1] + ' ' + '23:59:59' : ''
    },
    'params.publishedDateRange'() {
      this.params.publishedAtStart = this.params.publishedDateRange[0] ? this.params.publishedDateRange[0] + ' ' + '00:00:00' : ''
      this.params.publishedAtEnd = this.params.publishedDateRange[1] ? this.params.publishedDateRange[1] + ' ' + '23:59:59' : ''
    },
  },
  mounted() {
    this.params.policyId = this.policyId;
    this.params[this.type === 'amend' ? 'amendId' : 'checkId'] = this.mid;
    this.getList();
  },
  methods: {
    checkProblems() {
      this.$Spin.show();
      getProblemsList({checkId: this.tableSelectItem[0].checkId, limit: -1}).then(res => {
        let errorList = [], content = '';
        (res.data.list || []).forEach(item => {
          item.fileList = item.fileList || [];
          const errorItem = [];
          if (item.fileList.length === 0) errorItem.push("附件")
          if (!item.riskGrade) errorItem.push("风险等级")
          if (!item.accidentType) errorItem.push("可能导致的事故类型")
          if (!item.remarksText) errorItem.push("风险描述")
          if (!item.remarksRisk) errorItem.push("风险依据")
          if (!item.remarksSuggest) errorItem.push("整改建议")
          if (errorItem.length > 0) errorList.push({itemName: item.itemName, errorItem})
        })
        if (errorList.length > 0) {
          errorList.forEach((item, index) => {
            content = content + (index + 1) + '、【' + item.itemName + '】的 <span style="color:#ff0900">' + item.errorItem.join('、') + '</span> 等未填写完整；<br/>'
          })
          this.$Modal.remove();
          window.setTimeout(() => {
            this.$Modal.confirm({
              okText: '前往修改',
              width: 500,
              cancelText: '强制提交',
              title: '风险必填项检查失败！',
              content: content + '请前往任务详情查看风险信息，再仔细检查并完善信息！',
              onOk: () => {
                this.detailFlag = true;
              },
              onCancel: () => {
                window.setTimeout(() => {
                  this.forceSubmit();
                }, 500)
              }
            });
          }, 300);
        } else {
          this.commit();
        }
        this.$Spin.hide();
      }).catch(() => {
        this.$Spin.hide();
      })
    },
    forceSubmit() {
      this.$Modal.confirm({
        okText: '强制提交',
        title: '强制提交报告！',
        content: '确定要强制提交报告么？',
        onOk: () => {
          window.setTimeout(() => {
            this.commit();
          }, 500)
        },
      });
    },
    commit() {
      const {checkStatus, isInsCheckReport, isCheckReport} = this.tableSelectItem[0];
      let msg = '';
      if (isCheckReport) {
        if (checkStatus === 10 || checkStatus === 30 || checkStatus === 50) {
          /*提交给专家*/
          msg = "<div style='color:#ff0900'>本次将提交给专家组组长审核！</div>";
        }
      } else if (isInsCheckReport) {
        /*提交给保险公司审核*/
        msg = "<div style='color:#de4e00'>本次将提交给保险公司审核！</div>";
      } else {
        msg = "<div style='color:#007911'>本次将提交给保险公司，无需审核！</div>";
      }
      this.$Modal.confirm({
        title: '提示',
        content: '请确认项目阶段、五牌一图、单体建筑物是否更新！提交后将无法录入风险和生成整改单！' + msg,
        okText: '确定',
        loading: true,
        cancelText: '取消',
        onOk: () => {
          getReportInfo({id: this.tableSelectItem[0].id}).then((res) => {
            this.$Modal.remove();
            if (res.code === 200) {
              const {fileList = []} = res.data;
              if (!fileList || fileList.length === 0) {
                this.$Notice.error({
                  title: '提示',
                  desc: '还没有上传报告附件，请先上传！'
                });
                this.modShow = true;
              } else {
                this.submitFlag = true
              }
            }
          })
        }
      });
    },
    getRegion(data) {
      this.params.countyCode = data[2] ? data[2].code : '';
      this.params.cityCode = data[1] ? data[1].code : '';
      this.params.provinceCode = data[0] ? data[0].code : '';
    },
    getOrgData(data) {
      this.params.serOrgId = data[0].id
      this.params.serOrgName = data[0].iname
    },
    getExpertData(data) {
      this.params.publishExpertId = data[0].userId
      this.params.expertName = data[0].iname
    }
  }
}
</script>
