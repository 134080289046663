<template>
  <div class="home">
    <ys-page-list
        keyID="722D87985BCC7DD8DB2495D64C5ADDA4"
        ref="table"
        action="inspects/List"
        :params="params"
        :tableHeight="tableHeight"
        :multiBtn="multiBtn"
        showContextMenu
        :multiColumn="true"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns"
    >
      <template slot="search">
        <FormItem label="验收状态" prop="status">
          <Select v-model="params.status" clearable placeholder="验收状态" style="width: 120px;">
            <Option v-for="(item,index) in (baseData['验收任务状态'] || []).filter(item=>item.value!==4)" :value="item.value" :key="index">{{ item.name }}
            </Option>
          </Select>
        </FormItem>
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="验收名称、项目名称、保单编号等" style="width: 180px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template slot="btn">
      </template>
      <template slot="detail">
        <Detail :mid="detailID"></Detail>
      </template>
    </ys-page-list>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'
import Detail from "@/views/task/inspects/detail.vue";

export default {
  name: 'task',
  mixins: [pageList],
  components: {
    Detail,
  },
  data() {
    return {
      params: {
        status: '',
        keyword: ''
      },
      doFlag: false,
      tabNum: '',
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 70, align: 'center'},
        {title: '编号', width: 310, key: 'code', align: 'center'},
        {width: 250, title: '验收名称', key: 'iname', align: 'left', renderConfig: {type: 'gotoDetail'}},
        {minWidth: 250, title: '项目名称', key: 'projectName', align: 'left'},
        {width: 200, title: '发布时间', key: 'publishedAt', align: 'left'},
        {width: 150, title: '发布人', key: 'publishedUser', align: 'left'},
        {width: 120, title: '创建时间', key: 'createdAt', align: 'center', renderConfig: {type: 'formatDate'}},
        {width: 120, title: '状态', key: 'status', align: 'center', renderConfig: {type: 'baseData', parentName: '验收任务状态'}},
      ]
    }
  },
  computed: {
    multiBtn() {
      return [
        {
          children: [
            {
              type: 'detail',
              targetUrl: '/main/task/detail/' + this.detailID,
              title: '查看详情',
              icon: 'md-eye',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.delItem('/inspects/Remove', {ids: this.tableSelectItem.map(item => item.id)}),
              iconColor: '#ed4014',
              title: '删除',
              icon: 'md-trash',
              disabled: this.tableSelectItem.length !== 1
            },
          ]
        },
        {
          children: [
          ]
        },
      ]
    }
  },

  mounted() {
    this.getList();
  },

  methods: {
  }

}
</script>
