<template>
  <div>

    <Form ref="form" class="editForm twoPartsEditForm" inline :model="form" :rules="ruleValidate" :label-width="90" :style="{maxHeight:'calc(100vh - 200px)'}" style="overflow-y: auto">
      <FormItem label="通知名称" prop="title">
        <Input v-model="form.title" disabled placeholder="通知名称"> </Input>
      </FormItem>
      <FormItem label="默认接收人" prop="recvDefault">
        <i-switch :true-value="1" :false-value="2" v-model="form.recvDefault">
          <span slot="open">是</span>
          <span slot="close">否</span>
        </i-switch>
        默认接收人是否仍然接收通知
      </FormItem>

      <FormItem label="启停状态" prop="status">
        <i-switch :disabled="form.setStatus!==1" size="large" :true-value="1" :false-value="2" v-model="form.status">
          <span slot="open">启用</span>
          <span slot="close">停用</span>
        </i-switch>
      </FormItem>
      <FormItem label="指定接收人" prop="recvUser">
        <i-switch :disabled="form.setAcceptor!==1" :true-value="1" :false-value="2" v-model="form.recvUser">
          <span slot="open">是</span>
          <span slot="close">否</span>
        </i-switch>
      </FormItem>
      <FormItem label="备注" prop="desc" style="width: 100%">
        <Input v-model="form.desc" type="textarea" maxlength="128" show-word-limit style="width: 100%" :autosize="{minRows: 2,maxRows: 3}" placeholder="输入备注"></Input>
      </FormItem>
      <div v-if="form.recvUser===1">
        <h1 class="h1Title">指定接收人</h1>
        <div v-for="(item,index) in form.orgRecvUsers" :key="index">
          <FormItem label="姓名" style="width: 37%" prop="name">
            <Input v-model="item.name" placeholder="姓名"/>
          </FormItem>
          <FormItem label="" :labelWidth="0" style="width: 57%">
            <Input v-model="item.phone" placeholder="专家姓名"/>
          </FormItem>
          <Button icon="ios-close-circle-outline" @click="del(index)" style="margin-left: 5px;margin-top:1px;"></Button>
        </div>
        <FormItem label="" style="width: 100%">
          <Button icon="md-add" @click="userFlag=true" type="primary">新增</Button>
        </FormItem>
      </div>

    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="save">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
    <ys-modal v-model="userFlag" :width="1000" title="选择用户">
      <user :companyId="parentID" @on-ok="getUser"></user>
    </ys-modal>
  </div>
</template>
<script>
import edit from '@/mixins/edit'
import user from "_c/user";
import {smsOrgConfigInfo} from '@/api/base'

export default {
  name: 'orgEdit',
  mixins: [edit],
  components: {user},
  data() {
    return {
      userFlag: false,
      form: {
        title: '',
        smsTemplateId: '',
        orgId: '',
        setAcceptor: 1,
        setStatus: 1,
        recvDefault: 1,
        recvUser: 1,
        status: 1,
        desc: '',
        orgRecvUsers: [],
      },
    }
  },
  mounted() {
    if (this.midData.length === 1) {
      if (this.midData[0].orgId) {
        smsOrgConfigInfo({id: this.midData[0].smsOrgConfigId}).then(res => {
          if(res.code===200){
            Object.keys(this.form).forEach((item) => {
              this.form[item] = res.data[item]
            })
            this.form.orgRecvUsers = res.data.orgRecvUsers || [];
          }
        })
      } else {
        Object.keys(this.form).forEach((item) => {
          this.form[item] = this.midData[0][item]
        })
        this.form.smsTemplateId = this.midData[0].id;
        this.form.recvDefault = 1;
        this.form.recvUser = 2;
        this.form.orgId = this.parentID;
        this.form.orgRecvUsers = [];
      }
    }
  },
  methods: {
    del(index) {
      this.form.orgRecvUsers.splice(index, 1);
    },
    save(){
      if(this.form.recvUser===1&&this.form.orgRecvUsers.length===0){
        this.$Notice.error({
          title: "提示！",
          desc: '请选择指定接收人！'
        });
        return;
      }
      this.ok('/sms/orgConfig/AddOrEdit')
    },
    getUser(data) {
      data.forEach(item => {
        if (!this.form.orgRecvUsers.some(uItem => uItem.userId === item.id)) {
          this.form.orgRecvUsers.push({
            userId: item.id,
            phone: item.phone,
            name: item.iname
          })
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
</style>