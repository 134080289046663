<template>
  <div>
    <Form ref="form" class="editForm" :model="form" :rules="ruleValidate" :label-width="90">
      <FormItem label="服务类型" prop="type">
        <Select v-model="form.type" placeholder="服务类型">
          <Option v-for="(item) in baseData['随机任务组类型']" :value="item.value" :key="item.value">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="服务项目" prop="categoryList">
        <Cascader change-on-select :data="categoryList" v-model="form.categoryList" @on-change="categoryChange"/>
      </FormItem>
      <FormItem label="等级" prop="level">
        <Select v-model="form.level" placeholder="等级" clearable>
          <Option v-for="(item) in baseData['专家等级']" :value="item.value" :key="item.value">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="启停状态" prop="status">
        <Select v-model="form.status" placeholder="启停状态">
          <Option v-for="(item) in baseData['启停状态']" :value="item.value" :key="item.value">{{ item.name }}</Option>
        </Select>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="save">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>

<script>
import edit from '@/mixins/edit'
import {expertConfigInfo} from "@/api/expert";
import {categoryList} from '../category.js'

export default {
  name: 'expertsServiceEdit',
  mixins: [edit],
  data() {
    return {
      ruleValidate: {
        categoryList: [
          {required: true, type: 'array', message: '请选择服务项目', trigger: 'change'}
        ],
      },
      categoryList: categoryList,
      form: {
        id: '',
        type: 10,
        categoryList: [],
        expertId: this.parentID,
        category: '',
        categoryName: '',
        level: '',
        status: 1,
      }
    }
  },
  mounted() {
    if (this.mid) {
      expertConfigInfo({id: this.mid}).then((res) => {
        Object.keys(this.form).forEach((item) => {
          this.form[item] = res.data[item]
        })
        this.form.categoryList = res.data.category ? res.data.category.split(',') : [];
      })
    }
  },
  methods: {
    categoryChange(data = [], array = []) {
      this.form.category = data.join();
      this.form.categoryName = array.map(item => item.label).join('-')
    },
    save() {
      this.ok('/dynamicCheck/expertConfig/AddOrEdit')
    }
  }

}
</script>
