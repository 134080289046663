<template>
  <div>
    <Form ref="form" :model="form" inline :label-width="60" :style="{maxHeight:'calc(100vh - 200px)'}" style="overflow-y: auto">
      <div v-for="item in form.dictList" :key="item.id">
        <FormItem label="名称" prop="name" style="width: 55%">
          <Input v-model="item.name" placeholder="名称"/>
        </FormItem>
        <FormItem label="值" :labelWidth="40" prop="value" style="width: 20%">
          <Input v-model="item.value" disabled placeholder="值"/>
        </FormItem>
        <FormItem label="颜色" prop="color" style="width: 20%">
          <ColorPicker alpha recommend v-model="item.color" transfer/>
        </FormItem>
      </div>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="save">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>
<script>
import edit from '@/mixins/edit'
import {saveBaseData} from "@/api/base";

export default {
  name: 'projectEdit',
  mixins: [edit],
  data() {
    return {

      form: {
        dictList: [],
      },
    }
  },
  mounted() {
    if (this.midData.length > 0) this.form.dictList = this.midData;
  },
  methods: {
    save() {
      this.modal.spinShow = true;
      Promise.all([
        this.form.dictList.map((item) => saveBaseData(item))
      ]).then(() => {
        this.modal.spinShow = false;
        this.close();
        this.$emit('on-ok');
        this.$Notice.success({
          title: "提示！",
          desc: '操作成功！'
        });
      })
    }
  }
}
</script>

<style lang="less" scoped>

</style>


