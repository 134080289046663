<template>
  <div>
    <Form ref="form" class="editForm" :model="form" :rules="ruleValidate" :label-width="100">
      <FormItem label="上级部门名称" prop="parentName" v-if="form.parentId">
        <Input v-model="form.parentName" disabled placeholder="上级部门名称"></Input>
      </FormItem>
      <FormItem label="部门名称" prop="iname">
        <Input v-model="form.iname" placeholder="部门名称"></Input>
      </FormItem>
      <FormItem label="部门联系人" prop="contactName">
        <Input v-model="form.contactName" placeholder="部门联系人"></Input>
      </FormItem>
      <FormItem label="联系电话" prop="contactsPhone">
        <Input v-model="form.contactsPhone" placeholder="联系电话"></Input>
      </FormItem>
      <FormItem label="备注" prop="remarks" style="width: 100%">
        <Input v-model="form.remarks" type="textarea" maxlength="128" show-word-limit style="width: 100%" :autosize="{minRows: 3,maxRows: 5}" placeholder="输入备注"></Input>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok(form.id?'/manage/dep/Edit':'/manage/dep/Add')">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>

<script>
import edit from '@/mixins/edit'
import {getDepartmentInfo} from "@/api/department";

export default {
  name: '',
  mixins: [edit],
  props: {
    orgId: {type: [Number, String], default: ''},
  },
  data() {
    return {
      fileIDList: [],
      form: {
        "orgId": "",
        "parentId": "",
        "id": "",
        "contactName": "",
        "parentName": "",
        "contactsPhone": "",
        "iname": "",
        "remarks": "",
      }
    }
  },

  mounted() {
    this.form.orgId = this.orgId || this.userInfo.org_id;
    if (this.modData.length > 0) {
      getDepartmentInfo({id: this.modData[0].id}).then((res) => {
        Object.keys(this.form).forEach((item) => {
          this.form[item] = res.data[item]
        })
      })
    }
    if (this.midData && this.midData.length > 0) {
      this.form.parent_id = this.midData[0].id;
      this.form.parent_name = this.midData[0].iname;
    }
  }

}
</script>
