<template>
  <div>
    <Form ref="form" class="editForm twoPartsEditForm" :model="form" :rules="ruleValidate" inline :label-width="110" :style="{maxHeight:'calc(100vh - 200px)'}" style="overflow-y: auto">
      <FormItem label="新增方式" prop="addType" style="width: 100%" v-if="!form.orgProjectId">
        <RadioGroup v-model="addType" @on-change="addChange" type="button" button-style="solid">
          <Radio :label="1">新建参建单位</Radio>
          <Radio :label="2">关联已有参建单位</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="机构名称" prop="iname">
        <Input :key="key" :disabled="addType===2" v-model="form.iname" placeholder="机构名称">
          <Button slot="append" v-if="addType===2" @click="orgFlag=true">选择机构</Button>
        </Input>
      </FormItem>
      <FormItem label="社会信用代码" prop="societyCode">
        <Input v-model="form.societyCode" :disabled="addType===2" placeholder="社会信用代码"></Input>
      </FormItem>
      <FormItem label="英文缩写" prop="code">
        <Input v-model="form.code" placeholder="英文缩写"></Input>
      </FormItem>
      <FormItem label="简称" prop="shortName">
        <Input v-model="form.shortName" placeholder="简称"></Input>
      </FormItem>
      <FormItem label="单位类型" prop="type">
        <Select v-model="form.type" transfer placeholder="参建单位类型">
          <Option v-for="(item,index) in baseData['参建单位类型']" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="联系人" prop="contacts">
        <Input v-model="form.contacts" placeholder="联系人"></Input>
      </FormItem>
      <FormItem label="联系电话" prop="phone">
        <Input v-model="form.phone" placeholder="联系电话"></Input>
      </FormItem>
      <FormItem label="所在地区" prop="area">
        <ys-region v-model="form.area" :disabled="addType===2" @on-change="getArea" placeholder="所在地区"/>
      </FormItem>
      <FormItem label="办公地址" prop="address" style="width: 100%">
        <Input v-model="form.address" :disabled="addType===2" placeholder="办公地址"></Input>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok(addType === 1?'/projects/org/AddOrEdit':'/org/LinkProject')">{{ addType === 1 ? '新增' : '绑定' }}</Button>
      <Button @click="close">取消</Button>
    </div>
    <ys-modal v-model="orgFlag" :width="1200" title="选择项目">
      <org-component :limit="1" :oType="4" @on-ok="getData"></org-component>
    </ys-modal>
  </div>
</template>
<script>
import edit from '@/mixins/edit'
import {orgInfo} from "@/api/org";
import OrgComponent from "_c/org";

export default {
  name: 'projectEdit',
  components: {OrgComponent},
  mixins: [edit],
  data() {
    return {
      key: new Date().getTime(),
      orgFlag: false,
      ruleValidate: {
        iname: [
          {required: true, message: '请填机构名称', trigger: 'blur'}
        ],
        societyCode: [
          {required: true, pattern: this.$reg.unifiedSocialCreditReg.reg, message: this.$reg.unifiedSocialCreditReg.tips, trigger: 'blur'}
        ],
        area: [
          {required: true, message: '请选择所在区域', trigger: 'change'}
        ],
        phone: [
          {required: true, pattern: this.$reg.phoneOrTelReg.reg, message: this.$reg.phoneOrTelReg.tips, trigger: 'blur'}
        ],
      },
      addType: 2,
      form: {
        orgId: '',
        canEditOrg: true,
        iname: '',
        societyCode: '',
        orgProjectId: '',
        projectId: '',
        oType: 4,
        type: 31,
        shortName: '',
        id: '',
        logo: '',
        logoTitle: '',
        contacts: '',
        phone: '',
        code: '',
        provinceCode: '',
        cityCode: '',
        countyCode: '',
        address: '',
        streetCode: '',
        area: '',
        createUser: true,
      },
      timer: null
    }
  },
  mounted() {
    if (this.mid) {
      this.getInfo()
    } else {
      this.form.projectId = this.parentID
    }
  },
  methods: {
    addChange() {
      this.key = new Date().getTime();
      this.$refs.form.resetFields()
    },
    getInfo() {
      orgInfo({orgProjectId: this.mid}).then((res) => {
        Object.keys(this.form).forEach((item) => {
          this.form[item] = res.data[item] || null
        })
        this.form.area = res.data.countyCode;
        this.form.projectId = this.parentID
      })
    },
    getData(data) {
      this.form.iname = data[0].iname;
      this.form.orgId = data[0].id;
      Object.keys(this.form).forEach((item) => {
        this.form[item] = data[0][item]
      })
      this.form.area = data[0].countyCode;
      this.form.projectId = this.parentID
    },
    getArea(data) {
      this.form.provinceCode = data.length > 0 ? data[0].code : '';
      this.form.cityCode = data.length > 1 ? data[1].code : '';
      this.form.countyCode = data.length > 2 ? data[2].code : '';
    }
  }
}
</script>

<style lang="less" scoped>
</style>
