<template>
  <div>
    <Form ref="form" class="editForm twoPartsEditForm" :model="form" :rules="ruleValidate" inline :label-width="130" :style="{maxHeight:'calc(100vh - 200px)'}" style="overflow-y: auto">
      <h1 class="h1Title">政府端</h1>
      <FormItem label="站点title" prop="govTitle">
        <Input v-model="form.govTitle" placeholder="站点title"></Input>
      </FormItem>
      <FormItem label="看板标题" prop="govBoardTitle">
        <Input v-model="form.govBoardTitle" placeholder="看板标题"></Input>
      </FormItem>
      <FormItem label="站点描述" prop="govDesc" style="width: 100%" v-if="1===0">
        <Input v-model="form.govDesc" type="textarea" maxlength="128" show-word-limit style="width: 100%" :autosize="{minRows: 2,maxRows: 3}" placeholder="站点描述"></Input>
      </FormItem>
      <h1 class="h1Title">保险端</h1>
      <FormItem label="站点title" prop="insTitle">
        <Input v-model="form.insTitle" placeholder="站点title"></Input>
      </FormItem>
      <FormItem label="看板标题" prop="insBoardTitle">
        <Input v-model="form.insBoardTitle" placeholder="看板标题"></Input>
      </FormItem>
      <FormItem label="站点描述" prop="insDesc" style="width: 100%" v-if="1===0">
        <Input v-model="form.insDesc" type="textarea" maxlength="128" show-word-limit style="width: 100%" :autosize="{minRows: 2,maxRows: 3}" placeholder="站点描述"></Input>
      </FormItem>
      <h1 class="h1Title">专家端</h1>
      <FormItem label="站点title" prop="terTitle">
        <Input v-model="form.terTitle" placeholder="站点title"></Input>
      </FormItem>
      <FormItem label="看板标题" prop="terBoardTitle" v-if="1===0">
        <Input v-model="form.terBoardTitle" placeholder="看板标题"></Input>
      </FormItem>
      <FormItem label="站点描述" prop="terDesc" style="width: 100%" v-if="1===0">
        <Input v-model="form.terDesc" type="textarea" maxlength="128" show-word-limit style="width: 100%" :autosize="{minRows: 2,maxRows: 3}" placeholder="站点描述"></Input>
      </FormItem>
      <h1 class="h1Title">服务机构端</h1>
      <FormItem label="站点title" prop="serTitle">
        <Input v-model="form.serTitle" placeholder="站点title"></Input>
      </FormItem>
      <FormItem label="看板标题" prop="serBoardTitle" v-if="1===0">
        <Input v-model="form.serBoardTitle" placeholder="看板标题"></Input>
      </FormItem>
      <FormItem label="站点描述" prop="serDesc" style="width: 100%" v-if="1===0">
        <Input v-model="form.serDesc" type="textarea" maxlength="128" show-word-limit style="width: 100%" :autosize="{minRows: 2,maxRows: 3}" placeholder="站点描述"></Input>
      </FormItem>
      <h1 class="h1Title">企业端</h1>
      <FormItem label="站点title" prop="entTitle">
        <Input v-model="form.entTitle" placeholder="站点title"></Input>
      </FormItem>
      <FormItem label="看板标题" prop="entBoardTitle" v-if="1===0">
        <Input v-model="form.entBoardTitle" placeholder="看板标题"></Input>
      </FormItem>
      <FormItem label="站点描述" prop="entDesc" style="width: 100%" v-if="1===0">
        <Input v-model="form.entDesc" type="textarea" maxlength="128" show-word-limit style="width: 100%" :autosize="{minRows: 2,maxRows: 3}" placeholder="站点描述"></Input>
      </FormItem>
      <h1 class="h1Title">运维端</h1>
      <FormItem label="站点title" prop="admTitle">
        <Input v-model="form.admTitle" placeholder="站点title"></Input>
      </FormItem>
      <FormItem label="看板标题" prop="admBoardTitle" v-if="1===0">
        <Input v-model="form.admBoardTitle" placeholder="看板标题"></Input>
      </FormItem>
      <FormItem label="站点描述" prop="admDesc" style="width: 100%" v-if="1===0">
        <Input v-model="form.admDesc" type="textarea" maxlength="128" show-word-limit style="width: 100%" :autosize="{minRows: 2,maxRows: 3}" placeholder="站点描述"></Input>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <!-- 根据id值来判断是新增还是编辑,传入接口名调用写在mixins中的方法(会通过refs自动获取到当前form中数据) -->
      <Button type="primary" @click="ok('/systemConfig/EditSystemInfo')">确定并保存</Button>
    </div>
  </div>
</template>

<script>

import {getTitleInfo,} from "@/api/base";
import edit from "@/mixins/edit";

export default {
  name: 'set',
  mixins:[edit],
  data() {
    return {
      ruleValidate: {},
      form: {
        // 运维端
        admTitle: '',
        admBoardTitle: '',
        admDesc: '',
        // 专家端
        terTitle: '',
        terBoardTitle: '',
        terDesc: '',
        // 企业端
        entBoardTitle: "",
        entDesc: "",
        entTitle: "",
        // 政府端
        govBoardTitle: "",
        govDesc: "",
        govTitle: "",
        // 保险端
        insBoardTitle: "",
        insDesc: "",
        insTitle: "",
        // 服务机构端
        serBoardTitle: "",
        serDesc: "",
        serTitle: "",
      },
    }
  },
  mounted() {
    this.getInfo()
  },
  methods: {
    getInfo() {
      getTitleInfo().then((res) => {
        if (res.code === 200) {
          this.form = res.data
        }
      })
    },
  }
}
</script>
<style lang="less" scoped>

</style>