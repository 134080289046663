<template>
  <ys-detail :icon="detailIcon" :error="error" @on-error="getDetail" :image="'/web/resource/GetFile/'+detail.pictureId+'?size=100'" :loading="loading" :name="detail.name">
    <template slot="brief">
      <ys-cell title="账号" :label="detail.username"/>
      <ys-cell title="联系电话" :label="detail.phone"/>
      <ys-cell title="身份证" :label="detail.identityCode"/>
      <ys-cell title="邮箱" :label="detail.email"/>
    </template>
    <template slot="main-left">
      <Tabs v-model="tab" name="detail" type="card">
        <Tab-pane label="所在机构" name="1" icon="md-git-network">
          <org :tableHeight="pageListHeight" :mid="detailID" v-if="tab==='1'"/>
        </Tab-pane>
      </Tabs>
    </template>
    <template slot="main-right">
      <h1 class="h1Title">其他信息</h1>
      <div style="text-align: center">暂未开放！</div>
    </template>
  </ys-detail>
</template>

<script>
import detailMixins from '@/mixins/detail'
import {userInfo} from "@/api/org";
import org from "@/views/org/member/detail/org.vue";

export default {
  name: "detail",
  mixins: [detailMixins],
  props: {orgId: {type: Number, default: null}},
  components: {
    org
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.loading = true;
      userInfo({id: this.detailID}).then(res => {
        this.loading = false;
        if (res.code === 200) {
          this.detail = res.data;
          this.setTagName(this.detail.iname)
        }else{
          this.error = true;
        }
      }).catch(() => {
        this.loading = false;
        this.error = true;
      })
    }
  }
}
</script>

<style scoped>

</style>