<template>
  <div class="menu">
    <Row>
      <Col span="6">
        <Select v-model="params.oType" @on-change="getList" placeholder="平台类型">
          <Option v-for="(item,index) in baseData['平台类型']" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
        <div :style="{height:height-165+'px',overflowY:'auto'}" style="position: relative;margin: 10px 0 0 0;">
          <!--          <Tree class="demo-tree-render" check-strictly show-checkbox @on-select-change="onSelectChange" @on-check-change="onCheckChange" :data="treeData" :render="renderContent"></Tree>-->
          <ys-tree :draggable="true" :data="treeData" @on-draggable-end="draggableEnd" :render="renderContent" @on-select-change="onSelectChange"></ys-tree>
        </div>
      </Col>
      <Col span="18" style="padding: 0 15px 15px 15px;">
        <detail :mid="mid" :oType="params.oType"></detail>
      </Col>
    </Row>
    <ys-modal v-model="modShow" :width="800" title="新增/修改菜单">
      <Edit :mid="mid" :treeData="treeData" :midData="midData" :oType="params.oType" @on-ok="getList"></Edit>
    </ys-modal>
  </div>
</template>

<script>
import Edit from "@/views/base/menu/edit.vue";
import {getMenuList, deltMenu, exchangeIndex} from '@/api/base'
import {mapGetters} from 'vuex'
import {listToTree, getBaseDataName} from 'ys-admin'
import Detail from "@/views/base/menu/detail";

export default {
  name: 'projects',
  components: {Detail, Edit},
  computed: {
    checkedFlag() {
      return this.checkedData.length !== 2
    },
    ...mapGetters(['height', 'baseData'])
  },
  data() {
    return {
      midData: [],
      treeData: [],
      modShow: false,
      mid: '',
      params: {
        oType: 6,
        keyword: '',
      },
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 拖拽结束
    draggableEnd(oldData, newData) {
      if (oldData.id === newData.id) return;
      exchangeIndex({fromId: oldData.id, toId: newData.id}).then((res) => {
        if (res.code === 200) {
          this.$Notice.success({title: '提示', desc: '操作成功！'});
          this.checkedData = [];
          this.getList();
        }
      })
    },
    getList() {
      getMenuList(this.params).then(res => {
        if (res.code === 200) {
          let parent = [{
            children: [],
            id: 0,
            label: getBaseDataName('平台类型', this.params.oType, false) + '的菜单',
            value: 0,
            title: getBaseDataName('平台类型', this.params.oType, false) + '的菜单',
            expand: true
          }]
          parent[0].children = listToTree(res.data, {parentKey: 'parentId', topLevelVal: 0,addProperties: {expand: false}})
          this.treeData = parent;
        }
      })
    },
    onSelectChange(item) {
      this.mid = item.id
    },
    renderContent(h, {data}) {
      return h('div', {
        style: {height: '24px', lineHeight: '24px',},
      }, [
        h('span', [
          h('Icon', {
            props: {
              type: data.icon||'ios-apps',
              color: data.color
            },
            style: {
              color: data.color,
              marginRight: '8px'
            }
          }),
          h('span', {
            style: {opacity: data.hideInMenu ? 0.5 : 1}
          }, data.title + (data.index ? ' [' + data.index + ']' : ''))
        ]),
        h('span', {
          style: {display: 'inline-block', float: 'right', marginRight: '5px'}
        }, [
          h('Button', {
            props: Object.assign({}, this.buttonProps, {
              icon: 'md-add',
              size: 'small'
            }),
            style: {display: !data.dictId ? 'inline-block' : 'none'},
            nativeOn: {
              click: (e) => {
                this.mid = '';
                this.midData = [data];
                this.modShow = true;
                e.stopPropagation();
              }
            }
          }),
          h('Button', {
            props: Object.assign({}, this.buttonProps, {
              icon: 'md-create',
              size: 'small'
            }),
            style: {marginLeft: '5px', display: data.id === 0 ? 'none' : ''},
            nativeOn: {
              click: (e) => {
                this.mid = data.id;
                this.modShow = true;
                e.stopPropagation();
              }
            }
          }),
          h('Button', {
            props: Object.assign({}, this.buttonProps, {
              icon: 'md-close',
              size: 'small'
            }),
            style: {marginLeft: '5px', display: data.id === 0 ? 'none' : ''},
            nativeOn: {
              click: (e) => {
                this.remove(data)
                e.stopPropagation();
              }
            }
          })
        ])
      ]);
    },
    remove(data) {
      if (Array.isArray(data.children) && data.children.length > 0) {
        this.$Notice.error({
          title: '提示',
          desc: '该菜单下含有子菜单，请先删除子菜单！'
        })
        return false
      }
      this.$Modal.confirm({
        title: '提示',
        content: '确定要删除这一项么',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          deltMenu({id: data.id}).then((res) => {
            if (res.code === 200) {
              this.$Notice.success({title: '提示', desc: '删除成功！'});
              this.getList();
            }
          })
        }
      })
    },
  }
}
</script>
<style lang="less" scoped>
.demo-tree-render {
  overflow-x: hidden;

  .ivu-tree-title {
    width: 100%;
  }
}

.menu {
  padding: 10px;
}
</style>