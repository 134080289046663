<template>
  <ys-detail :icon="detailIcon" :image="detail.logo?'/web/resource/GetFile/'+detail.logo:''" :loading="loading" :name="detail.iname">
    <Tag size="medium" color="blue" slot="status">{{ detail.status|getBaseDataName('启停状态') }}</Tag>
    <template slot="brief">
      <ys-cell title="机构名称" :label="detail.iname"/>
      <ys-cell title="机构类型" :label="detail.oType|getBaseDataName('组织类型')"/>
      <ys-cell title="联系人" :label="detail.contacts"/>
      <ys-cell title="联系电话" :label="detail.phone"/>
    </template>
    <template slot="main-left">
      <Tabs v-model="tab" name="detail" :animated="false" type="card" :key="detail.id">
        <Tab-pane label="基本信息" name="1" tab="detail" icon="ios-paper">
          <base-info :mid="detailID" v-if="tab==='1'"/>
        </Tab-pane>
        <Tab-pane label="所有成员" name="2" tab="detail" icon="ios-contacts">
          <member :tableHeight="pageListHeight" v-if="tab==='2'" :mid="detailID" :midData="[detail]"></member>
        </Tab-pane>
        <Tab-pane label="管辖区域" name="3" icon="md-pin">
          <orgArea :tableHeight="pageListHeight" v-if="tab==='3'" :mid="detailID"></orgArea>
        </Tab-pane>
        <Tab-pane label="子机构" name="4" icon="md-git-network">
         <subOrgs :tableHeight="pageListHeight" :midData="[detail]" v-if="tab==='4'" :mid="detailID"></subOrgs>
        </Tab-pane>
        <Tab-pane label="服务项目" name="5" tab="detail" icon="ios-podium">
          <projects :tableHeight="pageListHeight" v-if="tab==='5'" :oType="detail.oType" :mid="detailID"></projects>
        </Tab-pane>
        <Tab-pane label="比例配置" name="6" v-if="detail.type===6" icon="ios-pie">
          <rate :tableHeight="pageListHeight" v-if="tab==='6'" :mid="detailID"></rate>
        </Tab-pane>
        <Tab-pane label="短信配置" name="7" icon="ios-pie">
          <smsConfig :tableHeight="pageListHeight" v-if="tab==='7'&&detailID" :mid="detailID"></smsConfig>
        </Tab-pane>
        <Tab-pane label="数据同步" name="8" icon="ios-pie" v-if="detail.oType===1">
          <async :tableHeight="pageListHeight" v-if="tab==='8'" :mid="detailID"></async>
        </Tab-pane>
      </Tabs>
    </template>
  </ys-detail>
</template>

<script>
import detailMixins from '@/mixins/detail'
import {orgInfo} from "@/api/org";
import Projects from "@/views/org/list/detail/projects.vue";

export default {
  name: "detail",
  mixins: [detailMixins],
  components: {
    Projects,
    orgArea: () => import('@/views/org/list/detail/area/index.vue'),
    rate: () => import('@/views/org/list/detail/rate/index.vue'),
    member: () => import('@/views/org/list/detail/member.vue'),
    baseInfo: () => import('@/views/org/list/detail/baseInfo.vue'),
    subOrgs: () => import('@/views/org/list/detail/subOrgs.vue'),
    smsConfig: () => import('@/views/org/list/detail/smsConfig/index.vue'),
    async: () => import('@/views/org/list/detail/async.vue')
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.loading = true;
      this.detail = {}
      orgInfo({id: this.detailID, noPending: true}).then(res => {
        this.loading = false;
        if (res.code === 200) {
          this.detail = res.data;
        }
        this.setTagName(this.detail.iname)
      })
    }
  }
}
</script>

<style scoped>

</style>