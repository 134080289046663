<template>
  <div>
    <Alert>
      请按照数据模板的格式准备要导入的数据。
      <template slot="desc">
        <p>1、模板中的表头名称不可更改，表头行不能删除</p>
        <p>2、其中*为必填项，必须保留</p>
        <p>3、点击此处<a href="javascript:;" @click="downFile">下载模板</a></p>
      </template>
    </Alert>
    <h1 class="h1Title">上传文件</h1>
    <ys-upload :defaultList="defaultList" :headers="{'token':token,orgId:userInfo.org_id}" @on-success="success" :limit="1" :action="'/web/dynamicCheck/expertConfig/Import'">
      <Button type="primary" long>上传模板</Button>
    </ys-upload>
    <ys-modal v-model="failFlag" :width="800" :title="'导入失败 '+failData.length+' 个'">
      <ys-page-list
          ref="table"
          :footer="false"
          :header="false"
          :tableHeight="height-150"
          :headerColumns="headerColumns"
          :tableData="failData"/>
    </ys-modal>
  </div>
</template>
<script>
import edit from '@/mixins/edit'; //引入混入组件edit

export default {
  name: 'projectPutIn',
  mixins: [edit],
  data() {
    return {
      defaultList: [],
      failData: [],
      failFlag: false,
      headerColumns: [
        {title: '序号', width: 50, key: 'index', align: 'center'},
        {minWidth: 230, title: '名称', tooltip: true, key: 'name', align: 'left',},
        {width: 230, title: '电话', tooltip: true, key: 'phone', align: 'left'},
        {width: 230, title: '结果', tooltip: true, key: 'msg', align: 'left'},
      ],
    }
  },
  methods: {
    success(file, res) {
      if (res.code === 200) {
        if (res.data.fail.length === 0) {
          this.$Modal.success({
            title: '操作成功',
            content: '导入成功' + res.data.success.length + '个，导入失败' + res.data.fail.length + '个',
          })
          this.close()
        } else {
          this.defaultList = [];
          this.failData = res.data.fail;
          this.failFlag = true;
          this.$emit('on-ok')
        }

      } else {
        this.$Modal.error({
          title: '操作失败',
          content: res.msg + '，请重新上传！',
        })
        this.defaultList = [];
      }
    },
    downFile() {
      window.location.href = "https://youshi-common.oss-cn-hangzhou.aliyuncs.com/model/专家配置导入模版v1.xlsx"
    }
  }
}
</script>
