<template>
  <!-- 新增/编辑弹出框 -->
  <div>
    <Form ref="form" class="editForm twoPartsEditForm" :model="form" :rules="ruleValidate" inline :label-width="130" :style="{maxHeight:'calc(100vh - 200px)'}" style="overflow-y: auto">
      <!--      <FormItem label="保单类型" prop="type">-->
      <!--        <Select v-model="form.type" placeholder="保单类型">-->
      <!--          <Option v-for="(item,index) in typeList" :value="item.value" :key="index">{{ item.name }}</Option>-->
      <!--        </Select>-->
      <!--      </FormItem>-->
      <FormItem label="子保单类型" prop="subType" style="width: 30%">
        <Select v-model="form.subType" :disabled="!!form.id||renewalFlag" @on-change="subTypeChange" placeholder="子保单类型">
          <Option v-for="(item,index) in baseData['保单子类型']" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="保单来源" prop="source" :label-width="70" style="width: 20%">
        <Select v-model="form.source" disabled>
          <Option v-for="(item,index) in baseData['保单来源']" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="保单号" prop="number">
        <Input v-model="form.number" :placeholder="form.subType===102?'自动生成':'保单号'"></Input>
      </FormItem>
      <FormItem label="项目名称" prop="projectName" style="width: 100%">
        <Input v-model="form.projectName" readonly placeholder="项目名称">
          <Button slot="append" :disabled="!!form.id||renewalFlag" @click="projectFlag=true">选择项目</Button>
        </Input>
      </FormItem>
      <FormItem label="保单开始时间" prop="startAt">
        <ys-date-picker v-model="form.startAt" @on-change="calcServiceCount" style="width: 100%;" placeholder="保单开始时间"></ys-date-picker>
      </FormItem>
      <FormItem label="保单结束时间" prop="endAt">
        <ys-date-picker v-model="form.endAt" @on-change="calcServiceCount" style="width: 100%;" placeholder="保单结束时间"></ys-date-picker>
      </FormItem>
      <!-- 通用安责险 -->
      <div v-if="form.subType===101">
        <FormItem label="保费总金额(元)" prop="premiumCost">
          <Tooltip :disabled="form.premiumCost * 1 < 1000000" content="保费过高请确认保费是否正确！" placement="top">
            <InputNumber v-model="form.premiumCost" placeholder="保费金额" style="width: 350px" @on-change="premiumCostChange"></InputNumber>
          </Tooltip>
        </FormItem>
        <FormItem label="项目总保额(元)" prop="insureAmount">
          <InputNumber v-model="form.insureAmount" placeholder="项目保额" style="width:100%"></InputNumber>
        </FormItem>
        <FormItem label="投保单位联系人" prop="enterpriseContact" style="width: 50%">
          <Input v-model="form.enterpriseContact" placeholder="联系人" style="width: 35%;"></Input>
          <Input v-model="form.enterprisePhone" placeholder="联系方式" style="width: 65%;"></Input>
        </FormItem>
        <FormItem label="保险业务经理" prop="companyContact" style="width: 50%">
          <Input v-model="form.companyContact" placeholder="保险业务经理" style="width: 35%;"></Input>
          <Input v-model="form.companyPhone" placeholder="联系方式" style="width: 65%;"></Input>
        </FormItem>
      </div>
      <FormItem label="投保时间" prop="insureAt" style="width: 50%">
        <ys-date-picker v-model="form.insureAt" @on-change="insureAtChange" style="width: 100%;" placeholder="投保时间"></ys-date-picker>
      </FormItem>
      <FormItem label="投保单号" prop="insureNum" style="width: 50%">
        <Input v-model="form.insureNum" :placeholder="form.subType===102?'自动生成':'投保单号'"></Input>
      </FormItem>
      <FormItem label="承保机构" prop="companyName" :rules="{required: true, message: '请选择承保机构', trigger: 'change'}">
        <Input v-model="form.companyName" readonly placeholder="承保机构">
          <Button slot="append" :disabled="!!form.id||renewalFlag" @click="companyFlag=true">选择</Button>
        </Input>
      </FormItem>
      <FormItem label="服务状态" prop="isServe">
        <Select v-model="form.isServe" transfer placeholder="保单服务状态">
          <Option v-for="(item,index) in baseData['保单服务状态']" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="服务次数" prop="serviceType">
        <Select v-model="form.serviceType" @on-change="calcServiceCount" style="width: 50%">
          <Option :value="1">每一月一次</Option>
          <Option :value="2">每二月一次</Option>
          <Option :value="3">每季度一次</Option>
          <Option :value="6">每半年一次</Option>
        </Select>
        <Input v-model="form.serverCount" placeholder="服务次数" style="width: 50%">
          <Tooltip :max-width="280" :content="'一个月按 '+monthDays+' 天计算，保留2位小数，如365/30.42=11.9986850756≈12.00。\n'+betweenDays" slot="suffix" placement="top">
            <Icon type="md-alert"/>
          </Tooltip>
        </Input>
      </FormItem>
      <FormItem label="保单状态" prop="activeStatus">
        <Select v-model="form.activeStatus" disabled placeholder="保单状态">
          <Option v-for="(item,index) in baseData['保单生效状态']" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <!-- 通用安责险（共保体单一模式） -->
      <div v-show="form.subType===101&&form.isCoIns===1">
        <FormItem label="适用份额配置" prop="coInsurerId">
          <Select v-model="form.coInsurerId" @on-change="getCoInsurerInfo()" placeholder="适用份额配置" not-found-text="请先选择承保机构">
            <rate slot="prefix" :extType="form.extType" :member="form.member" :premiumCost="form.premiumCost" :ownerId="form.ownerId"></rate>
            <Option v-for="(item,index) in coInsurerList" :value="item.id" :key="index">{{ item.iname }}</Option>
          </Select>
        </FormItem>
        <FormItem label="引流方" prop="ownerId">
          <Select v-model="form.ownerId" placeholder="引流方" not-found-text="请先选择份额配置名称">
            <Option v-for="(subItem,index) in form.member" :value="subItem.insId" :key="index">{{ subItem.iname }}</Option>
          </Select>
        </FormItem>
      </div>
      <FormItem label="无需服务的理由" prop="reason" v-if="form.isServe===2">
        <Select v-model="form.reason" placeholder="无需服务的理由">
          <Option v-for="(item,index) in baseData['保单无服务理由']" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="备注" prop="remarks" style="width: 100%">
        <Input v-model="form.remarks" type="textarea" maxlength="128" show-word-limit style="width: 100%" :autosize="{minRows: 2,maxRows: 5}" placeholder="备注"></Input>
      </FormItem>
      <!-- 组合安责险 -->
      <div v-show="form.subType===102">
        <h1 class="h1Title">子保单配置</h1>
        <ul class="subList">
          <li v-for="(item,index) in form.subPolicies" :key="index">
            <div class="index">{{ index + 1 }}</div>
            <div class="content">
              <FormItem label="保单类型" :prop="'subPolicies.' + index + '.type'">
                <Select v-model="item.type" disabled placeholder="保单类型">
                  <Option v-for="(subItem,subIndex) in subTypeList" :value="subItem.value" :key="subIndex">{{ subItem.name }}</Option>
                </Select>
              </FormItem>
              <FormItem label="保单号" :prop="'subPolicies.' + index + '.number'" :rules="{required: true, message: '请填写保单号', trigger: 'blur'}">
                <Input v-model="item.number" @on-change="setNumber" placeholder="保单号"></Input>
              </FormItem>
              <FormItem label="保费金额(元)" :prop="'subPolicies.' + index + '.premiumCost'" :rules="{required: true, pattern: /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/, message: '请填写保费金额且不能为0', trigger: 'blur'}">
                <Tooltip :disabled="item.premiumCost * 1 < 1000000" content="保费过高请确认保费是否正确！" placement="top">
                  <InputNumber v-model="item.premiumCost" placeholder="保费金额" style="width: 310px" @on-change="premiumCostChange"></InputNumber>
                </Tooltip>
              </FormItem>
              <FormItem label="项目保额(元)" :prop="'subPolicies.' + index + '.insureAmount'" :rules="{required: true, pattern: /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/, message: '请填写项目保额且不能为0', trigger: 'blur'}">
                <InputNumber v-model="item.insureAmount" placeholder="项目保额" style="width:100%"></InputNumber>
              </FormItem>

              <FormItem label="投保单位联系人" :prop="'subPolicies.' + index + '.enterpriseContact'" style="width: 50%">
                <Input v-model="item.enterpriseContact" placeholder="联系人" style="width: 40%;"></Input>
                <Input v-model="item.enterprisePhone" placeholder="联系方式" style="width: 60%;"></Input>
              </FormItem>
              <FormItem label="保险业务经理" :prop="'subPolicies.' + index + '.companyContact'" style="width: 50%">
                <Input v-model="item.companyContact" placeholder="保险业务经理" style="width: 40%;"></Input>
                <Input v-model="item.companyPhone" placeholder="联系方式" style="width: 60%;"></Input>
              </FormItem>
              <FormItem label="适用份额配置" :prop="'subPolicies.' + index + '.coInsurerId'" :rules="{required: true,type:'number', message: '请选择适用份额配置', trigger: 'change'}">
                <Select v-model="item.coInsurerId" @on-change="getCoInsurerInfo(index)" placeholder="适用份额配置" not-found-text="请先选择承保机构">
                  <rate slot="prefix" :member="item.member" :extType="item.extType" :premiumCost="item.premiumCost" :ownerId="item.ownerId"></rate>
                  <Option v-for="(subItem,subIndex) in coInsurerList" :value="subItem.id" :key="subIndex">{{ subItem.iname }}</Option>
                </Select>
              </FormItem>
              <FormItem label="引流方" :prop="'subPolicies.' + index + '.ownerId'">
                <Select v-model="item.ownerId" placeholder="引流方" not-found-text="请先选择份额配置名称">
                  <Option v-for="(subItem,index) in item.member" :value="subItem.insId" :key="index">{{ subItem.iname }}</Option>
                </Select>
              </FormItem>
              <FormItem label="投保单号" :prop="'subPolicies.' + index + '.insureNum'" style="width: 50%">
                <Input v-model="item.insureNum" placeholder="投保单号" @on-change="setInsureNum"></Input>
              </FormItem>
              <FormItem label="投保时间" :prop="'subPolicies.' + index + '.insureAt'" style="width: 50%" :rules="{required: true, message: '请选择投保时间', trigger: 'blur'}">
                <ys-date-picker :disabled="true" v-model="item.insureAt" style="width: 100%;" placeholder="同主保单"></ys-date-picker>
              </FormItem>
            </div>
          </li>
        </ul>
      </div>
      <FormItem label="服务方案附件" prop="planFileList" style="width: 100%;text-align: center">
        <ys-upload
            @on-success="bLFile1"
            :headers="{token:token}"
            ref="upload"
            type="halfList"
            :defaultList.sync="planFileList"
            action="/web/resource/UploadFile">
        </ys-upload>
      </FormItem>
      <FormItem label="合同附件" prop="contractFileList" style="width: 100%;text-align: center">
        <ys-upload
            @on-success="bLFile2"
            :headers="{token:token}"
            ref="upload"
            type="halfList"
            :defaultList.sync="contractFileList"
            action="/web/resource/UploadFile">
        </ys-upload>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok('/policy/AddEdit')">确认并保存</Button>
      <Button @click="close">取消</Button>
    </div>
    <ys-modal v-model="projectFlag" :width="1000" title="选择项目">
      <ProComponent :limit="1" :hasAzx="0" @on-ok="getProjectData"></ProComponent>
    </ys-modal>
    <ys-modal v-model="companyFlag" :width="1000" title="承保机构">
      <OrgComponent :limit="1" :oType="1" :type="form.subType===102?6:''" @on-ok="getOrgData"></OrgComponent>
    </ys-modal>
  </div>
</template>
<script>
import edit from '@/mixins/edit'
import {policyInfo} from "@/api/policy";
import {coInsurerInfo, getCoInsurer} from "@/api/org";
import ProComponent from "_c/projects";
import OrgComponent from "_c/org";
import {math, uuid, getDaysBetween, formatDate} from "ys-admin";
import Rate from "@/views/policy/edit/rate";

export default {
  name: 'projectEdit',
  mixins: [edit],
  components: {
    Rate,
    ProComponent,
    OrgComponent
  },
  data() {
    return {
      ruleValidate: {
        number: [
          {required: true, message: '请填保单号', trigger: 'blur'}
        ],
        projectName: [
          {required: true, message: '请选择项目', trigger: 'change'}
        ],
        startAt: [
          {required: true, message: '请选择保单开始时间', trigger: 'blur'}
        ],
        endAt: [
          {
            required: true, trigger: 'blur',
            validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('请选择保单结束时间!'));
              } else if (new Date(value).getTime() < new Date(this.form.startAt).getTime()) {
                callback(new Error('保单结束时间不能小于保单开始时间!'));
              } else {
                callback();
              }
            }
          }
        ],
        premiumCost: [
          {required: true, pattern: /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/, message: '请填写保费金额且不能为0',  trigger: 'blur'}
        ],
        insureAmount: [
          {required: true, pattern: /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/, message: '请填写项目保额且不能为0', trigger: 'blur'}
        ],
        insureAt: [
          {required: true, message: '请选择投保时间', trigger: 'blur'}
        ]
      },
      planFileList: [],
      contractFileList: [],
      projectFlag: false,
      companyFlag: false,
      reasonFlag: false,
      monthDays: 30.42,
      form: {
        id: '',
        oldPolicyId: '',
        source: 1,
        subType: 101,
        iname: '',
        projectId: '',
        companyId: '',
        industryType: 1,
        serviceType: 1,
        type: 1,
        activeStatus: 20,
        status: 1, // 该字段强制写为1
        number: '',
        projectName: '',
        startAt: '',
        endAt: '',
        companyName: '',
        premiumCost: 0,
        insureAmount: 0,
        coInsurerId: '',
        ownerId: '',
        enterpriseContact: '',
        enterprisePhone: '',
        companyContact: '',
        companyPhone: '',
        isCoIns: 0,
        isServe: 1,
        "extRate": 0,
        serverCount: 5,
        remarks: '',
        reason: '',
        member: [],
        subPolicies: [],
        contractFileList: [],
        planFileList: [],
        insureAt: '', // 投保时间
        insureNum: '', // 投保单号
      },
      coInsurerId: '',
      coInsurerList: [],
    }
  },
  computed: {
    betweenDays() {
      const {startAt, endAt} = this.form
      return startAt && endAt ? formatDate(startAt) + '至' + formatDate(endAt) + '共有' + getDaysBetween(startAt, endAt) + '天！' : '';
    },
    renewalFlag() {
      return !!this.oldId;
    },
    math() {
      return math
    },
    typeList() {
      return this.form.id && !this.form.subPolicies ? this.baseData['保单类型'] : this.baseData['保单类型'].filter(item => item.value < 100);
    },
    subTypeList() {
      return this.baseData['保单类型'].filter(item => 200 > item.value && item.value > 100);
    },
  },
  async mounted() {
    if (this.mid) {
      this.modal.initLoading = true;
      await policyInfo({id: this.mid}).then((res) => {
        // 运维端若前端创建保单时，未传status字段为1时，后端会自动处理为0，在编辑时若检测到为0时强制改为1
        res.data.status = res.data.status === 0 ? 1 : res.data.status
        this.modal.initLoading = false;
        Object.keys(this.form).forEach((item) => {
          this.form[item] = res.data[item]
        });
        this.planFileList = res.data['planFileList'] || [];
        this.contractFileList = res.data['contractFileList'] || [];
        this.form.planFileList = (res.data['planFileList'] || []).map((item) => {
          return item.id
        })
        this.form.contractFileList = (res.data['contractFileList'] || []).map((item) => {
          return item.id
        })
        this.getCoInsurerList(this.form.companyId);
        if (this.oldId) {/*续保时需要初始化的数据*/
          this.form.oldPolicyId = this.form.id
          this.form.number = '';
          this.form.source = 2;
          this.form.serviceType = 1;
          this.form.premiumCost = null;
          this.form.insureAmount = null;
          this.form.id = '';
          let startAt = new Date(this.form.endAt);
          /*初始化续保后的日期，旧保单往后延一年*/
          this.form.startAt = new Date(startAt.setDate(startAt.getDate() + 1));
          this.form.endAt = '';
          if (this.form.subType === 102) {
            this.form.subPolicies.forEach(item => {
              item.premiumCost = null;
              item.number = '';
              item.insureAmount = null;
            })
          }
          // 续保时，可能服务方案附件以及合同附件已经变化故而置空
          this.planFileList = [];
          this.contractFileList = [];
          this.form.planFileList = []
          this.form.contractFileList = []
        }
      }).catch(() => {
        this.modal.initLoading = false;
      });
    } else {
      this.form.startAt = new Date();
      this.form.insureAt = new Date();
      const date = new Date();
      date.setFullYear(date.getFullYear() + 1);
      this.form.endAt = date;
    }
  },
  methods: {
    calcServiceCount() {
      const {startAt, endAt, serviceType} = this.form
      if (!startAt || !endAt || !serviceType) return;
      const month = math.divide(getDaysBetween(startAt, endAt), this.monthDays).toFixed(2);
      this.form.serverCount = Math.ceil(math.divide(month, serviceType))
    },
    bLFile1(data) {
      this.form.planFileList = data.map((item) => item.id)
    },
    bLFile2(data) {
      this.form.contractFileList = data.map((item) => item.id)
    },
    async getCoInsurerList(orgId) {
      this.coInsurerId = '';
      await getCoInsurer({orgId: orgId, policyType: this.form.type}).then((res) => {
        if (res.code === 200) {
          this.coInsurerList = res.data.list || [];
          let defaultCoInsurer = this.coInsurerList.filter(item => item.isDefault === 1);
          this.coInsurerId = defaultCoInsurer.length > 0 ? defaultCoInsurer[0].id : (this.coInsurerList.length > 0 ? this.coInsurerList[0].id : '');
          if (this.form.subType === 101 && this.form.isCoIns === 1) {
            if (!this.mid) this.form.coInsurerId = this.coInsurerId;
            this.getCoInsurerInfo();
          }
          if (this.form.subType === 102) {
            this.form.subPolicies.forEach((item, index) => {
              if (!this.mid) item.coInsurerId = this.coInsurerId;
              this.getCoInsurerInfo(index);
            })
          }
        }
      })
    },
    subTypeChange() {
      this.form.subPolicies = [];
      if (this.form.subType === 102) {
        this.subTypeList.forEach((item, index) => {
          this.form.subPolicies.push({
            "type": item.value,
            "id": '',
            "number": "",
            "startAt": this.form.startAt,
            "endAt": this.form.endAt,
            "premiumCost": null,
            "insureAmount": null,
            "enterpriseContact": "",
            "enterprisePhone": "",
            "companyContact": "",
            "member": [],
            "companyPhone": "",
            "coInsurerId": this.coInsurerId,
            "extRate": 0,
            "extType": 1,
            "ownerId": '',
            "insureAt": this.form.insureAt || '', // 投保时间
            "insureNum": '', // 投保单号
          });
          this.$set(this.form.subPolicies[index], 'member', []);
          if (this.coInsurerId) this.getCoInsurerInfo(index);
          this.$forceUpdate();
        })
      }
    },
    setNumber() {
      this.form.number = this.form.subPolicies.map(item => item.number).join('');
    },
    setInsureNum() {
      this.form.insureNum = this.form.subPolicies.map(item => item.insureNum).join('');
    },
    async getCoInsurerInfo(index) {
      let id = index !== undefined ? this.form.subPolicies[index].coInsurerId : this.form.coInsurerId
      if (!id) return;
      await coInsurerInfo({id: id, time: uuid()}).then((res) => {
        if (res.code === 200) {
          if (index === undefined) {
            this.form.member = res.data.member || [];
            this.form.extRate = res.data.extRate || 0;
            this.form.extType = res.data.type || 1;
          } else {
            this.$set(this.form.subPolicies[index], 'member', res.data.member || []);
            this.form.subPolicies[index].extRate = res.data.extRate || 0;
            this.form.subPolicies[index].extType = res.data.type || 1;
          }
        }
      })
    },
    getProjectData(data) {
      this.form.projectId = data[0].id // 项目ID或施工许可编号
      this.form.projectName = data[0].iname
      this.form.enterpriseContact = data[0].principalName
      this.form.enterprisePhone = data[0].principalPhone
      this.form.startAt = data[0].startPlan // 保单开始时间
      this.form.endAt = data[0].endPlan // 保单结束时间
      this.calcServiceCount();

    },
    getOrgData(data) {
      this.form.companyName = data[0].iname
      this.form.companyId = data[0].id // 保险公司ID
      this.form.isCoIns = data[0].type === 6 ? 1 : 0
      this.form.companyContact = data[0].contacts
      this.form.companyPhone = data[0].phone
      if (this.form.isCoIns === 1) {
        this.subTypeChange();
        this.getCoInsurerList(this.form.companyId);
      }
    },
    premiumCostChange(event) {
      if(event * 1 >= 1000000) {
        this.$Message.destroy()
        this.$Message.warning({
          background: true,
          content: '保费过高请确认保费是否正确！',
          duration: 2
        });
      }
    },
    insureAtChange(date) {
      // 通用安责险/（共保体单一模式）没有子保单
      if(this.form.subType===101) return
      this.form.subPolicies.forEach(item => item.insureAt = date)
    }
  },
  watch: {}
}
</script>

<style lang="less" scoped>
</style>
