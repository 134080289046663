<template>
  <div>
    <ys-page-list
        ref="table"
        :params="params"
        action="/sms/orgConfig/List"
        :tableHeight="tableHeight"
        @on-selection-change="checkItem"
        :showContextMenu="false"
        :multiColumn="false"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="关键词" clearable style="width: 150px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template slot="btn">
<!--        <ButtonGroup>-->
<!--          <Button type="primary" @click="add">新增服务项目</Button>-->
<!--        </ButtonGroup>-->
      </template>
      <!-- <template slot="detail">
        <Detail :mid="detailID"></Detail>
      </template> -->
    </ys-page-list>
    <!-- <ys-modal v-model="modShow" :width="500" title="新增服务项目">
      <EditProject @on-ok="getList" :parentID="mid"></EditProject>
    </ys-modal> -->
  </div>
</template>

<script>
import pageListMixins from '@/mixins/pageList';
// import {orgLinkProject, orgUnLinkProject} from "@/api/org";

export default {
  name: 'corp',
  mixins: [pageListMixins],
  components: {
    // 'EditProject': () => import('@/views/org/list/detail/editProject.vue'),
    // 'Detail': () => import('@/views/projects/list/detail.vue')
  },

  data() {
    return {
      params: {
        orgId: '',
      },
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 50, align: 'center'},
        {minWidth: 230, title: '项目名称', tooltip: true, key: 'iname', align: 'left', renderConfig: {type: 'gotoDetail'}},
        // {width: 180, title: '施工许可证', key: 'licenseNum', align: 'left'},
        // {width: 90, title: '参建类型', key: 'orgProjectType', align: 'left', renderConfig: {type: 'baseData', parentName: '参建单位类型'}},
        // {width: 220, title: '联系人', key: 'principalName', align: 'left'},
        // {width: 250, title: '联系电话', tooltip: true, key: 'principalPhone', align: 'left'},
        // {width: 220, title: '承保机构', key: 'policyOrgName', align: 'left'},
      ]
    }
  },
  mounted() {
    this.params.orgId = this.mid;
    this.getList()
  },
  methods: {
    // unlink() {
    //   this.$Modal.confirm({
    //     title: '提示',
    //     content: '确认要解绑该项目么？',
    //     okText: '确认',
    //     cancelText: '取消',
    //     onOk: () => {
    //       orgUnLinkProject({id: this.tableSelectItem[0].id, org: this.mid}).then(res => {
    //         if (res.code === 200) {
    //           this.$Notice.success({
    //             title: '提示',
    //             desc: '操作成功！'
    //           });
    //           this.getList();
    //         }
    //       })
    //     }
    //   })
    // },
    // getData(data) {
    //   orgLinkProject({id: this.mid, projectId: data[0].id}).then(res => {
    //     if (res.code === 200) {
    //       this.$Notice.success({title: '提示', desc: '绑定成功!'});
    //       this.getList();
    //     }
    //   })
    // },
  }
}
</script>
