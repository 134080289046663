<template>
  <Tabs v-model="tabs">
    <TabPane label="风险明细" name="1">
      <detail-list :midData="midData" :standardInfo="standardInfo"></detail-list>
    </TabPane>
    <TabPane label="风险依据" name="2">
      <read-list :midData="midData"></read-list>
    </TabPane>
  </Tabs>
</template>

<script>
import DetailList from "@/views/base/standard/detail-list";
import ReadList from "@/views/base/standard/reson-list";

export default {
  name: "tabs",
  props: {
    midData: {type: Array, default: () => []},
    standardInfo: {type: Array, default: () => []},
  },
  components: {ReadList, DetailList},
  data() {
    return {
      tabs: '1'
    }
  },
}
</script>

<style scoped>

</style>