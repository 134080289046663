<template>
  <div>
    <Form ref="form" class="editForm twoPartsEditForm" :model="form" :rules="ruleValidate" inline :label-width="90" :style="{maxHeight:'calc(100vh - 200px)'}" style="overflow-y: auto">
      <div v-for="(item,index) in form.data" :key="index">
        <FormItem label="管辖区域" :prop="'data.'+index+'.area'" style="width: 93%" :rules="{required: true, message: '请选择管辖区域', trigger: 'change'}">
          <ys-region changeOnSelect v-model="item.area" @on-change="getArea($event,index)" placeholder="管辖区域"/>
        </FormItem>
        <Button @click="del(index)" :disabled="index===0" style="margin-left: 1%;margin-top: 1px;" icon="ios-close-circle"></Button>

      </div>
      <Button type="primary" long @click="add" style="margin-bottom: 10px;">新增区域</Button>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok(form.id?'org/area/Edit':'org/area/Add')">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>
<script>
import edit from '@/mixins/edit'

export default {
  name: 'orgEdit',
  mixins: [edit],
  props: {
    orgName: {type: String, default: ''}
  },
  data() {
    return {
      form: {
        orgId: '',
        data: [{
          area: '',
          provinceCode: '',
          cityCode: '',
          countyCode: '',
        }],
      },
    }
  },
  mounted() {
    this.form.orgId = this.parentID;
  },
  methods: {
    del(index) {
      this.form.data.splice(index, 1);
    },
    add() {
      this.form.data.push({
        area: '',
        provinceCode: '',
        cityCode: '',
        countyCode: '',
      })
    },
    getArea(data, index) {
      this.form.data[index].provinceCode = data.length > 0 ? data[0].code : '';
      this.form.data[index].cityCode = data.length > 1 ? data[1].code : '';
      this.form.data[index].countyCode = data.length > 2 ? data[2].code : '';
    }
  }
}
</script>

<style lang="less" scoped>
</style>
