<template>
  <div>
    <div v-if="!form.id">
      <Alert type="warning" v-if="form.virtual===0">无实体机构(如共保体)时，请点击此处切换到<a href="javascript:" @click="addVirtual(1)">虚拟机构</a>！</Alert>
      <Alert type="warning" v-else>请点击此处切换到<a href="javascript:" @click="addVirtual(0)">普通机构</a>！</Alert>
    </div>
    <Form ref="form" class="editForm twoPartsEditForm" :model="form" :rules="ruleValidate" inline :label-width="120" :style="{maxHeight:'calc(100vh - 200px)'}" style="overflow-y: auto">
      <FormItem :label-width="0" label="" prop="logo" style="width: 100%;margin: 0 auto;">
        <div style="margin: 0 auto;width: 0;padding: 10px 0;">
          <ysUpload
              @on-success="bLFile(arguments, 'logo')"
              :limit="1"
              :headers="{token:token}"
              ref="upload"
              :format="['jpg','jpeg','png','gif']"
              :defaultList.sync="defaultList"
              action="/web/resource/UploadFile"
          ></ysUpload>
        </div>
      </FormItem>
      <FormItem label="机构名称" prop="iname" style="width: 100%;" class="ys-business">
        <ys-business :source="source" @on-clear="clearBusiness" :disabled="!!form.id" @on-select="selectOrg" v-if="!form.id&&!form.virtual"></ys-business>
        <Input v-model="form.iname" placeholder="机构名称" v-else>
          <Tooltip content="非虚拟机构请不要随意更改机构名称" slot="append">
            <Icon type="ios-alert"/>
          </Tooltip>
        </Input>
      </FormItem>
      <FormItem label="信用代码" prop="societyCode">
        <Input v-model="form.societyCode" placeholder="社会信用代码">
          <Tooltip content="非虚拟机构请不要随意更改社会信用代码" max-width="600" slot="append">
            <Icon type="ios-alert"/>
          </Tooltip>
        </Input>
      </FormItem>
      <FormItem label="八大行业" prop="industryTypeList">
        <Cascader :data="industryTypeData" v-model="form.industryTypeList" clearable change-on-select @on-change="industryTypeChange" placeholder="八大行业"></Cascader>
      </FormItem>
      <FormItem label="国民经济编码" prop="economyIdList" style="width: 100%;">
        <Cascader :data="economyData" v-model="form.economyIdList" clearable change-on-select placeholder="国民经济分类编码"></Cascader>
      </FormItem>
      <FormItem label="法人" prop="operName" v-if="!form.virtual">
        <Input v-model="form.operName" disabled placeholder="法人"></Input>
      </FormItem>
      <FormItem label="成立日期" prop="startDate" v-if="!form.virtual">
        <Input v-model="form.startDate" disabled placeholder="成立日期"></Input>
      </FormItem>
      <FormItem label="企业状态" prop="regStatus">
        <Select v-model="form.regStatus">
          <Option v-for="(item,index) in baseData['企业状态']" :value="item.value" :key="index">{{ item.value }}</Option>
        </Select>
      </FormItem>
      <FormItem label="安全经营许可证" prop="licenseNo">
        <Input v-model="form.licenseNo" placeholder="安全经营许可证"></Input>
      </FormItem>
      <FormItem label="许可证有效期" prop="daterange">
        <ys-date-picker type="daterange" style="width: 100%" v-model="form.daterange" placeholder="许可证有效期"/>
      </FormItem>
      <FormItem label="年营业额" prop="businessVolume">
        <InputNumber :min="0" style="width: 100%" v-model="form.businessVolume" placeholder="年营业额"/>
      </FormItem>
      <FormItem label="上级机构" prop="parentId">
        <Input v-model="form.parentName" :disabled="oType!==null" @on-clear="clearParent" clearable placeholder="机构名称">
          <Button slot="append" @click="orgFlag=true">选择</Button>
        </Input>
      </FormItem>
      <FormItem label="机构类型" prop="oType" style="width: 30%">
        <Select v-model="form.oType" :disabled="oType!==null" placeholder="组织类型" @on-change="oTypeSelect" transfer>
          <Option v-for="(item,index) in baseData['组织类型']" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="" :label-width="0" prop="type" style="width: 20%">
        <Select v-model="form.type" transfer placeholder="机构类型">
          <Option v-for="(item,index) in orgBaseData" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="英文缩写" prop="code" style="width: 28%">
        <Input v-model="form.code" placeholder="英文缩写">
          <Select v-model="form.code" slot="append" style="width: 75px" v-if="form.oType===1">
            <Option v-for="(item,index) in baseData['保险公司编号']" :value="item.value" :key="index">{{ item.value }}</Option>
          </Select>
        </Input>
      </FormItem>
      <FormItem label="简称" prop="shortName" :label-width="50" style="width: 22%">
        <Input v-model="form.shortName" placeholder="简称"></Input>
      </FormItem>
      <FormItem label="负责人" prop="contacts" style="width: 22%">
        <Input v-model="form.contacts" placeholder="负责人"></Input>
      </FormItem>
      <FormItem label="" :label-width="0" prop="phone" style="width: 28%">
        <Tooltip transfer content="该号码较为重要，会用于接收短信通知，请正确填写！" max-width="240">
          <Input v-model="form.phone" autocomplete="off" placeholder="负责人电话" style="width: 240px;"></Input>
        </Tooltip>
      </FormItem>
      <FormItem label="标化等级" prop="standardLevel">
        <Select v-model="form.standardLevel"  placeholder="安全生产标化等级" transfer>
          <Option v-for="(item,index) in baseData['安全生产标准化等级']" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="安全员" prop="contactHeader" style="width: 22%">
        <Input v-model="form.contactHeader" placeholder="联系人"></Input>
      </FormItem>
      <FormItem label="" :label-width="0" prop="contactHeaderMobile" style="width: 28%">
        <Input v-model="form.contactHeaderMobile" autocomplete="off" placeholder="电话" style="width: 240px;"></Input>
      </FormItem>
      <FormItem label="应急值班电话" prop="emergencyMobile">
        <Input v-model="form.emergencyMobile" placeholder="应急值班电话"></Input>
      </FormItem>
      <FormItem label="从业人数" prop="orgScale">
        <InputNumber :min="0" style="width: 100%" v-model="form.orgScale" placeholder="从业人数"/>
      </FormItem>
      <!-- 保险公司和服务机构特有 -->
      <!-- <FormItem label="技术人员信息" prop="staff" style="width: 100%" v-if="form.oType === 1 || form.oType === 3">
        <Input v-model="form.staff" type="textarea" maxlength="128" show-word-limit :autosize="{minRows: 3,maxRows: 10}" placeholder="技术人员信息"></Input>
      </FormItem> -->
      <!-- 服务机构特有 -->
      <FormItem label="行业领域编码" prop="businessScope" style="width: 100%" v-if="form.oType === 3">
        <Input v-model="form.businessScope" placeholder="八大行业 行业领域编码"></Input>
      </FormItem>
      <div>
        <FormItem label="办公地址" prop="area" style="width: 40%">
          <ys-region changeOnSelect v-model="form.area" @on-change="getArea" placeholder="所在区域"/>
        </FormItem>
        <FormItem label="" :label-width="0" prop="address" style="width: 60%">
          <Input v-model="form.address" placeholder="详细地址"></Input>
        </FormItem>
      </div>
      <FormItem label="经纬度" prop="lnglat" style="width: 80%">
        <Input v-model="form.lnglat" placeholder="经纬度,请使用英文逗号隔开"></Input>
      </FormItem>
      <FormItem :labelWidth="0" style="width: 20%">
        <Button type="primary" target="_blank" to="https://lbs.qq.com/getPoint/" long>手动选择经纬度</Button>
      </FormItem>
      <FormItem label="证件图片" prop="certFileList" style="width: 100%;">
        <ysUpload @on-success="bLFile(arguments, 'certFileList')" :format="['jpg','jpeg','png','gif']" :headers="{token:token}" :defaultList.sync="certFileList" action="/web/resource/UploadFile"></ysUpload>
      </FormItem>
      <FormItem label="安全生产许可证" prop="safeLicenseFileList" style="width: 100%;">
        <ysUpload @on-success="bLFile(arguments, 'safeLicenseFileList')" :format="['jpg','jpeg','png','gif']" :headers="{token:token}" :defaultList.sync="safeLicenseFileList" action="/web/resource/UploadFile"></ysUpload>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="save">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
    <ys-modal v-model="orgFlag" :width="1200" title="选择上级机构">
      <org-component :limit="1" :oType="form.oType" :typesListValue="form.oType === 1 ? (form.type === 6 ? [6] : [1,2,3,4,5]) : [] " @on-ok="getData"></org-component>
    </ys-modal>
  </div>
</template>
<script>
import edit from '@/mixins/edit'
import {orgInfo, getOrgListByCode} from "@/api/org";
import OrgComponent from "_c/org";
import {generateSocialCreditCode, getRecorderAryByDeep} from "ys-admin";

export default {
  name: 'projectEdit',
  mixins: [edit],
  props: {
    source: {type: [Number], default: 2},
    type: {type: Number, default: null},
    oType: {type: Number, default: null},
  },
  components: {OrgComponent},
  data() {
    return {
      ruleValidate: {
        iname: [
          {required: true, message: '请填公司名称', trigger: 'blur,change'},
        ],
        societyCode: [
          {required: true, pattern: this.$reg.unifiedSocialCreditReg.reg, message: this.$reg.unifiedSocialCreditReg.tips, trigger: 'blur,change'},
        ],
        type: [
          {required: true, type: 'number', message: '请选择类型', trigger: 'change'}
        ],
        phone: [
          {required: true, pattern: this.$reg.phoneOrTelReg.reg, message: this.$reg.phoneOrTelReg.tips, trigger: 'blur'}
        ],
        industryTypeList: [
          {required: true, type: 'array', message: '请选择八大行业', trigger: 'change'}
        ],
        economyIdList: [
          {required: true, type: 'array', message: '请选择国民经济分类编码', trigger: 'change'}
        ],
      },
      orgFlag: false,
      detail: {},
      defaultList: [],
      economyData: [],
      industryTypeData: [],
      certFileList: [], // 证件扫瞄件数组
      safeLicenseFileList: [], // 安全生产许可证数组
      form: {
        id: '',
        iname: '',
        societyCode: '',
        status: 1,
        oType: 1,
        type: 1,
        parentId: '',
        parentName: '',
        uuid: '',
        shortName: '',
        operName: '',
        startDate: '',
        regStatus: '',
        logo: '',
        logoTitle: '',
        contacts: '',
        phone: '',
        code: '',
        provinceCode: '',
        cityCode: '',
        countyCode: '',
        address: '',
        area: '',
        virtual: 0,
        economyIdList: [], // 国民经济分类编码id已选级联List
        economyId: null, // 国民经济分类编码id
        industryTypeList: [], // 八大行业已选级联List
        industryType: null, // 八大行业一级
        subIndustryType: '', // 八大行业二级
        licenseNo: '', // 安全生产经营许可证
        daterange: [], // 许可证有效期区间
        licenseInvalidDate: '', // 许可证有效期开始时间
        licenseInvalidEndDate: '', // 许可证有效期结束时间
        standardLevel: '', // 安全生产标准化等级
        businessVolume: null, // 年营业额
        contactHeader: '', // 安全员
        contactHeaderMobile: '', // 安全员电话
        emergencyMobile: '', // 应急值班电话
        orgScale: null, // 从业人数
        staff: '', // 技术人员信息
        businessScope: '', // 八大行业领域编码,服务机构特有
        certFileList: [], // 证件扫瞄件id数组
        safeLicenseFileList: [], // 安全生产许可证id数组
        lnglat: '',
        lng: null,
        lat: null,
      },
    }
  },
  computed: {
    orgBaseData() {
      let baseData = [];
      switch (this.form.oType) {
        case 1:
          baseData = this.baseData['保险公司类型'];
          break;
        case 2:
          baseData = this.baseData['职能部门类型'];
          break;
        case 3:
          baseData = this.baseData['服务机构类型'];
          break;
        case 4:
          baseData = this.baseData['参建单位类型'];
          break;
        case 5:
          baseData = this.baseData['运营中心类型'];
          break;
      }
      return baseData;
    }
  },
  created() {
    if (this.type) this.form.type = this.type;
    if (this.oType) {
      this.form.oType = this.oType;
      if (!this.type) this.oTypeSelect();
    }
  },
  async mounted() {
    if (this.mid) { // 编辑
      await this.getInfo();
    } else {
      if (this.midData.length === 1) {
        this.form.parentId = this.midData[0].id;
        this.form.parentName = this.midData[0].iname;
      }
    }
    // 八大行业
    this.industryTypeData = this.baseData['行业类型']
    this.getEconomyList()
    if(this.form.oType === 1 && this.form.economyIdList.length === 0) {
      this.form.economyIdList = [19, 114]
    }
  },
  methods: {
    async getInfo() {
      await orgInfo({id: this.mid}).then((res) => {
        if (res.code === 200) {
          this.detail = res.data;
          this.initData()
        }
      })
    },
    initData() {
      Object.keys(this.form).forEach((item) => {
        this.form[item] = this.detail[item]
      })
      this.form.area = this.detail.countyCode || this.detail.cityCode || this.detail.provinceCode;
      if (this.form.logo) this.defaultList.push({
        id: this.form.logo,
        fileName: '',
        createdAt: '',
      })
      this.certFileList = this.form.certFileList || []
      this.safeLicenseFileList = this.form.safeLicenseFileList || []
      this.form.industryTypeList = [this.form.industryType, this.form.subIndustryType]
      this.form.daterange = [this.form.licenseInvalidDate, this.form.licenseInvalidEndDate]
      this.form.lnglat = this.form.lng && this.form.lat ?  this.form.lng + ',' + this.form.lat : ''
    },
    // 国民经济分类编码id
    getEconomyList() {
      this.$get('/economic/ListTree',{}).then(res => {
        this.economyData = this.restructure(res?.data || [])
        // 若已有已有分类id, 寻找当前分类id所在的路径
        if(this.form.economyId) {
          this.form.economyIdList = getRecorderAryByDeep({targetData: this.economyData, targetValue: this.form.economyId})
        }
      })
    },
    // 重构国民经济分类列表数据
    restructure(list) {
      return list.map(item => {
        item.value = item.id
        item.label = item.name
        item.children = item.children || []
        if(item.children && item.children.length > 0) {
          this.restructure(item.children)
        }
        return item
      })
    },
    // // 国民经济分类编码级联选择change改变
    // economyListChange(item) {
    //   this.form.economyId = item[item.length - 1] || ''
    // },
    // 八大行业级联选择change改变
    industryTypeChange(item) {
      this.form.industryType = item[0] || null
      this.form.subIndustryType = item[1] || ''
    },
    generate() {
      this.form.societyCode = generateSocialCreditCode()
    },
    getData(data) {
      if (data[0].id === this.mid) {
        this.$Notice.error({title: '提示', desc: '所选机构为当前机构，请检查！'});
        return false;
      }
      this.form.parentName = data[0].iname;
      this.form.parentId = data[0].id;
    },
    oTypeSelect() {
      this.form.type = this.orgBaseData.length > 0 ? this.orgBaseData[0].value : '';
      if(this.form.oType === 1) {
        this.form.economyIdList = [19, 114]
      } else {
        this.form.economyIdList = []
      }
    },
    clearParent() {
      this.form.parentId = "";
      this.form.parentName = "";
    },
    addVirtual(type) {
      this.clearBusiness();
      if (this.mid) this.initData()
      this.form.societyCode = this.form.societyCode || generateSocialCreditCode();
      this.form.virtual = type;
      if (!this.mid && this.midData.length === 1) { // 编辑
        this.form.parentId = this.midData[0].id;
        this.form.parentName = this.midData[0].iname;
      }
      if(this.form.oType === 1 && this.form.economyIdList.length === 0) {
        this.form.economyIdList = [19, 114]
      }
    },
    // 初始话数据
    clearBusiness() {
      this.$refs['form'].resetFields();
      this.form.id = ''
      this.form.iname = ''
      this.form.societyCode = ''
      if (this.type) this.form.type = this.type;
      if (this.oType) {
        this.form.oType = this.oType;
        if (!this.type) this.oTypeSelect();
      }
      this.form.parentId = ''
      this.form.parentName = ''
      this.form.uuid = ''
      this.form.shortName = ''
      this.form.operName = ''
      this.form.startDate = ''
      this.form.regStatus = ''
      this.form.logo = ''
      this.form.logoTitle = ''
      this.form.contacts = ''
      this.form.phone = ''
      this.form.code = ''
      this.form.provinceCode = ''
      this.form.cityCode = ''
      this.form.countyCode = ''
      this.form.address = ''
      this.form.area = ''
      this.form.virtual = 0
      this.defaultList = []

      this.form.economyIdList = []
      this.form.economyId = null
      this.form.industryTypeList = []
      this.form.industryType = null
      this.form.subIndustryType = ''
      this.form.licenseNo = ''
      this.form.daterange = []
      this.form.licenseInvalidDate = ''
      this.form.licenseInvalidEndDate = ''
      this.form.standardLevel = ''
      this.form.businessVolume = null
      this.form.contactHeader = ''
      this.form.contactHeaderMobile = ''
      this.form.emergencyMobile = ''
      this.form.orgScale = null
      this.form.staff = ''
      this.form.businessScope = ''
      this.form.certFileList = []
      this.form.safeLicenseFileList = []
      this.form.lnglat = ''
      this.form.lng = null
      this.form.lat = null
      this.certFileList = []
      this.safeLicenseFileList = []
    },
    selectOrg(data) {
      Object.keys(this.form).forEach((item) => {
        switch (item) {
          case 'oType':
          case 'type':
            break;
          default:
            this.form[item] = data[item] || this.form[item];
            break;
        }
      });
      this.form.area = data.countyCode || data.cityCode || data.provinceCode || '';
      if(this.form.oType === 1 && this.form.economyIdList.length === 0) {
        this.form.economyIdList = [19, 114]
      }
      if (this.form.logo) this.defaultList.push({
        id: this.form.logo,
        fileName: '',
        createdAt: '',
      })
      if (!data.societyCode) {
        this.$Notice.warning({
          title: "提示！",
          desc: '该机构处于非正常经营状态，已无社会统一代码，系统将自动生成此代码！'
        });
        this.form.societyCode = generateSocialCreditCode();
      }
    },
    bLFile(data, key) {
      if(key === 'logo') {
        this.form.logo = data.length >= 1 && data[0].length >= 1 ? `${data[0][0].id}` : '';
        return
      }
      this[key] = data.length >= 1 && data[0].length >= 1 ? data[0] : []
    },
    getArea(data) {
      this.form.provinceCode = data.length > 0 ? data[0].code : '';
      this.form.cityCode = data.length > 1 ? data[1].code : '';
      this.form.countyCode = data.length > 2 ? data[2].code : '';
    },
    save() {
      this.form.certFileList = this.certFileList.map(item => item.id)
      this.form.safeLicenseFileList = this.safeLicenseFileList.map(item => item.id)
      this.form.economyId = this.form.economyIdList[this.form.economyIdList.length - 1] || null
      this.form.licenseInvalidDate = this.form.daterange.length > 0 ? this.form.daterange[0] : ''
      this.form.licenseInvalidEndDate = this.form.daterange.length > 0 ? this.form.daterange[1] : ''
      this.form.lng = this.form.lnglat && this.form.lnglat.split(',').length === 2 ? Number(this.form.lnglat.split(',')[0]) : null
      this.form.lat = this.form.lnglat && this.form.lnglat.split(',').length === 2 ? Number(this.form.lnglat.split(',')[1]) : null
      if (this.form.id) {
        this.ok('/organization/AddOrEdit');
      } else {
        getOrgListByCode({code: this.form.societyCode}).then(res => {
          if (res.code === 200) {
            if ((res.data.list || []).length === 0) {
              this.ok('/organization/AddOrEdit');
            } else {
              this.$Notice.error({title: '提示', desc: '该社会信用代码对应的机构已经存在，无法操作！'});
            }
          }
        })
      }
    },
  }
}
</script>

<style lang="less" scoped>

</style>
