<template>
  <div>
  <template v-for="(file) in fileList">
    <ys-file-list :key="file.id" :fileList="[file]" :width="70" :height="70" :gutter="1"></ys-file-list>
  </template>
  </div>
</template>
<script>
import edit from '@/mixins/edit.js'
import {getFile} from "@/api/common.js";

export default {
  name: '',
  mixins: [edit],
  data() {
    return {
      fileList: [],
    }
  },
  created() {
  },
  async mounted() {
    this.modal.initLoading = true;
    if (this.mid) {
      await getFile({
        resourceType: 14,
        type: '',
        resourceId: this.mid
      }).then(res => {
        this.fileList = (res.data.list || []).map(item=>{
          let fileName = item.fileName.split('.')
          if (fileName.length > 1) {
            item.fileName = item.fileName.substring(0, item.fileName.length - fileName[fileName.length - 1].length - 1);
            item.suffix = item.suffix || fileName[fileName.length - 1];
          }
          return item
        })
      })
    }
    this.modal.initLoading = false;
  },
  computed: {},
  methods: {}
}
</script>
<style lang="less" scoped>


</style>
