<template>
  <div class="home">
    <ys-page-list
        ref="table"
        :action="'/user/List'"
        keyID="46676E19596B11EC9C5C00163E15C775"
        :params="params"
        :tableHeight="tableHeight"
        :multiBtn="multiBtn"
        showContextMenu
        @on-selection-change="checkItem"
        :headerColumns="headerColumns">
      <template slot="search">
<!--        <FormItem label="所在地区" prop="tag">-->
<!--          <ysRegion v-model="params.region" @on-change="getRegion"></ysRegion>-->
<!--        </FormItem>-->
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="关键词" clearable style="width: 150px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template slot="btn">
        <ButtonGroup>
          <Button type="primary" @click="add">新增成员</Button>
        </ButtonGroup>
      </template>
      <template slot="detail">
        <Detail :tabName="tabName" :mid="detailID"></Detail>
      </template>
    </ys-page-list>
    <ys-modal v-model="modShow" :width="800" title="新增/修改成员">
      <Edit :mid="detailID" :mid-data="tableSelectItem" @on-ok="getList"></Edit>
    </ys-modal>
    <ys-modal v-model="enterFlag" title="选择平台">
      <enter :mid-data="tableSelectItem"></enter>
    </ys-modal>
    <ys-modal v-model="modFlag" title="修改密码">
      <modPassword :mid="detailID"></modPassword>
    </ys-modal>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'
import enter from "@/views/org/member/enter";
import Edit from "@/views/org/member/edit";
import Detail from "@/views/org/member/detail.vue";
import modPassword from '_c/header/ModPwd.vue'

export default {
  name: 'projects',
  mixins: [pageList],
  components: {enter, Detail, modPassword, Edit},
  data() {
    return {
      params: {
        countyCode: '',
        isAdmin: 0,
        cityCode: '',
        provinceCode: '',
        orgId: '',
        keyword: '',
      },
      enterFlag: false,
      modFlag: false,
      IDs: [],
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 80, align: 'center'},
        {width: 130, title: '姓名', key: 'iname', align: 'left', renderConfig: {type: 'gotoDetail'}},
        {minWidth: 150, title: '所在机构', key: 'orgName', align: 'left'},
        {width: 130, title: '联系电话', key: 'phone', align: 'left'},
        {width: 180, title: '身份证号码', key: 'identityCode', align: 'left'},
        {width: 200, title: '邮箱', key: 'mail', align: 'left'},
        {width: 180, title: '登录账号', key: 'username', align: 'left'},
        {width: 180, title: '创建时间', key: 'createdAt', align: 'left'},
        {width: 180, title: '最近修改时间', key: 'updatedAt', align: 'left'},
      ]
    }
  },
  computed: {
    multiBtn() {
      return [
        {
          children: [
            {
              type: 'detail',
              title: '查看详情',
              targetUrl: '/main/org/member/detail/' + (this.tableSelectItem.length === 1 ? this.tableSelectItem[0].id : null),
              icon: 'md-eye',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.modShow = true,
              title: '编辑',
              icon: 'md-create',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.delItem('/user/Remove', {ids: this.tableSelectItem.map(item => item.id)}),
              iconColor: '#ed4014',
              title: '删除',
              icon: 'md-trash',
              disabled: this.tableSelectItem.length !== 1
            },
          ]
        },
        {
          children: [
            {
              click: () => this.modFlag = true,
              title: '修改密码',
              icon: 'ios-lock',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.enterFlag = true,
              title: '进入账户',
              icon: 'ios-contact',
              disabled: this.tableSelectItem.length !== 1
            },
          ]
        }
      ]
    },
  },
  mounted() {
    this.params.orgId = this.mid;
    this.getList();
  },
  methods: {
    getRegion(data) {
      this.params.countyCode = data[2] ? data[2].code : '';
      this.params.cityCode = data[1] ? data[1].code : '';
      this.params.provinceCode = data[0] ? data[0].code : '';
    }
  }
}
</script>
