<template>
  <div>
    <Tabs v-model="tab" name="detail" v-if="mid">
      <Tab-pane label="权限列表" name="1">
        <power :mid="mid" :tableHeight="pageListHeight+30" :oType="oType" v-if="tab==='1'"></power>
      </Tab-pane>
      <Tab-pane label="菜单详情" name="2">
        <baseInfo :mid="mid" v-if="tab==='2'"></baseInfo>
      </Tab-pane>
    </Tabs>
    <ys-empty title="请先从左侧选择一项菜单！" v-else></ys-empty>
  </div>
</template>

<script>
import detailMixins from '@/mixins/detail'
import BaseInfo from "@/views/base/menu/detail/baseInfo";
import power from "@/views/base/menu/detail/power/index.vue";

export default {
  name: "detail",
  components: {BaseInfo, power},
  mixins: [detailMixins],
  props: {
    oType: {type: Number, default: 2},
  },
  data() {
    return {}
  },

  methods: {}
}
</script>

<style scoped>

</style>