<template>
  <div>
    <Form ref="form" class="editForm" :model="form" :label-width="80">
      <FormItem label="平台类型" prop="platform" :label-width="90" v-if="type">
        <Select v-model="form.platform" @on-change="getUrl">
          <Option v-for="(item,index) in platformList" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="平台类型" prop="oType" :label-width="90" v-else>
        <Select v-model="form.oType" @on-change="getUrl">
          <Option v-for="(item,index) in platformList" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" target="_blank" :disabled="!url" :to="url">进入账户</Button>
      <Button @click="close">取消</Button>
    </div>

  </div>
</template>
<script>
import edit from '@/mixins/edit'
import {get} from 'ys-admin'

export default {
  name: 'projectEdit',
  mixins: [edit],
  props: {type: {type: String, default: ''}},
  data() {
    return {
      form: {
        username: '',
        platform: '',
        oType: '',
      },
      url: '',
      orgOTypeList: [],
    }
  },
  computed: {
    platformList() {
      let data = [];
      if (this.type !== 'expert') {
        this.baseData['组织类型'].forEach(item => {
          if (this.orgOTypeList.indexOf(item.value) >= 0) data.push(item);
        })
      } else {
        data = this.baseData['平台类型'].filter(item => item.value === 5)
      }
      console.log(data)
      return data
    }
  },
  async mounted() {
    if (this.midData.length === 1) {
      this.form.username = this.midData[0].username;
      if (this.type !== 'expert') await this.getOrgList();
      const key = this.type !== 'expert' ? 'oType' : 'platform';
      this.form[key] = this.platformList.length > 0 ? this.platformList[0].value : '';
      if (this.form[key]) this.getUrl();
    }
  },
  methods: {
    async getOrgList() {
      await get('/org/ListByUser', {userId: this.midData[0].id}).then(res => {
        if (res.code === 200) {
          this.orgOTypeList = (res.data.list || []).map(item=>item.oType);
        }
      });
    },
    getUrl() {
      get('/auth/RedirectPlatform', this.form).then(res => {
        if (res.code === 200) {
          this.url = res.data;
        }
      });
    },
  }
}
</script>

<style lang="less" scoped>
</style>
