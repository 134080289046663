<template>
  <div>
    <ys-page-list
        ref="table"
        :multiBtn="multiBtn"
        showContextMenu
        :params="params"
        action="/checkUser/ListByHat"
        :tableHeight="tableHeight - 50"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="关键词" clearable style="width: 150px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template slot="detail">
        <Detail :mid="detailID"></Detail>
      </template>
    </ys-page-list>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'
import Detail from "@/views/base/smartDevice/detail/usageRecord/detail";

export default {
  name: '',
  mixins: [pageList],
  components: {
    Detail
  },
  data() {
    return {
      params: {
        hatSeqNo: '',
        type: '',
        keyword: '',
      },
      headerColumns: [
        {type: 'index', title: '序号', width: 50, align: 'center'},
        {minWidth: 100, title: '使用专家', key: 'iname', align: 'center', renderConfig: {type: 'gotoDetail'}},
        {width: 150, title: '联系电话', key: 'phone', align: 'center'},
        {minWidth: 100, title: '签到经纬度', key: 'signLat', align: 'center', render: (h, params) => {
          const latlng = params.row.signLat + ',' + params.row.signLng
          return h('span', latlng)
        }},
        {width: 180, title: '签到时间', key: 'signTime', align: 'center'},
        {minWidth: 100, title: '签出经纬度', key: 'signOutLat', align: 'center', render: (h, params) => {
          const latlng = params.row.signOutLat + ',' + params.row.signOutLng
          return h('span', latlng)
        }},
        {width: 180, title: '签出时间', key: 'signOutTime', align: 'center'},
      ],
      orgFlag: false,
    }
  },
  computed: {
    multiBtn() {
      return [
        // {
        //   children: [
        //     {
        //       type: 'detail',
        //       title: '查看详情',
        //       icon: 'md-eye',
        //       // disabled: this.tableSelectItem.length !== 1
        //     },
        //   ]
        // },
      ]
    },
  },
  mounted() {
    this.params.hatSeqNo = this.midData[0].seqNo || ''
    if(!this.params.hatSeqNo) return
    this.getList();
  },
  methods: {}
}
</script>

<style>

</style>