<template>
  <div style="padding: 0 10px 10px 10px">
    <h1 class="h1Title">导入二次确认</h1>
    <ys-page-list
        ref="table"
        :footer="false"
        :frozenData="false"
        :header="false"
        :tableLoading="loading"
        :multiColumn="false"
        :tableHeight="height-100"
        :headerColumns="headerColumns"
        :tableData="tableList"/>
    <div class="modalEditBtnArea">
      <Alert type="error" v-if="errorLen>0" show-icon style="float: left;margin-top: 0;">有{{ errorLen }}条数据导入前需要二次确认，已对该数据背景标红色，请及时处理！</Alert>
      <Button type="primary" :disabled="errorLen>0" @click="save">确定并导入</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'
import {clone, getBaseDataName, formatDate, getRegionByCode, generateSocialCreditCode} from "ys-admin";
import {multiAddPolicy} from '@/api/policy'
import policyRate from '@/views/policy/edit/rate'
import {getCoInsurer} from "@/api/org";

export default {
  name: 'confirm',
  mixins: [pageList],
  data() {
    return {
      loading: true,
      headerColumns: [
        {title: '序号', width: 50, key: 'indexPrint', align: 'center'},
        {
          title: '导入状态', width: 110, key: 'status', align: 'center', render: (h, params) => {
            const {status = 0} = params.row;
            const {projectId, entOrgId, insOrgId, coInsurerId} = params.row.cellClassName
            switch (status) {
              case 0:
                return h('div', [
                  h('span', '待导入'),
                  h('a', {
                    style: {
                      display: !projectId && !entOrgId && !insOrgId && !coInsurerId ? '' : 'none'
                    },
                    on: {
                      'click': () => {
                        this.reload(params.index);
                      }
                    }
                  }, '[导入]'),
                ])
              case 1:
                return h('span', {style: {color: '#d26f02'}}, '导入中...')
              case 2:
                return h('span', {style: {color: '#007911'}}, '导入成功')
              case 3:
                return h('Tooltip', {
                  slot: 'prefix',
                  props: {
                    maxWidth: 300,
                    placement: 'left',
                  }
                }, [
                  h('div', {slot: 'content'}, [
                    h('span', params.row.errorTip + '。'),
                    h('a', {
                      on: {
                        'click': () => {
                          this.reload(params.index);
                        }
                      }
                    }, '重新导入'),
                  ]),
                  h('Icon', {props: {type: 'ios-alert'}, style: {color: '#ff0900'}}),
                  h('span', {style: {color: '#ff0900'}}, ' 导入失败')])
            }
          }
        },
        {
          title: '项目信息', align: 'center', children: [
            {minWidth: 230, title: '名称', key: 'projectName', align: 'left',},
            {
              width: 300, title: '施工许可证号', key: 'projectId', align: 'left', render: (h, params) => {
                const {project = []} = params.row;
                switch (project.length) {
                  case 0:
                    return h('Input', {
                      props: {value: params.row.license},
                      on: {
                        'on-blur': (data) => {
                          if (data.target.value === '') {
                            this.$Notice.error({
                              title: "提示！",
                              desc: '施工许可证不能为空，已经自动生成临时施工许可证！'
                            });
                            params.row.license = generateSocialCreditCode();
                          } else {
                            params.row.license = data.target.value
                          }
                          this.tableList[params.index] = params.row;
                          this.calcErrorLen();

                        }
                      }
                    }, [
                      h('Tooltip', {
                        slot: 'prefix',
                        props: {
                          maxWidth: 300,
                          placement: 'left',
                          content: '项目、导入模板中的施工许可证都不存在，已自动生成临时施工许可证！'
                        }
                      }, [
                        h('Icon', {props: {type: 'ios-alert'}})
                      ])
                    ]);
                  default:
                    return h('Select', {
                          props: {value: params.row.projectId},
                          on: {
                            'on-select': (data) => {
                              params.row.projectId = data.value
                              this.tableList[params.index] = params.row;
                              this.calcErrorLen();

                            }
                          }
                        }, [
                          h('Tooltip', {
                            slot: 'prefix',
                            props: {
                              maxWidth: 300,
                              placement: 'left',
                              content: '导入模板中的施工许可证为：\n' + params.row.license || '空'
                            }
                          }, [
                            h('Icon', {props: {type: 'ios-alert'}})
                          ]),
                          project.map((item) => {
                            return h('Option', {
                              props: {
                                value: item.id,
                                label: item.licenseNum + '-' + item.iname
                              }
                            });
                          })
                        ],
                    )
                }
              }
            },
            {
              width: 120, title: '工程类型', key: 'projectCategory', align: 'left', render: (h, params) => {
                return h('span', getBaseDataName('项目类型', params.row.projectCategory, false))
              }
            },
            {width: 230, title: '地址', key: 'address', align: 'left'},
            {width: 120, title: '工程造价', key: 'projectAmount', align: 'left'},
            {
              width: 180, title: '所属辖区', key: 'countyCode', align: 'left', render: (h, params) => {
                return h('span', getRegionByCode(params.row.countyCode || params.row.cityCode || params.row.provinceCode))
              }
            },
            {
              width: 110, title: '开工日期', key: 'projectStartAt', align: 'left', render: (h, params) => {
                return h('span', formatDate(params.row.projectStartAt))
              }
            },
            {
              width: 110, title: '竣工日期', key: 'projectEndAt', align: 'left', render: (h, params) => {
                return h('span', formatDate(params.row.projectEndAt))
              }
            },
          ]
        },
        {
          title: '投保单位', align: 'center', children: [
            {width: 230, title: '名称', key: 'entName', align: 'left'},
            {
              width: 300, title: '统一社会信用代码', key: 'endOrgId', align: 'left', render: (h, params) => {
                const {entOrg = []} = params.row;
                switch (entOrg.length) {
                  case 0:
                    return h('span', params.row.entCode);
                  default:
                    return h('Select', {
                          props: {value: params.row.entOrgId},
                          on: {
                            'on-select': (data) => {
                              params.row.entOrgId = data.value
                              this.tableList[params.index] = params.row;
                              this.calcErrorLen();
                            }
                          }
                        }, [
                          h('Tooltip', {
                            slot: 'prefix',
                            props: {
                              maxWidth: 300,
                              placement: 'left',
                              content: '导入模板中的统一社会信用代码：\n' + params.row.entCode || '空'
                            }
                          }, [
                            h('Icon', {props: {type: 'ios-alert'}})
                          ]),
                          entOrg.map((item) => {
                            return h('Option', {
                              props: {
                                value: item.id,
                                label: item.societyCode + '-' + item.iname
                              }
                            });
                          })
                        ]
                    )
                }
              }
            },
            {width: 100, title: '联系人', key: 'entContact', align: 'left'},
            {width: 180, title: '联系电话', key: 'entPhone', align: 'left'},
          ]
        },
        {
          title: '承保单位', align: 'center', children: [
            {width: 230, title: '名称', key: 'insName', align: 'left'},
            {
              width: 300, title: '统一社会信用代码', key: 'insOrgId', align: 'left', render: (h, params) => {
                const {insOrg = []} = params.row;
                switch (insOrg.length) {
                  case 0:
                    return h('span', params.row.insCode);
                  default:
                    return h('Select', {
                          props: {value: params.row.insOrgId},
                          on: {
                            'on-select': (data) => {
                              params.row.insOrgId = data.value
                              const currentInsOrg = insOrg.find(subItem => subItem.id === params.row.insOrgId) || {};
                              params.row.conIns = currentInsOrg.conIns || [];
                              params.row.coInsurerId = params.row.conIns.length > 0 ? params.row.conIns[0].id : 0;
                              params.row.member = params.row.coInsurerId ? (params.row.conIns[0].member || []) : [];
                              params.row.hasExt = params.row.coInsurerId ? params.row.conIns[0].hasExt : 0;
                              params.row.extType = params.row.coInsurerId ? params.row.conIns[0].type : 0;
                              params.row.ownerId = params.row.member.length > 0 ? params.row.member[0].insId : 0;
                              this.tableList[params.index] = params.row;
                              this.calcErrorLen();
                            }
                          }
                        }, [
                          h('Tooltip', {
                            slot: 'prefix',
                            props: {
                              maxWidth: 300,
                              placement: 'left',
                              content: '导入模板中的统一社会信用代码：\n' + params.row.insCode || '空'
                            }
                          }, [
                            h('Icon', {props: {type: 'ios-alert'}})
                          ]),
                          insOrg.map((item) => {
                            return h('Option', {
                              props: {
                                value: item.id,
                                label: item.societyCode + '-' + item.iname
                              }
                            });
                          })]
                    )
                }
              }
            },
            {width: 100, title: '联系人', key: 'insContact', align: 'left'},
            {width: 180, title: '联系电话', key: 'insPhone', align: 'left'},
          ]
        },
        {
          title: '保单信息', align: 'center', children: [
            {width: 210, title: '保单号', key: 'policyNumber', align: 'left'},
            {width: 210, title: '投保单号', key: 'insureNum', align: 'left'},
            {
              width: 120, title: '保单类型', key: 'type', align: 'center', render: (h, params) => {
                return h('span', getBaseDataName('保单类型', params.row.type, false))
              }
            },
            {
              width: 100, title: '共保体保单', key: 'isCoIns', align: 'center', render: (h, params) => {
                return h('span', getBaseDataName('是否', params.row.isCoIns, false))
              }
            },
            {
              width: 400, title: '适用份额配置', key: 'coInsurerId', align: 'left', render: (h, params) => {
                const {conIns = [], member = [], hasExt, isCoIns} = params.row;
                if (isCoIns) {
                  return h(
                      'div',
                      {style: {display: 'flex'}},
                      [
                        h('Select',
                            {
                              props: {value: params.row.coInsurerId},
                              on: {
                                'on-select': (data) => {
                                  params.row.coInsurerId = data.value;
                                  const currentConIn = conIns.find(subItem => subItem.id === params.row.coInsurerId) || {};
                                  params.row.member = currentConIn.member || [];
                                  params.row.hasExt = currentConIn.hasExt;
                                  params.row.extType = currentConIn.type;
                                  params.row.ownerId = params.row.member.length > 0 ? params.row.member[0].insId : 0;
                                  this.tableList[params.index] = params.row;
                                  this.calcErrorLen();
                                }
                              }
                            },
                            [
                              h(policyRate, {
                                slot: 'prefix',
                                props: {
                                  member: params.row.member,
                                  insOrgId: params.row.insOrgId,
                                  extType: params.row.extType,
                                  premiumCost: params.row.premiumCost,
                                  ownerId: params.row.ownerId,
                                  conIns: conIns,
                                },
                                on: {
                                  'on-add': () => {
                                    this.getConIns(params.index)
                                  }
                                }
                              }),
                              conIns.map((item) => {
                                return h('Option', {
                                  props: {value: item.id, label: item.iname}
                                });
                              })]
                        ),
                        h('Select',
                            {
                              style: {display: hasExt ? '' : 'none'},
                              props: {value: params.row.ownerId},
                              on: {
                                'on-select': (data) => {
                                  params.row.ownerId = data.value
                                  this.tableList[params.index] = params.row;
                                  this.calcErrorLen();
                                }
                              }
                            },
                            member.map((item) => {
                              return h('Option', {
                                props: {value: item.insId, label: item.iname}
                              });
                            })
                        )
                      ])
                }
              }
            },
            {
              width: 110, title: '投保时间', key: 'insureAt', align: 'left', render: (h, params) => {
                return h('span', formatDate(params.row.insureAt))
              }
            },
            {
              width: 110, title: '保单开始时间', key: 'startAt', align: 'left', render: (h, params) => {
                return h('span', formatDate(params.row.startAt))
              }
            },
            {
              width: 110, title: '保单结束时间', key: 'endAt', align: 'left', render: (h, params) => {
                return h('span', formatDate(params.row.endAt))
              }
            },
            {width: 100, title: '保费', key: 'premiumCost', align: 'left'},
            {width: 100, title: '保额', key: 'insureAmount', align: 'left'},
            {
              width: 120, title: '报告是否审核', key: 'isCheckReport', align: 'center', render: (h, params) => {
                return h('span', getBaseDataName('是否', params.row.isCheckReport, false))
              }
            },
            {width: 90, title: '应服务数', key: 'serverCount', align: 'center'},
          ]
        },
      ],
      errorLen: 0,
    }
  },
  mounted() {
    this.loading = true
    this.tableList = clone(this.midData);
    this.errorLen = 0;
    this.tableList.forEach((item, index) => {
      const {project = [], entOrg = [], insOrg = []} = item;
      this.$set(this.tableList[index], 'status', 0)
      this.$set(this.tableList[index], 'errorTip', '')
      item.projectId = project.length === 1 ? project[0].id : 0;
      item.entOrgId = entOrg.length === 1 ? entOrg[0].id : 0;
      item.insOrgId = insOrg.length === 1 ? insOrg[0].id : 0;
      item.conIns = item.insOrgId ? (insOrg.find(subItem => subItem.id === item.insOrgId).conIns || []) : [];
      item.coInsurerId = item.conIns.length > 0 ? item.conIns[0].id : 0;
      item.member = item.coInsurerId ? (item.conIns.find(subItem => subItem.id === item.coInsurerId).member || []) : [];
      item.hasExt = item.coInsurerId ? item.conIns[0].hasExt : 0;
      item.extType = item.coInsurerId ? item.conIns[0].type : 0;
      item.ownerId = item.member.length > 0 ? item.member[0].insId : 0;
      if ((item.project || []).length === 0) {
        window.setTimeout(() => {
          item.license = item.license || generateSocialCreditCode();
          if (index === this.tableList.length - 1) this.loading = false;
        }, 1)
      } else {
        this.loading = false;
      }
      if (item.relateIndex > index) {
        let realRelateIndex = this.tableList.findIndex(subItem => subItem.index === item.relateIndex);
        let relateData = clone(this.tableList[realRelateIndex]);
        this.tableList.splice(realRelateIndex, 1);
        relateData.index = index + 1;
        relateData.indexPrint = index + 2;
        item.relateIndex = index + 1;
        this.tableList.splice(index + 1, 0, relateData);
      } else {
        item.indexPrint = index + 1;
        item.index = index;
      }
    })
    this.calcErrorLen();
  },
  methods: {
    calcErrorLen() {
      this.errorLen = 0;
      this.tableList.forEach(item => {
        const {project = [], entOrg = [], insOrg = [], isCoIns, coInsurerId} = item;
        item.cellClassName = {
          projectId: project.length > 1 && !item.projectId ? 'warning' : '',
          entOrgId: entOrg.length > 1 && !item.entOrgId ? 'warning' : '',
          insOrgId: insOrg.length > 1 && !item.insOrgId ? 'warning' : '',
          coInsurerId: isCoIns && !coInsurerId ? 'warning' : '',
        }
        if ((project.length > 1 && !item.projectId) || (entOrg.length > 1 && !item.entOrgId) || (insOrg.length > 1 && !item.insOrgId) || (isCoIns && !coInsurerId)) this.errorLen++;
      });
    },
    getConIns(index) {
      getCoInsurer({orgId: this.tableList[index].insOrgId, policyType: this.tableList[index].type}).then((res) => {
        if (res.code === 200) {
          this.tableList[index].conIns = res.data.list || [];
          this.tableList[index].coInsurerId = res.data.list[0].id;
          this.tableList[index].insOrg.forEach(item => {
            if (item.id === this.tableList[index].insOrgId) {
              item.conIns = res.data.list;
            }
          })
          this.calcErrorLen();
        }
      })
    },
    async reload(index) {
      let List = [];
      List.push(this.tableList[index]);
      const relateIndex = this.tableList[index].relateIndex
      this.tableList[index].status = 1
      if (relateIndex >= 0) {
        List.push(this.tableList[relateIndex]);
        this.tableList[relateIndex].status = 1
      }
      await multiAddPolicy({list: List, noErrorCallback: true}).then(res => {
        this.tableList[index].status = res.code === 200 ? 2 : 3;
        this.tableList[index].errorTip = res.code === 200 ? '' : res.msg;
        if (relateIndex >= 0) {
          this.tableList[relateIndex].status = res.code === 200 ? 2 : 3
          this.tableList[relateIndex].errorTip = res.code === 200 ? '' : res.msg;
        }
      })
    },
    async save() {
      let batchAdd = async (index = 0) => {
        if (index >= this.tableList.length) {
          batchAdd = null;
          const errorLen = this.tableList.filter(item => item.status === 3).length;
          const successLen = this.tableList.filter(item => item.status === 2).length;
          this.$Modal[errorLen > 0 ? 'error' : 'success']({
            title: '提示',
            content: '导入成功' + successLen + '个，导入失败' + errorLen + '个',
          })
          return false
        }
        if (this.tableList[index].status === 2) {
          await batchAdd(index + 1);
          return false;
        }
        await this.reload(index)
        const relateIndex = this.tableList[index].relateIndex
        await batchAdd((relateIndex > index ? relateIndex : index) + 1);
      }
      await batchAdd();
    },
    close() {
      this.$emit('on-close')
    },
  }
}
</script>
<style lang="less">
.ivu-table .warning, .ys-page-list-tableBox .tableRowCheckBg td.warning {
  background-color: #fdece7 !important;
}
</style>
