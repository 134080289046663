<template>
  <div>
    <Form ref="form" class="editForm twoPartsEditForm" :model="form" :rules="ruleValidate" inline :label-width="100" :style="{maxHeight:'calc(100vh - 200px)'}" style="overflow-y: auto">
      <FormItem label="模板名称" prop="name">
        <Input v-model="form.name" placeholder="模板名称"/>
      </FormItem>
      <FormItem label="模板类型" prop="type">
        <Select v-model="form.type" placeholder="模板类型">
          <Option v-for="(item,index) in baseData['模板类型']" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="适用检查标准" prop="standardList" style="width: 100%">
        <Select v-model="form.standardList" clearable multiple placeholder="模板类型">
          <Option v-for="(item,index) in standardList" :value="item.id" :key="index">{{ item.iname || item.shortName }}</Option>
        </Select>
      </FormItem>
      <FormItem label="机构名称" prop="orgId">
        <Input v-model="form.orgName" @on-clear="clearParent" clearable placeholder="机构名称">
          <Button slot="append" @click="orgFlag=true">选择机构</Button>
        </Input>
      </FormItem>
      <FormItem label="状态" prop="status">
        <Select v-model="form.status" placeholder="状态">
          <Option v-for="(item,index) in baseData['启停状态']" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="描述" prop="describe" style="width: 100%">
        <Input v-model="form.describe" type="textarea" maxlength="128" show-word-limit :autosize="{minRows: 3,maxRows: 5}" placeholder="描述"></Input>
      </FormItem>
      <FormItem label="模板文件" prop="licenseFileList">
        <ysUpload @on-success="bLFile" :headers="{token:token}" type="fullList" :limit="1" :format="['docx']" :defaultList.sync="fileList" action="/web/resource/UploadFile"></ysUpload>
      </FormItem>
      <FormItem label="预览图片" prop="previewId">
        <ysUpload @on-success="previewFile" :headers="{token:token}" type="fullList" :limit="1" :defaultList.sync="previewList" action="/web/resource/UploadFile"></ysUpload>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok('/template/AddOrEdit')">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
    <ys-modal v-model="orgFlag" :width="1200" title="选择机构">
      <org-component :limit="1" :oType="form.oType" @on-ok="getData"></org-component>
    </ys-modal>
  </div>
</template>
<script>
import edit from '@/mixins/edit'
import OrgComponent from "_c/org";
import {templateInfo, getStandardList} from "@/api/base";

export default {
  name: 'projectEdit',
  mixins: [edit],
  components: {OrgComponent},
  props: {oType: {type: Number, default: 1}},
  data() {
    return {
      ruleValidate: {
        name: [
          {required: true, message: '请填写模板名称', trigger: 'blur'}
        ],
        time: [
          {required: true, type: 'fileId', message: '请上传模板文件', trigger: 'change'}
        ],
      },
      orgFlag: false,
      previewList: [],
      standardList: [],
      fileList: [],
      form: {
        id: '',
        orgName: '',
        orgId: '',
        name: '',
        status: 1,
        type: 1,
        standardList: [],
        describe: '',
        fileId: '',
        previewId: '',
      },
    }
  },

  mounted() {
    this.getStandardList();
    if (this.mid) {
      this.getDetail()
    }
  },
  methods: {
    getStandardList() {
      getStandardList({limit: -1}).then(res => {
        if (res.code === 200) {
          this.standardList = res.data.list||[]
        }
      })
    },
    getDetail() {
      templateInfo({id: this.mid}).then(res => {
        if (res.code === 200) {
          Object.keys(this.form).forEach((item) => {
            this.form[item] = res.data[item]
          })
          this.fileList = res.data.file?[res.data.file]:[]
          this.previewList = res.data.preview?[res.data.preview]:[]
          this.form.standardList = res.data.standardList?.map(item=>item.id);
          this.form.fileId = res.data.file?.id
          this.form.previewId = res.data.preview?.id
        }
      })
    },
    getData(data) {
      this.form.orgName = data[0].iname;
      this.form.orgId = data[0].id;
    },
    clearParent() {
      this.form.orgId = "";
      this.form.orgName = "";
    },
    bLFile(data) {
      this.form.fileId = data[0].id;
    },
    previewFile(data) {
      this.form.previewId = data[0].id;
    },
  }
}
</script>

<style lang="less" scoped>
</style>
