<template>
  <div>
    <Form ref="form" class="editForm twoPartsEditForm" inline :model="form" :rules="ruleValidate" :label-width="110" :style="{maxHeight:'calc(100vh - 200px)'}" style="overflow-y: auto">
      <FormItem label="名称" prop="iname">
        <Input v-model="form.iname" placeholder="名称"></Input>
      </FormItem>
      <!--      <FormItem label="保单类型" prop="policyType">-->
      <!--        <Select v-model="form.policyType" @on-change="typeChange" transfer placeholder="保单类型">-->
      <!--          <Option v-for="(item,index) in baseData['保单类型'].filter(item => item.value < 100)" :value="item.value" :key="index">{{ item.name }}</Option>-->
      <!--        </Select>-->
      <!--      </FormItem>-->
      <FormItem label="分配模式" prop="type">
        <Select v-model="form.type" transfer placeholder="分配模式" @on-change="rateCalc" style="width: 100%">
          <Tooltip :content="tips" max-width="300" slot="prefix">
            <Icon type="ios-alert"/>
          </Tooltip>
          <Option v-for="(item,index) in baseData['共保体配置类型']" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="激励份额(%)" prop="extRate" :rules="{required: true,type:'number', message: '请填写激励份额', trigger: 'blur'}" v-if="form.type===3">
        <InputNumber v-model="form.extRate" @on-change="rateCalc" :max="100" style="width: 100%" placeholder="激励份额"></InputNumber>
      </FormItem>
      <FormItem label="默认配置" prop="isDefault">
        <i-switch v-model="form.isDefault" :true-value="1" :false-value="0">
          <span slot="open">是</span>
          <span slot="close">否</span>
        </i-switch>
      </FormItem>
      <h1 class="h1Title">比例配置</h1>
      <template v-if="form.member.length===0">
        <ys-empty title="尚未选择保险机构，请选择！"></ys-empty>
      </template>
      <template v-else>
        <Alert :type="leftRate<0?'error':'success'">剩余可分配比例： {{ leftRate + '%' }}</Alert>
        <div v-for="(item,index) in form.member" :key="index">
          <FormItem label="保险名称" prop="orgName" :style="{width: form.type===2?'44.5%':'69.5%'}" :label-width="80">
            <Input v-model="item.iname" disabled placeholder="项目名称"> </Input>
          </FormItem>
          <FormItem label="激励份额(%)" :prop="'member.' + index + '.extRate'" :rules="{required: true,type:'number', message: '请填写激励份额', trigger: 'blur'}" v-if="form.type===2" style="width: 25%;">
            <InputNumber v-model="item.extRate" :max="100" style="width: 100%" placeholder="激励份额"></InputNumber>
          </FormItem>
          <FormItem label="份额占比(%)" :prop="'member.' + index + '.rate'" :rules="{required: true,type:'number', message: '请填写份额占比', trigger: 'blur'}" style="width: 25%;">
            <InputNumber
                v-model="item.rate"
                :disabled="!item.rate&&leftRate<=0"
                @on-change="rateCalc" :max="100"
                style="width: 100%" placeholder="份额占比"></InputNumber>
          </FormItem>
          <Button @click="del(index)" style="margin-left: 1%;margin-top: 1px;" icon="ios-close-circle"></Button>
        </div>
      </template>
      <Button long type="primary" @click="orgFlag=true" style="margin-bottom: 10px">新增保险机构</Button>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="save">确定</Button>
      <Button @click="close">取消</Button>
    </div>
    <ys-modal v-model="orgFlag" :width="1200" title="选择保险机构">
      <OrgComponent :oType="1" :types="[1,2,3,4,5]" @on-ok="getData"></OrgComponent>
    </ys-modal>
  </div>
</template>
<script>
import edit from '@/mixins/edit'
import {coInsurerInfo} from "@/api/org";
import OrgComponent from "_c/org";
import {math} from 'ys-admin'

export default {
  name: 'projectEdit',
  components: {OrgComponent},
  mixins: [edit],
  data() {
    return {
      orgFlag: false,
      ruleValidate: {
        iname: [
          {required: true, message: '请输入名称', trigger: 'blur'}
        ],
        member: {
          type: 'array',
          required: true,
          len: 2,
          fields: {
            0: {required: true, type: 'string', message: '请输入份额比例', trigger: 'blur'},
            1: {required: true, type: 'number', message: '请输入份额比例', trigger: 'blur'},
          },
        },
      },
      leftRate: 0,
      form: {
        id: '',
        iname: '',
        orgId: '',
        orgName: '',
        isDefault: 0,
        policyType: 1,
        type: 1,
        extRate: null,
        member: [],
      },
    }
  },
  computed: {
    tips() {
      return this.baseData['共保体配置类型'].filter(item => item.value === this.form.type)[0].desc;
    }
  },
  mounted() {
    if (this.mid) {
      this.getInfo()
    } else {
      this.form.orgId = this.parentID
    }
  },
  methods: {
    getInfo() {
      coInsurerInfo({id: this.mid}).then((res) => {
        Object.keys(this.form).forEach((item) => {
          this.form[item] = res.data[item]
        });
        this.form.member = this.form.member || [];
      })
    },
    rateCalc() {
      this.leftRate = math.subtract(100, this.form.member.reduce((per, next) => math.add(per, (next.rate || 0)), 0));
      if (this.form.type === 3) {
        this.leftRate = math.subtract(this.leftRate, (this.form.extRate || 0))
      }
    },
    typeChange() {
      this.form.extRate = this.form.type === 1 ? 0 : 20
    },
    save() {
      if (this.form.member.length < 2) {
        this.$Notice.warning({
          title: "提示！",
          desc: '共保体成员必须大于2位，请检查！'
        });
        return false;
      }
      let currentRate = this.form.member.reduce((per, next) => math.add(per, (next.rate || 0)), 0);
      if (this.form.type === 3) {
        this.form.member.forEach(item => {
          item.extRate = this.form.extRate;
        })
        if (math.add(this.form.extRate, currentRate) !== 100) {
          this.$Notice.warning({
            title: "提示！",
            desc: '份额占比、激励份额合计不等于100%，请检查！'
          });
          return false;
        }
      } else {
        if (currentRate !== 100) {
          this.$Notice.warning({
            title: "提示！",
            desc: '份额占比合计不等于100%，请检查！'
          });
          return false;
        }
      }
      this.ok('/coInsurer/AddOrEdit');
    },
    del(index) {
      this.form.member.splice(index, 1);
    },
    getData(data) {
      let exists = [];
      data.forEach(item => {
        if (this.form.member.filter(memberItem => memberItem.insId === item.id).length === 0) {
          this.form.member.push({
            insId: item.id,
            iname: item.iname || item.shortName,
            extRate: null,
            rate: null,
          })
        } else {
          exists.push(item.iname);
        }
      });
      this.rateCalc();
      if (exists.length > 0) {
        this.$Notice.warning({
          title: "提示！",
          desc: '已过滤相同保险公司【' + exists.join('，') + '】。'
        });
      }
    },
  }
}
</script>

