import { render, staticRenderFns } from "./appid.vue?vue&type=template&id=ca62ebbc&scoped=true&"
import script from "./appid.vue?vue&type=script&lang=js&"
export * from "./appid.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca62ebbc",
  null
  
)

export default component.exports