<template>
  <div class="home">
    <ys-page-list
        ref="table"
        :multiBtn="multiBtn"
        showContextMenu
        action="/taskScheduler/List"
        :params="params"
        keyID="46676E80596B11EC9C5C00163E15C775"
        :multiColumn="true"
        :is-page="false"
        :tableHeight="tableHeight"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="关键词" clearable style="width: 150px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template slot="btn">
        <ButtonGroup>
          <Button type="primary" @click="add">新增定时任务</Button>
        </ButtonGroup>
      </template>
      <template slot="detail">
        <detail :midData="tableSelectItem" :mid="detailID"></detail>
      </template>
    </ys-page-list>
    <ys-modal v-model="modShow" :width="800" title="新增/修改定时任务">
      <Edit :midData="tableSelectItem" @on-ok="getList"></Edit>
    </ys-modal>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList'
import Edit from "@/views/base/autoTask/edit.vue";
import {runOnce} from '@/api/base.js'
import Detail from "@/views/base/autoTask/detail.vue";

export default {
  name: 'projects',
  mixins: [pageList],
  components: {Detail, Edit},
  data() {
    return {
      params: {
        o_type: '',
        keyword: '',
      },
      putInFlag: false,
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 50, align: 'center'},
        {width: 130, title: '任务名称', key: 'name', align: 'left',},
        {width: 100, title: '触发月份', key: 'month', align: 'center',},
        {width: 130, title: '触发日', key: 'dayOfMonth', align: 'center'},
        {width: 130, title: '每周触发日', key: 'dayOfWeek', align: 'center', render: (h, params) => {
          const list = [{value:'日',num:'0'},{value:'一',num:'1'},{value:'二',num:'2'},{value:'三',num:'3'},{value:'四',num:'4'},{value:'五',num:'5'},{value:'六',num:'6'},]
          return h('span', list.find(item => item.num === params.row.dayOfWeek)?.value || '')
        }},
        {width: 130, title: '触发小时', key: 'hour', align: 'center'},
        {width: 130, title: '触发分钟', key: 'min', align: 'center'},
        {minWidth: 130, title: '参数', key: 'args', align: 'left'},
        {width: 170, title: '创建时间', key: 'createdAt', align: 'center'},
      ]
    }
  },
  computed: {
    multiBtn() {
      return [
        {
          children: [
            {
              type: 'detail',
              click: () => this.modShow = true,
              title: '查看详情',
              icon: 'md-eye',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.modShow = true,
              title: '编辑',
              icon: 'md-create',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.delItem('/taskScheduler/Remove'),
              iconColor: '#ed4014',
              title: '删除',
              icon: 'md-trash',
              disabled: this.tableSelectItem.length !== 1
            },
          ]
        },
        {
          children: [
            {
              click: () => this.doTask(),
              title: '手动触发',
              disabled: this.tableSelectItem.length !== 1
            },
          ]
        }
      ]
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    doTask() {
      this.$Modal.confirm({
        title: '提示',
        content: '确定要手动触发么？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          runOnce({id: this.tableSelectItem[0].id}).then((res) => {
            if (res.code === 200) {
              this.$Notice.success({title: '提示', desc: '操作成功！'});
              this.getList();
            }
          })
        }
      })
    },
  }
}
</script>
