<template>
  <div>
    <Form ref="form" class="editForm" :model="form" :rules="ruleValidate" :label-width="70">
      <FormItem label="提交专家" prop="updatedBy">
        <Select v-model="form.updatedBy" label-in-value filterable placeholder="提交专家" @on-change="createdChange">
          <Option v-for="item in expertList" :value="item.userId" :key="item.id">{{ item.iname }}</Option>
        </Select>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok('/report/Commit')">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>

<script>
import edit from '@/mixins/edit'
import {checkExpertList} from "@/api/expert";

export default {
  name: 'status',
  mixins: [edit],
  props: {
    status: {type: Number, default: null}
  },
  data() {
    return {
      expertList: [],
      form: {
        checkId: '',
        updatedBy: '',
        updatedUser: '',
      }
    }
  },
  async mounted() {
    this.form.checkId = this.mid;
    this.getExpert();
  },
  methods: {
    createdChange(data) {
      this.form.updatedBy = data.value;
      this.form.updatedUser = data.label;
    },
    getExpert() {
      checkExpertList({checkId: this.form.checkId, limit: -1}).then(res => {
        this.expertList = res.data.list || [];
        this.form.updatedBy = this.form.updatedBy || (this.expertList.length > 0 ? this.expertList[0].userId : '')
        this.form.updatedUser = this.form.updatedUser || (this.expertList.length > 0 ? this.expertList[0].iname : '')
      })
    },
  }
}
</script>
<style lang="less" scoped>
</style>
