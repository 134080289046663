<template>
  <div class="home">
    <ys-page-list
        ref="table"
        :multiBtn="multiBtn"
        showContextMenu
        :params="params"
        keyID="46676E59596B11EC9C5C00163E15C775"
        action="/template/List"
        :multiColumn="true"
        :is-page="false"
        :tableHeight="tableHeight"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="模板类型" prop="type">
          <Select v-model="params.type" clearable placeholder="模板类型" style="width: 120px">
            <Option v-for="(item,index) in baseData['模板类型']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="状态" prop="status">
          <Select v-model="params.status" placeholder="状态">
            <Option v-for="(item,index) in baseData['启停状态']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="关键词" clearable style="width: 150px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template slot="btn">
        <ButtonGroup>
          <Button type="primary" @click="add">新增</Button>
        </ButtonGroup>
      </template>
    </ys-page-list>
    <ys-modal v-model="modShow" :width="800" title="新增/修改报告模板">
      <Edit :mid="detailID" @on-ok="getList"></Edit>
    </ys-modal>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList'
import Edit from "@/views/task/template/edit.vue";

export default {
  name: 'projects',
  mixins: [pageList],
  components: {Edit},
  data() {
    return {
      params: {
        status: '',
        type: '',
        keyword: '',
        orgId: ''
      },
      putInFlag: false,
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 50, align: 'center'},
        {width: 200, title: '模板名称', key: 'name', align: 'left',},
        {width: 100, title: '模板类型', key: 'type', align: 'center', renderConfig: {type: 'baseData',parentName: '模板类型'}},
        {width: 200, title: '机构名称', key: 'orgName', align: 'left'},
        {minWidth: 130, title: '描述', key: 'describe', align: 'left'},
        {width: 170, title: '创建时间', key: 'createdAt', align: 'center'},
        {width: 100, title: '状态', key: 'status', align: 'center', renderConfig: {type: 'baseData',parentName: '启停状态'}},
      ]
    }
  },
  computed: {
    multiBtn() {
      return [
        {
          children: [

            {
              click: () => this.modShow = true,
              title: '编辑',
              icon: 'md-create',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.delItem('/template/Del',{ids:this.tableSelectItem.map(item=>item.id)}),
              iconColor: '#ed4014',
              title: '删除',
              icon: 'md-trash',
              disabled: this.tableSelectItem.length !== 1
            },
          ]
        }
      ]
    },
  },
  mounted() {
    this.getList();
  },
  methods: {

  }
}
</script>
