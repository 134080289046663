<template>
  <div class="home">
    <ys-page-list
        ref="table"
        action="policy/List"
        :params="params"
        :multiBtn="multiBtn"
        keyID="46676E21596B11EC9C5C00163E15C775"
        showContextMenu
        :multiColumn="true"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="所在地区" prop="region" show>
          <ys-region changeOnSelect v-model="params.region" @on-change="getRegion"></ys-region>
        </FormItem>
        <!-- <FormItem label="服务开始日期" prop="serveStart">
          <ys-date-picker v-model="params.serveStart" clearable style="width: 120px" placeholder="服务开始日期"></ys-date-picker>
        </FormItem>
        <FormItem label="服务结束日期" prop="serveEnd">
          <ys-date-picker v-model="params.serveEnd" clearable style="width: 120px" placeholder="服务结束日期"></ys-date-picker>
        </FormItem> -->
        <FormItem label="共保体保单" prop="isCoIns">
          <Select v-model="params.isCoIns" transfer clearable placeholder="共保体保单" style="width: 100px;">
            <Option v-for="(item,index) in baseData['是否']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="服务状态" prop="isServe" show>
          <Select v-model="params.isServe" transfer clearable placeholder="保单服务状态" style="width: 120px;">
            <Option v-for="(item,index) in baseData['保单服务状态']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="保单生效状态" prop="activeStatus" show>
          <Select v-model="params.activeStatus" transfer clearable placeholder="保单生效状态" style="width: 100px;">
            <Option v-for="(item,index) in baseData['保单生效状态']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="保单类型" prop="type" show>
          <Select v-model="params.type" transfer clearable placeholder="保单类型" style="width: 100px;">
            <Option v-for="(item,index) in baseData['保单类型']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="项目状态" prop="projectStatus">
          <Select v-model="params.projectStatus" transfer clearable placeholder="项目状态" style="width: 100px;">
            <Option v-for="(item,index) in baseData['项目进度']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <!-- <FormItem label="服务次数" prop="serveNum">
          <InputNumber placeholder="服务次数" clearable style="width: 100px;" v-model="params.serveNum"></InputNumber>
        </FormItem> -->
        <FormItem label="关键词" prop="keyword" show>
          <Input placeholder="关键词" clearable style="width: 150px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template slot="btn">
        <ButtonGroup>
          <Button type="primary" @click="add">新增保单</Button>
          <Button type="primary" @click="putInFlag=true">导入保单</Button>
        </ButtonGroup>
      </template>
      <template slot="detail">
        <Detail :tabName="tabName" :mid="detailID"></Detail>
      </template>
    </ys-page-list>
    <ys-modal v-model="modShow" :width="1000" :title="oldId?'续保':'新增/修改保单'">
      <Edit :mid="detailID" @on-ok="getList" :oldId="oldId"></Edit>
    </ys-modal>
    <ys-modal v-model="putInFlag" :width="500" title="导入保单">
      <PutIn @on-ok="getData"></PutIn>
    </ys-modal>
    <ys-modal v-model="exchangeFlag" :width="500" title="保单迁移">
      <exchange :mid="detailID" :companyId="tableSelectItem.length===1?tableSelectItem[0].companyId:''" @on-ok="getList"></exchange>
    </ys-modal>
    <ys-modal v-model="bindProjectFlag" :width="500" title="绑定项目">
      <bindProject :midData="tableSelectItem" @on-ok="getList"></bindProject>
    </ys-modal>
    <ys-drawer mask v-model="confirmFlag" :width="85">
      <confirm :midData="tableList" @on-close="confirmFlag=false" @on-ok="getList"></confirm>
    </ys-drawer>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'
import PutIn from "@/views/policy/putIn.vue";
import Detail from "@/views/policy/detail.vue";
import Confirm from "@/views/policy/putIn/confirm";

export default {
  name: 'projects',
  mixins: [pageList],
  components: {
    Confirm,
    Detail,
    PutIn,
    Edit: () => import('./edit.vue'),
    exchange: () => import('./exchange.vue'),
    bindProject: () => import('./bindProject.vue')
  },
  data() {
    return {
      params: {
        status: '',
        activeStatus: '',
        region: '',
        countyCode: '',
        cityCode: '',
        isCoIns: '',
        projectStatus: '',
        serveStart: '',
        isServe: '',
        serveEnd: '',

        serveNum: null,
        provinceCode: '',
        orgId: '',
        keyword: '',
        type: '',
      },
      bindProjectFlag: false,
      confirmFlag: false,
      putInFlag: false,
      exchangeFlag: false,
      oldId: '',
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 50, align: 'center'},
        {minWidth: 180, title: '保单号', key: 'number', align: 'left', renderConfig: {type: 'gotoDetail'}},
        {width: 120, title: '安责险类型', key: 'industryType', align: 'center', renderConfig: {type: 'baseData', parentName: '行业类型'}},
        {width: 110, title: '保单类型', key: 'type', align: 'center', renderConfig: {type: 'baseData', parentName: '保单类型'}},
        {width: 80, title: '保单来源', key: 'source', align: 'center', renderConfig: {type: 'baseData', parentName: '保单来源'}},
        {width: 100, title: '共保体保单', key: 'isCoIns', align: 'center', renderConfig: {type: 'baseData', parentName: '是否'}},
        {width: 130, title: '保费(元)', key: 'premiumCost', align: 'left', renderConfig: {type: 'fixed', unitType: 9}},
        {width: 130, title: '保额(元)', key: 'insureAmount', align: 'left', renderConfig: {type: 'fixed', unitType: 9}},
        {minWidth: 180, title: '项目名称', key: 'projectName', align: 'left'},
        {width: 120, title: '项目工程类型', key: 'projectCategory', align: 'center', renderConfig: {type: 'baseData', parentName: '项目类型'}},
        {width: 120, title: '保单开始日期', key: 'startAt', align: 'left', renderConfig: {type: 'formatDate'}},
        {width: 120, title: '保单结束日期', key: 'endAt', align: 'left', renderConfig: {type: 'formatDate'}},
        {width: 220, title: '投保单位', key: 'enterpriseName', align: 'left'},
        {width: 220, title: '承保单位', key: 'companyName', align: 'left'},
        {width: 180, title: '项目所属区', key: 'projectCountyCode', align: 'left', renderConfig: {type: 'area', key: ['projectCountyCode', 'projectCityCode', 'projectProvinceCode']}},
        {width: 170, title: '项目所在地', key: 'address', align: 'left'},
        {width: 100, title: '应服务次数', key: 'serverCount', align: 'center'},
        {width: 100, title: '已服务次数', key: 'reportedCount', align: 'center'},
        {width: 100, title: '项目状态', key: 'projectStatus', align: 'center', renderConfig: {type: 'baseData', parentName: '项目进度'}},
        {width: 100, title: '服务状态', key: 'isServe', align: 'center', renderConfig: {type: 'baseData', parentName: '保单服务状态'}},
        {width: 180, title: '服务机构', key: 'serviceName', align: 'center'},
        {width: 80, title: '保单状态', key: 'activeStatus', align: 'center', renderConfig: {type: 'baseData', parentName: '保单生效状态'}},
      ]
    }
  },
  computed: {
    multiBtn() {
      return [
        {
          children: [
            {
              type: 'detail',
              title: '查看详情',
              targetUrl: '/main/policy/detail/' + this.detailID,
              icon: 'md-eye',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.edit(),
              title: '编辑',
              icon: 'md-create',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.delItem('/policy/Remove', {id: this.tableSelectItem[0].id}),
              iconColor: '#ed4014',
              title: '删除',
              icon: 'md-trash',
              disabled: this.tableSelectItem.length !== 1
            },
          ]
        },
        {
          children: [
            {
              click: () => this.bindProjectFlag = true,
              title: '绑定项目',
              icon: 'md-trending-down',
              disabled: this.tableSelectItem.length !== 1 || this.tableSelectItem[0].projectId > 0
            },
            {
              click: () => this.renewal(),
              title: '续保',
              icon: 'md-trending-up',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.exchangeFlag = true,
              title: '保单迁移',
              icon: 'md-trending-up',
              disabled: this.tableSelectItem.length !== 1
            },
          ]
        }
      ]
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    getData(data) {
      this.tableList = data;
      this.confirmFlag = true;
    },
    edit() {
      this.oldId = '';
      this.modShow = true
    },
    renewal() {
      this.oldId = this.tableSelectItem[0].parentId || this.detailID;
      this.modShow = true;
    },
    getRegion(data) {
      this.params.countyCode = data[2] ? data[2].code : '';
      this.params.cityCode = data[1] ? data[1].code : '';
      this.params.provinceCode = data[0] ? data[0].code : '';
    }
  }
}
</script>
