<template>
  <div class="home">
    <ys-page-list
        ref="table"
        tableSize="small"
        :params="params"
        :multiBtn="multiBtn"
        :tableHeight="addModel?'auto':resultHeight"
        :showContextMenu="!addModel"
        :header="!addModel"
        :footer="!addModel"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns"
        :tableData="tableList">
      <template slot="search"></template>
      <template slot="btn">
        <ButtonGroup>
          <Button type="primary" @click="add">新增</Button>
        </ButtonGroup>
      </template>
    </ys-page-list>
    <Button type="primary" long style="margin: 10px 0" @click="add">新增</Button>
    <ys-modal v-model="modShow" :width="700" title="新增/修改等级配置规则">
      <edit :midData="tableSelectItem" @on-ok="getData"></edit>
    </ys-modal>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'
import edit from './edit'
import {clone} from 'ys-admin'

export default {
  name: '',
  mixins: [pageList],
  components: {edit},
  data() {
    return {
      params: {
        configId: '',
        keyword: '',
      },
      levelList: [],
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {width: 100, title: '等级名称', key: 'name', align: 'left'},
        {width: 100, title: '默认配置', key: 'default', align: 'left', renderConfig: {type: 'baseData', parentName: '是否'}},
        {minWidth: 150, title: '配置规则', key: 'rule', align: 'left'},
      ]
    }
  },
  computed: {
    addModel() {
      return !!this.midData
    },
    multiBtn() {
      return [
        {
          children: [
            {
              click: () => this.modShow = true,
              title: '编辑',
              icon: 'md-create',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.del(),
              iconColor: '#ed4014',
              title: '删除',
              icon: 'md-trash',
              disabled: this.tableSelectItem.length !==1
            },
          ]
        },
      ]
    }
  },
  mounted() {
    this.tableList = clone(this.midData);

  },
  methods: {
    getData(data) {
      const index = this.tableList.findIndex(item => item.index === data.index);
      if (index < 0) {
        this.tableList.push(data)
      } else {
        this.tableList[index].name = data.name;
        this.tableList[index].default = data.default;
        this.tableList[index].rule = data.rule;
      }
    },
    del() {
      const index = this.tableList.findIndex(item => item.index === this.tableSelectItem[0].index);
      this.tableList.splice(index, 1)
    },

  }

}
</script>
