<template>
  <div class="home">
    <ys-page-list
        ref="table"
        :multiBtn="multiBtn"
        showContextMenu
        keyID="46676E66596B11EC9C5C00163E15C778"
        :params="params"
        action="/orgApprovalConfig/List"
        :tableHeight="tableHeight"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="类型" prop="type">
          <Select v-model="params.type" clearable placeholder="审核流类型" style="width: 120px">
            <Option v-for="(item,index) in baseData['审核流类型']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="关键词" clearable style="width: 150px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template slot="btn">
        <ButtonGroup>
          <Button type="primary" @click="add">新增角色</Button>
        </ButtonGroup>
      </template>
      <template slot="detail">
        <Detail :mid="detailID"></Detail>
      </template>
    </ys-page-list>
    <ys-modal v-model="modShow" :width="900" title="新增/修改角色">
      <Edit :mid="detailID" @on-ok="getList"></Edit>
    </ys-modal>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'
import Edit from "@/views/base/approval/edit.vue";
import Detail from "@/views/base/approval/detail.vue";

export default {
  name: 'projects',
  mixins: [pageList],
  components: {Edit, Detail},
  data() {
    return {
      params: {
        type: '',
        keyword: '',
      },
      putInFlag: false,
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 50, align: 'center'},
        {width: 130, title: '审核流名称', key: 'name', align: 'left', renderConfig: {type: 'gotoDetail'}},
        {width: 300, title: '所属机构', key: 'orgName', align: 'left',},
        {width: 100, title: '类型', key: 'type', align: 'center', renderConfig: {type: 'baseData', parentName: '审核流类型'}},
        {minWidth: 170, title: '创建时间', key: 'createdAt', align: 'left'},
      ]
    }
  },
  computed: {
    multiBtn() {
      return [
        {
          children: [
            {
              click: () => this.modShow = true,
              title: '编辑',
              icon: 'md-create',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.delItem('/orgApprovalConfig/Remove'),
              iconColor: '#ed4014',
              title: '删除',
              icon: 'md-trash',
              disabled: this.tableSelectItem.length !== 1 || this.tableSelectItem[0].orgId === 0
            },
          ]
        },
      ]
    },
  },
  mounted() {
    this.getList();
  },
  methods: {}
}
</script>
