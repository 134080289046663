<template>
  <div v-if="detail">
    <ys-detail :icon="detailIcon" :loading="loading" :name="detail.number">
      <Tag size="medium" color="blue" slot="status">{{ detail.status|getBaseDataName('报告状态') }}</Tag>
      <template slot="brief">
        <ys-cell title="投保机构" :label="detail.enterpriseName"/>
        <ys-cell title="报告类型" :label="detail.type|getBaseDataName('检查单类型') "/>
        <ys-cell title="创建时间" :label="detail.createdAt|formatDate"/>
        <ys-cell title="报告结论" :label="detail.desc"/>
        <!-- <ys-cell title="任务名称" :label="detail.check.iname"/>
        <ys-cell title="项目名称" :label="detail.project.iname"/>
        <ys-cell title="创建时间" :label="detail.createdAt|formatDate"/>
        <ys-cell title="报告结论" :label="detail.desc"/> -->
      </template>
      <template slot="main-left">
        <Tabs v-model="tab" type="card" :key="detail.id">
          <Tab-pane label="报告明细" name="1" icon="ios-paper">
            <baseInfo :detailInfo="detail" v-if="tab==='1' && detail" :style="{height: `${detailHeight}px`, overflow: 'auto'}"></baseInfo>
          </Tab-pane>
          <Tab-pane label="风险列表" name="2" icon="md-list-box">
            <problems :tableHeight="pageListHeight" :mid="detail.checkId" v-if="tab==='2'&&detail.type!==9"></problems>
            <risk :tableHeight="pageListHeight" :mid="detail.checkId" v-if="tab==='2'&&detail.type===9"></risk>
          </Tab-pane>
          <Tab-pane label="报告预览" icon="md-chatbubbles" name="5">
            <template v-if="tab==='5'">
              <ys-file-review :file-list="detail.fileList" v-if="detail.fileList.length > 0"></ys-file-review>
              <ys-empty v-else/>
            </template>
          </Tab-pane>
          <Tab-pane label="相关附件" name="3" icon="md-document">
            <ys-resource :resourceType="6" :tableHeight="pageListHeight" :mid="detail.id" v-if="tab==='3'"></ys-resource>
          </Tab-pane>
        </Tabs>
      </template>
      <template slot="main-right">
        <h1 class="h1Title">其他信息</h1>
      </template>
    </ys-detail>
  </div>
</template>
<script>
import detail from '@/mixins/detail'
import {getReportInfo, reportCommit, reportEdit} from '@/api/report'
import problems from "@/views/task/problems/index";
import risk from "@/views/task/list/detail/risk/index";
import baseInfo from "@/views/task/reports/detail/baseInfo";

export default {
  name: 'reportDetail',
  mixins: [detail],
  components: {
    baseInfo,
    problems,
    risk,
  },
  data() {
    return {
      url: '',
      detail: null,
      modShow: false,
      spinShow: false,
      fileList: [],
    }
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    async commit() {
      this.$Modal.confirm({
        title: '提示',
        content: '确认要提交该报告么？提交后将无法录入风险信息，也无法生成整改单！',
        okText: '确定',
        loading: true,
        cancelText: '取消',
        onOk: () => {
          if (!this.detail.fileList || this.detail.fileList.length === 0) {
            this.$Notice.error({
              title: '提示',
              desc: '还没有上传报告附件，请先上传！'
            });
            return false;
          } else {
            reportCommit({checkId: this.detail.checkId}).then((res) => {
              if (res.code === 200) {
                this.$Notice.success({
                  title: '操作成功！'
                });
                this.getList();
              }
            });
          }
        }
      });
    },
    getDetail() {
      this.spinShow = true;
      this.detail = {}
      getReportInfo({id: this.mid}).then((res) => {
        this.spinShow = false;
        if (res.code === 200) {
          this.detail = res.data;
          this.fileList = this.detail.fileList || []
        }
      })
    },

    bLFile(data) {
      if (data.length === 1) {
        reportEdit({
          checkId: this.mid,
          desc: this.detail.desc,
          fileList: [data[0].id]
        }).then((res) => {
          if (res.code === 200) this.getDetail();
        })
      }
    }
  }
}
</script>
