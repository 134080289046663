<template>
  <ys-detail :icon="detailIcon" :image="detail.logo?'/web/resource/GetFile/'+detail.logo:''" :loading="loading" :name="detail.name">
    <template slot="brief">
      <ys-cell title="触发月份" :label="detail.month"/>
      <ys-cell title="触发日" :label="detail.dayOfMonth"/>
      <ys-cell title="触发小时" :label="detail.hour"/>
      <ys-cell title="触发分钟" :label="detail.min"/>
    </template>
    <template slot="main-left">
      <Tabs v-model="tab" name="detail" :animated="false">
        <Tab-pane label="服务项目" name="1" tab="detail">
          <list :tableHeight="pageListHeight" v-if="tab==='1'" :mid="detailID"></list>
        </Tab-pane>
      </Tabs>
    </template>
  </ys-detail>
</template>

<script>
import detailMixins from '@/mixins/detail'
import list from "@/views/base/autoTask/detail/list.vue";

export default {
  name: "detail",
  mixins: [detailMixins],
  components: {
    list,
  },
  mounted() {
    this.detail = this.midData.length === 1 ? this.midData[0] : {}
  },
  methods: {
    getDetail() {

    }
  }
}
</script>

<style scoped>

</style>