<template>
  <div>
    <Steps :current="current">
      <Step title="行业信息" content="完善行业信信息，建工行业需完善项目信息。"></Step>
      <Step title="保单类型" content="通用安责险、组合安责险。"></Step>
      <Step title="保单信息" content="录入并完善保单信息。"></Step>
      <Step title="完成" content="录入完成！"></Step>
    </Steps>
    <div class="stepItem" :style="{maxHeight:'calc(100vh - 200px)'}">
      <template v-if="ready&&current===0">
        <Form class="editForm" :label-width="110">
          <FormItem label="行业类型" style="margin-bottom: 15px;" v-if="baseData['行业类型'].length>1">
            <ul class="industry">
              <li v-for="(item,index) in baseData['行业类型']" :key="index" class="item">
                <div class="icon" :class="{active:item.value===industryType}" @click="industryType=item.value">
                  <Icon type="md-checkmark-circle" class="activeIcon" size="18" color="#0a69f4"/>
                  <Icon class="iconfont" :custom="industry[item.value]?industry[item.value].icon:'ios-aperture-outline'" :size="industry[item.value].size||35"/>
                </div>
                <p class="name">{{ item.name }}</p>
              </li>
            </ul>
          </FormItem>
        </Form>
        <project :formData="detail" ref="step0"></project>
      </template>
      <policyType v-if="ready&&current===1" :formData="detail" ref="step1"></policyType>
      <Policy v-if="ready&&current===2" :formData="detail" :oldId="oldId" :industryType="industryType" ref="step2"></Policy>
      <div v-if="ready&&current===3" class="result">
        <Icon type="ios-checkmark-circle" color="#19be6b" size="80"/>
        <h1>保单提交成功</h1>
      </div>
    </div>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="current--" v-if="[1,2].indexOf(current)>=0">上一步</Button>
      <Button type="primary" @click="save" v-if="current===0">下一步</Button>
      <Button type="primary" @click="save" v-else-if="current===1">下一步</Button>
      <Button type="primary" @click="save" v-else-if="current===2">保存保单</Button>
      <Button @click="closeModal">{{ ready && current === 3 ? '确定' : '取消' }}</Button>
    </div>
  </div>
</template>
<script>
import edit from '@/mixins/edit'
import {post, getBaseDataName} from 'ys-admin'
import {policyInfo} from "@/api/policy";
import Project from "@/views/policy/edit/project";
import policyType from "@/views/policy/edit/policyType";
import Policy from "@/views/policy/edit/policy";

export default {
  name: 'policyEdit',
  components: {
    Project,
    policyType,
    Policy,
  },
  mixins: [edit],
  data() {
    return {
      industryType: 1,
      industry: {
        1: {icon: 'icon-shigong03', size: 35},
        2: {icon: 'icon-jiaotongyunshu', size: 28},
        3: {icon: 'icon-shuili', size: 30},
        4: {icon: 'icon-kuangshan', size: 30},
        5: {icon: 'icon-gongyede-', size: 30},
        6: {icon: 'icon-weixianhuaxuepin', size: 30},
        7: {icon: 'icon-yanhua', size: 30},
        8: {icon: 'icon-jinshu', size: 30},
        9: {icon: 'icon-haofangtuo400iconfont2meiqizao', size: 30},
        10: {icon: 'icon-A001chuan', size: 35},
        11: {icon: 'icon-meikuangqiye', size: 35},
        9999: {icon: 'icon-icon_xinyong_xianxing_jijin-', size: 30},
      },
      current: 0,
      detail: {},
      ready: false,
    }
  },
  computed: {},
  mounted() {
    if (this.oldId || this.mid) {
      this.ready = false;
      this.current = 2;
      this.getPolicyInfo()
    } else {
      this.ready = true;
    }
  },
  methods: {
    getPolicyInfo() {
      this.detail = {};
      policyInfo({id: this.oldId || this.mid}).then((res) => {
        this.detail = res.data;
        this.ready = true;
      })
    },
    closeModal() {
      if (this.ready && this.current === 3) this.$emit('on-ok');
      this.close();
    },
    save() {
      const that = this.$refs['step' + this.current];
      switch (this.current) {
        case 0:
          if (that.editFlag) {
            this.saveProject()
          } else {
            this.current++;
          }
          break;
        case 1:
          this.detail.subType = that.azxType[that.typeIndex].type;
          this.detail.isCoIns = that.typeIndex > 0 ? 1 : 0;
          this.current = 2;
          break;
        case 2:
          if (!that.form.id) {
            this.$Modal.confirm({
              title: '信息确认',
              content: `以下信息一经保存将无法修改，请仔细检查：<br>承保项目：${that.form.projectName || '暂无'}<br>保单类型：${getBaseDataName('保单子类型', that.form.subType)}`,
              okText: '确定保存',
              cancelText: '取消',
              onOk: () => {
                this.savePolicy();
              }
            })
          } else {
            this.savePolicy();
          }
          break;
      }
    },
    savePolicy() {
      this.modal.spinShow = true;
      const that = this.$refs['step' + this.current];
      that.$refs['form'].validate((valid) => {
        if (valid) {
          post('/policy/AddOrEdit', that.form).then((res) => {
            this.modal.spinShow = false;
            if (res.code === 200) {
              this.detail.id = res.data.id;
              this.current = 3;
            }
          })
        } else {
          this.$Notice.error({
            title: "操作失败！",
            desc: '请检查表单是否填项正确！'
          });
          this.modal.spinShow = false;
        }
      })
    },
    saveProject() {
      this.modal.spinShow = true;
      const that = this.$refs['step' + this.current];
      // 若数据中不存在投保单位（可能是用户手动输入，需交给后端自动创建）
      if (!that.form.orgList || that.form.orgList.length <= 0) {
        that.form.orgList = [{
          code: that.form.contractCode, // 信用代码
          iname: that.form.contractUnit, // 名称
          otype: 4, // 组织类型(企业)
          type: 31, // 参建单位类型(默认施工单位)
        }]
      }
      that.$refs['form'].validate((valid) => {
        if (valid) {
          post(that.form.id ? '/projects/Edit' : '/projects/Add', that.form).then(res => {
            this.modal.spinShow = false;
            if (res.code === 200) {
              this.current = 1;
              this.detail.projectId = res.data.id;
              this.detail.projectName = res.data.iname;
              this.detail.enterpriseContact = res.data.principalName;
              this.detail.enterprisePhone = res.data.principalPhone;
              this.detail.provinceCode = res.data.provinceCode;
              this.detail.cityCode = res.data.cityCode;
              this.detail.countyCode = res.data.countyCode;
            }
          })
        } else {
          this.$Notice.error({
            title: "操作失败！",
            desc: '请检查表单是否填项正确！'
          });
          this.modal.spinShow = false;
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
@import "../../assets/font/iconfont.css";

.industry {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-items: center;

  li.item {
    list-style: none;
    flex-basis: 95px;
    text-align: center;
    margin-bottom: 10px;

    .icon {
      cursor: pointer;
      width: 60px;
      height: 60px;
      margin: 0 auto;
      background-color: rgba(10, 105, 244, 0.1);
      text-align: center;
      line-height: 64px;
      border-radius: 60px;
      color: #0a69f4;
      position: relative;

      &:hover, &.active {
        background-color: #0a69f4;
        color: #ffffff;
      }

      &.active {
        .activeIcon {
          background-color: #ffffff;
          display: block;
        }
      }

      .activeIcon {
        position: absolute;
        right: 5px;
        top: -3px;
        width: 19px;
        height: 18px;
        border-radius: 15px;
        overflow: hidden;
        display: none;
      }
    }

    .name {

    }
  }
}

.stepItem {
  padding: 30px 0;
  min-height: 250px;
  overflow-y: auto;
}

.result {
  text-align: center;
  width: 600px;
  margin: 0 auto;
  padding: 30px 0;

  h1 {
    padding: 15px 0;
  }
}
</style>
