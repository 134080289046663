<template>
  <div>
    <Form ref="form" class="editForm twoPartsEditForm" :model="form" :rules="ruleValidate" inline :label-width="130" :style="{maxHeight:'calc(100vh - 200px)'}" style="overflow-y: auto">
      <h1 class="h1Title">智能安全帽</h1>
      <FormItem label="nsnbbAccessToken" prop="nsnbbAccessToken" style="width: 100%">
        <Input v-model="form.nsnbbAccessToken" type="textarea" show-word-limit style="width: 100%" :autosize="{minRows: 4,maxRows: 6}" placeholder="nsnbbAccessToken"></Input>
      </FormItem>
      <FormItem label="nsnbbUrl" prop="nsnbbUrl">
        <Input v-model="form.nsnbbUrl" placeholder="nsnbbUrl"></Input>
      </FormItem>
      <FormItem label="tctrAppId" prop="tctrAppId">
        <Input v-model="form.tctrAppId" placeholder="tctrAppId"></Input>
      </FormItem>
      <FormItem label="tctrSecretKey" prop="tctrSecretKey" style="width: 100%">
        <Input v-model="form.tctrSecretKey" type="textarea" show-word-limit style="width: 100%" :autosize="{minRows: 2,maxRows: 3}" placeholder="tctrSecretKey"></Input>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <!-- 根据id值来判断是新增还是编辑,传入接口名调用写在mixins中的方法(会通过refs自动获取到当前form中数据) -->
      <Button type="primary" @click="ok('/systemConfig/SetDeviceConfig')">确定并保存</Button>
    </div>
  </div>
</template>

<script>

import {getSystemConfig,} from "@/api/base";
import edit from "@/mixins/edit";

export default {
  name: 'set',
  mixins:[edit],
  data() {
    return {
      ruleValidate: {},
      form: {
        nsnbbAccessToken: "",
        nsnbbUrl: "",
        tctrAppId: "",
        tctrSecretKey: "",
      },
    }
  },
  mounted() {
    this.getInfo()
  },
  methods: {
    getInfo() {
      getSystemConfig().then((res) => {
        if (res.code === 200) {
          this.form = res.data
        }
      })
    },
  }
}
</script>
<style lang="less" scoped>

</style>