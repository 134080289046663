<template>
  <div class="home">
    <ys-page-list
        ref="table"
        keyLabel="category"
        keyID="46676E79596B11EC9C5C00163E15C775"
        showContextMenu
        :multiBtn="multiBtn"
        :params="params"
        :multiColumn="true"
        action="/globalDict/List"
        :tableHeight="tableHeight"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="关键词" clearable style="width: 150px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template slot="btn">
        <ButtonGroup>
          <!--          <Button type="primary" @click="add">新增数据项</Button>-->
        </ButtonGroup>
      </template>
    </ys-page-list>
    <ys-modal v-model="modShow" :width="600" title="新增/修改数据项">
      <Edit :midData="tableSelectItem.length===1?tableSelectItem[0].dictList:[]" @on-ok="getList"></Edit>
    </ys-modal>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'
import Edit from "@/views/base/baseData/edit.vue";

export default {
  name: 'projects',
  mixins: [pageList],
  components: {Edit},
  data() {
    return {
      params: {
        category: '',
        keyword: '',
      },
      putInFlag: false,
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 50, align: 'center'},
        {width: 130, title: '类型名称', key: 'category', align: 'left',},
        {
          minWidth: 130, title: '内容', key: 'name', align: 'left', render: (h, params) => {
            const {dictList} = params.row;
            return h('ul', {
              attrs: {
                class: 'dictList'
              }
            }, [
              dictList.map(item => {
                return h('Tag', {props: {color: item.color||'default'}}, `${item.value}：${item.name}；`)
              })
            ]);
          }
        },
      ]
    }
  },
  computed: {
    multiBtn() {
      return [
        {
          children: [
            {
              click: () => this.modShow = true,
              title: '编辑',
              icon: 'md-create',
              disabled: this.tableSelectItem.length !== 1
            },
          ]
        },
      ]
    },
  },
  mounted() {
    this.getList();
  },
  methods: {}
}
</script>
<style lang="less" scoped>
.dictList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;

  li {
    list-style: none;
    padding-right: 10px;
  }
}
</style>
