<template>
  <div>
    <Alert>
      请按照数据模板的格式准备要导入的数据。
      <template slot="desc">
        <p>1、模板中的表头名称不可更改，表头行不能删除</p>
        <p style="color: crimson">2、其中红色背景为必填项，必须保留</p>
        <p>3、点击此处<a href="javascript:;" @click="downFile">下载模板</a></p>
      </template>
    </Alert>
    <h1 class="h1Title">上传文件</h1>
    <ys-upload
        :defaultList="defaultList"
        :headers="{'token':token,'orgId':userInfo.org_id}"
        @on-success="success"
        :limit="1"
        :action="'/web/policy/ValidateImportData'">
      <Button type="primary" long>上传模板</Button>
    </ys-upload>
  </div>
</template>
<script>
import edit from '@/mixins/edit';

export default {
  name: 'policyPutIn',
  mixins: [edit],
  data() {
    return {
      defaultList: []
    }
  },
  methods: {
    success(file, res) {
      if (res.code === 200) {
        this.$emit('on-ok', res.data)
        this.close();
      } else {
        this.defaultList = [];
        this.$Modal.error({
          title: '操作失败',
          content: res.msg
        })
      }
    },
    downFile() {
      window.location.href = "https://youshi-common.oss-cn-hangzhou.aliyuncs.com/model/%E4%BF%9D%E5%8D%95%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF_v1.xlsx"
    }
  }
}
</script>
