<template>
  <div>
    <ys-page-list
        ref="table"
        method="$get"
        action="/filterItem/List"
        :params="params"
        :tableHeight="tableHeight"
        :multiBtn="multiBtn"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns">
      <template slot="search"></template>
      <template slot="btn">
        <ButtonGroup>
          <Button type="primary" @click="add">新增筛选项</Button>
        </ButtonGroup>
      </template>
    </ys-page-list>
    <ys-modal v-model="modShow" :width="800" title="新增/修改筛选项">
      <Edit :mid="detailID" :midData="tableSelectItem" :parentID="keyId" @on-ok="getList"></Edit>
    </ys-modal>
  </div>

</template>

<script>
import pageList from '@/mixins/pageList.js'

export default {
  name: 'projects',
  mixins: [pageList],
  props: {keyId: {type: String, default: ''}},
  components: {
    Edit: () => import('./edit')
  },
  data() {
    return {
      params: {
        listId: '',
        keyword: '',
      },
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        { title: '序号', width: 60, key: 'order',  align: 'center'},
        {width: 100, title: 'KEY', key: 'key', align: 'center'},
        {width: 100, title: '项名称', key: 'label', align: 'center'},
        {minWidth: 150, title: '提示文字', key: 'placeholder', align: 'center'},
        {width: 90, title: '类型', key: 'origin_type', align: 'center'},
        {width: 100, title: '数据项值', key: 'origin_value', align: 'center'},
        {width: 80, title: '默认值', key: 'defaultValue', align: 'center'},
        {width: 60, title: '宽度', key: 'width', align: 'center'},
        {width: 70, title: '常显示', key: 'show', align: 'center', renderConfig: {type: 'baseData', parentName: '是否'}},
      ]
    }
  },
  computed: {
    multiBtn() {
      return [
        {
          children: [
            {
              click: () => this.modShow = true,
              title: '编辑',
              icon: 'md-create',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.delItem('/filterItem/Remove', {ids: this.tableSelectItem.map(item => item.id)}),
              iconColor: '#ed4014',
              title: '删除',
              icon: 'md-trash',
              disabled: this.tableSelectItem.length === 0
            },
          ]
        }
      ]
    },
  },
  mounted() {
    this.params.keyId = this.keyId;
    this.getList();
  },
  methods: {}
}
</script>
