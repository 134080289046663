<template>
  <div class="globalSet">
    <h1 class="h1Title">系统配置</h1>
    <Row>
      <Col span="4">
        <ul class="tabList">
          <li v-for="(item,index) in tabList" :class="{active:tab===index}" @click="changeTab(index)" :key="index">{{ item.name }}</li>
        </ul>
      </Col>
      <Col span="20">
        <div class="content">
          <template v-if="tab===0">
            <titleSet></titleSet>
          </template>
          <template v-if="tab===1">
            <appid></appid>
          </template>
          <template v-if="tab===2">
            <equipment></equipment>
          </template>
          <template v-if="tab===3">
            <setting></setting>
          </template>
        </div>
      </Col>
    </Row>
  </div>
</template>
<script>
import appid from './detail/appid'
import titleSet from './detail/titleSet'
import equipment from './detail/equipment'
import setting from './detail/setting'

export default {
  name: 'set',
  components: {setting, equipment, appid, titleSet},
  data() {
    return {
      tabList: [
        {name: '系统标题', desc: ''},
        {name: '秘钥管理', desc: ''},
        {name: '安全帽配置', desc: ''},
        {name: '系统配置', desc: ''},
      ],
      tab: 0,
    }
  },
  mounted() {
  },
  methods: {
    changeTab(index) {
      this.tab = index;
    }
  }
}
</script>
<style lang="less" scoped>
.globalSet {
  padding: 10px;
  overflow-y: auto;

  .tabList {
    height: calc(100vh - 170px);
    padding-right: 10px;

    li {
      list-style: none;
      height: 35px;
      line-height: 35px;
      padding: 0 20px;
      cursor: pointer;
    }
  }

  .content {
    padding: 0 20px;
  }
}
</style>