<template>
  <div>
    <Form ref="form" class="editForm" :model="form" :rules="ruleValidate" :label-width="80" :style="{maxHeight:'calc(100vh - 200px)'}" style="overflow-y: auto">
      <FormItem label="分发对象" prop="orgType">
        <Select :disabled="!orgId" v-model="form.orgType" @on-change="changeOrgId" transfer placeholder="分发对象">
          <Option v-for="item in [{name:'仅当前机构',value:0},{name:'全部',value:1}]" :value="item.value" :key="item.value">{{ item.name }}</Option>
        </Select>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok('/page-list/SetGlobal')">确定</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>
<script>
import edit from '@/mixins/edit'

export default {
  name: 'projectEdit',
  mixins: [edit],
  props: {
    orgId: {type: [Number,String], default: null}
  },
  data() {
    return {
      form: {
        id: '',
        orgType: 1,
        orgId: '',
      },
    }
  },
  mounted() {
    if (!this.orgId) {
      this.form.orgType = 1;
    }
    this.form.id = this.mid;
  },
  methods: {
    changeOrgId() {
      this.form.orgId = this.form.orgType === 0 ? this.orgId : '';
    },
  }
}
</script>

<style lang="less" scoped>

</style>


