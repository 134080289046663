import {get} from "ys-admin";

/**
 * 查询部门列表
 * @param {Object} params
 * @returns {Array}
 */
export const getDepartmentList = function (params) {
  return get('/manage/dep/List',params)
};

/**
 * 查询用户信息
 * @param {Object} params
 * @returns {Array}
 */
export const getUserInfo = function (params) {
  return get('/manage/user/GetInfo',params)
};
/**
 * 查询部门列表
 * @param {Object} params
 * @returns {Array}
 */
export const getDepartmentInfo = function (params) {
  return get('/manage/dep/GetInfo',params)
};

/**
 * 查询角色列表
 * @param {Object} params
 * @returns {Array}
 */
export const getRoleList = function (params) {
  return get('/permission/role/List',params)
};