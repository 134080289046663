<template>
  <ys-detail :icon="detailIcon" :loading="loading" :name="detail.checkName">
    <Tag size="medium" color="blue" slot="status">{{ detail.checkStatus|getBaseDataName('专项任务状态') }}</Tag>
    <template slot="brief">
      <ys-cell title="项目名称" :label="detail.projectName"/>
      <ys-cell title="打卡时间" :label="detail.signTime"/>
      <ys-cell title="打卡地址" :label="detail.address"/>
      <ys-cell title="打卡经纬度" :label="detail.signLat+','+detail.signLng"/>
    </template>
    <template slot="btn">

    </template>
    <template slot="main-left">
      <Tabs v-model="tab">
        <Tab-pane label="打卡位置" name="1">
          <div id="container" :style="{height:height-205+'px',margin:'10px 0'}"></div>
        </Tab-pane>
      </Tabs>
    </template>
    <template slot="main-right">
      <h1 class="h1Title">打卡图片</h1>
      <template v-for="fileItem in detail.fileList">
        <ys-image :width="400" :height="400" :src="config.baseURL + '/resource/GetFile/'+fileItem.id" :key="fileItem.id"></ys-image>
      </template>
    </template>
  </ys-detail>
</template>

<script>
import detail from '@/mixins/detail'
import {checkRecordInfo} from "@/api/expert";
import config from "@/config";

export default {
  name: "detail",
  mixins: [detail],
  components: {},
  data() {
    return {
      loading: false
    }
  },
  computed: {
    config() {
      return config
    }
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    initMap() {
      //初始化地图
      // eslint-disable-next-line
      this.map = new TMap.Map("container", {
        zoom: 16,//设置地图缩放级别
      });
    },
    getDetail() {
      this.loading = true;
      this.$all([
        checkRecordInfo({id: this.detailID}),
      ]).then((res) => {
        this.loading = false
        this.detail = res[0].data;
        this.setTagName(this.detail.iname);
        if (this.detail.signLat && this.detail.signLng) {
          this.$nextTick(() => {
            if (!this.map) this.initMap();
            // eslint-disable-next-line
            this.map.setCenter(new TMap.LatLng(this.detail.signLat, this.detail.signLng))
            // eslint-disable-next-line
            new TMap.InfoWindow({
              map: this.map,
              // eslint-disable-next-line
              position: new TMap.LatLng(this.detail.signLat, this.detail.signLng), // eslint-disable-next-line
              content: "<font color='#000000'>打卡地点：" + this.detail.address + "</font>" //设置信息框内容
            });
          })
        } else {
          this.map ? this.map.destroy() : '';
          this.map = null
        }
      }).catch(() => {
        this.loading = false
      })
    },
  }
}
</script>

<style scoped>

</style>