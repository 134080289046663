<template>
  <div class="organization">
    <div class="main">
      <div class="mainItem mainLeft">
        <Dept :orgId="orgId" @treeSelect="treeSelect" :tableHeight="tableHeight"></Dept>
      </div>
      <div class="mainItem mainRight">
        <member :depId="depId" :tableHeight="tableHeight" :orgId="orgId"></member>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'organization',
  props: {
    orgId: {type: [Number, String], default: ''},
    tableHeight: {type: [Number, String], default: null},
  },
  components: {
    'Dept': () => import('./dept'),
    'member': () => import('./member.vue'),
  },
  data() {
    return {
      depId: '',
    }
  },
  mounted() {
  },

  methods: {
    treeSelect(data) {
      this.depId = data.length === 1 ? data[0].id : '';
    }
  }

}
</script>
<style lang="less" scoped>
.main {
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  width: 100%;

  .mainItem {
    width: 0;
  }

  .mainRight {
    flex-grow: 1;

  }

  .mainLeft {
    height: 100%;
    padding: 0 10px 10px 10px;
    box-sizing: border-box;
    width: 350px;
  }
}


</style>

