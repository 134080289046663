<template>
  <div>
    <Form ref="form" class="editForm" :model="form" :rules="ruleValidate" :label-width="90" :style="{maxHeight:'calc(100vh - 200px)'}" style="overflow-y: auto">
      <FormItem label="项目名称" prop="projectName">
        <Input v-model="form.projectName" disabled placeholder="项目名称">
          <Button slot="append" @click="projectFlag=true">选择项目</Button>
        </Input>
      </FormItem>
      <FormItem label="参建类型" prop="type">
        <Select v-model="form.type" transfer placeholder="参建类型">
          <Option v-for="(item,index) in baseData['参建单位类型']" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="联系人" prop="contacts">
        <Input v-model="form.contacts" disabled placeholder="联系人"></Input>
      </FormItem>
      <FormItem label="联系电话" prop="phone">
        <Input v-model="form.phone" disabled placeholder="联系电话"></Input>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok('/org/LinkProject')">确定</Button>
      <Button @click="close">取消</Button>
    </div>
    <ys-modal v-model="projectFlag" :width="1200" title="选择项目">
      <projects-component :limit="1" :oType="4" @on-ok="getData"></projects-component>
    </ys-modal>
  </div>
</template>
<script>
import edit from '@/mixins/edit'
import {orgInfo} from "@/api/org";
import ProjectsComponent from "_c/projects";

export default {
  name: 'projectEdit',
  components: {ProjectsComponent},
  mixins: [edit],
  data() {
    return {
      projectFlag: false,
      ruleValidate: {
        iname: [
          {required: true, message: '请选择项目', trigger: 'blur'}
        ],
        phone: [
          {pattern: this.$reg.phoneOrTelReg.reg, message: this.$reg.phoneOrTelReg.tips, trigger: 'blur'}
        ],
      },
      form: {
        id: '',
        iname: '',
        projectId: '',
        oType: 4,
        type: 31,
        contacts: '',
        phone: '',
      },
    }
  },
  mounted() {
    if (this.mid) {
      this.getInfo()
    } else {
      this.form.id = this.parentID
    }
  },
  methods: {
    getInfo() {
      orgInfo({orgProjectId: this.mid}).then((res) => {
        Object.keys(this.form).forEach((item) => {
          this.form[item] = res.data[item] || null
        })
      })
    },
    getData(data) {
      this.form.projectName = data[0].iname;
      this.form.contacts = data[0].principalName;
      this.form.phone = data[0].principalPhone;
      this.form.id = this.parentID;
      this.form.projectId = data[0].id
    },
  }
}
</script>

