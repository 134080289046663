<template>
  <div :style="{height:height-180+'px'}" style="overflow-y: auto">
    <ys-cell-group :gutter="2">
      <ys-cell title="机构名称" :label="detail.iname"></ys-cell>
      <ys-cell title="上级机构名称" :label="detail.parentName"></ys-cell>
      <ys-cell title="英文缩写" :label="detail.code"></ys-cell>
      <ys-cell title="社会信用代码" :label="detail.societyCode"></ys-cell>
      <ys-cell title="八大行业" :label="industryTypeName"></ys-cell>
      <ys-cell title="国民经济分类编码" :label="economyName" block></ys-cell>
      <ys-cell title="企业状态" :label="detail.regStatus|getBaseDataName('企业状态')"></ys-cell>
      <ys-cell title="安全经营许可证" :label="detail.licenseNo"></ys-cell>
      <ys-cell title="许可证有效期开始时间" :label="detail.licenseInvalidDate|formatDate"></ys-cell>
      <ys-cell title="许可证有效期结束时间" :label="detail.licenseInvalidEndDate|formatDate"></ys-cell>
      <ys-cell title="从业人数" :label="detail.orgScale"></ys-cell>
      <ys-cell title="年营业额" :label="detail.businessVolume"></ys-cell>
      <ys-cell title="安全生产标化等级" :label="detail.standardLevel|getBaseDataName('安全生产标准化等级')"></ys-cell>
      <ys-cell title="行业领域编码" :label="detail.businessScope" v-if="detail.oType === 3"></ys-cell>
      <ys-cell title="组织类型" :label="detail.oType|getBaseDataName('组织类型')"></ys-cell>
      <ys-cell title="保险公司类型" :label="detail.type|getBaseDataName('保险公司类型')" v-if="detail.oType===1"></ys-cell>
      <ys-cell title="服务机构类型" :label="detail.type|getBaseDataName('服务机构类型')" v-if="detail.oType===3"></ys-cell>
      <ys-cell title="职能部门类型" :label="detail.type|getBaseDataName('职能部门类型')" v-if="detail.oType===2"></ys-cell>
      <ys-cell title="参建单位类型" :label="detail.type|getBaseDataName('参建单位类型')" v-if="detail.oType===4"></ys-cell>
      <ys-cell title="简称" :label="detail.shortName"></ys-cell>
      <ys-cell title="联系人" :label="detail.contacts"></ys-cell>
      <ys-cell title="联系人电话" :label="detail.phone"></ys-cell>
      <ys-cell title="安全员" :label="detail.contactHeader"></ys-cell>
      <ys-cell title="安全员电话" :label="detail.contactHeaderMobile"></ys-cell>
      <ys-cell title="应急值班电话" :label="detail.emergencyMobile"></ys-cell>
      <ys-cell title="办公地所在区" :label="(detail.countyCode || detail.cityCode || detail.provinceCode)|getRegionByCode"></ys-cell>
      <ys-cell title="办公地址" :label="detail.address"></ys-cell>
      <ys-cell title="经纬度" :label="detail.lng && detail.lat ?  detail.lng + ',' + detail.lat : ''"></ys-cell>
    </ys-cell-group>
    <ys-cell-group title="证件图片">
      <div v-viewer="{url: 'data-src'}">
        <ys-image 
          :width="115"
          :height="115"
          :src="'/web/resource/GetFile/' + src.id + '?size=100'"
          :data-src="'/web/resource/GetFile/' + src.id"
          v-for="src in detail.certFileList"
          :key="src.id"
        ></ys-image>
      </div>
    </ys-cell-group>
    <ys-cell-group title="安全生产许可证">
      <div v-viewer="{url: 'data-src'}">
        <ys-image 
          :width="115"
          :height="115"
          :src="'/web/resource/GetFile/' + src.id + '?size=100'"
          :data-src="'/web/resource/GetFile/' + src.id"
          v-for="src in detail.safeLicenseFileList"
          :key="src.id"
        ></ys-image>
      </div>
    </ys-cell-group>
    <div v-if="detail.uuid">
      <Divider plain>以下信息来自全国工商信息库</Divider>
      <ys-business-info :businessDetail="detail"></ys-business-info>
    </div>
  </div>
</template>

<script>
import baseInfoMixins from '@/mixins/baseInfo'
import {mapGetters} from 'vuex'
import {orgInfo} from "@/api/org";
import {getRecorderAryByDeep} from 'ys-admin'
export default {
  name: "baseInfo",
  mixins: [baseInfoMixins],
  data() {
    return {
      detail: {},
      industryTypeName: '',
      economyName: '',
    }
  },
  computed: {
    ...mapGetters(['height', 'baseData']),
    logo() {
      return this.detail.imageUrl ? this.detail.imageUrl.split('?')[0] : ''
    },
  },
  async mounted() {
    await this.getDetail()
    this.getEconomyList()
  },
  methods: {
    getDetail() {
      this.loading = true;
      this.detail = {}
      orgInfo({id: this.mid, noPending: true}).then(res => {
        this.loading = false;
        if (res.code === 200) {
          this.detail = res.data;
          this.industryTypeName = getRecorderAryByDeep({
            targetData: this.baseData['行业类型'],
            targetValue: this.detail.subIndustryType || this.detail.industryType,
            label: {valueKey: 'value', childKey: 'children', resultKey: 'name'}
          }).join(' / ')
        }
      })
    },
    // 国民经济分类编码id
    getEconomyList() {
      this.$get('/economic/ListTree', {noPending: true}).then(res => {
        this.economyName = getRecorderAryByDeep({
          targetData: res?.data || [],
          targetValue: this.detail.economyId,
          label: {valueKey: 'id', childKey: 'children', resultKey: 'name'}
        }).join(' / ')
      })
    },
  }
}
</script>

<style scoped>

</style>