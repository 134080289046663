<template>
  <div>
    <Tree ref="menuTree" show-checkbox :data="treeData" check-strictly check-directly :style="{maxHeight:'calc(100vh - 200px)'}" style="overflow-y: auto;overflow-x: hidden"></Tree>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>

<script>
import {getMenuList} from "@/api/base";
import edit from "@/mixins/edit";

export default {
  name: "menuTree",
  mixins: [edit],
  props: {
    oType: {type: Number, default: 1},
    limit: {type: Number, default: 1}
  },
  data() {
    return {
      params: {
        o_type: 1,
        keyword: '',
      },
      treeData: [],
    }
  },
  mounted() {
    this.params.o_type = this.oType;
    this.getList();
  },
  methods: {
    getList() {
      getMenuList(this.params).then(res => {
        this.treeData = this.$listToTree(res.data, {parentKey: 'parentId', topLevelVal: 0, addProperties: {expand: true}});
      });
    },
    ok() {
      const checkedNodes = this.$refs.menuTree.getCheckedNodes();
      if (checkedNodes.length !== this.limit) {
        this.$Notice.warning({
          title: "操作提示！",
          desc: "最多可选择 " + this.limit + ' 条数据！',
        });
        return false;
      }
      this.$emit('on-ok', checkedNodes);
      this.close()
    }
  }
}
</script>

<style scoped>

</style>