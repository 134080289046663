<template>
  <div>
    <ys-page-list
        ref="table"
        :params="params"
        action="/permission/role/UserList"
        :tableHeight="tableHeight"
        @on-selection-change="checkItem"
        :multiBtn="multiBtn"
        :multiColumn="false"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="关键词" clearable style="width: 150px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template slot="btn">
        <ButtonGroup>
<!--          <Button type="primary" @click="add">新增角色账户</Button>-->
        </ButtonGroup>
      </template>
    </ys-page-list>
    <ys-modal v-model="modShow" :width="800" title="新增/修改角色账户">
      <EditCorp :mid="tableSelectItem.length===1?tableSelectItem[0].orgProjectId:''" :parentID="mid" @on-ok="getList"></EditCorp>
    </ys-modal>
  </div>
</template>

<script>
import pageListMixins from '@/mixins/pageList';

export default {
  name: 'corp',
  mixins: [pageListMixins],
  components: {
    'EditCorp': () => import('./editCorp.vue')
  },
  computed: {
    multiBtn() {
      return [
        // {
        //   children: [
        //     {
        //       click: () => this.delItem('/projects/org/Remove', {orgProjectId: this.tableSelectItem[0].orgProjectId}),
        //       iconColor: '#ed4014',
        //       title: '删除',
        //       icon: 'md-trash',
        //       disabled: this.tableSelectItem.length !== 1
        //     },
        //   ]
        // }
      ]
    },
  },
  data() {
    return {
      params: {
        keyword: '',
        roleId: ''
      },
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 80, align: 'center'},
        {width: 130, title: '姓名', key: 'realname', align: 'left'},
        {width: 180, title: '账号', key: 'username', align: 'left'},
        {minWidth: 130, title: '联系电话', key: 'phone', align: 'left'},
        {minWidth: 180, title: '所属机构', key: 'orgName', align: 'left'},
        {width: 160, title: '创建时间', key: 'createdAt', align: 'left'},
      ]
    }
  },
  mounted() {
    this.params.roleId = this.mid;
    this.getList()
  }
}
</script>
