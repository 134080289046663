<template>
  <!-- 新增/编辑弹出框 -->
  <div>
    <div style="margin: 0 auto;width: 0">
      <ysUpload
          @on-success="bLFile"
          :limit="1"
          :headers="{token:token}"
          ref="upload"
          :format="['jpg','jpeg','png','gif']"
          :defaultList.sync="defaultList"
          action="/web/resource/UploadFile">
      </ysUpload>
    </div>
    <Form ref="form" class="editForm twoPartsEditForm" :model="form" :rules="ruleValidate" inline :label-width="120" :style="{maxHeight:'calc(100vh - 200px)'}" style="overflow-y: auto">
      <FormItem label="姓名" prop="iname">
        <Input v-model="form.iname" placeholder="姓名"></Input>
      </FormItem>
      <FormItem label="性别" prop="gender">
        <Select v-model="form.gender" placeholder="性别">
          <Option v-for="(item,index) in baseData['性别']" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
<!--      <FormItem label="服务类型" prop="dynamicCheckTypeList">-->
<!--        <Select v-model="form.dynamicCheckTypeList" placeholder="服务类型" multiple class="selectMultiple">-->
<!--          <Option v-for="(item) in baseData['随机任务组类型']" :value="item.value" :key="item.value">{{ item.name }}</Option>-->
<!--        </Select>-->
<!--      </FormItem>-->
      <FormItem label="专家类型" prop="type">
        <Select v-model="form.type" placeholder="专家类型">
          <Option v-for="(item) in baseData['专家类型']" :value="item.value" :key="item.value">{{ item.name }}</Option>
        </Select>
      </FormItem>
<!--      <FormItem label="专家等级" prop="level">-->
<!--        <Select v-model="form.level" placeholder="专家等级">-->
<!--          <Option v-for="(item) in baseData['专家等级']" :value="item.value" :key="item.value">{{ item.name }}</Option>-->
<!--        </Select>-->
<!--      </FormItem>-->
      <FormItem label="联系电话" prop="phone">
        <Tooltip transfer content="该号码较为重要，会用于接收短信通知，请正确填写！" max-width="260">
          <Input v-model="form.phone" autocomplete="off" placeholder="联系电话" style="width: 260px;"></Input>
        </Tooltip>
      </FormItem>
      <FormItem label="身份证号码" prop="identityCode">
        <Input v-model="form.identityCode" placeholder="身份证号码" @on-change="IDCardChange"></Input>
      </FormItem>
      <FormItem label="出生日期" prop="birthday">
        <ys-date-picker v-model="form.birthday" style="width: 100%;" placeholder="出生日期"></ys-date-picker>
      </FormItem>
      <FormItem label="职称" prop="position">
        <Input v-model="form.position" placeholder="职称"></Input>
      </FormItem>
      <FormItem label="学历" prop="education">
        <Select v-model="form.education" placeholder="文化程度">
          <Option v-for="(item) in baseData['文化程度']" :value="item.name" :key="item.value">{{ item.name }}
          </Option>
        </Select>
      </FormItem>
      <FormItem label="专业方向" prop="territory">
        <Input v-model="form.territory" placeholder="专业方向"></Input>
      </FormItem>
      <FormItem label="开始工作日期" prop="workStart">
        <ys-date-picker v-model="form.workStart" style="width: 100%;" placeholder="开始工作日期"></ys-date-picker>
      </FormItem>
      <FormItem label="所在区域" prop="areaId">
        <ys-region changeOnSelect v-model="form.areaId" @on-change="getArea" placeholder="所在区域"/>
      </FormItem>
      <FormItem label="工作经历" prop="experience" style="width: 100%">
        <Input v-model="form.experience" type="textarea" maxlength="128" show-word-limit style="width: 100%" :autosize="{minRows: 3,maxRows: 5}" placeholder="工作经历"></Input>
      </FormItem>
      <FormItem label="备注" prop="remarks" style="width: 100%">
        <Input v-model="form.remarks" type="textarea" maxlength="128" show-word-limit style="width: 100%" :autosize="{minRows: 3,maxRows: 5}" placeholder="备注"></Input>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <!-- 根据id值来判断是新增还是编辑,传入接口名调用写在mixins中的方法(会通过refs自动获取到当前form中数据) -->
      <Button type="primary" @click="save">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>
<script>
import edit from '@/mixins/edit'
import {expertInfo} from "@/api/expert";

export default {
  name: 'projectEdit',
  mixins: [edit],
  data() {
    return {
      ruleValidate: {
        iname: [
          {required: true, message: '请填姓名', trigger: 'blur'}
        ],
        phone: [
          {required: true, pattern: this.$reg.phoneOrTelReg.reg, message: this.$reg.phoneOrTelReg.tips, trigger: 'blur'}
        ],
      },
      defaultList: [], // 上传文件列表
      form: {
        id: '',
        picture: '',
        pictureId: '',
        iname: '',
        dynamicCheckTypeList: '',
        categoryList: [],
        type: 10,
        gender: 2,
        phone: '',
        identityCode: '',
        level: '',
        birthday: '',
        position: '',
        education: '',
        territory: '',
        workStart: '',
        areaId: '',
        experience: '',
        remarks: '',
        provinceCode: '',
        cityCode: '',
        countyCode: ''
      },
    }
  },
  mounted() {
    // 请求接口数据,根据携带过来的mid判断是弹出新增窗口还是编辑窗口
    if (this.mid) {
      expertInfo({id: this.mid}).then((res) => {
        Object.keys(this.form).forEach((item) => {
          this.form[item] = res.data[item] || null
        })
        this.form.categoryList = res.data.category ? res.data.category.split(',') : [];
        this.form.dynamicCheckTypeList = (res.data.dynamicCheckType ? res.data.dynamicCheckType.split(',') : []).map(item=>parseInt(item));
        this.form.areaId = `${this.form.areaId || ''}`
        if (this.form.pictureId) this.defaultList.push({
          id: this.form.pictureId,
          fileName: '',
          createdAt: '',
        })
      })
    }
  },
  methods: {
    // 上传图片回调，
    bLFile(data) {
      this.form.picture = this.form.pictureId = data.length >= 1 ? data[0].id : '';
    },
    // 地址选择回调
    getArea(data) {
      this.form.provinceCode = data[0] ? data[0].code : '';
      this.form.cityCode = data[1] ? data[1].code : '';
      this.form.countyCode = data[2] ? data[2].code : '';
      if(data.length === 0) this.form.areaId =  '';
    },
    IDCardChange() {
      let str = this.form.identityCode
      if (str.length >= 14) {
        this.form.birthday = `${str.slice(6, 10)}-${str.slice(10, 12)}-${str.slice(12, 14)} 00:00:00`
      }
      if (str.length >= 17) {
        this.form.gender = str.slice(16, 17) % 2 == 0 ? 1 : 2
      }
    },
    save() {
      this.ok('/expert/AddOrEdit')
    },
  }
}
</script>

<style lang="less" scoped>
</style>
