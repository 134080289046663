<template>
  <div class="home">
    <ys-page-list
        ref="table"
        action="/dynamicCheck/expertConfig/ListByExpert"
        :params="params"
        :multiBtn="multiBtn"
        :footer="false"
        :tableHeight="resultHeight"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns">
      <template slot="btn">
        <ButtonGroup>
          <Button type="primary" @click="add">新增</Button>
        </ButtonGroup>
      </template>
    </ys-page-list>
    <ys-modal v-model="modShow" :width="500" :title="(tableSelectItem.length===0?'新增':'修改')+'专家'">
      <experts-service-edit @on-ok="getList" :parentID="mid" :mid="detailID"/>
    </ys-modal>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'
import ExpertsServiceEdit from "@/views/experts/detail/service/edit.vue";

export default {
  name: 'expertsService',
  components: {ExpertsServiceEdit},
  mixins: [pageList],
  data() {
    return {
      params: {
        expertId: '',
      },
      userShow: false,
      tabNum: '',
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 70, align: 'center'},
        {width: 130, title: '服务类型', key: 'type', align: 'center', renderConfig: {type: 'baseData', parentName: '随机任务组类型'}},
        {minWidth: 250, title: '服务项目', key: 'categoryName', align: 'left'},
        {width: 100, title: '等级', key: 'level', align: 'center', renderConfig: {type: 'baseData', parentName: '专家等级'}},
        {width: 100, title: '启停状态', key: 'status', align: 'center', renderConfig: {type: 'baseData', parentName: '启停状态'}},
        {width: 180, title: '操作时间', key: 'createdAt', align: 'left'},
        {width: 180, title: '操作人', key: 'createdUser', align: 'left'},
      ]
    }
  },

  computed: {
    multiBtn() {
      return [
        {
          hiddenBtnIcon: true,
          children: [
            {
              click: () => this.modShow = true,
              title: '修改',
              icon: 'md-create',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.delItem('/dynamicCheck/expertConfig/RemoveById', {id: this.tableSelectItem[0].id}),
              iconColor: '#ed4014',
              title: '删除',
              icon: 'md-trash',
              disabled: this.tableSelectItem.length !== 1
            },
          ]
        }

      ]
    }
  },

  mounted() {
    this.params.expertId = this.mid;
    this.getList();
  },

  methods: {}
}
</script>
