<template>
  <!-- 新增/编辑弹出框 -->
  <div>
    <Form ref="form" class="editForm twoPartsEditForm" :model="form" :rules="ruleValidate" inline :label-width="130">
      <FormItem label="保单来源" prop="source" style="width: 25%">
        <Select v-model="form.source" disabled>
          <Option v-for="(item,index) in baseData['保单来源']" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="安责险类型" prop="industryType" :label-width="90" style="width: 25%">
        <Select v-model="form.industryType" @on-change="setIndustryType">
          <Option v-for="(item,index) in baseData['行业类型']" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="保单号" prop="number">
        <Input v-model="form.number" id="FatNumber" @on-change="setNumber" :placeholder="form.subType===102?'自动生成':'保单号'">
          <Tooltip :max-width="500" :content="'注意：自动剔除前后空格'" slot="suffix" placement="top">
            <Icon type="md-alert"/>
          </Tooltip>
        </Input>
      </FormItem>
      <FormItem label="保单开始时间" prop="startAt">
        <ys-date-picker v-model="form.startAt" @on-change="calcServiceCount" style="width: 100%;" placeholder="保单开始时间"></ys-date-picker>
      </FormItem>
      <FormItem label="保单结束时间" prop="endAt">
        <ys-date-picker v-model="form.endAt" @on-change="calcServiceCount" style="width: 100%;" placeholder="保单结束时间"></ys-date-picker>
      </FormItem>
      <FormItem label="投保单位" prop="enterpriseName" class="ys-business">
        <ys-business :source="0" v-model="keyword" @on-clear="clearBusiness" @on-select="selectOrg"></ys-business>
      </FormItem>
      <FormItem label="投保单位信用代码" prop="enterpriseCode">
        <Input v-model="form.enterpriseCode" disabled placeholder="社会信用代码"></Input>
      </FormItem>
      <FormItem label="服务地区" prop="area">
        <ys-region changeOnSelect v-model="form.area" @on-change="getArea" placeholder="一般是所需服务项目/投保机构所在区域"/>
        <!--        <Input v-model="form.address" placeholder="项目地址" style="width: 70%"></Input>-->
      </FormItem>
<!--       通用安责险 -->
      <div v-if="form.subType===101">
        <FormItem label="保费总金额(元)" prop="premiumCost">
          <Tooltip :disabled="form.premiumCost < 1000000" content="保费过高请确认保费是否正确！" placement="top">
            <InputNumber v-model="form.premiumCost" placeholder="保费金额" style="width: 350px" @on-change="premiumCostChange"></InputNumber>
          </Tooltip>
        </FormItem>
        <FormItem label="项目总保额(元)" prop="insureAmount">
          <InputNumber v-model="form.insureAmount" placeholder="项目保额" style="width:100%"></InputNumber>
        </FormItem>
        <FormItem label="投保单位联系人" prop="enterpriseContact" style="width: 50%">
          <Input v-model="form.enterpriseContact" placeholder="联系人" style="width: 35%;"></Input>
          <Input v-model="form.enterprisePhone" placeholder="联系方式" style="width: 65%;"></Input>
        </FormItem>
        <FormItem label="保险业务经理" prop="companyContact" style="width: 50%">
          <Input v-model="form.companyContact" placeholder="保险业务经理" style="width: 35%;"></Input>
          <Input v-model="form.companyPhone" placeholder="联系方式" style="width: 65%;"></Input>
        </FormItem>
      </div>
      <FormItem label="投保时间" prop="insureAt" style="width: 50%">
        <ys-date-picker v-model="form.insureAt" @on-change="insureAtChange" style="width: 100%;" placeholder="投保时间"></ys-date-picker>
      </FormItem>
      <FormItem label="投保单号" prop="insureNum" style="width: 50%">
        <Input v-model="form.insureNum" :placeholder="form.subType===102?'自动生成':'投保单号'"></Input>
      </FormItem>
      <FormItem label="承保机构" prop="companyName" :rules="{required: true, message: '请选择承保机构', trigger: 'change'}">
        <Input v-model="form.companyName" readonly placeholder="承保机构">
          <Button slot="append" :disabled="!!form.id||renewalFlag" @click="companyFlag=true">选择</Button>
        </Input>
      </FormItem>
      <FormItem label="服务状态" prop="isServe">
        <Select v-model="form.isServe" transfer placeholder="保单服务状态">
          <Option v-for="(item,index) in baseData['保单服务状态']" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="服务次数" prop="serviceType">
        <Select v-model="form.serviceType" @on-change="calcServiceCount" style="width: 50%">
          <Option :value="1">每一月一次</Option>
          <Option :value="2">每二月一次</Option>
          <Option :value="3">每季度一次</Option>
          <Option :value="6">每半年一次</Option>
        </Select>
        <Input v-model="form.serverCount" placeholder="服务次数" style="width: 50%">
          <Tooltip :max-width="280" :content="'一个月按 '+monthDays+' 天计算，保留2位小数，如365/30.42=11.9986850756≈12.00。\n'+betweenDays" slot="suffix" placement="top">
            <Icon type="md-alert"/>
          </Tooltip>
        </Input>
      </FormItem>
      <FormItem label="保单状态" prop="activeStatus">
        <Select v-model="form.activeStatus" disabled placeholder="保单状态">
          <Option v-for="(item,index) in baseData['保单生效状态']" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="报告需审核" prop="isCheckReport">
        <Select v-model="form.isCheckReport" placeholder="报告需审核">
          <Option v-for="(item,index) in baseData['是否']" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <!-- 通用安责险（共保体单一模式） -->
      <div v-if="form.subType===101&&form.isCoIns===1">
        <FormItem label="适用份额配置" prop="coInsurerId">
          <Select v-model="form.coInsurerId" @on-change="getCoInsurerInfo()" placeholder="适用份额配置" not-found-text="请先选择承保机构">
            <rate slot="prefix" :extType="form.extType" :member="form.member" :premiumCost="form.premiumCost" :ownerId="form.ownerId"></rate>
            <Option v-for="(item,index) in coInsurerList" :value="item.id" :key="index">{{ item.iname }}</Option>
          </Select>
        </FormItem>
        <FormItem label="引流方" prop="ownerId" v-if="form.extType!==1">
          <Select v-model="form.ownerId" placeholder="引流方" not-found-text="请先选择份额配置名称">
            <Option v-for="(subItem,index) in form.member" :value="subItem.insId" :key="index">{{ subItem.iname }}</Option>
          </Select>
        </FormItem>
      </div>
      <FormItem label="无需服务的理由" prop="reason" v-if="form.isServe===2">
        <Select v-model="form.reason" placeholder="无需服务的理由">
          <Option v-for="(item,index) in baseData['保单无服务理由']" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="备注" prop="remarks" style="width: 100%">
        <Input v-model="form.remarks" type="textarea" maxlength="128" show-word-limit style="width: 100%" :autosize="{minRows: 2,maxRows: 5}" placeholder="备注"></Input>
      </FormItem>
      <!-- 组合安责险 -->
      <div v-if="form.subType===102">
        <h1 class="h1Title">子保单配置</h1>
        <ul class="subList">
          <li v-for="(item,index) in form.subPolicies" :key="index">
            <div class="index">{{ index + 1 }}</div>
            <div class="content">
              <FormItem label="保单类型" :prop="'subPolicies.' + index + '.type'">
                <Select v-model="item.type" disabled placeholder="保单类型">
                  <Option v-for="(subItem,subIndex) in subTypeList" :value="subItem.value" :key="subIndex">{{ subItem.name }}</Option>
                </Select>
              </FormItem>
              <FormItem label="保单号" :prop="'subPolicies.' + index + '.number'" :rules="{required: true, message: '请填写保单号', trigger: 'blur'}">
                <Input v-model="item.number" id="SubNumber" @on-change="setNumber" placeholder="保单号">
                  <Tooltip :max-width="500" :content="'注意：自动剔除前后空格'" slot="suffix" placement="top">
                    <Icon type="md-alert"/>
                  </Tooltip>
                </Input>
              </FormItem>
              <FormItem label="保费金额(元)" :prop="'subPolicies.' + index + '.premiumCost'" :rules="{required: true, trigger: 'blur', validator: (rule, value, callback) => {amountVerification(rule, value, callback)}}">
                <Tooltip :disabled="item.premiumCost * 1 < 1000000" content="保费过高请确认保费是否正确！" placement="top">
                  <InputNumber v-model="item.premiumCost" placeholder="保费金额" style="width: 310px" @on-change="premiumCostChange"></InputNumber>
                </Tooltip>
              </FormItem>
              <FormItem label="项目保额(元)" :prop="'subPolicies.' + index + '.insureAmount'" :rules="{required: true, trigger: 'blur', validator: (rule, value, callback) => {amountVerification(rule, value, callback)}}">
                <InputNumber v-model="item.insureAmount" placeholder="项目保额" style="width:100%"></InputNumber>
              </FormItem>
              <FormItem label="投保单位联系人" :prop="'subPolicies.' + index + '.enterpriseContact'" style="width: 50%">
                <Input v-model="item.enterpriseContact" placeholder="联系人" style="width: 40%;"></Input>
                <Input v-model="item.enterprisePhone" placeholder="联系方式" style="width: 60%;"></Input>
              </FormItem>
              <FormItem label="保险业务经理" :prop="'subPolicies.' + index + '.companyContact'" style="width: 50%">
                <Input v-model="item.companyContact" placeholder="保险业务经理" style="width: 40%;"></Input>
                <Input v-model="item.companyPhone" placeholder="联系方式" style="width: 60%;"></Input>
              </FormItem>
              <FormItem label="适用份额配置" :prop="'subPolicies.' + index + '.coInsurerId'" :rules="{required: true,type:'number', message: '请选择适用份额配置', trigger: 'change'}">
                <Select v-model="item.coInsurerId" @on-change="getCoInsurerInfo(index)" placeholder="适用份额配置" not-found-text="请先选择承保机构">
                  <rate slot="prefix" :member="item.member" :extType="item.extType" :premiumCost="item.premiumCost" :ownerId="item.ownerId"></rate>
                  <Option v-for="(subItem,subIndex) in coInsurerList" :value="subItem.id" :key="subIndex">{{ subItem.iname }}</Option>
                </Select>
              </FormItem>
              <FormItem label="引流方" :prop="'subPolicies.' + index + '.ownerId'" v-if="item.extType!==1" :rules="{required: true,type:'number', message: '请选择引流方', trigger: 'change',validator:validator}">
                <Select v-model="item.ownerId" placeholder="引流方" not-found-text="请先选择份额配置名称">
                  <Option v-for="(subItem,index) in item.member" :value="subItem.insId" :key="index">{{ subItem.iname }}</Option>
                </Select>
              </FormItem>
              <FormItem label="投保单号" :prop="'subPolicies.' + index + '.insureNum'" style="width: 50%">
                <Input v-model="item.insureNum" placeholder="投保单号" @on-change="setInsureNum"></Input>
              </FormItem>
              <FormItem label="投保时间" :prop="'subPolicies.' + index + '.insureAt'" style="width: 50%" :rules="{required: true, message: '请选择投保时间', trigger: 'blur'}">
                <ys-date-picker :disabled="true" v-model="item.insureAt" style="width: 100%;" placeholder="同主保单"></ys-date-picker>
              </FormItem>
            </div>
          </li>
        </ul>
      </div>
      <FormItem label="服务方案附件" prop="planFileList" style="width: 100%;text-align: center">
        <ys-upload
            @on-success="bLFile1"
            :headers="{token:token}"
            ref="upload"
            type="halfList"
            :defaultList.sync="planFileList"
            action="/web/resource/UploadFile">
        </ys-upload>
      </FormItem>
      <FormItem label="合同附件" prop="contractFileList" style="width: 100%;text-align: center">
        <ys-upload
            @on-success="bLFile2"
            :headers="{token:token}"
            ref="upload"
            type="halfList"
            :defaultList.sync="contractFileList"
            action="/web/resource/UploadFile">
        </ys-upload>
      </FormItem>
    </Form>
    <ys-modal v-model="companyFlag" :width="1000" title="承保机构">
      <OrgComponent :limit="1" :oType="1" :types="form.subType===102 || (form.subType===101 && form.isCoIns===1) ? [6] : [1, 2, 3, 4, 5]" @on-ok="getOrgData"></OrgComponent>
    </ys-modal>
  </div>
</template>
<script>
import edit from '@/mixins/edit'
import {coInsurerInfo, getCoInsurer} from "@/api/org";
import OrgComponent from "_c/org";
import {math, uuid, getDaysBetween, formatDate, strReword} from "ys-admin";
import Rate from "@/views/policy/edit/rate";
import {generateSocialCreditCode} from "ys-admin";
import {getProjectOrgList} from "@/api/projects";

const validator = (rule, value, callback) => {
  if (!value) {
    callback(new Error('请选择适引流方!'));
  } else {
    callback();
  }
}
export default {
  name: 'projectEdit',
  mixins: [edit],
  props: {
    formData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    industryType: {type: Number, default: 1}
  },
  components: {
    Rate,
    OrgComponent
  },
  data() {
    return {
      ruleValidate: {
        area: [
          {required: true, message: '请选择项目/投保机构所在区域', trigger: 'change,blur'}
        ],
        number: [
          {required: true, message: '请填保单号', trigger: 'blur'}
        ],
        projectName: [
          {required: true, message: '请选择项目', trigger: 'change'}
        ],
        ownerId: [
          {required: true, type: 'number', message: '请选择适引流方', trigger: 'change', validator: validator}
        ],
        enterpriseName: [
          {required: true, message: '请选择投保机构', trigger: 'change,blur'}
        ],
        startAt: [
          {required: true, message: '请选择保单开始时间', trigger: 'blur',}
        ],
        endAt: [
          {
            required: true, trigger: 'blur',
            validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('请选择保单结束时间!'));
              } else if (new Date(value).getTime() < new Date(this.form.startAt).getTime()) {
                callback(new Error('保单结束时间不能小于保单开始时间!'));
              } else {
                callback();
              }
            }
          }
        ],
        premiumCost: [
          {
            required: true, trigger: 'blur', validator: (rule, value, callback) => {
              this.amountVerification(rule, value, callback)
            }
          }
        ],
        insureAmount: [
          {
            required: true, trigger: 'blur', validator: (rule, value, callback) => {
              this.amountVerification(rule, value, callback)
            }
          }
        ],
        insureAt: [
          {required: true, message: '请选择投保时间', trigger: 'blur'}
        ]
      },
      planFileList: [],
      contractFileList: [],
      projectFlag: false,
      companyFlag: false,
      reasonFlag: false,
      monthDays: 30.42,
      keyword: '',
      form: {
        id: '',
        oldPolicyId: '',
        isCheckReport: 0,
        source: 1,
        subType: 101,
        iname: '',
        projectId: '',
        companyId: '',
        industryType: 1,
        serviceType: 1,
        type: 1,
        activeStatus: 20,
        status: 1, // 该字段强制写为1
        number: '',
        projectName: '',
        startAt: '',
        endAt: '',
        companyName: '',
        enterpriseName: '',
        enterpriseCode: '',
        enterpriseId: '',
        premiumCost: 0,
        insureAmount: 0,
        coInsurerId: '',
        ownerId: 0,
        enterpriseContact: '',
        enterprisePhone: '',
        companyContact: '',
        companyPhone: '',
        isCoIns: 0,
        isServe: 1,
        "extRate": 0,
        serverCount: 5,
        remarks: '',
        reason: '',
        member: [],
        subPolicies: [],
        contractFileList: [],
        planFileList: [],
        insureAt: '', // 投保时间
        insureNum: '', // 投保单号
        provinceCode: '',
        cityCode: '',
        area: '',
        countyCode: '',
      },
      coInsurerId: '',
      coInsurerList: [],
    }
  },
  computed: {
    validator() {
      return validator;
    },
    betweenDays() {
      const {startAt, endAt} = this.form
      return startAt && endAt ? formatDate(startAt) + '至' + formatDate(endAt) + '共有' + getDaysBetween(startAt, endAt) + '天！' : '';
    },
    renewalFlag() {
      return !!this.oldId;
    },
    typeList() {
      return this.form.id && !this.form.subPolicies ? this.baseData['保单类型'] : this.baseData['保单类型'].filter(item => item.value < 100);
    },
    subTypeList() {
      return this.baseData['保单类型'].filter(item => 200 > item.value && item.value > 100);
    },
    // 保费/保额，金额验证
    amountVerification() {
      return (rule, value, callback) => {
        if (value <= 0) {
          callback(new Error('金额不能为空且必须大于0!'));
        } else {
          callback();
        }

      }
    }
  },
  async mounted() {
    if (this.formData.id) {
      this.$nextTick(() => {
        Object.keys(this.form).forEach((item) => {
          this.form[item] = this.formData[item]
        });
        this.keyword = this.formData.enterpriseName;
        this.form.area = this.form.countyCode || this.form.cityCode || this.form.provinceCode;
        this.planFileList = this.formData['planFileList'] || [];
        this.contractFileList = this.formData['contractFileList'] || [];
        this.form.planFileList = (this.formData['planFileList'] || []).map((item) => {
          return item.id
        })
        this.form.contractFileList = (this.formData['contractFileList'] || []).map((item) => {
          return item.id
        })
      })

      await this.getCoInsurerList(this.formData.companyId);
      if (this.oldId) {/*续保时需要初始化的数据*/
        this.form.oldPolicyId = this.form.id
        this.form.number = '';
        this.form.source = 2;
        this.form.serviceType = 1;
        this.form.premiumCost = null;
        this.form.insureAmount = null;
        this.form.id = '';
        let startAt = new Date(this.form.endAt);
        /*初始化续保后的日期，旧保单往后延一年*/
        this.form.startAt = new Date(startAt.setDate(startAt.getDate() + 1));
        this.form.endAt = '';
        if (this.form.subType === 102) {
          this.form.subPolicies.forEach(item => {
            item.premiumCost = null;
            item.number = '';
            item.insureAmount = null;
          })
        }
        // 续保时，可能服务方案附件以及合同附件已经变化故而置空
        this.planFileList = [];
        this.contractFileList = [];
        this.form.planFileList = []
        this.form.contractFileList = []
      }
      this.form.serverCount = this.formData.serverCount;

    } else {
      this.$nextTick(() => {
        this.form.provinceCode = this.formData.provinceCode;
        this.form.cityCode = this.formData.cityCode;
        this.form.countyCode = this.formData.countyCode;
        this.form.industryType = this.industryType;
        this.form.area = this.form.countyCode || this.form.cityCode || this.form.provinceCode;
        this.setIndustryType(this.industryType);
      });
      this.form.startAt = new Date();
      this.form.insureAt = new Date();
      const date = new Date();
      this.form.subType = this.formData.subType;
      this.form.isCoIns = this.formData.isCoIns;
      this.form.projectName = this.formData.projectName;
      this.form.projectId = this.formData.projectId;
      this.form.enterpriseContact = this.formData.enterpriseContact;
      this.form.enterprisePhone = this.formData.enterprisePhone;
      date.setFullYear(date.getFullYear() + 1);
      this.form.endAt = date;
      if (this.form.isCoIns === 1) {
        await this.subTypeChange();
      }
      await this.getProjectOrgList();
    }
  },
  methods: {
    getArea(data) {
      this.form.provinceCode = data[0] ? data[0].code : '';
      this.form.cityCode = data[1] ? data[1].code : '';
      this.form.countyCode = data[2] ? data[2].code : '';
      if (data.length === 0) this.form.area = '';
    },
    clearBusiness() {
      this.form.enterpriseName = '';
      this.form.enterpriseCode = '';
      this.keyword = '';
      this.form.enterpriseId = '';
    },
    selectOrg(data) {
      this.form.enterpriseName = data.iname;
      this.form.enterpriseId = data.id;
      this.form.enterpriseCode = data.societyCode || generateSocialCreditCode();
      if (!data.societyCode) {
        this.$Notice.warning({
          title: '提示',
          desc: '该单位没有社会统一代码，已自动创建临时社会统一代码！'
        });
      }
    },
    calcServiceCount() {
      const {startAt, endAt, serviceType} = this.form
      if (!startAt || !endAt || !serviceType) return;
      const month = math.divide(getDaysBetween(startAt, endAt), this.monthDays).toFixed(2);
      this.form.serverCount = Math.ceil(math.divide(month, serviceType))
    },
    bLFile1(data) {
      this.form.planFileList = data.map((item) => item.id)
    },
    bLFile2(data) {
      this.form.contractFileList = data.map((item) => item.id)
    },
    // 适用份额配置成员
    async getProjectOrgList() {
      if (!this.form.projectId) return;
      getProjectOrgList({projectId: this.form.projectId}).then(res => {
        let enterprise = res.data.list.find(item => item.type === 31) || {};
        this.keyword = enterprise.iname;
        this.form.enterpriseName = enterprise.iname;
        this.form.enterpriseCode = enterprise.societyCode;
        this.form.enterpriseId = enterprise.id;
      })
    },
    async getCoInsurerList(orgId) {
      this.coInsurerId = '';
      await getCoInsurer({orgId: orgId, policyType: this.form.type}).then((res) => {
        if (res.code === 200) {
          this.coInsurerList = res.data.list || [];
          let defaultCoInsurer = this.coInsurerList.filter(item => item.isDefault === 1);
          this.coInsurerId = defaultCoInsurer.length > 0 ? defaultCoInsurer[0].id : (this.coInsurerList.length > 0 ? this.coInsurerList[0].id : '');
          if (this.form.subType === 101 && this.form.isCoIns === 1) {
            if (!this.mid && !this.oldId) this.form.coInsurerId = this.form.coInsurerId || this.coInsurerId;
            this.getCoInsurerInfo();
          }
          if (this.form.subType === 102) {
            this.form.subPolicies.forEach((item, index) => {
              if (!this.mid && !this.oldId) item.coInsurerId = item.coInsurerId || this.coInsurerId;
              this.getCoInsurerInfo(index);
            })
          }
        }
      })
    },
    subTypeChange() {
      this.form.subPolicies = [];
      if (this.form.subType === 102) {
        this.subTypeList.forEach((item, index) => {
          this.form.subPolicies.push({
            "type": item.value,
            "id": '',
            "number": "",
            "startAt": this.form.startAt,
            "endAt": this.form.endAt,
            "premiumCost": null,
            "insureAmount": null,
            "enterpriseId": '',
            "enterpriseContact": this.formData.enterpriseContact,
            "industryType": 1,
            "enterprisePhone": this.formData.enterprisePhone,
            "companyContact": "",
            "member": [],
            "companyPhone": "",
            "coInsurerId": this.coInsurerId,
            "extRate": 0,
            "extType": 1,
            "ownerId": 0,
            "insureAt": this.form.insureAt || '', // 投保时间
            "insureNum": '', // 投保单号
          });
          this.$set(this.form.subPolicies[index], 'member', []);
          if (this.coInsurerId) this.getCoInsurerInfo(index);
          this.$forceUpdate();
        })
      }
    },
    setIndustryType(data) {
      this.form.subPolicies.forEach(item => item.industryType = data)
    },
    setNumber(event) {
      const ev = window.event || event;
      // 火狐以及Safari内核浏览器兼容
      const path = ev.path || (ev.composedPath && ev.composedPath());
      switch (path[1].id) {
        case 'FatNumber':
          this.$nextTick(() => {
            this.form.number = strReword(this.form.number)
          })
          break;
        case 'SubNumber':
          this.$nextTick(() => {
            this.form.number = (this.form.subPolicies || []).map(item => item.number = strReword(item.number)).join('');
          })
          break;
      }
    },
    setInsureNum() {
      this.form.insureNum = this.form.subPolicies.map(item => item.insureNum).join('');
    },
    async getCoInsurerInfo(index) {
      let id = index !== undefined ? this.form.subPolicies[index].coInsurerId : this.form.coInsurerId
      if (!id) return;
      await coInsurerInfo({id: id, time: uuid()}).then((res) => {
        if (res.code === 200) {
          if (index === undefined) { // 共保体单一模式
            this.form.member = res.data.member || [];
            this.form.extRate = res.data.extRate || 0;
            this.form.extType = res.data.type || 1;
            // 非编辑的时候再判断。编辑的时候判断使用份额配置是否是一般模式（需清空已选引流方）
            this.form.ownerId = !this?.formData?.id ? (res.data.hasExt === 1 ? res.data.member[0].insId : 0) : (this.form.extType === 1 ? 0 : this.form.ownerId);
          } else { // 共保体组合安责险
            this.$set(this.form.subPolicies[index], 'member', res.data.member || []);
            this.form.subPolicies[index].extRate = res.data.extRate || 0;
            this.form.subPolicies[index].extType = res.data.type || 1;
            // 非编辑的时候再判断。编辑的时候判断使用份额配置是否是一般模式（需清空已选引流方）
            this.form.subPolicies[index].ownerId = !this?.formData?.id ? (res.data.hasExt === 1 ? res.data.member[0].insId : 0) : (this.form.subPolicies[index].extType === 1 ? 0 : this.form.subPolicies[index].ownerId);
          }
        }
      })
    },
    getProjectData(data) {
      this.form.projectId = data[0].id // 项目ID或施工许可编号
      this.form.projectName = data[0].iname
      this.form.enterpriseContact = data[0].principalName
      this.form.enterprisePhone = data[0].principalPhone
      this.form.startAt = data[0].startPlan // 保单开始时间
      this.form.endAt = data[0].endPlan // 保单结束时间
      this.calcServiceCount();

    },
    getOrgData(data) {
      this.form.companyName = data[0].iname
      this.form.companyId = data[0].id // 保险公司ID
      this.form.isCoIns = data[0].type === 6 ? 1 : 0
      this.form.companyContact = data[0].contacts
      this.form.companyPhone = data[0].phone
      if (this.form.isCoIns === 1) {
        this.subTypeChange();
        this.getCoInsurerList(this.form.companyId);
      }
    },
    premiumCostChange(event) {
      if (event >= 1000000) {
        this.$Message.destroy()
        this.$Message.warning({
          background: true,
          content: '保费过高请确认保费是否正确！',
          duration: 2
        });
      }
    },
    insureAtChange(date) {
      // 通用安责险/（共保体单一模式）没有子保单
      if (this.form.subType === 101) return
      this.form.subPolicies.forEach(item => item.insureAt = date)
    }
  },
  watch: {}
}
</script>

<style lang="less" scoped>
</style>
