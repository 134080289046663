<template>
  <div>
    <ys-page-list
        ref="table"
        action="inspects/List"
        :params="params"
        :multiColumn="false"
        :tableHeight="height-300"
        :headerColumns="headerColumns"
        @on-selection-change="checkItem"
        :isEmptySelect="isEmptySelect">
      <template slot="search">
        <FormItem label="验收状态" prop="status">
          <Select v-model="params.status" clearable placeholder="验收状态" style="width: 120px;">
            <Option v-for="(item,index) in (baseData['验收任务状态'] || []).filter(item=>item.value!==4)" :value="item.value" :key="index">{{ item.name }}
            </Option>
          </Select>
        </FormItem>
        <FormItem label="非管辖区域" prop="noArea">
          <Checkbox v-model="params.noArea" border>包含</Checkbox>
        </FormItem>
        <FormItem label="子公司数据" prop="isTop">
          <Checkbox v-model="params.isTop" border>不包含</Checkbox>
        </FormItem>
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="验收名称、项目名称、保单编号等" style="width: 180px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
    </ys-page-list>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok">确定</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'
import components from '@/mixins/components';

export default {
  name: '',
  mixins: [pageList, components],
  props: {
    policyId: {
      type: [Number, String],
      default: ''
    }
  },
  computed: {},
  components: {},
  data() {
    return {
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 70, align: 'center'},
        {title: '编号', width: 180, key: 'code', align: 'center'},
        {width: 250, title: '验收名称', key: 'iname', align: 'left', renderConfig: {type: 'gotoDetail'}},
        {minWidth: 250, title: '项目名称', key: 'projectName', align: 'left'},
        {width: 200, title: '发布时间', key: 'publishedAt', align: 'left'},
        {width: 150, title: '发布人', key: 'publishedUser', align: 'left'},
        {width: 120, title: '创建时间', key: 'createdAt', align: 'center', renderConfig: {type: 'formatDate'}},
        {width: 120, title: '状态', key: 'status', align: 'center', renderConfig: {type: 'baseData', parentName: '验收任务状态'}},
      ],
      params: {
        status: '',
        isTop: false,
        keyword: '',
        noArea: true,
        policyId: ''
      },
    }
  },
  created() {
  },
  mounted() {
    this.params.policyId = this.policyId
    this.getList();
  },
  methods: {}


}
</script>
