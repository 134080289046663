<template>
  <div class="standard">
    <div class="category" :style="categoryStyle">
      <div class="standardList">
        <Select v-model="standardId" placeholder="请选择检查标准" @on-change="getCategory" style="margin-right:8px;width: calc(100% - 165px)">
          <Option v-for="(item) in standardList" :value="item.id" :key="item.id">{{item.uuid?'[已绑定]':''}}{{ item.iname }}</Option>
        </Select>
        <ButtonGroup style="margin-right: 0">
          <Button type="primary" icon="md-add" @click="addFlag=true;standardFlag=true"></Button>
          <Button type="primary" icon="md-create" @click="addFlag=false;standardFlag=true"></Button>
          <!--          <Button type="primary" icon="md-trash"></Button>-->
          <Button type="primary" icon="md-list" title="初始化序号" @click="initIndex"></Button>
          <Button type="primary" title="导入标准" icon="md-cloud-upload" @click="putInFlag=true"></Button>
          <Button type="primary" title="绑定标准" icon="ios-ionic" @click="bindFlag=true"></Button>
        </ButtonGroup>
      </div>
      <Button type="primary" size="small" long icon="md-add" @click="add()">新增总项</Button>
      <div :style="{height:height-240+'px',overflowY:'auto',paddingRight:'10px'}" style="position: relative;margin: 10px 0;">
        <Tree
            class="demo-tree-render"
            :data="treeData"
            show-checkbox
            check-strictly
            @on-check-change="onCheckChange"
            :render="renderContent"
            @on-select-change="onSelectChange"
            :load-data="loadData"></Tree>
      </div>
      <div class="operation">
        <Button type="primary" icon="md-git-compare" @click="turnIndex" :disabled="checkedFlag">调换顺序</Button>
      </div>
    </div>
    <div class="itemDetail">
      <tabs :midData="parentData" :standardInfo="standardInfo"></tabs>
    </div>
    <ys-modal v-model="categoryFlag" :width="600" title="新增/修改总项">
      <editCategory :standardId="standardId" :mid="detailID" @on-ok="getCategory"></editCategory>
    </ys-modal>
    <ys-modal v-model="dictFlag" :width="600" title="新增/修改风险表项">
      <editDict :mid="detailID" :parentID="standardId" :midData="midData" @on-ok="getCategory"></editDict>
    </ys-modal>
    <ys-modal v-model="itemFlag" :width="600" title="新增/修改风险大项">
      <editItem :mid="detailID" :midData="midData" @on-ok="getCategory"></editItem>
    </ys-modal>
    <ys-modal v-model="standardFlag" :width="800" title="新增/修改检查标准">
      <editStandard :mid="standardId" :type="addFlag?'add':''" @on-ok="getStandardList"></editStandard>
    </ys-modal>
    <ys-modal v-model="putInFlag" :width="500" title="导入检查字典">
      <import :mid="standardId" @on-ok="getCategory"></import>
    </ys-modal>
    <ys-modal v-model="bindFlag" :width="1000" title="绑定标准">
      <remoteStandard @on-ok="getRemote"></remoteStandard>
    </ys-modal>
  </div>
</template>

<script>
import {delItem, delCategory, delDict, getCategoryDict, getCategoryDictItem, getStandardList, getStandardInfo, bindRemoteStandard} from '@/api/base'
import {mapGetters} from 'vuex'
import Tabs from "@/views/base/standard/tabs";

export default {
  name: 'projects',
  components: {
    Tabs,
    'editStandard': () => import('@/views/base/standard/editStandard.vue'),
    'editItem': () => import('@/views/base/standard/editItem.vue'),
    'editDict': () => import('@/views/base/standard/editDict.vue'),
    'import': () => import('@/views/base/standard/import.vue'),
    'editCategory': () => import('@/views/base/standard/editCategory.vue'),
    'remoteStandard': () => import('@/components/remoteStandard.vue'),
  },
  data() {
    return {
      buttonProps: {
        type: 'default',
        size: 'small',
      },
      standardId: '',
      detailID: '',
      dictFlag: false,
      bindFlag: false,
      putInFlag: false,
      itemFlag: false,
      addFlag: false,
      standardFlag: false,
      categoryFlag: false,
      checkedData: [],
      midData: [],
      parentData: [],
      treeData: [],
      standardList: [],
      standardInfo: []
    }
  },
  computed: {
    checkedFlag() {
      return this.checkedData.length !== 2
    },
    ...mapGetters(['height']),
    categoryStyle() {
      let config = localStorage.getItem('set') ? JSON.parse(localStorage.getItem('set')) : {}
      return {height: !config.scroll ? this.height - 106 + 'px' : ''}
    },
  },
  mounted() {
    this.getStandardList();
  },
  methods: {
    initIndex() {
      this.$Modal.confirm({
        title: '提示',
        content: '确定要初始化检查字典顺序么？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          this.$post('/standard/InitIndex', {id: this.standardId}).then((res) => {
            if (res.code === 200) {
              this.$Notice.success({title: '提示', desc: '操作成功！'});
              this.getCategory();
            }
          })
        }
      })
    },
    turnIndex() {
      let isOk = false;
      let exchangeUrl = '';
      if (this.checkedData[0].dictId) {
        if (this.checkedData[0].dictId === this.checkedData[1].dictId) {
          isOk = true;
          exchangeUrl = '/standard/dictItem/ExchangeIndex'
        }
      } else if (this.checkedData[0].categoryId) {
        if (this.checkedData[0].categoryId !== this.checkedData[1].categoryId) {
          isOk = true;
          exchangeUrl = '/standard/dict/ExchangeIndex'
        }
      } else {
        if (this.checkedData[0].id !== this.checkedData[1].id) {
          isOk = true;
          exchangeUrl = '/standard/dictCategory/ExchangeIndex'
        }
      }
      if (!isOk) {
        this.$Notice.error({
          title: '提示',
          desc: '当前选中菜单不在同一菜单层级，请确认！'
        })
        return false
      }
      this.$Modal.confirm({
        title: '提示',
        content: '确定要调换位置么？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          this.$post(exchangeUrl, {fromId: this.checkedData[0].id, toId: this.checkedData[1].id}).then((res) => {
            if (res.code === 200) {
              this.$Notice.success({title: '提示', desc: '操作成功！'});
              this.checkedData = [];
              this.getStandardList();
            }
          })
        }
      })
    },
    onCheckChange(ary) {
      this.checkedData = ary
    },
    getRemote(data) {
      bindRemoteStandard({id: this.standardId, uuid: data[0].uuid}).then(res => {
        if (res.code === 200) {
          this.$Notice.success({title: '提示', desc: '操作成功！'});
        }
      })
    },
    getStandardList() {
      getStandardList({limit: -1}).then((res) => {
        if (res.code === 200) {
          this.standardList = res.data.list || [];
          this.standardId = this.standardList[0].id;
          this.getCategory();
        }
      })
    },
    renderContent(h, {data}) {
      return h('div', {
        style: {height: '24px', lineHeight: '24px', paddingRight: '12px',},
      }, [
        h('span', (data.index == undefined ? data.num : data.index) + '、' + data.title),
        h('span', {
          style: {display: 'inline-block', float: 'right'}
        }, [
          h('Button', {
            props: Object.assign({}, this.buttonProps, {
              icon: 'md-add'
            }),
            style: {display: !data.dictId ? 'inline-block' : 'none'},
            on: {
              click: () => {
                this.add(data, 'add')
              }
            }
          }),
          h('Button', {
            props: Object.assign({}, this.buttonProps, {
              icon: 'md-create'
            }),
            style: {marginLeft: '5px'},
            on: {
              click: () => {
                this.edit(data, 'edit')
              }
            }
          }),
          h('Button', {
            props: Object.assign({}, this.buttonProps, {
              icon: 'md-close'
            }),
            style: {marginLeft: '5px'},
            on: {
              click: () => {
                this.remove(data)
              }
            }
          })
        ])
      ]);
    },
    edit(data) {
      this.detailID = data.id;
      this.midData = [];
      if (data.categoryId) {
        if (data.dictId) {
          this.itemFlag = true;
        } else {
          this.dictFlag = true;
        }
      } else {
        this.categoryFlag = true;
      }
    },
    add(data) {
      this.detailID = '';
      this.midData = [data];
      if (!data) {
        this.categoryFlag = true;
        return false;
      }
      if (data.categoryId) {
        if (!data.dictId) {
          this.itemFlag = true;
        }
      } else {
        this.dictFlag = true;
      }
    },
    remove(data) {
      let delFun;
      if (data.categoryId) {
        if (data.dictId) {
          delFun = () => delItem({ids: [data.id]});
        } else {
          delFun = () => delDict({ids: [data.id]});
        }
      } else {
        delFun = () => delCategory({ids: [data.id]});
      }
      this.$Modal.confirm({
        title: '提示',
        content: '确定要删除这一项么？删除后子项也会被删除!',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          delFun().then((res) => {
            if (res.code === 200) {
              this.$Notice.success({title: '提示', desc: '删除成功！'});
              this.getCategory();
            }
          })
        }
      })
    },
    onSelectChange(ary, item) {
      this.parentData = [item];
    },
    getCategory() {
      getStandardInfo({id: this.standardId}).then(res => {
        this.treeData = (res.data.categoryList || []).map(item => {
          item.title = item.iname;
          item.loading = false;
          item.children = [];
          return item;
        })
        this.standardInfo = [res.data]
      })
    },
    loadData(item, callback) {
      !item.categoryId ?
          getCategoryDict({categoryId: item.id}).then(res => {
            let data = (res.data || []).map(item => {
              item.title = item.iname;
              item.loading = false;
              item.children = [];
              return item;
            });
            callback(data)
          }) : getCategoryDictItem({dictId: item.id}).then(res => {
            let data = (res.data || []).map(item => {
              item.title = item.iname;
              return item;
            });
            callback(data)
          })
    }
  }
}
</script>
<style lang="less" scoped>
.standardList {
  padding-bottom: 10px;
}

.standard {
  display: flex;

  .category {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px 15px 0 10px;
    width: 28%;

    .operation {
      width: 100%;
      text-align: center;
    }

  }

  .itemDetail {
    width: 72%;
    padding-left: 10px;
  }
}
</style>
