<template>
  <div class="uuid_box" :style="{height:'calc(100vh - 105px)'}">
    <Form ref="form" :model="form" class="editForm fourPartsEditForm" inline :label-width="80">
      <FormItem label="生成个数">
        <InputNumber v-model="form.limit" style="width: 70%" :max="100" :min="1" placeholder="生成个数"></InputNumber>
        <Button type="primary" @click="generate">生成</Button>
      </FormItem>
    </Form>
    <ul class="list">
      <li v-for="(item,index) in List" :class="{'delete':item.delete,['uuid'+index]:true}" :data-clipboard-text="item.value" class="uuid" :key="item.value">
        {{ item.value }}
        <Button size="small" type="primary" class="copy" @click="copy(index)">复制</Button>
      </li>
    </ul>
  </div>
</template>

<script>
import {uuid} from 'ys-admin'
import Clipboard from 'clipboard';

export default {
  name: "index",
  data() {
    return {
      List: [],
      form: {
        limit: 60
      }
    }
  },
  mounted() {
    this.generate()

  },
  methods: {

    copy(index) {
      // this.putOut({
      //   url: 'report/Gen',
      //   params: {checkId: 12, templateId: 1}
      // })
      let clipboard = new Clipboard('.uuid' + index)
      clipboard.on('success', () => {
        this.$Message.success('复制成功！');
        this.List[index].delete = true;
        // 释放内存
        clipboard.destroy();
      })
      clipboard.on('error', () => {
        // 不支持复制
        this.$Message.error('该浏览器不支持自动复制')
        // 释放内存
        clipboard.destroy()
      })
    },
    generate() {
      this.List = [];
      for (let i = 1; i <= this.form.limit; i++) {
        this.List.push({
          value: uuid(),
          delete: false
        })
      }
    },
  }
}
</script>

<style scoped lang="less">

</style>