<template>
  <div class="orgHome">
    <ys-page-list
        ref="table"
        action="/organization/List"
        :params="params"
        :tableHeight="tableHeight"
        :multiBtn="multiBtn"
        showContextMenu
        @on-selection-change="checkItem"
        :headerColumns="headerColumns"    >
      <template slot="search">
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="关键词" clearable style="width: 150px;" v-model="params.keyword"></Input>
        </FormItem>
        <FormItem label="所在区域" prop="region">
          <ys-region changeOnSelect v-model="params.region" @on-change="getRegion"></ys-region>
        </FormItem>
      </template>
      <template slot="btn">
        <ButtonGroup>
          <Button type="primary" @click="add">新增子机构</Button>
        </ButtonGroup>
      </template>
      <template slot="detail">
        <detail :mid="detailID"></detail>
      </template>
    </ys-page-list>
    <ys-modal v-model="modShow" :width="900" title="新增/修改公司">
      <Edit :mid="detailID" :midData="midData" @on-ok="getList"></Edit>
    </ys-modal>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'
import Detail from "@/views/org/list/detail.vue";
import Edit from "@/views/org/list/edit.vue";

export default {
  name: 'projects',
  mixins: [pageList],
  components: {
    Detail,
    Edit
  },
  data() {
    return {
      params: {
        countyCode: '',
        containSelf: 1,
        depth: 1,
        cityCode: '',
        provinceCode: '',
        parentId: '',
        oType: '',
        keyword: '',
        orgId: '',
      },
      putInFlag: false,
      headerColumns: [
        {type: 'selection', title: '选择框', width: 50, align: 'center'},
        {type: 'index', title: '序号', width: 50, align: 'center'},
        {width: 75, title: 'logo', key: 'logo', align: 'center', renderConfig: {type: 'image'}},
        {minWidth: 230, title: '公司名称', key: 'iname', align: 'left', renderConfig: {type: 'gotoDetail'}},
        {width: 200, title: '上级机构', key: 'parentName', align: 'center',},
        {width: 100, title: '公司类型', key: 'oType', align: 'center', renderConfig: {type: 'baseData', parentName: '组织类型'}},
        {width: 130, title: '公司简称', key: 'shortName', align: 'left'},
        {width: 200, title: '社会信用代码', key: 'societyCode', align: 'left'},
        {width: 100, title: '联系人', key: 'contacts', align: 'left'},
        {width: 150, title: '联系电话', key: 'phone', align: 'left'},
        {width: 230, title: '办公地所在区域', key: 'countyCode', align: 'left', renderConfig: {type: 'area', key: ['countyCode', 'cityCode', 'provinceCode']}},
        {width: 180, title: '创建时间', key: 'createdAt', align: 'left'},
        {width: 180, title: '最近修改时间', key: 'updatedAt', align: 'left'},
      ]
    }
  },
  computed: {
    org: function () {
      const orgId = parseInt(sessionStorage.getItem('orgId') || 0);
      return this.userInfo.orgs.filter(item => item.id === orgId).length > 0 ? this.userInfo.orgs.filter(item => item.id === orgId)[0] : {};
    },
    multiBtn() {
      return [
        {
          children: [
            {
              type: 'detail',
              click: () => this.modShow = true,
              title: '查看详情',
              targetUrl: '/main/org/detail/' + this.detailID,
              icon: 'md-eye',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.modShow = true,
              title: '编辑',
              icon: 'md-create',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.delItem('/manage/org/Remove '),
              iconColor: '#ed4014',
              title: '删除',
              icon: 'md-trash',
              disabled: this.tableSelectItem.length !== 1
            },
          ]
        }
      ]
    },
  },
  mounted() {
    this.params.parentId = this.mid
    this.getList();
  },
  methods: {
    getRegion(data) {
      this.params.countyCode = data[2] ? data[2].code : '';
      this.params.cityCode = data[1] ? data[1].code : '';
      this.params.provinceCode = data[0] ? data[0].code : '';
    }
  }
}
</script>

<style>

</style>