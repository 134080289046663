<template>
  <div class="async">
    <h1 class="h1Title">数据同步</h1>
    <ys-cell-group :gutter="2">
      <ys-cell title="数据同步上传">
        <div slot="value">
          <i-switch size="large" :loading="upLoading" @on-change="upChange" v-model="detail.syncUpload" :true-value="1" :false-value="0">
            <span slot="open">开启</span>
            <span slot="close">关闭</span>
          </i-switch>
        </div>
      </ys-cell>
      <ys-cell title="数据同步更新" v-show="hostname!=='adm.cicaq.cn'">
        <div slot="value">
          <i-switch size="large" :loading="downLoading" @on-change="downChange" v-model="detail.syncDownload" :true-value="1" :false-value="0">
            <span slot="open">开启</span>
            <span slot="close">关闭</span>
          </i-switch>
        </div>
      </ys-cell>
    </ys-cell-group>
    <ys-modal v-model="orgFlag" :width="1200" title="选择上级机构">
      <org-component :limit="1" @on-ok="getData"></org-component>
    </ys-modal>
  </div>
</template>

<script>
import {syncInfo, enableDownload, disableDownload, disableUpload, enableUpload} from "@/api/org";
import OrgComponent from "_c/centerOrg";

export default {
  name: "orgAsync",
  props: {
    mid: {type: [String, Number], default: null}
  },
  components: {OrgComponent},
  data() {
    return {
      detail: {},
      hostname: window.location.hostname,
      orgFlag: false,
      upLoading: false,
      downLoading: false,
      params: {
        orgId: '',
        platform: '',
        uuid: '',
      }
    }
  },
  mounted() {
    this.params.orgId = this.mid;
    this.getDetail()
  },
  methods: {
    getData(data) {
      this.params.platform = data[0].platformId;
      this.params.uuid = data[0].uuid;
      this.downChange(true);
    },
    upChange(data) {
      const requestUp = data ? enableUpload : disableUpload;
      requestUp(this.params).then(res => {
        if (res.code === 200) {
          this.$Notice.success({
            title: '提示',
            desc: '操作成功！'
          });
          this.getDetail()
        } else {
          this.detail.syncUpload = data ? 0 : 1
        }
      }).catch(() => {
        this.detail.syncUpload = data ? 0 : 1
      })
    },
    downChange(data) {
      if (data) {
        if (!this.params.uuid) {
          this.orgFlag = true;
          this.$nextTick(() => {
            this.detail.syncDownload = 0;
          })
          return false;
        }
      }
      const requestDown = data ? enableDownload : disableDownload;
      requestDown(this.params).then(res => {
        if (res.code === 200) {
          this.$Notice.success({
            title: '提示',
            desc: '操作成功！'
          });
          this.getDetail()
        } else {
          this.detail.syncDownload = data ? 0 : 1
        }
      }).catch(() => {
        this.detail.syncDownload = data ? 0 : 1
      })
    },
    getDetail() {
      syncInfo({orgId: this.mid}).then(res => {
        if (res.code === 200) {
          this.detail = res.data;
          this.params.uuid = this.detail.syncDownload === 1 ? this.detail.syncDownloadUuid : '';
        }
      })
    }
  }
}
</script>

<style scoped>
.async {
  padding: 10px;
}
</style>