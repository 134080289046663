<template>
  <div>
    <Form ref="form" @on-validate="formValidate" class="editForm twoPartsEditForm" :model="form" :rules="ruleValidate" inline :label-width="90" :style="{maxHeight:'calc(100vh - 200px)'}" style="overflow-y: auto">
      <FormItem label="配置名称" prop="name">
        <Input v-model="form.name" placeholder="配置名称"/>
      </FormItem>
      <FormItem label="平台类型" prop="platform">
        <Select v-model="form.platform" placeholder="平台类型">
          <Option v-for="(item,index) in baseData['平台类型'].filter(item => item.value !== 6)" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="域名" prop="domain">
        <Input v-model="form.domain" placeholder="域名">
          <Tooltip :max-width="500" :content="'填写规则：域名:端口号\n示例：www.baidu.com或localhost:8080'" slot="suffix" placement="top">
            <Icon type="md-alert"/>
          </Tooltip>
        </Input>
      </FormItem>
      <FormItem label="站点title" prop="title">
        <Input v-model="form.title" placeholder="站点title">
          <Tooltip :max-width="500" :content="'注意：该字段可用于配置当前域名下的站点title，使用后在全局配置内配置的当前平台下的站点title在该域名下会自动失效\n该字段亦可作为标题文字使用，填写后标题图片自动失效'" slot="suffix" placement="top">
            <Icon type="md-alert"/>
          </Tooltip>
        </Input>
      </FormItem>
      <FormItem label="登陆框位置" prop="loginFormPosition">
        <Input v-model="form.loginFormPosition" placeholder="登陆框位置">
          <Tooltip :max-width="280" :content="'默认横向据中\n填写规则：css定位\n示例：left: 10px; top: 2%;'" slot="suffix" placement="top">
            <Icon type="md-alert"/>
          </Tooltip>
        </Input>
      </FormItem>
      <FormItem label="底部版权" prop="copyright" style="width: 100%">
        <Input v-model="form.copyright" type="textarea" maxlength="128" show-word-limit :autosize="{minRows: 2,maxRows: 3}" placeholder="底部版权，支持html标签"></Input>
      </FormItem>
      <FormItem label="描述" prop="describe" style="width: 100%">
        <Input v-model="form.describe" type="textarea" maxlength="128" show-word-limit :autosize="{minRows: 2,maxRows: 3}" placeholder="描述"></Input>
      </FormItem>
      <FormItem label="登陆框背景" prop="loginBackPicFileId">
        <ysUpload
            @on-success="loginBackFile"
            :headers="{token:token}"
            type="fullList"
            :limit="1"
            :format="['jpg','jpeg','png','gif']"
            :defaultList.sync="loginBackPicFileList"
            action="/web/resource/UploadFile"
        ></ysUpload>
      </FormItem>
      <FormItem label="标题图片" prop="loginTitlePicFileId">
        <Tooltip content="建议最大尺寸：500像素 X 60像素" style="width: 100%;">
          <ysUpload
              @on-success="logoTitleFile"
              :headers="{token:token}"
              type="fullList"
              :limit="1"
              :format="['jpg','jpeg','png','gif']"
              :defaultList.sync="loginTitlePicFileList"
              action="/web/resource/UploadFile"
          ></ysUpload>
        </Tooltip>
      </FormItem>
      <FormItem label="站点logo" prop="logoFileId">
        <Tooltip content="建议最大尺寸：500像素 X 80像素" style="width: 100%;">
          <ysUpload
              @on-success="logoFile"
              :headers="{token:token}"
              type="fullList"
              :limit="1"
              :format="['jpg','jpeg','png','gif']"
              :defaultList.sync="logoFileList"
              action="/web/resource/UploadFile"
          ></ysUpload>
        </Tooltip>
      </FormItem>
      <FormItem label="登陆页背景" prop="loginFormFileId">
        <ysUpload
            @on-success="formFile"
            :headers="{token:token}"
            type="fullList"
            :limit="1"
            :format="['jpg','jpeg','png','gif']"
            :defaultList.sync="loginFormFileList"
            action="/web/resource/UploadFile"
        ></ysUpload>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="save">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>
<script>
import edit from '@/mixins/edit'
import {siteSetInfo} from "@/api/base";

export default {
  name: 'projectEdit',
  mixins: [edit],
  data() {
    return {
      ruleValidate: {
        name: [
          {required: true, message: '请填写模板名称', trigger: 'blur'}
        ],
        domain: [
          {
            required: true, trigger: 'blur', validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('请填写域名!'));
              } else {
                let lastString = value.substring(value.length - 1, value.length);
                if (lastString === '/') {
                  callback(new Error('不能以/结尾！'))
                } else {
                  callback()
                }
              }
            }
          }
        ],
      },
      orgFlag: false,
      logoFileList: [],
      loginBackPicFileList: [],
      loginFormFileList: [],
      loginTitlePicFileList: [],
      form: {
        id: '',
        domain: '',
        title: '',
        name: '',
        platform: 1,
        loginFormPosition: '',
        describe: '',
        logoFileId: '',
        copyright: '',
        loginTitlePicFileId: '',
        loginBackPicFileId: '',
        loginFormFileId: '',
        titleType: 0,
      },
    }
  },

  mounted() {
    if (this.mid) {
      this.getDetail()
    }
  },
  methods: {
    getDetail() {
      siteSetInfo({id: this.mid}).then(res => {
        if (res.code === 200) {
          Object.keys(this.form).forEach((item) => {
            this.form[item] = res.data[item]
          })
          this.form.logoFileId = res.data.Logo ? res.data.Logo.id : ''
          this.form.loginFormFileId = res.data.LoginForm ? res.data.LoginForm.id : ''
          this.logoFileList = res.data.Logo ? [res.data.Logo] : []
          this.loginFormFileList = res.data.LoginForm ? [res.data.LoginForm] : []
          this.form.loginTitlePicFileId = res.data.LoginTitlePic ? res.data.LoginTitlePic.id : ''
          this.form.loginBackPicFileId = res.data.LoginBackPic ? res.data.LoginBackPic.id : ''
          this.loginTitlePicFileList = res.data.LoginTitlePic ? [res.data.LoginTitlePic] : []
          this.loginBackPicFileList = res.data.LoginBackPic ? [res.data.LoginBackPic] : []
        }
      })
    },
    domainChange() {
      let lastString = this.form.domain.substring(this.form.domain.length - 1, this.form.domain.length)
      if (lastString === '/') {
        this.$Notice.error({
          title: "操作失败！",
          desc: '不能以/结尾'
        });
      }
    },
    formFile(data) {
      this.form.loginFormFileId = data.length >= 1 ? data[0].id : '';
    },
    logoFile(data) {
      this.form.logoFileId = data.length >= 1 ? data[0].id : '';
    },
    loginBackFile(data) {
      this.form.loginBackPicFileId = data.length >= 1 ? data[0].id : '';
    },
    logoTitleFile(data) {
      this.form.loginTitlePicFileId = data.length >= 1 ? data[0].id : '';
    },
    save() {
      if (this.form.loginTitlePicFileId) {
        this.form.titleType = 1
      } else {
        this.form.titleType = 0
      }
      this.ok('/platformConfig/AddOrEdit')
    }
  }
}
</script>

<style lang="less" scoped>
</style>
