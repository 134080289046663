<template>
  <div>
    <Form ref="form" class="editForm" :model="form" :rules="ruleValidate" :label-width="80">
      <FormItem label="审批名称" prop="name">
        <Input v-model="form.name" placeholder="审批名称"></Input>
      </FormItem>
      <FormItem label="备注" prop="desc" style="width: 100%">
        <Input v-model="form.desc" type="textarea" maxlength="128" show-word-limit :autosize="{minRows: 3,maxRows: 10}" placeholder="备注"></Input>
      </FormItem>
      <FormItem label="附件" prop="contractFileList" style="width: 100%;text-align: center">
        <ys-upload
            @on-success="getFile"
            :headers="{token:token}"
            ref="upload"
            type="fullList"
            :limit="1"
            action="/web/resource/UploadFile">
        </ys-upload>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok('/orgApprovalResult/Add')">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>
<script>
import edit from '@/mixins/edit'

export default {
  name: 'projectEdit',
  mixins: [edit],
  props: {
    approvalConfig: {type: Object, default: ()=>{return {}}},
    resourceId: {type: Number,},
    resourceType: {type: Number,}
  },
  data() {
    return {
      form: {
        desc: '',
        resourceId: '',
        resourceType: '',
        args: {},
        fileId: '',
        name: '',
      },
    }
  },
  computed: {},
  async mounted() {
    this.form.name = this.approvalConfig.name;
  },
  methods: {
    getFile(data) {
      this.form.fileId = data.map((item) => item.id).join()
    },
  }
}
</script>

<style lang="less" scoped>

</style>


