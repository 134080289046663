<template>
  <ys-detail :icon="detailIcon" :loading="loading" :name="detail.projectName">
    <Tag size="medium" color="blue" slot="status">{{ detail.status|getBaseDataName('保单状态') }}</Tag>
    <template slot="brief">
      <ys-cell title="保单号" :label="detail.number" style="word-break: break-all;"/>
      <ys-cell title="项目地址" :label="detail.address"/>
      <ys-cell title="保费金额" :label="detail.premiumCost|fixed"/>
      <ys-cell title="创建时间" :label="detail.createdAt"/>
    </template>
    <template slot="main-left">
      <Tabs v-model="tab" name="detail" type="card" @on-click="tabClick">
        <Tab-pane label="保单信息" name="1" icon="ios-paper">
          <base-info :mid="detailID" v-if="tab==='1'"/>
        </Tab-pane>
        <Tab-pane label="任务列表" name="2" icon="md-list-box">
          <task :policyId="detail.id" :tableHeight="pageListHeight" v-if="tab==='2'"></task>
        </Tab-pane>
        <Tab-pane label="检查报告" icon="ios-albums" name="7">
          <reports :tableHeight="pageListHeight" :policyId="detail.id" v-if="tab==='7'"></reports>
        </Tab-pane>
        <Tab-pane label="服务计划" name="3" icon="md-list-box">
          <subPlan :policyId="detail.id" :modData="[detail]" :midData="planList" :tableHeight="pageListHeight" v-if="tab==='3'"></subPlan>
        </Tab-pane>
        <Tab-pane label="相关附件" name="6" icon="md-document">
          <ys-resource :resourceType="9" :tableHeight="pageListHeight" :mid="detailID" v-if="tab==='6'"></ys-resource>
        </Tab-pane>
      </Tabs>
    </template>
    <template slot="main-right">
      <h1 class="h1Title">其他信息</h1>
      <div style="text-align: center">暂未开放！</div>
    </template>
  </ys-detail>
</template>

<script>
import detailMixins from '@/mixins/detail'
import BaseInfo from "@/views/policy/detail/baseInfo.vue";
import subPlan from "@/views/task/plan/detail/subPlan/index.vue";
import task from "_c/task";
import {servePlanList} from "@/api/plan";
import {policyInfo} from "@/api/policy";
import Reports from "@/views/task/reports/index.vue";

export default {
  name: "detail",
  mixins: [detailMixins],
  components: {
    Reports,
    subPlan,
    task,
    BaseInfo,
  },
  data() {
    return {
      planList: []
    }
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.loading = true;
      policyInfo({id: this.detailID}).then(res => {
        this.loading = false;
        if (res.code === 200) {
          this.detail = res.data;
        }
        this.setTagName(this.detail.number)
      })
    },
    // 服务计划列表
    async getPlanList() {
      await servePlanList({policyId: this.detailID, random:new Date().getTime()}).then(res => {
        this.planList = res.data.list || []
      })
    },
    // 当点击服务计划tab的时候请求一下服务计划列表,查看一下当前保单下是否存在服务计划，且可使用其中的数据来新增明细
    tabClick(name) {
      switch(name) {
        case '3':
          this.getPlanList()
          break;
      }
    },
    setPlan() {
      this.getPlanList()
    }
  }
}
</script>

<style scoped>

</style>