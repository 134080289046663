<template>
  <div>
    <Form ref="form" class="editForm" :model="form" :rules="ruleValidate" :label-width="80" :style="{maxHeight:'calc(100vh - 200px)'}" style="overflow-y: auto">
      <FormItem label="排序" prop="order">
        <InputNumber style="width: 100%" v-model="form.order" placeholder="排序"/>
      </FormItem>
      <FormItem label="KEY" prop="key">
        <Input v-model="form.key" placeholder="KEY"/>
      </FormItem>
      <FormItem label="KEY名称" prop="keyName">
        <Input v-model="form.keyName" placeholder="KEY名称"/>
      </FormItem>
      <FormItem label="KEY类型" prop="type">
        <Select v-model="form.type" transfer placeholder="KEY类型" :style="{width: ['baseData','formatDate'].indexOf(form.type)>=0?'40%':'100%'}">
          <Option v-for="item in baseData['表头配置key类型']" :key="item.value" :value="item.value">{{ item.name }}</Option>
        </Select>
        <Select v-model="form.params.fmt" placeholder="日期格式" v-if="form.type === 'formatDate'" style="width: 60%">
          <Option v-for="item in ['yyyy','yyyy年','yyyy-MM','yyyy年MM月','yyyy-MM-dd','yyyy年MM月dd日','yyyy-MM-dd hh:mm:ss','yyyy年MM月dd日 hh点mm分ss秒','yyyy-MM-dd hh:mm','yyyy年MM月dd日 hh点mm分']" :key="item" :value="item">{{ item }}</Option>
        </Select>
        <Select v-model="form.params.parentName" placeholder="数据项" v-if="form.type === 'baseData'" style="width: 60%">
          <Option v-for="item in Object.keys(baseData)" :key="item" :value="item">{{ item }}</Option>
        </Select>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="save">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>
<script>
import edit from '@/mixins/edit'

export default {
  name: 'projectEdit',
  mixins: [edit],
  data() {
    return {
      form: {
        type: '',
        key: '',
        keyName: '',
        force: 1,
        order: null,
        keyId: '',
        ID: '',
        params: {},
        typeParams: {}
      },
    }
  },
  mounted() {
    this.form.keyId = this.parentID;
    this.form.type = 'none';
    if (this.midData.length === 1) {
      Object.keys(this.form).forEach((item) => {
        this.form[item] = this.midData[0][item];
      })
      this.form.params = JSON.parse(this.midData[0].typeParams);
      this.form.typeParams = {};
    }
    this.form.force = 1;
  },
  methods: {
    save() {
      this.form.typeParams = JSON.stringify(this.form.params);
      this.ok('pageListKeys/AddOrEdit');
    },
  }
}
</script>

<style lang="less" scoped>
</style>
