<template>
<!-- 暂无用 -->
  <div>
    <Tabs v-model="tab">
      <TabPane label="服务机构" name="1">
        <OrgComponent :limit="1" :oType="3" @on-ok="getOrgData" v-if="tab === '1'"></OrgComponent>
      </TabPane>
      <TabPane label="保险公司" name="2">
        <OrgComponent :limit="1" :oType="1" @on-ok="getOrgData" v-if="tab === '2'"></OrgComponent>
      </TabPane>
    </Tabs>
  </div>
</template>

<script>
import OrgComponent from "_c/org";

export default {
  components: {
    OrgComponent
  },
  computed: {},
  data() {
    return {
      tab: '1'
    }
  },
  mounted() {},
  methods: {
    getOrgData(data) {
      this.$emit('on-ok', data)
    }
  }
}
</script>
