<template>
  <div>
    <ys-page-list
        ref="table"
        :tableData="midData"
        :header="false"
        :showContextMenu="false"
        :multiColumn="false"
        :footer="false"
        :headerColumns="headerColumns">
    </ys-page-list>
  </div>

</template>

<script>
import pageList from '@/mixins/pageList.js'

export default {
  name: 'projects',
  mixins: [pageList],
  data() {
    return {
      params: {
        id: '',
        keyword: '',
      },
      putInFlag: false,
      headerColumns: [
        {width: 120, title: '抬头', key: 'title', align: 'center'},
        {minWidth: 100, title: '对应KEY', key: 'key', align: 'center'},
        {width: 100, title: '类型', key: 'type', align: 'center'},
        {width: 100, title: '宽度类型', key: 'widthType', align: 'center'},
        {width: 80, title: '宽度', key: 'width', align: 'center'},
        {width: 100, title: '对其方式', key: 'align', align: 'center'},
        {width: 100, title: '固定方式', key: 'fixed', align: 'center'},
        {width: 100, title: '允许拖拽', key: 'resizable', align: 'center', renderConfig: {type: 'baseData', parentName: '是否'}},
        {width: 100, title: '是否不换行', key: 'tooltip', align: 'center', renderConfig: {type: 'baseData', parentName: '是否'}},
        {width: 100, title: '显示排序', key: 'sortable', align: 'center', renderConfig: {type: 'baseData', parentName: '是否'}},
        {width: 100, title: '是否显示', key: 'isShow', align: 'center', renderConfig: {type: 'baseData', parentName: '是否'}},
      ]
    }
  },

  mounted() {
  },
  methods: {}
}
</script>
