<template>
  <div class="home">
    <ys-page-list
        ref="table"
        action="/apilogs/List"
        :params="params"
        :tableHeight="tableHeight"
        :multiBtn="multiBtn"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="时间周期" prop="duration">
          <ys-date-picker type="datetimerange" style="width: 320px;" v-model="params.dateTime" placeholder="时间周期"></ys-date-picker>
        </FormItem>
        <FormItem label="最小调用时间" prop="duration">
          <InputNumber placeholder="最小调用时间" clearable style="width: 150px;" v-model="params.duration"></InputNumber>
        </FormItem>
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="关键词" clearable style="width: 150px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template slot="detail">
        <Detail :tabName="tabName" :mid="detailID"></Detail>
      </template>
    </ys-page-list>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'
import Detail from "@/views/base/logs/detail";

export default {
  name: 'projects',
  mixins: [pageList],
  components: {Detail},
  data() {
    return {
      params: {
        dateTime: '',
        startAt: '',
        endAt: '',
        duration: null,
        keyword: '',
      },
      putInFlag: false,
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 50, align: 'center'},
        {width: 130, title: '来源IP', tooltip: true, key: 'addr', align: 'left',},
        {minWidth: 130, title: '接口地址', key: 'url', align: 'left', renderConfig: {type: 'gotoDetail'}},
        {width: 100, title: 'method', key: 'method', align: 'left'},
        {width: 100, title: '耗时(ms)', tooltip: true, key: 'duration', align: 'left'},
        {minWidth: 100, title: 'errMsg', tooltip: true, key: 'errMsg', align: 'left'},
        {width: 100, title: 'errCode', tooltip: true, key: 'errCode', align: 'left'},
        {width: 180, title: '创建时间', tooltip: true, key: 'createdAt', align: 'left'},
      ]
    }
  },
  computed: {
    multiBtn() {
      return [
        {
          children: [
            {
              type: 'detail',
              title: '查看详情',
              icon: 'md-eye',
              disabled: this.tableSelectItem.length !== 1
            },
          ]
        },

      ]
    },
  },
  mounted() {
    this.params.orgId = this.mid;
    this.getList();
  },
  methods: {
    getRegion(data) {
      this.params.countyCode = data[2] ? data[2].code : '';
      this.params.cityCode = data[1] ? data[1].code : '';
      this.params.provinceCode = data[0] ? data[0].code : '';
    }
  }
}
</script>
