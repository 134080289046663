<template>
  <div>
    <Form ref="form" class="editForm twoPartsEditForm" :model="form" :rules="ruleValidate" inline :label-width="80" :style="{maxHeight:'calc(100vh - 200px)'}" style="overflow-y: auto">
      <FormItem label="大项名称" prop="iname">
        <Input v-model="form.iname" placeholder="大项名称"></Input>
      </FormItem>
      <FormItem label="序号" prop="num">
        <InputNumber :min="0" style="width: 100%" v-model="form.num" placeholder="总分"></InputNumber>
      </FormItem>
      <FormItem label="总分" prop="score">
        <InputNumber :min="0" style="width: 100%" v-model="form.score" placeholder="总分"></InputNumber>
      </FormItem>
      <FormItem label="及格分" prop="passScore">
        <InputNumber :min="0" style="width: 100%" v-model="form.passScore" placeholder="及格分"></InputNumber>
      </FormItem>
      <FormItem label="备注" prop="remarks" style="width: 100%">
        <Input v-model="form.remarks" :autosize="{minRows: 2,maxRows: 5}" type="textarea" maxlength="128" show-word-limit placeholder="备注"></Input>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok(form.id?'/standard/dictItem/Edit':'/standard/dictItem/Add')">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>
<script>
import edit from '@/mixins/edit'
import {getItemInfo} from "@/api/base";

export default {
  name: 'projectEdit',
  mixins: [edit],
  data() {
    return {
      ruleValidate: {
        iname: [
          {required: true, message: '请填写大项名称', trigger: 'blur'}
        ],
        score: [
          {required: true, type: 'number', message: '请设定总分', trigger: 'change'}
        ],
        passScore: [
          {required: true, type: 'number', message: '请设定及格分', trigger: 'change'}
        ],
      },
      form: {
        id: '',
        num: 1,
        iname: '',
        remarks: '',
        type: 1,
        general: 1,
        score: 10,
        passScore: 6,
      },
    }
  },
  mounted() {
    if (this.mid) {
      getItemInfo({id: this.mid}).then((res) => {
        Object.keys(this.form).forEach((item) => {
          this.form[item] = res.data[item]
        })
      })
    } else {
      this.form.dictId = this.midData[0].id;
      this.form.categoryId = this.midData[0].categoryId;
    }
  },
  methods: {}
}
</script>

