<template>
  <div>
    <Form ref="form" class="editForm twoPartsEditForm" :model="form" :rules="ruleValidate" inline :label-width="80" :style="{maxHeight:'calc(100vh - 200px)'}" style="overflow-y: auto">
      <FormItem label="KEY" prop="key">
        <Input v-model="form.key" placeholder="KEY"/>
      </FormItem>
      <FormItem label="项名称" prop="label">
        <Input v-model="form.label" placeholder="项名称"/>
      </FormItem>
      <FormItem label="提示文字" prop="placeholder">
        <Input v-model="form.placeholder" placeholder="提示文字"/>
      </FormItem>
      <FormItem label="类型" prop="originType">
        <Select v-model="form.originType" @on-change="originTypeChange" placeholder="类型">
          <Option value="input">输入框</Option>
          <Option value="baseData">数据项</Option>
          <Option value="region">区域选择</Option>
          <Option value="dateTimer">日期选择</Option>
          <Option value="checkBox">复选框</Option>
        </Select>
      </FormItem>
      <FormItem label="数据项值" prop="originValue" v-if="form.originType === 'baseData'">
        <Select @on-change="baseDataChange" v-model="form.originValue" filterable placeholder="数据项">
          <Option v-for="item in Object.keys(baseData)" :key="item" :value="item">{{ item }}</Option>
        </Select>
      </FormItem>
      <FormItem label="可选数" prop="limit">
        <Select v-model="form.limit" placeholder="可选数">
          <Option :value="1">1个</Option>
          <Option :value="0">0个(不限)</Option>
        </Select>
      </FormItem>
      <FormItem label="默认值" prop="defaultValue">
        <Input v-model="form.defaultValue" clearable placeholder="默认值" v-if="form.originType==='input'"/>
        <ys-region clearable changeOnSelect @on-change="region" v-model="form.defaultValue" placeholder="默认值" style="width: 100%" v-if="form.originType==='region'"/>
        <Select transfer clearable v-model="form.defaultValue" placeholder="可搜索" filterable v-if="form.originType === 'baseData'">
          <Option v-for="item in baseData[form.originValue]" :key="item.value" :value="item.value">{{ item.name }}</Option>
        </Select>
        <ys-date-picker clearable v-model="form.defaultValue" style="width: 100%" placeholder="默认值" v-if="form.originType==='dateTimer'"></ys-date-picker>
        <Checkbox border v-model="form.defaultValue" v-if="form.originType === 'checkBox'">{{ form.label }}</Checkbox>
      </FormItem>
      <FormItem label="宽度" prop="width">
        <InputNumber :min="0" style="width: 100%" :max="300" v-model="form.width" placeholder="宽度"/>
      </FormItem>
      <FormItem label="排序" prop="order">
        <InputNumber style="width: 100%"  v-model="form.order" placeholder="排序"/>
      </FormItem>
      <FormItem label="不折叠" prop="show">
        <Tooltip placement="top" content="开起后不会被隐藏折叠！">
          <i-switch :trueValue="1" :falseValue="0" v-model="form.show"></i-switch>
        </Tooltip>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok('/filterItem/AddOrEdit')">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>
<script>
import edit from '@/mixins/edit'
import {getFilterInfo} from "@/api/base";

export default {
  name: 'projectEdit',
  mixins: [edit],
  data() {
    return {
      ruleValidate: {
        key: [
          {required: true, message: '请填写key', trigger: 'blur'}
        ],
        label: [
          {required: true, message: '请填写项名称', trigger: 'blur'}
        ],
        area: [
          {required: true, type: 'string', message: '请选择地区', trigger: 'change'}
        ],
        phone: [
          {required: true, pattern: this.$reg.phoneOrTelReg.reg, message: this.$reg.phoneOrTelReg.tips, trigger: 'blur'}
        ],
      },
      form: {
        id: '',
        captureKey: '',
        clearable: 1,
        defaultValue: '',
        key: '',
        label: '',
        limit: 1,
        keyId: '',
        originType: 'input',
        originValue: '',
        placeholder: '请选择',
        valueType: 'string',
        width: 120,
        order: 0,
        show: 0,
      },
    }
  },
  mounted() {
    if (this.mid) {
      getFilterInfo({id: this.mid}).then((res) => {
        Object.keys(this.form).forEach((item) => {
          this.form[item] = res.data[item]
        })
        if (this.form.defaultValue) {
          switch (this.form.valueType) {
            case 'number':
              this.form.defaultValue = this.form.defaultValue ? Number(this.form.defaultValue) : null;
              break;
            case 'array':
              this.form.defaultValue = this.form.defaultValue ? JSON.parse(this.form.defaultValue) : [];
              break;
            case 'boolean':
              this.form.defaultValue = this.form.defaultValue ? !!this.form.defaultValue : false;
              break;
          }
        }
        this.form.keyId = res.data.KeyId
      })
    } else {
      this.form.keyId = this.parentID;
    }
  },
  methods: {
    originTypeChange() {
      this.form.defaultValue = '';
      this.form.captureKey = this.form.originType === 'region' ? JSON.stringify(['provinceCode', 'cityCode', 'countyCode']) : '';
      switch (this.form.originType) {
        case 'checkBox':
          this.form.valueType = 'boolean';
          break;
        default:
          this.form.valueType = 'string';
          break;
      }
    },
    baseDataChange() {
      this.form.valueType = typeof this.baseData[this.form.originValue][0].value;
    },
    del(index) {
      this.form.keysForm.splice(index, 1)
    },
    region(event) {
      this.form.defaultValue = event.length > 0 ? event[event.length - 1].code : '';
    },
    add() {
      this.form.keysForm.push({key: '', keyName: '', type: 'none', typeParams: {},})
    },
  }
}
</script>

<style lang="less" scoped>
</style>
