<template>
  <div style="padding: 0 30px;">
    <ul class="azxType">
      <li v-for="(item,index) in azxType" :key="index" class="item" @click="choice(index)">
        <div class="box" :class="{active:typeIndex===index,disabled:formData.id}">
          <div class="choice" v-if="typeIndex===index">
            <Icon type="md-checkmark" size="25" color="#ffffff"/>
          </div>
          <Icon :type="item.icon" size="55"/>
          <p class="name">{{ item.name }}</p>
          <p class="title">（{{ item.title }}）</p>
        </div>
      </li>
    </ul>
    <Alert show-icon type="warning">
      {{ azxType[typeIndex].name }}
      <template slot="desc">{{ azxType[typeIndex].desc }}</template>
    </Alert>
  </div>
</template>

<script>

export default {
  name: "policyType",
  props: {
    formData: {
      type: Object, default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      policyType: [101],
      typeIndex: 0,
      azxType: [
        {type: 101, icon: 'md-paper', name: '通用安责险', title: '非共保体模式', desc: '通用安责险全称为安全生产责任保险，是指保险机构对投保的生产经营单位发生的生产安全事故造成的人员伤亡和有关经济损失等予以赔偿，并且为投保的生产经营单位提供事故预防服务的商业保险。'},
        {type: 101, icon: 'md-list-box', name: '通用安责险', title: '共保体单一模式', desc: '共保单一安责险为通用安责险的一个扩展安责险类型，承保单位为共保体，按成员份额设置分配保费比例，此功能为特殊功能，需要特殊申请才能开放使用！'},
        {type: 102, icon: 'ios-browsers', name: '组合模式安责险', title: '共保体组合模式', desc: '共保组合安责险为通用安责险的一个扩展安责险类型，包含从业者安责险和第三者安责险，此功能为特殊功能，需要特殊申请才能开放使用！'}
      ]
    }
  },
  computed: {},
  mounted() {
    if (this.formData.subType) {
      if (this.formData.subType === 101) {
        this.typeIndex = this.formData.isCoIns === 1 ? 1 : 0
      } else {
        this.typeIndex = 2;
      }
    }
  },
  methods: {
    choice(index) {
      if (this.typeIndex === index) return;
      if (this.formData.id) {
        this.$Notice.error({
          title: "操作失败！",
          desc: '续保和修改模式下无法修改保单类型！'
        });
        return;
      }
      this.typeIndex = index
    },
  }
}
</script>

<style scoped lang="less">

</style>