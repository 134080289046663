<template>
  <div>
    <Form ref="form" class="editForm twoPartsEditForm" :model="form" :rules="ruleValidate" inline :label-width="80" :style="{maxHeight:'calc(100vh - 200px)'}" style="overflow-y: auto">
      <FormItem label="行政区名" prop="name">
        <Input v-model="form.name" placeholder="行政区名"/>
      </FormItem>
      <FormItem label="区号" prop="quhao">
        <Input v-model="form.quhao" maxlength="4" show-word-limit placeholder="区号"/>
      </FormItem>
      <FormItem label="地区code" prop="code">
        <Input v-model="form.code" placeholder="地区code"/>
      </FormItem>
      <FormItem label="系统区域" prop="buildIn">
        <Select v-model="form.buildIn" placeholder="是否">
          <Option v-for="(item,index) in baseData['是否']" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem label="级别" prop="level">
        <Select disabled v-model="form.level" placeholder="级别">
          <Option v-for="(item,index) in levelList" :value="item.value" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <!-- 
      <FormItem label="备注" prop="desc" style="width: 100%">
        <Input v-model="form.desc" type="textarea" maxlength="128" show-word-limit :autosize="{minRows: 3,maxRows: 5}" placeholder="备注"/>
      </FormItem> -->
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok('/area/Add', form.id ? 'edit': 'add')">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>
<script>
import edit from '@/mixins/edit'
import {areaInfo} from '@/api/base'

export default {
  name: 'projectEdit',
  mixins: [edit],
  props: {
    oType: {type: Number, default: 2},
    treeData: {type: Array, default: () => []},
  },
  data() {
    return {
      ruleValidate: {
        title: [
          {required: true, message: '请填写菜单名称', trigger: 'blur'}
        ],
      },
      levelList: [{
        name: "省级",
        value: 1,
      },{
        name: "市级",
        value: 2,
      },{
        name: "县级",
        value: 3,
      },],
      form: {
        id: '',
        pid: '',
        buildIn: '',
        name: '',
        quhao: '',
        code: '',
        level: '',
      },
    }
  },
  mounted() {
    if (this.mid) {
      areaInfo({id: this.mid, noPending: true}).then(res => {
        if (res.code === 200) {
          Object.keys(this.form).forEach((item) => {
            this.form[item] = res.data[item] || null;
          })
          this.form.name = res.data.iname
        }
      })
    } else {
      if (this.midData.length >= 1) {
        this.form.level = this.midData[0].level + 1
        this.form.pid = this.midData[0].id
      } else {
        this.form.level = 1
        this.form.pid = 0
      }
    }
  },
  methods: {}
}
</script>

<style lang="less" scoped>
</style>