<template>
  <div class="home">
    <ys-page-list
        ref="table"
        action="expert/List"
        keyID="46676E53596B11EC9C5C00163E15C775"
        :params="params"
        :multiBtn="multiBtn"
        showContextMenu
        :multiColumn="true"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns"
    >
      <template slot="search">
        <FormItem label="专家类型" prop="type">
          <Select v-model="params.type" placeholder="专家类型" style="width: 120px;">
            <Option v-for="(item,index) in baseData['专家类型']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="专家状态" prop="status">
          <Select v-model="params.status" clearable placeholder="专家状态" style="width: 120px;">
            <Option v-for="(item,index) in (baseData['专家状态'] || [])" :value="item.value" :key="index">{{ item.name }}
            </Option>
          </Select>
        </FormItem>
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="关键词" clearable style="width: 150px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template slot="btn">
        <ButtonGroup>
          <Button type="primary" @click="add">新增专家</Button>
          <Button type="primary" @click="putInFlag=true">导入专家</Button>
          <Button type="primary" @click="putInConfigFlag=true">导入专家服务范围</Button>
        </ButtonGroup>
      </template>
      <!-- 侧边弹出抽屉(查看详情) -->
      <template slot="detail">
        <Detail :tabName="tabName" :mid="detailID"></Detail>
      </template>
    </ys-page-list>
    <!-- 弹出新增/修改框 -->
    <ys-modal v-model="modShow" :width="800" title="新增/修改专家">
      <Edit :mid="detailID" @on-ok="getList"></Edit>
    </ys-modal>
    <ys-modal v-model="enterFlag" title="选择平台">
      <enter :mid-data="tableSelectItem" type="expert"></enter>
    </ys-modal>
    <ys-modal v-model="putInFlag" :width="500" title="导入专家">
      <expert-put-in></expert-put-in>
    </ys-modal>
    <ys-modal v-model="putInConfigFlag" :width="500" title="导入专家服务范围">
      <expert-put-in-config></expert-put-in-config>
    </ys-modal>
    <ys-modal v-model="modFlag" title="修改密码">
      <modPassword :mid="tableSelectItem.length > 0 ? tableSelectItem[0].userId : ''"></modPassword>
    </ys-modal>
    <ys-modal v-model="orgFlag" :width="1000" title="选择机构">
      <org-component :isChildren="false" @on-ok="setOrg"></org-component>
    </ys-modal>
    <ys-modal v-model="unbindFlag" :width="500" title="解绑">
      <expert-unbind-org :mid-data="tableSelectItem" @on-ok="getList"></expert-unbind-org>
    </ys-modal>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'
import Detail from "@/views/experts/detail.vue";
import {expertStatus} from "@/api/expert";
import enter from "@/views/org/member/enter";
import modPassword from '_c/header/ModPwd.vue'
import OrgComponent from "_c/org.vue";
import {expertBind} from "../../api/expert";
import ExpertUnbindOrg from "./unbind.vue";

export default {
  name: 'experts',
  mixins: [pageList],
  components: {
    ExpertUnbindOrg,
    OrgComponent,
    enter,
    Detail,
    modPassword,
    Edit: () => import('./edit.vue'),
    expertPutIn: () => import('./putIn.vue'),
    expertPutInConfig: () => import('./putInConfig.vue'),
  },
  data() {
    return {
      params: { // 请求体
        type: 10,
        status: '',
        keyword: '',
      },
      putInConfigFlag: false,
      unbindFlag: false,
      enterFlag: false,
      orgFlag: false,
      putInFlag: false,
      modFlag: false,
      headerColumns: [ // column表格
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 50, align: 'center'},
        {width: 75, title: 'logo', key: 'pictureId', align: 'center', renderConfig: {type: 'image'}},
        {minWidth: 230, title: '姓名', key: 'iname', align: 'left', renderConfig: {type: 'gotoDetail'}},
        {width: 100, title: '专家类型', key: 'type', align: 'center', renderConfig: {type: 'baseData', parentName: '专家类型'}},
        {width: 200, title: '所属机构', key: 'orgName', align: 'center'},
        {width: 130, title: '登录账号', key: 'username', align: 'left'},
        {width: 130, title: '联系电话', key: 'phone', align: 'left'},
        {width: 180, title: '身份证号码', key: 'identityCode', align: 'left'},
        {width: 150, title: '职称', key: 'position', align: 'left'},
        {width: 200, title: '专业方向', key: 'territory', align: 'left'},
        {width: 130, title: '开始工作日期', key: 'workStart', align: 'center'},
        {width: 100, title: '工作年限', key: 'workYears', align: 'center'},
        {width: 80, title: '专家状态', key: 'status', align: 'center', renderConfig: {type: 'baseData', parentName: '专家状态'}},
      ]
    }
  },
  computed: {
    multiBtn() {
      return [
        {
          children: [
            {
              type: 'detail',
              title: '查看详情',
              targetUrl: '/main/experts/detail/' + this.detailID,
              icon: 'md-eye',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.modShow = true,
              title: '编辑',
              icon: 'md-create',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.delItem('/expert/Delete', {id: this.tableSelectItem[0].id}),
              iconColor: '#ed4014',
              title: '删除',
              icon: 'md-trash',
              disabled: this.tableSelectItem.length !== 1
            },
          ]
        },
        {
          hiddenBtnIcon: true,
          children: [
            {
              click: () => this.enable(1),
              title: '启用',
              icon: 'md-checkmark-circle',
              disabled: this.tableSelectItem.length !== 1 || this.tableSelectItem[0].status === 1
            },
            {
              click: () => this.enable(2),
              title: '停用',
              icon: 'md-close-circle',
              disabled: this.tableSelectItem.length !== 1 || this.tableSelectItem[0].status === 2
            },
            {
              click: () => this.enterFlag = true,
              title: '进入账户',
              icon: 'ios-contact',
              disabled: this.tableSelectItem.length !== 1
            },
          ]
        },
        {
          children: [
            {
              click: () => this.modFlag = true,
              title: '修改密码',
              icon: 'ios-lock',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.orgFlag = true,
              title: '绑定机构',
              icon: 'md-checkbox',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.unbindFlag = true,
              title: '解绑机构',
              icon: 'md-checkbox',
              disabled: this.tableSelectItem.length !== 1
            },
          ]
        }
      ]
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    setOrg(data) {
      expertBind({org_id: data?.length === 1 ? data[0].id : '', expert_id: this.tableSelectItem[0].id}).then((res) => {
        if (res.code === 200) {
          this.$Notice.success({
            title: '提示',
            desc: '操作成功！'
          });
          this.getList();
        }
      })
    },
    enable(type) {
      this.$Modal.confirm({
        title: '提示',
        content: '确定要' + (type === 2 ? '停用' : '启用') + '么？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          expertStatus(type, {id: this.tableSelectItem[0].id}).then((res) => {
            if (res.code === 200) {
              this.getList();
            }
          })
        }
      })
    },
  }
}
</script>
