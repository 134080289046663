<template>
  <div>
    <ys-detail :icon="detailIcon" :loading="loading" :name="detail.title">
      <template slot="brief">
        <ys-cell title="服务期间" :label="detail.startAt+'至'+detail.endAt"/>
        <ys-cell title="项目名称" :label="detail.projectName"/>
        <ys-cell title="总服务次数" :label="String(detail.serveCount+detail.specialCount+detail.rescueCount+detail.safetyCount+detail.inspectsCount)"/>
        <ys-cell title="组长" :label="detail.leaderName"/>
      </template>
      <template slot="btn">

      </template>
      <template slot="main-left">
        <Tabs type="card" v-model="tab" @on-click="tabClick" v-if="policyInfo.id">
          <Tab-pane label="计划明细" icon="ios-paper-outline" name="1">
            <SubPlan :mid="detailID" :table-height="pageListHeight" :modData="[policyInfo]" :midData="[detail]" v-if="tab==='1'"></SubPlan>
          </Tab-pane>
          <Tab-pane label="保单信息" icon="ios-paper" name="2">
            <policy-info :mid="detail.policyId" v-if="tab==='2'"></policy-info>
          </Tab-pane>
        </Tabs>
      </template>
      <template slot="main-right">
        <h1 class="h1Title">其他信息</h1>
        <div style="text-align: center">暂未开放！</div>
      </template>
    </ys-detail>
  </div>
</template>

<script>
import detail from '@/mixins/detail.js'
import {planInfo} from "@/api/plan";
import {policyInfo} from "@/api/policy";
import PolicyInfo from "@/views/policy/detail/baseInfo.vue";
import SubPlan from "@/views/task/plan/detail/subPlan/index.vue";
import {formatDate} from "ys-admin";

export default {
  name: '',
  mixins: [detail],
  props: {},
  components: {
    PolicyInfo,
    SubPlan
  },
  data() {
    return {
      settleFlag: false,
      modShow: false,
      policyInfo: {},
    }
  },
  created() {
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    getDetail() {
      this.loading = true;
      planInfo({id: this.detailID}).then((res) => {
        if (res.code === 200) {
          this.detail = res.data
          this.detail.startAt = formatDate(new Date(this.detail.startAt), 'yyyy-MM-dd')
          this.detail.endAt = formatDate(new Date(this.detail.endAt), 'yyyy-MM-dd')
          this.setTagName(this.detail.title)
          if (this.tab === '1') this.policy()
        }
        this.loading = false;
      })
    },
    tabClick(name) {
      switch (name) {
        case '1':
          this.policy()
          break;
      }
    },
    async policy() {
      await policyInfo({id: this.detail.policyId, random: new Date().getTime()}).then(res => {
        if (res.code === 200) {
          this.policyInfo = res.data;
        }
      })
    }
  }
}
</script>

<style>

</style>