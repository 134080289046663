<template>
  <ys-detail :icon="detailIcon" :loading="loading" :name="detail.iname">
    <Tag size="medium" color="blue" slot="status">{{ detail.category|getBaseDataName('平台类型') }}</Tag>
    <template slot="brief">
      <ys-cell title="所属机构" :label="detail.orgName"/>
      <ys-cell title="角色类型" :label="detail.category|getBaseDataName('平台类型')"/>
      <ys-cell title="创建时间" :label="detail.createdAt"/>
      <ys-cell title="更新时间" :label="detail.updatedAt"/>
    </template>
    <template slot="main-left">
      <Tabs v-model="tab" name="detail" type="card">
        <Tab-pane label="角色账号" name="1" icon="ios-contacts">
          <roleUsers :tableHeight="pageListHeight" :mid="detailID" v-if="tab==='1'"></roleUsers>
        </Tab-pane>
      </Tabs>
    </template>
    <template slot="main-right">
      <h1 class="h1Title">菜单权限</h1>
      <Tree ref="menuTree" empty-text="该角色无菜单权限" :data="menuList" show-checkbox :style="{height:'calc(100vh - 190px)'}" style="overflow-y: scroll"></Tree>
    </template>
  </ys-detail>
</template>

<script>
import detailMixins from '@/mixins/detail'
import {roleInfo} from "@/api/base";

export default {
  name: "detail",
  mixins: [detailMixins],
  components: {
    'roleUsers':()=>import('@/views/base/role/detail/roleUsers')
  },
  data() {
    return {
      menuList: [],
    }
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getBaseMenuList() {
      this.$get("/menu/RoleMenuListByAdm", {oType: this.detail.category, roleId: this.detail.id}).then(res => {
        if (res.code === 200) {
          const menu = (res.data || []).filter(item => !item.hideInMenu);
          this.menuList = this.$listToTree(menu, {parentKey: 'parentId', sonKey: 'id', opLevelVal: 0, addProperties: {expand: true,disabled: true}});
        }
      })
    },
    getDetail() {
      this.loading = true;
      roleInfo({id: this.detailID}).then(res => {
        this.loading = false;
        if (res.code === 200) {
          this.detail = res.data;
        }
        this.getBaseMenuList()
        this.setTagName(this.detail.number)
      })
    }
  }
}
</script>

<style scoped>

</style>