<template>
  <div>
    <Form ref="form" class="editForm twoPartsEditForm" :model="form" :rules="ruleValidate" inline :label-width="60" :style="{maxHeight:'calc(100vh - 200px)'}" style="overflow-y: auto">
      <FormItem label="序号" prop="index">
        <InputNumber :min="0" style="width: 100%" v-model="form.index" placeholder="序号，越小越前面"></InputNumber>
      </FormItem>
      <FormItem label="款项" prop="remarks">
        <Input v-model="form.remarks" placeholder="款项"></Input>
      </FormItem>
      <FormItem label="依据" prop="desc" style="width: 100%">
        <Input v-model="form.desc" :autosize="{minRows: 2,maxRows: 5}" type="textarea" maxlength="128" show-word-limit placeholder="依据"></Input>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok(form.id?'/standard/dictReason/Edit':'/standard/dictReason/Add')">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>
<script>
import edit from '@/mixins/edit'
import {getDictReasonInfo} from "@/api/base";

export default {
  name: 'projectEdit',
  mixins: [edit],
  data() {
    return {
      ruleValidate: {
        desc: [
          {required: true, message: '请填写风险项依据', trigger: 'blur'}
        ],
      },
      form: {
        id: '',
        index: 0,
        remarks: '',
        categoryId: '',
        dictId: '',
        dictItemId: '',
        desc: '',
      },
    }
  },
  mounted() {
    if (this.mid) {
      getDictReasonInfo({id: this.mid}).then((res) => {
        Object.keys(this.form).forEach((item) => {
          this.form[item] = res.data[item]
        })
      })
    } else {
      let data = this.midData;
      if (data[0].categoryId) {
        this.form.categoryId = data[0].categoryId;
        if (data[0].dictId) {
          this.form.dictId = data[0].dictId;
          this.form.dictItemId = data[0].id;
        } else {
          this.form.dictId = data[0].id;
        }
      } else {
        this.form.categoryId = data[0].id;
      }
    }
  },
  methods: {}
}
</script>

