<template>
  <div>
    <template v-if="!failFlag">
      <Alert>
        请按照数据模板的格式准备要导入的数据。
        <template slot="desc">
          <p>1、模板中的表头名称不可更改，表头行不能删除</p>
          <p>2、其中*为必填项，必须保留</p>
          <p>3、点击此处<a href="javascript:;" @click="downFile">下载模板</a></p>
        </template>
      </Alert>
      <h1 class="h1Title">上传文件</h1>
      <ys-upload :defaultList="defaultList" type="fullList" :headers="{'token':token,'orgId':orgId}" :data="{'id':mid,update:true}" @on-success="success" :limit="1" :action="'/web/standard/Import'"/>
    </template>
    <template v-else>
      <ul class="failList" :style="{maxHeight:'calc(100vh - 300px)'}">
        <li v-for="(item,index) in failData" :key="index">{{ item }}</li>
      </ul>
      <Button type="primary" long @click="failFlag=false">重新上传</Button>
    </template>
  </div>
</template>
<script>
import edit from '@/mixins/edit'; //引入混入组件edit

export default {
  name: 'projectPutIn',
  mixins: [edit],
  data() {
    return {
      failFlag: false,
      defaultList: [],
      failData: [],
      headerColumns: [
        {title: '序号', width: 50, key: 'index', align: 'center'},
        {minWidth: 230, title: '名称', tooltip: true, key: 'iname', align: 'left',},
        {width: 230, title: '施工许可证', tooltip: true, key: 'licenseNum', align: 'left'},
        {width: 230, title: '结果', tooltip: true, key: 'msg', align: 'left'},
      ],
    }
  },
  computed: {
    orgId() {
      return sessionStorage.getItem('orgId')
    }
  },
  mounted() {
  },
  methods: {
    success(file, res) {
      if (res.code === 200) {
        if (!res.data) {
          this.$Modal.success({
            title: '操作成功',
            content: '导入成功',
          })
          this.close()
        } else {
          this.defaultList = [];
          this.failData = res.data;
          this.failFlag = true;
          this.$emit('on-ok')
        }
      } else {
        this.$Modal.error({
          title: '操作失败',
          content: res.msg + '，请重新上传！',
        })
        this.defaultList = [];
      }
    },
    downFile() {
      window.location.href = "/group1/M00/module/%E5%AD%97%E5%85%B8%E5%AF%BC%E5%85%A5%E6%A8%A1%E7%89%88.xlsx"
    }
  }
}
</script>
<style lang="less" scoped>
.failList {
  overflow-y: auto;

  li {
    list-style: none;
    padding: 3px 0;
  }
}
</style>
