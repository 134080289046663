<template>
  <div :style="iframesHeight">
    <iframe frameborder="0" src="https://lbs.qq.com/getPoint/" height="100%" width="100%"></iframe>
  </div>
</template>
<script>
import edit from '@/mixins/edit'; //引入混入组件edit

export default {
  name: 'iframes',
  mixins: [edit],
  computed: {
    iframesHeight() {
      return {height: this.height - 106 + 'px'}
    },
  },
  methods: {}
}
</script>
