<template>
  <div>
    <Form ref="form" class="editForm" :model="form" :rules="ruleValidate" :label-width="80">
      <FormItem label="解绑机构" prop="orgId">
        <Select v-model="form.orgId">
          <Option v-for="(item) in orgList" :value="item.id" :key="item.id">{{ item.orgName }}</Option>
        </Select>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok('/expert/Bind-handle')">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>

<script>
import edit from '@/mixins/edit'

export default {
  name: 'expert-unbind-org',
  mixins: [edit],
  data() {
    return {
      ruleValidate: {
        orgId: [
          {required: true, message: '请选择解绑机构', trigger: 'change'}
        ],
      },
      form: {
        orgId: '',
        expertId: '',
      }
    }
  },
  computed: {
    orgList() {
      return this.midData[0]?.orgIds.split(',').map((item, index) => {
        return {
          id: item,
          orgName: this.midData[0].orgName.split(',')[index]
        }
      })

    }
  },
  mounted() {
    this.form.expertId = this.midData[0].id;
    this.form.orgId =this.midData[0]?.orgIds?.split(',')[0]
  },
  methods: {}

}
</script>
