<template>
  <div>
    <ys-page-list
        ref="table"
        action="dataCenter/org/List"
        :params="params"
        :showContextMenu="false"
        :isEmptySelect="isEmptySelect"
        :multiColumn="false"
        :tableHeight="height-260"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="平台类型" prop="platformId">
          <Select v-model="params.platformId" placeholder="机构类型" style="width: 220px">
            <Option v-for="(item,index) in platformList" :value="item.id" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="机构类型" prop="type">
          <Select v-model="params.type" placeholder="机构类型" style="width: 220px">
            <Option v-for="(item,index) in orgTypesData" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="关键词" clearable style="width: 150px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
    </ys-page-list>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok">确定</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList';
import components from '@/mixins/components';
import {get} from "ys-admin";

export default {
  name: 'org-component',
  mixins: [pageList, components],
  props: {
    oType: {type: [Number, String], default: 1}, // oType默认选中的值
  },
  computed: {
    orgTypesData() {
      let baseData = [];
      switch (this.params.oType) {
        case 1:
          baseData = this.baseData['保险公司类型'];
          break;
        case 2:
          baseData = this.baseData['职能部门类型'];
          break;
        case 3:
          baseData = this.baseData['服务机构类型'];
          break;
        case 4:
          baseData = this.baseData['参建单位类型'];
          break;
      }
      return baseData;
    }
  },
  data() {
    return {
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 80, align: 'center'},
        {minWidth: 230, title: '机构名称', key: 'name', align: 'left',},
        {width: 100, title: '组织类型', key: 'oType', align: 'center', renderConfig: {type: 'baseData', parentName: '组织类型'}},
        {width: 100, title: '机构类型', key: 'type', align: 'center', renderConfig: {type: 'baseData', parentName: '组织子类型'}},
        {width: 180, title: '社会信用代码', key: 'creditCode', align: 'left'},
        {width: 100, title: '法人', key: 'operName', align: 'left'},
        {width: 200, title: '注册地', key: 'belongOrg', align: 'left'},
        {width: 200, title: '成立日期', key: 'startDate', align: 'left'},
        {width: 200, title: '登记状态', key: 'status', align: 'left'},
        {width: 230, title: '办公地所在区', key: 'countyCode', align: 'left', renderConfig: {type: 'area', key: ['countyCode', 'cityCode', 'provinceCode']}},
      ],
      platformList: [],
      params: {
        countyCode: '',
        cityCode: '',
        provinceCode: '',
        oType: '',
        region: '',
        type: '',
        keyword: '',
        platformId: '',
      },
    }
  },
  mounted() {
    this.params.oType = this.oType;
    this.getList();
    this.getPlatform();
  },
  methods: {
    getPlatform() {
      get('/platform/List').then(res => {
        this.platformList = res.data.list;
      })
    },
  },
}
</script>
