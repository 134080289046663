<template>
  <div>
    <ys-page-list
        ref="table"
        :params="params"
        action="/projects/ListByOrg"
        :tableHeight="tableHeight"
        @on-selection-change="checkItem"
        :multiBtn="multiBtn"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="非管辖区域" prop="noArea">
          <Checkbox v-model="params.noArea" border>包含</Checkbox>
        </FormItem>
        <FormItem label="子公司数据" prop="isTop">
          <Checkbox v-model="params.isTop" border>不包含</Checkbox>
        </FormItem>
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="关键词" clearable style="width: 150px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template slot="btn">
        <ButtonGroup>
          <Button type="primary" @click="putIn" v-if="oType===2">导入项目</Button>
          <Button type="primary" @click="add" v-if="oType===2">绑定项目</Button>
        </ButtonGroup>
      </template>
      <template slot="detail">
        <Detail :mid="detailID"></Detail>
      </template>
    </ys-page-list>
    <ys-modal v-model="modShow" :width="1000" title="绑定项目">
      <unlinkProjects @on-ok="getData" :mid="mid"></unlinkProjects>
    </ys-modal>
  </div>
</template>

<script>
import pageListMixins from '@/mixins/pageList';
import {orgLinkProject, orgUnLinkProject, initGovProject} from "@/api/org";

export default {
  name: 'corp',
  mixins: [pageListMixins],
  components: {
    'unlinkProjects': () => import('@/components/unlinkProjects'),
    'Detail': () => import('@/views/projects/list/detail.vue')
  },
  props: {
    oType: {type: Number, default: null, required: true}
  },
  data() {
    return {
      params: {
        orgId: '',
        noArea: true,
        isTop: false,
      },
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 70, align: 'center'},
        {minWidth: 230, title: '项目名称', key: 'iname', align: 'left', renderConfig: {type: 'gotoDetail'}},
        {width: 120, title: '工程类型', key: 'category', align: 'left', renderConfig: {type: 'baseData', parentName: '项目类型'}},
        {width: 110, title: '安监号', key: 'code', align: 'left'},
        {width: 180, title: '施工许可证', key: 'licenseNum', align: 'left'},
        {width: 220, title: '施工单位', key: 'contractName', align: 'left'},
        {width: 230, title: '项目所属区', key: 'countyCode', align: 'left', renderConfig: {type: 'area', key: ['countyCode', 'cityCode', 'provinceCode']}},
        {width: 220, title: '承保机构', key: 'policyOrgName', align: 'left'},
        {width: 120, title: '项目阶段', tooltip: true, key: 'status', align: 'left', renderConfig: {type: 'baseData', parentName: '项目进度'}},
      ]
    }
  },
  computed: {
    multiBtn() {
      return [
        {
          children: [
            {
              type: 'detail',
              title: '查看详情',
              targetUrl: '/main/project/detail/' + (this.tableSelectItem.length === 1 ? this.tableSelectItem[0].id : null),
              icon: 'md-eye',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.unlink(),
              iconColor: '#ed4014',
              title: '解绑',
              icon: 'md-trash',
              show: this.oType === 2,
              disabled: this.tableSelectItem.length !== 1
            },
          ]
        }
      ]
    },
  },
  mounted() {
    this.params.orgId = this.mid;
    this.getList()
  },
  methods: {
    putIn() {
      this.$Modal.confirm({
        title: '提示',
        content: '确认要一键导入该机构管辖区域内所有项目么？如导入后有问题，可以批量解绑或者单个绑定！',
        okText: '确认',
        loading: true,
        cancelText: '取消',
        onOk: () => {
          initGovProject({id: this.mid}).then(res => {
            if (res.code === 200) {
              this.$Notice.success({
                title: '提示',
                desc: '操作成功！'
              });
              this.$Modal.remove();
              this.getList();
            }
          })
        }
      })
    },
    unlink() {
      this.$Modal.confirm({
        title: '提示',
        content: '确认要解绑该项目么？',
        okText: '确认',
        cancelText: '取消',
        onOk: () => {
          // 父机构解绑子机构项目市需要用子机构的orgId来解绑（待后端添加机构id）
          orgUnLinkProject({projectId: this.tableSelectItem[0].id, oType: this.oType, id: this.mid}).then(res => {
            if (res.code === 200) {
              this.$Notice.success({
                title: '提示',
                desc: '操作成功！'
              });
              this.getList();
            }
          })
        }
      })
    },
    getData(data) {
      orgLinkProject({id: this.mid, projectId: data[0].id,}).then(res => {
        if (res.code === 200) {
          this.$Notice.success({title: '提示', desc: '绑定成功!'});
          this.getList();
        }
      })
    },
  }
}
</script>
