<template>
  <div>
    <ys-cell-group>
      <ys-cell title="姓名" :label="detail.iname" />
      <ys-cell title="性别" :label="detail.gender|getBaseDataName('性别')" />
<!--      <ys-cell title="服务类型" :label="dynamicCheckTypeName"/>-->
<!--      <ys-cell title="专家类型" :label="categoryListName"/>-->
      <ys-cell title="专家类型" :label="detail.type|getBaseDataName('专家类型')"/>
      <ys-cell title="联系电话" :label="detail.phone" />
      <ys-cell title="身份证号码" :label="detail.identityCode" />
      <ys-cell title="出生日期" :label="detail.birthday" />
      <ys-cell title="职称" :label="detail.position" />
      <ys-cell title="学历" :label="detail.education" />
      <ys-cell title="专业方向" :label="detail.territory" />
      <ys-cell title="开始工作日期" :label="detail.workStart" />
      <ys-cell title="所在区域" :label="detail.areaId|getRegionByCode" />
      <ys-cell title="工作经历" :label="detail.experience" block />
      <ys-cell title="备注" :label="detail.remarks" block/>
    </ys-cell-group>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: "baseInfo",
  props: {detail: Object},
  computed: {
    ...mapGetters(['baseData']),
    categoryListName: function () {
      let data = [];
      if (this.detail.category) {
        this.baseData['项目类型'].forEach(item => {
          if (this.detail.category.split(',').indexOf(item.value) >= 0) {
            data.push(item.name)
          }
        })
      }
      return data.join('，');
    },
    dynamicCheckTypeName: function () {
      let data = [];
      if (this.detail.dynamicCheckType) {
        this.baseData['随机任务组类型'].forEach(item => {
          if (this.detail.dynamicCheckType.split(',').indexOf(String(item.value)) >= 0) {
            data.push(item.name)
          }
        })
      }
      return data.join('，');
    }
  },
}
</script>

<style scoped>

</style>