<template>
  <div>
    <Poptip trigger="hover" title="当前份额占比情况" :width="600">
      <div slot="content">
        <div v-if="rateList.length>0">
          <Alert type="warning">当前配置为：{{ extType|getBaseDataName('共保体配置类型') }}！ <br/>以下保费计算仅供参考，实际以保单详情页的保费计算为准！<br/>当前保费为：{{ premiumCost }}</Alert>
          <table cellspacing="1" width="100%" cellpadding="0" border="0" class="baseTable">
            <tr>
              <th width="60%" align="left">机构名称</th>
              <th width="20%" align="center">总保费</th>
              <th width="10%">比例</th>
              <th width="10%">份额</th>
              <th width="10%" v-if="extType!==1">激励比例</th>
              <th width="10%" v-if="extType!==1">激励保费</th>
            </tr>
            <tr v-for="(subItem) in rateList" :key="subItem.id">
              <td>{{ subItem.iname }}</td>
              <td align="center">{{ subItem.totalAmt || 0 }}元</td>
              <td align="center">{{ subItem.rate }}%</td>
              <td align="center">{{ subItem.cost || 0 }}元</td>
              <td align="center" v-if="extType!==1">{{ ownerId === subItem.insId ? subItem.extCost + '元' : '' }}</td>
              <td align="center" v-if="extType!==1">{{ ownerId === subItem.insId ? subItem.extRate + '%' : '' }}</td>
            </tr>
          </table>
        </div>
        <ys-empty title="请检查是否有承保机构，再选择适用份额配置和保额！" v-else></ys-empty>
        <div style="text-align: center;padding-bottom: 10px;">
          <Button @click="modShow=true" type="primary" v-if="conIns&&conIns.length===0&&insOrgId>0">创建份额</Button>
        </div>
      </div>
      <Icon type="ios-alert"/>
    </Poptip>
    <ys-modal v-model="modShow" :width="800" title="新增/修改配置">
      <Edit @on-ok="$emit('on-add')" :parentID="insOrgId"></Edit>
    </ys-modal>
  </div>

</template>

<script>
import {math} from "ys-admin";

export default {
  name: "rate",
  components: {
    'Edit': () => import('@/views/org/list/detail/rate/edit.vue'),
  },
  props: {
    conIns: {type: Array, default: null},
    member: {type: Array, default: () => []},
    premiumCost: {type: [Number, String], default: null},
    ownerId: {type: [Number, String], default: null},
    extType: {type: [Number], default: 1},
    insOrgId: {type: [Number], default: 0},
  },
  computed: {
    rateList() {
      if (this.member.length > 0 && this.premiumCost >= 0) {
        let totalAmt = 0; /*已分摊的保费*/
        let premiumCost = this.premiumCost; /*可分摊总保费，排除激励保费*/
        let extCost = 0; /*激励保费*/
        if (this.extType !== 3) {
          let extMember = this.member.find(item => item.insId === this.ownerId), extRate = 0;
          if (extMember) extRate = extMember.extRate;
          extCost = math.multiply(this.premiumCost, math.divide(extRate, 100));
          premiumCost = math.subtract(premiumCost, extCost);
        }
        let currentCost = 0;
        return this.member.map(({...item}, index) => {
          extCost = 0; /*激励保费*/
          if (index === this.member.length - 1) {
            currentCost = math.subtract(this.premiumCost, totalAmt).toFixed(2);
          } else {
            if (this.extType === 2) {
              currentCost = math.multiply(math.divide(item.rate, 100), premiumCost).toFixed(2);
            } else {
              currentCost = math.multiply(math.divide(item.rate, 100), premiumCost).toFixed(2);
            }
            totalAmt = math.add(totalAmt, currentCost);
          }
          if (this.extType !== 1 && item.insId === this.ownerId) {
            extCost = math.multiply(this.premiumCost, math.divide(item.extRate, 100))
            totalAmt = math.add(totalAmt, extCost);
          }
          item.totalAmt = math.add(extCost, currentCost);//总保费
          item.extCost = extCost;//激励份额
          item.cost = currentCost;//比例份额
          return item;
        })
      } else {
        return [];
      }
    },
  },
  data() {
    return {
      modShow: false,
    }
  }
}
</script>

<style scoped>

</style>