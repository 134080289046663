<template>
  <div>
    <Form ref="form" class="editForm" :model="form" :rules="ruleValidate" :label-width="80">
      <FormItem label="模板名称" prop="templateId">
        <Select v-model="form.templateId" placeholder="模板名称" transfer>
          <Option v-for="(item) in templateList" :value="item.id" :key="item.id">{{ item.name }}-{{ item.orgName }}</Option>
        </Select>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="putOut({url: 'report/Gen',name:name,params: form})">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>
<script>
import edit from '@/mixins/edit'
import {templateList} from "@/api/task";

export default {
  name: 'projectEdit',
  mixins: [edit],
  props: {
    type: {type: Number, default: 1},
    name: {type: String, default: ''},
  },
  data() {
    return {
      ruleValidate: {
        templateId: [
          {required: true, type: 'number', message: '请填选择模板', trigger: 'change'}
        ],
      },
      templateList: [],
      form: {
        checkId: '',
        templateId: '',
      },
    }
  },
  mounted() {
    this.getTemplateList()
  },
  methods: {
    putOut(config) {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          config = {
            url: config.url || '',
            params: config.params || {},
            name: config.name || this.$formatDate(new Date(), 'yyyy-MM-dd'),
            method: config.method || 'get'
          };
          this.modal.spinShow = true
          this.$axios({
            method: config.method,
            responseType: 'blob',
            url: 'report/Gen?checkId=' + this.mid + '&templateId=' + this.form.templateId,
          }).then((res) => {
            this.close()
            this.modal.spinShow = false
            const blob = new Blob([res.data], {type: 'application/octet-stream'});
            const fileName = config.name + '.docx';
            //IE10以上支持blob但是依然不支持download
            if ('download' in document.createElement('a')) { //支持a标签download的浏览器
              const link = document.createElement('a');//创建a标签
              link.download = fileName;
              link.style.display = 'none';
              link.href = URL.createObjectURL(blob);
              document.body.appendChild(link);
              link.click();
              URL.revokeObjectURL(link.href);
              document.body.removeChild(link);
            } else { //其他浏览器
              navigator.msSaveBlob(blob, fileName)
            }
          }).catch(() => {
            this.close()
            this.modal.spinShow = false
          });
        }
      });
    },
    getTemplateList() {
      templateList({type: this.type, limit: 0}).then((res) => {
        this.templateList = res.data.list || [];
        this.form.templateId = this.templateList.length >= 1 ? this.templateList[0].id : '';
      })
    },
  }
}
</script>

<style lang="less" scoped>
</style>
