<template>
  <div>
    <div v-if="mid">
      <ys-cell-group :gutter="gutter ? 2 : 3">
        <ys-cell title="父级行政区" :label="detail.fullName"></ys-cell>
        <ys-cell title="行政区名" :label="detail.iname"></ys-cell>
        <ys-cell title="区号" :label="detail.quhao|getBaseDataName('项目类型')"></ys-cell>
        <ys-cell title="级别" :label="detail.level === 1 ? '省级' : (detail.level === 2 ? '市级' : '县级')"></ys-cell>
        <ys-cell title="地区code" :label="detail.code"></ys-cell>
        <ys-cell title="系统区域" :label="detail.buildIn|getBaseDataName('是否')"></ys-cell>
      </ys-cell-group>
    </div>
    <ys-empty title="请先从左侧选择一项菜单！" v-else></ys-empty>
  </div>
</template>

<script>
import {areaInfo} from '@/api/base'

export default {
  name: "baseInfo",
  props: {
    mid: {type: [Number, String], default: 0},
    gutter: {type: Boolean, default: false}
  },
  data() {
    return {
      detail: {}
    }
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.loading = true;
      if (!this.mid) return;
      areaInfo({id: this.mid, noPending: true}).then(res => {
        if (res.code === 200) {
          this.detail = res.data;
        }
      })
    }
  },
  watch: {
    "mid"() {
      this.getDetail();
    }
  }
}
</script>

<style scoped>

</style>